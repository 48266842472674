export const SearchFormMapper = [
    // {
    //     label: 'Course Name',
    //     name: 'course_name',
    //     type: 'input',
    //     rules: [],
    //     placeholder: 'Enter Course Name',
    // },
    // {
    //     label: 'Teacher Name',
    //     name: 'teacher_name',
    //     type: 'input',
    //     rules: [],
    //     placeholder: 'Enter Teacher Name',
    // },
   
]