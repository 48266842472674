import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalTitle } from '../../components/ModalTitle/ModalTitle';
import BaseList from "../../containers/BaseList/BaseList";
import * as http from '../../http';
import { Addpage } from './addPage';
import { columnMapper } from './columnMapper';
import { SearchFormMapper } from './SearchFormMapper';
import ListConfig from '../../containers/BaseList/DataTable/ListConfig';
import PageConfig from '../../containers/Layout/PageConfig';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

const copy = <FontAwesomeIcon icon={faCopy}/>
const psConfig = ListConfig();
psConfig.columnMap = columnMapper;
psConfig.dataUrl = '/api/power_supply/';
psConfig.multiselect = false;


const pageConfig = PageConfig();
pageConfig.pageTitle = 'Power Source List';


class PsListPage extends BaseList {
constructor(props){
  super(props);
}

  getColumns(){
    const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
    const deleteClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';
    return [
      ...columnMapper,
      {
        title: 'Action',
        key: 'action',
        width: 'auto',
        render: (text, record) => (
          <Space size="middle">
            <Button 
              key='editButton' 
              className={themeClass}
              icon={<EditOutlined />} 
              onClick={()=>this.showModal(`/api/power_supply/${record.id}`)}>
            </Button>
            <Button 
              key='duplicateButton'
              className={themeClass}
              icon={copy}
              style={{marginLeft:"-15px"}}
              onClick={()=>this.showModal(`/api/power_supply/${record.id}`, "duplicate")} 
            />
            <Popconfirm okType='default' title="Are sure to Delete?" okText="Yes" onConfirm={() => this.handleDelete(record)}>
                <Button 
                  key='deleteButton'
                  className={deleteClass}
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
          </Space>
        )
      }
    ];
  }

  getDataUrl(){
    return '/api/power_supply/'; 
  }

  getPageConfig(){
    return {
      pageTitle:'Power Source List',
      pageButtons: [
        <Button
          key='add'
          className={'create_btn'}
          onClick={()=>this.showModal()}
          type="primary"
          size="small"
          icon={<PlusOutlined />}
        >
          ADD NEW
        </ Button>
      ],
    }
  }
  handleDelete(record){
    axios({
      url: `/api/power_supply/${record.id}`,
      method: 'delete',
      type: 'json',
      }).then(res => {
        message.success("Deleted Successfully")
        this.fetch()
      
    }).catch((err) => {
      message.error('Delete Failed');
    });
  }

  getSearchFields(){
    return SearchFormMapper;
  }

  onAddSaveClick=(values)=>{
    console.log(values)
    http.post('/api/power_supply', values.PowerSupply)
      .then((response)=> {
      if(response.status == 201){
        this.setState({
          isModalVisible: false,
          
        })
      }
      message.success('Power Source Created Successfully');
      this.fetch();

    }).catch((err)=>{
      message.error('Power Source Creation Failed');
      this.errorCallback(err.response);
    })
  }
  onUpdateClick=(values)=>{
    http.put(`/api/power_supply/${values.PowerSupply.id}`, values.PowerSupply)
      .then((response)=> {
      if(response.status == 200){
        this.setState({
          isModalVisible: false,
        })
      }
      message.success('Power Source Updated Successfully');
      this.fetch();
    }).catch((err)=>{
      message.error('Power Source Update Failed');
      this.errorCallback(err.response);
    })
  }

  onFormFinish=(values)=>{
    if(this.state.duplicate){
      this.onAddSaveClick(values);
    }
    else if(this.state.editUrl){
      this.onUpdateClick(values);
    }
    else{
      this.onAddSaveClick(values);
    }
  }
  
  render(){
    const colorClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'customantmodal' : 'notfound';

    return (
      <>
      {super.render()}
      <Modal
        title={<ModalTitle title='Add Power Source' />}
        // title={<ModalTitle title={this.state.editUrl? 'Update Antenna' : 'Add Antenna'} />}

        visible={this.state.isModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        destroyOnClose={true}
        footer={null}
        width={1000}
        bodyStyle={{height:"500px"}}
        centered={true}
        className={colorClass}
      >
      <Addpage 
            onFinish={this.onFormFinish.bind(this)}
            data={{supply_voltage: 0, supply_current:0}}
            editUrl={this.state.editUrl} 
            duplicate={this.state.duplicate} 
            errors={this.state.errors}
            />
      </Modal>
      </>
    );

  }


}
const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(withRouter(PsListPage));
