import { Button, Col, Row, Steps } from "antd";
import React, { useState } from "react";
import classes from './StepPanel.module.css';

const StepPanel = (props) => {
    const classStepContent = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitestepsContent : classes.stepsContent ;
    const classPageTitle = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitepageTitle : classes.pageTitle ;
    const classContent = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitecontent : classes.content ;
    const classActionBar = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteactionBar : classes.actionBar ;

    const [activeStep, setActiveStep] = useState(0);

    function next() {
        const nextStep = activeStep + 1;
        setActiveStep(nextStep);
    }

    function prev() {
        const prevStep = activeStep - 1;
        setActiveStep(prevStep);
    }

    const buildCustomActionButtons=()=>{
        if(props.customButton && activeStep === props.customButton.step){
            return (
                <Button
                  className={classes.actionBar1}
                  type="primary"
                  onClick={() => props.customButton.onClick()}>
                    {props.customButton.title}
                </Button>
            )
        }
    };
    return (
        <>
            <div style={{height: '100%'}}>
                {props.direction == 'vertical' ?
                    <Row style={{height: '100%'}}>
                        <Col span={4} className={'step_title'}>
                            <Steps className={classes.steps_main} current={activeStep}  direction={props.direction}>
                                {props.steps.map((item) => (
                                    <Steps.Step key={item.title} title={<p>{item.title}</p>} />
                                ))}
                            </Steps>
                        </Col>
                        <Col span={20} className={classStepContent}>
                            <div className={classes.pageHeader}>
                                <p className={classPageTitle}>{props.steps[activeStep].title}</p>
                            </div>
                            {props.steps.map((item) => (
                                <div key={item.step} className={`${classContent} ${item.step !== activeStep + 1? classes.hidden: ''}`}>
                                    {item.content}
                                </div>
                            ))}
                            <div className={classActionBar}>
                                {buildCustomActionButtons()}
                                {activeStep > 0 && (
                                    <Button className={classes.actionBar1} onClick={() => prev()}>
                                        Previous
                                    </Button>
                                )}
                                {activeStep < props.steps.length - 1 && (
                                    <Button className={classes.actionBar1} type="primary" onClick={() => next()}>
                                        Next
                                    </Button>
                                )}
                                {activeStep === props.steps.length - 1 && (
                                    <Button className={classes.actionBar1} type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                )}
                                
                            </div>
                        </Col>
                    </Row>:
                    <Col>
                        <Row span={4}>
                            <Steps className={classes.steps_main} current={activeStep}  direction={props.direction}>
                                {props.steps?.map((item) => (
                                    <Steps.Step key={item.title} title={<p>{item.title}</p>} />
                                ))}
                            </Steps>
                        </Row>
                        <Row span={20} className={[classStepContent, 'stepscontent'].join(' ')}>
                            <div className={[classes.pageHeader,'form_title_div'].join(' ')}>
                                <p className={[classPageTitle, 'form_title'].join(' ')}>{props.steps[activeStep].title}</p>
                            </div>

                            {props.steps.map((item) => (
                                <div key={item.step} className={[`${classContent} ${item.step !== activeStep + 1? classes.hidden: ''}`, 'stepper_content'].join(' ')}>
                                    {item.content}
                                </div>
                            ))}
                            <div className={classActionBar}>
                              {buildCustomActionButtons()}
                                {activeStep > 0 && (
                                    <Button className={classes.actionBar1} onClick={() => prev()}>
                                        Previous
                                    </Button>
                                )}
                                {activeStep < props.steps.length - 1 && (
                                    <Button className={classes.actionBar1} type="primary" onClick={() => next()}>
                                        Next
                                    </Button>
                                )}
                                
                                {activeStep === props.steps.length - 1 && (
                                    <Button className={classes.actionBar1} htmlType="submit">
                                        Submit
                                    </Button>
                                )}
                                
                            </div>
                        </Row>
                    </Col>}
            </div>
        </>
    );
};

export { StepPanel };
