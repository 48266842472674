import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Row, Slider } from 'antd';
import React, { useEffect } from 'react';
import '../../App.css';
import { InputField } from '../../components/Fields/InputField';
import { SelectField } from '../../components/Fields/SelectField';
import * as http from '../../http';
import classes from './addAntennaPage.module.css';

const monthOptions = [
    { label: 'January', value: "01" },
    { label: 'February', value: "02" },
    { label: 'March', value: "03" },
    { label: 'April', value: "04" },
]

export const Addpage = (props) => {
   
    const [antenna_length, setAntennaLength] = React.useState(0);
    const [antenna_gain, setAntennaGain] = React.useState(0);
    const [vswr1, setVswr1] = React.useState(0);
    const [vswr2, setVswr2] = React.useState(0);
    const [WindRating, setWindRating] = React.useState(0);
    const [AntennaDiameter, setAntennaDiameter] = React.useState(0);
    const [Impedence, setImpedence] = React.useState(0);
    const [Efficiency, setEfficiency] = React.useState(0);
    const [PowerRating, setPowerRating] = React.useState(0);
    const [frequency, setFrequnecy] = React.useState(null);
    const [manufacturer, setManufacturer] = React.useState(null);
    const [antennaType, setAntennaType] = React.useState(null);
    const [antennaCategory, setAntennaCategory] = React.useState(null);
    const [stationType, setStationType] = React.useState(null);
    const [radiationPattern, setRadiationPattern] = React.useState(null);
    const [polarization, setPolarization] = React.useState(null);
    const [nvis, setNvis] = React.useState(0);
    const [installation_type, setInstalltionType] = React.useState([]);
    const [min_freq, setMinFrequnecy] = React.useState(30);
    const [max_freq, setMaxFrequnecy] = React.useState(80);
    const [bandwidth, setBandwidth] = React.useState(30);
    const [isLoading, setLoading] = React.useState(true);


    const [form] = Form.useForm();
    const installationTypeOptions = [
        { label: 'V', value: '0' },
        { label: 'H', value: '1' },
        { label: 'I-V', value: '2' },
        { label: 'I-L', value: '3' },
        { label: 'I-U', value: '4' },
        { label: 'S', value: '5' },
        { label: 'S-V', value: '6' },
        { label: 'S-L', value: '7' },
      ];
    useEffect(() => {
        if (props.editUrl) {
            http.get(`${props.editUrl}`, {
                method: 'get',
                type: 'json',
            }).then(data => {

                setVswr1(data.data.vswr_min)//set field value from get data
                setVswr2(data.data.vswr_max)
                setAntennaLength(data.data.antenna_length)
                setAntennaDiameter(data.data.antenna_diameter)
                setAntennaGain(data.data.antenna_gain)
                setImpedence(data.data.impedence)
                setWindRating(data.data.wind_rating)
                setPowerRating(data.data.power_rating)
                setEfficiency(data.data.efficiency)

                form.setFieldsValue({ 'model': data.data.model });
                form.setFieldsValue({ 'frequency': data.data.frequency });
                form.setFieldsValue({ 'antennaType': data.data.type });
                form.setFieldsValue({ 'station_type': data.data.station_type });
                form.setFieldsValue({ 'radiationPattern': data.data.radiation_pattern });
                form.setFieldsValue({ 'min_freq': data.data.min_freq });
                form.setFieldsValue({ 'max_freq': data.data.max_freq });
                form.setFieldsValue({ 'antenna_length': data.data.antenna_length });
                form.setFieldsValue({ 'antenna_gain': data.data.antenna_gain });
                form.setFieldsValue({ 'vswr1': data.data.vswr_min });
                form.setFieldsValue({ 'vswr2': data.data.vswr_max });
                form.setFieldsValue({ 'polarization': data.data.polarization });
                form.setFieldsValue({ 'WindRating': data.data.wind_rating });
                form.setFieldsValue({ 'antenna_name': data.data.name });
                form.setFieldsValue({ 'manufacturer': data.data.manufacturer_id });
                form.setFieldsValue({ 'antennaCategory': data.data.category_id });
                form.setFieldsValue({ 'bandwidth': data.data.bandwidth });
                form.setFieldsValue({ 'AntennaDiameter': data.data.antenna_diameter });
                form.setFieldsValue({ 'Impedence': data.data.impedence });
                form.setFieldsValue({ 'Efficiency': data.data.efficiency });
                form.setFieldsValue({ 'PowerRating': data.data.power_rating });
                form.setFieldsValue({ 'id': data.data.id });
                setFrequnecy({ value: data.data.frequency, label: data.data.frequency_text });
                setManufacturer({ value: data.data.manufacturer_id, label: data.data.manufacturer_name });
                setAntennaType({ value: data.data.type, label: data.data.type_text });
                setAntennaCategory({ value: data.data.category_id, label: data.data.category_text });
                setStationType({ value: data.data.station_type, label: data.data.station_type_text });
                setRadiationPattern({ value: data.data.radiation_pattern, label: data.data.radiation_pattern_text });
                setPolarization({ value: data.data.polarization, label: data.data.polrization_text });
                setInstalltionType(data.data.installation_type);
                setNvis(data.data.NVIS == 1 ?true:false);
                setLoading(false);
            });
        }
    }, []);

    const onFinish = (values) => {
        console.log(frequency);
        let postData = {'Antenna': {}}
        postData['Antenna']['id'] = values.id;
        postData['Antenna']['name'] = values.antenna_name;
        postData['Antenna']['model'] = values.model;
        postData['Antenna']['manufacturer_id'] = manufacturer?.value;
        postData['Antenna']['category_id'] = antennaCategory?.value;
        postData['Antenna']['frequency'] = frequency?.value;
        postData['Antenna']['type'] = antennaType?.value;
        postData['Antenna']['installation_type'] = installation_type;
        postData['Antenna']['NVIS'] = nvis;
        postData['Antenna']['radiation_pattern'] = radiationPattern?.value;
        postData['Antenna']['station_type'] = stationType?.value;
        postData['Antenna']['max_freq'] = max_freq;
        postData['Antenna']['min_freq'] = min_freq;
        postData['Antenna']['bandwidth'] = bandwidth;
        postData['Antenna']['antenna_length'] = values.antenna_length;
        postData['Antenna']['antenna_diameter'] = values.AntennaDiameter;
        postData['Antenna']['antenna_gain'] = values.antenna_gain;
        postData['Antenna']['impedence'] = values.Impedence;
        postData['Antenna']['vswr_max'] = values.vswr1;
        postData['Antenna']['vswr_min'] = values.vswr2;
        postData['Antenna']['efficiency'] = values.Efficiency;
        postData['Antenna']['polarization'] = polarization?.value;
        postData['Antenna']['power_rating'] = values.PowerRating;
        postData['Antenna']['wind_rating'] = values.WindRating;
        // console.log(postData);
        return props.onFinish(postData);
    };
   

    const handledIcrementAntennaLength = () => {
        setAntennaLength(
            (antenna_length) => {
                form.setFieldsValue({ 'antenna_length': antenna_length + 1 })
                return antenna_length + 1
            })
    }

    const handledDeccrementAntennaLength = () => {
        setAntennaLength(
            (antenna_length) => {
                form.setFieldsValue({ 'antenna_length': antenna_length - 1 })
                return antenna_length - 1
            })
    }

    const handledIcrementGain = () => {
        setAntennaGain(
            (antenna_gain) => {
                form.setFieldsValue({ 'antenna_gain': antenna_gain + 1 })
                return antenna_gain + 1
            })
    }
    const handledDecrementGain = () => {
        setAntennaGain(
            (antenna_gain) => {
                form.setFieldsValue({ 'antenna_gain': antenna_gain - 1 })
                return antenna_gain - 1
            })
    }
    const handledIcrementVSWR1 = () => {
        setVswr1(
            (vswr1) => {
                form.setFieldsValue({ 'vswr1': vswr1 + 1 })
                return vswr1 + 1
            })
    }
    const handledDecrementVSWR1 = () => {
        setVswr1(
            (vswr1) => {
                form.setFieldsValue({ 'vswr1': vswr1 - 1 })
                return vswr1 - 1
            })
    }
    const handledIncrementVSWR2 = () => {
        setVswr2(
            (vswr2) => {
                form.setFieldsValue({ 'vswr2': vswr2 + 1 })
                return vswr2 + 1
            })
    }
    const handledDecrementVSWR2 = () => {
        setVswr2(
            (vswr2) => {
                form.setFieldsValue({ 'vswr2': vswr2 - 1 })
                return vswr2 - 1
            })
    }
    const handledIncrementWindRatiing = () => {
        setWindRating(
            (WindRating) => {
                form.setFieldsValue({ 'WindRating': WindRating + 1 })
                return WindRating + 1
            })
    }
    const handledDecrementWindRating = () => {
        setWindRating(
            (WindRating) => {
                form.setFieldsValue({ 'WindRating': WindRating - 1 })
                return WindRating - 1
            })
    }
    const handledIncrementAntennaDiameter = () => {
        setAntennaDiameter(
            (AntennaDiameter) => {
                form.setFieldsValue({ 'AntennaDiameter': AntennaDiameter + 1 })
                return AntennaDiameter + 1
            })
    }
    const handledDecrementAntennaDiameter = () => {
        setAntennaDiameter(
            (AntennaDiameter) => {
                form.setFieldsValue({ 'AntennaDiameter': AntennaDiameter - 1 })
                return AntennaDiameter - 1
            })
    }
    const handledIncrementImpedence = () => {
        setImpedence(
            (Impedence) => {
                form.setFieldsValue({ 'Impedence': Impedence + 1 })
                return Impedence + 1
            })
    }
    const handledDecrementImpedence = () => {
        setImpedence(
            (Impedence) => {
                form.setFieldsValue({ 'Impedence': Impedence - 1 })
                return Impedence - 1
            })
    }
    const handledIncrementEfficiency = () => {
        setEfficiency(
            (Efficiency) => {
                form.setFieldsValue({ 'Efficiency': Efficiency + 1 })
                return Efficiency + 1
            })
    }
    const handledDecrementEfficiency = () => {
            setEfficiency(
                (Efficiency) => {
                    form.setFieldsValue({ 'Efficiency': Efficiency - 1 })
                    return Efficiency - 1
                })
    }
    const handledIncrementPowerRating = () => {
            setPowerRating(
                (PowerRating) => {
                    form.setFieldsValue({ 'PowerRating': PowerRating + 1 })
                    return PowerRating + 1
                })
    }
    const handledDecrementPowerRating = () => {
            setPowerRating(
                (PowerRating) => {
                    form.setFieldsValue({ 'PowerRating': PowerRating - 1 })
                    return PowerRating - 1
                })
    }
    const handleFrequency = (value)=>{
            // setFrequnecy(value.value);
            // console.log(value);
            setFrequnecy({ value: value?.value, label: value?.label });

    }
    const handleManufacturer= (value)=>{
        // setManufacturer(value.value);
        setManufacturer({ value: value?.value, label: value?.label });

    }
    const handleAntennaType = (value)=>{
        // setAntennaType(value.value);
        setAntennaType({ value: value?.value, label: value?.label });

        
    }
    const handleAntennaCategory = (value)=>{
        // setAntennaCategory(value.value);
        setAntennaCategory({ value: value?.value, label: value?.label });

    }
    const handleStationType = (value)=>{
        // setStationType(value.value);
        setStationType({ value: value?.value, label: value?.label });

    }
    const handleRadiationPattern = (value)=>{
        // setRadiationPattern(value.value);
        setRadiationPattern({ value: value?.value, label: value?.label });

    }
    const handlePolarization = (value)=>{
        // setPolarization(value.value);
        setPolarization({ value: value?.value, label: value?.label });

    }
    const handleNvis = (value)=>{
        
        if((value.target.checked) == true)
        setNvis(1);
    }
    const handleInstallation = (checked_values)=> {
        console.log(checked_values)
        setInstalltionType(checked_values);
    }
    const handleMinFreq = (value) => {
        setMinFrequnecy(value)
    }
    const handleMaxFreq = (value) => {
        setMaxFrequnecy(value)
    }
    const handleBandwidth = (value) => {
        setBandwidth(value)
    }
    const newForm = ()=>{
        const IrDrClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteMode_Ir_Dr_Input : classes.Ir_Dr_Input ;

        return(
        <div>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11}>
                    <Form.Item
                    name="antenna_name"
                    label={<p className={'formLabel'}>Antenna Name</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.name?.validateStatus}
                    help={props.errors?.name?.errorMsg || null}
                    required={true}
                    >
                        <InputField />
                    </Form.Item>
                    <Form.Item name="id" hidden><InputField /></Form.Item>

                </Col>
                <Col span={11}>
                    <Form.Item
                    name="model"
                    label={<p className={'formLabel'}>Antenna Model</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.model?.validateStatus}
                    help ={props.errors?.model?.errorMsg || null}
                    required = {true}
                    >
                        <InputField />
                    </Form.Item>
                </Col>
            </Row>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11}>
                    <Form.Item
                    name="frequency"
                    label={<p className={'formLabel'}>Frequency</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.frequency?.validateStatus}
                    help={props.errors?.frequency?.validateStatus}
                    required = {true}
                    >
                        <SelectField
                        value={frequency}
                        url="/api/combo/frequency_combo"
                        onSelectChange={handleFrequency}
                        /> </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                    name="manufacturer"
                    label={<p className={'formLabel'}>Manufacturer</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.manufacturer_id?.validateStatus}
                    help={props.errors?.manufacturer_id?.validateStatus}
                    required = {true}
                    >
                        <SelectField
                        value={manufacturer}
                        url="/api/manufacturer/manufacturer_combo"
                        onSelectChange={handleManufacturer}/> </Form.Item>
                </Col>
            </Row>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11}>
                    <Form.Item
                    name="antennaType"
                    label={<p className={'formLabel'}>Antenna Type</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.type?.validateStatus}
                    help={props.errors?.type?.validateStatus}
                    required = {true}
                    >
                        <SelectField
                        value={antennaType}
                        url="/api/combo/antenna/antenna_type_combo"
                        onSelectChange={handleAntennaType}/> </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                    name="antennaCategory"
                    label={<p className={'formLabel'}>Antenna Category</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.category_id?.validateStatus}
                    help={props.errors?.category_id?.validateStatus}
                    required = {true}
                    >
                        <SelectField
                        value={antennaCategory}
                        url="/api/combo/antenna/antenna_category_combo"
                        onSelectChange={handleAntennaCategory}/> </Form.Item>
                </Col>
            </Row>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11}>
                    <Form.Item
                    name="nvis"
                    label={<p className={'formLabel'}>NVIS</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.NVIS?.validateStatus}
                    help={props.errors?.NVIS?.validateStatus}
                    required = {false}
                    >
                        <Checkbox
                        style={{ color: "white" }}
                        checked={nvis}
                        className={'custom-check-box'}
                        onChange={(e)=>handleNvis(e)} /> </Form.Item>
                </Col>
                <Col span={11} className={IrDrClass}>
                    {/* <Form.Item
                    name="installation"
                    label={<p className={'formLabel'}>Installation Type</p>}
                    labelCol={{span: 24}}
                    > */}
                    <p  className={['formLabel', 'custom-form-level'].join(' ')}>Installation Type</p>
                        <Checkbox.Group
                        
                        onChange={handleInstallation}
                        value={installation_type}
                        options={installationTypeOptions}
                        validateStatus={props.errors?.installation_type?.validateStatus}
                        help={props.errors?.installation_type?.validateStatus}
                        required = {true}
                        />
                    {/* </Form.Item> */}
                </Col> 
            </Row>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11}>
                    <Form.Item
                    name="stationType"
                    label={<p className={'formLabel'}>Station Type</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.station_type?.validateStatus}
                    help={props.errors?.station_type?.validateStatus}
                    required = {true}
                    >
                        <SelectField
                        value={stationType}
                        url="/api/combo/station_type_combo"
                        onSelectChange={handleStationType}/> </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                    name="radiationPattern"
                    label={<p className={'formLabel'}>Radioation Pattern</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.radiation_pattern?.validateStatus}
                    help={props.errors?.radiation_pattern?.validateStatus}
                    required = {true}
                    >
                        <SelectField
                        value={radiationPattern}
                        url="/api/combo/antenna/radition_pattern_combo"
                        onSelectChange ={handleRadiationPattern}/> </Form.Item>
                </Col>
            </Row>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11}>
                    <Form.Item
                    name="min_freq"
                    label={<p className={'formLabel'}>Min. Frequency (MHz)</p>}
                    labelCol={{span: 24}}
                    > 
                        <Slider 
                        className={'frequency_handler'} 
                        min={0}
                        max={3000}
                        defaultValue={20} 
                        onChange={handleMinFreq}/>
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                    name="max_freq"
                    label={<p className={'formLabel'}>Max. Frequency (MHz)</p>}
                    labelCol={{span: 24}}
                    
                    >
                        <Slider 
                        className={'frequency_handler'}
                        min={0}
                        max={3000}  
                        defaultValue={80} 
                        onChange={handleMaxFreq} 
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11}>
                    <Form.Item
                    name="polarization"
                    label={<p className={'formLabel'}>Polarization</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.polarization?.validateStatus}
                    help={props.errors?.polarization?.validateStatus}
                    required = {false}
                    >
                        <SelectField
                        value={polarization}
                        url="/api/combo/antenna/polarization_combo"
                        onSelectChange={handlePolarization} /> </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item
                    name="bandwidth"
                    label={<p className={'formLabel'}>Bandwidth(MHz)</p>}
                    labelCol={{span: 24}}
                    >
                        <Slider 
                        className={'frequency_handler_1'} 
                        min={0}
                        max={3000}
                        defaultValue={30} 
                        onChange={handleBandwidth}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11}>
                    <p className={['formLabel', 'custom-form-level'].join(' ')}>VSWR Min.</p>
                    <div style={{display: 'flex'}} className={[IrDrClass, 'd-inline-flex p-2'].join(' ')}>
                    <Button onClick={() => handledDecrementVSWR1()}>-</Button>
                    <Form.Item
                    name='vswr1'
                    // label={<p className={'formLabel'}>VSWR Min.</p>}
                    // labelCol={{span: 24}}
                    >
                        <InputField />
                    </Form.Item>
                    <Button onClick={() => handledIcrementVSWR1()}>+</Button>
                    </div>
                </Col>
                <Col span={11}>
                    <p className={['formLabel', 'custom-form-level'].join(' ')}>VSWR Max.</p>
                    <div style={{display: 'flex'}} className={[IrDrClass, 'd-inline-flex p-2'].join(' ')}>
                    <Button onClick={() => handledDecrementVSWR2()}>-</Button>
                    <Form.Item
                    name='vswr2'
                    // label={<p className={'formLabel'}>VSWR Max.</p>}
                    // labelCol={{span: 24}}
                    >
                        <InputField />
                    </Form.Item>
                    <Button onClick={() => handledIncrementVSWR2()}>+</Button>
                    </div>
                </Col>
            </Row>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11}>
                    <p className={['formLabel', 'custom-form-level'].join(' ')}>Antenna Length (m)</p>
                    <div style={{display: 'flex'}} className={[IrDrClass, 'd-inline-flex p-2'].join(' ')}>
                    <Button onClick={() => handledDeccrementAntennaLength()}>-</Button>
                    <Form.Item
                    name='antenna_length'
                    // label={<p className={'formLabel'}>Antenna Length</p>}
                    // labelCol={{span: 24}}
                    >
                        <InputField />
                    </Form.Item>
                    <Button onClick={() => handledIcrementAntennaLength()}>+</Button>
                    </div>
                </Col>
                <Col span={11}>
                    <p className={['formLabel', 'custom-form-level'].join(' ')}>Antenna Diameter (m)</p>
                    <div style={{display: 'flex'}} className={[IrDrClass, 'd-inline-flex p-2'].join(' ')}>
                    <Button onClick={() => handledDecrementAntennaDiameter()}>-</Button>
                    <Form.Item
                    name='AntennaDiameter'
                    // label={<p className={'formLabel'}>Antenna Diameter</p>}
                    // labelCol={{span: 24}}
                    >
                        <InputField />
                    </Form.Item>
                    <Button onClick={() => handledIncrementAntennaDiameter()}>+</Button>
                    </div>
                </Col>
            </Row>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11} >
                    <p className={['formLabel', 'custom-form-level'].join(' ')}>Antenna Gain (dB)</p>
                    <div style={{display: 'flex'}} className={[IrDrClass, 'd-inline-flex p-2'].join(' ')}>
                    <Button onClick={() => handledDecrementGain()}>-</Button>
                    <Form.Item
                    name='antenna_gain'
                    // label={<p className={'formLabel'}>Antenna Gain</p>}
                    // labelCol={{span: 24}}
                    >
                        <InputField />
                    </Form.Item>
                    <Button onClick={() => handledIcrementGain()}>+</Button>
                    </div>
                </Col>
                <Col span={11}>
                    <p className={['formLabel', 'custom-form-level'].join(' ')}>Impedence (Ohms)</p>
                    <div style={{display: 'flex'}} className={[IrDrClass, 'd-inline-flex p-2'].join(' ')}>
                    <Button onClick={() => handledDecrementImpedence()}>-</Button>
                    <Form.Item
                    name='Impedence'
                    // label={<p className={'formLabel'}>Impedence</p>}
                    // labelCol={{span: 24}}
                    >
                        <InputField />
                    </Form.Item>
                    <Button onClick={() => handledIncrementImpedence()}>+</Button>
                    </div>
                </Col>
            </Row>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11}>
                    <p className={['formLabel', 'custom-form-level'].join(' ')}>Wind Rating (Km/h)</p>
                    <div style={{display: 'flex'}} className={[IrDrClass, 'd-inline-flex p-2'].join(' ')}>
                    <Button onClick={() => handledDecrementWindRating()}>-</Button>
                    <Form.Item
                    name='WindRating'
                    // label={<p className={'formLabel'}>Wind Rating</p>}
                    // labelCol={{span: 24}}
                    >
                        <InputField />
                    </Form.Item>
                    <Button onClick={() => handledIncrementWindRatiing()}>+</Button>
                    </div>
                </Col>
                <Col span={11}>
                    <p className={['formLabel', 'custom-form-level'].join(' ')}>Power Rating (Watt)</p>
                    <div style={{display: 'flex'}} className={[IrDrClass, 'd-inline-flex p-2'].join(' ')}>
                    <Button onClick={() => handledDecrementPowerRating()}>-</Button>
                    <Form.Item
                    name='PowerRating'
                    // label={<p className={'formLabel'}>Power Rating</p>}
                    // labelCol={{span: 12}}
                    >
                        <InputField />
                    </Form.Item>
                    <Button onClick={() => handledIncrementPowerRating()}>+</Button>
                    </div>
                </Col>
            </Row>
            <Row className={'justify-content-space-between margin-y-5'} glutter={[16, 16]}>
                <Col span={11} >
                    <p className={['formLabel', 'custom-form-level'].join(' ')}>Efficiency</p>
                    <div style={{display: 'flex'}} className={[IrDrClass, 'd-inline-flex p-2'].join(' ')}>
                    <Button onClick={() => handledDecrementEfficiency()}>-</Button>
                    <Form.Item
                    name='Efficiency'
                    // label={<p className={'formLabel'}>Efficiency</p>}
                    // labelCol={{span: 24}}
                    >
                        <InputField placeholder="Efficiency" />
                    </Form.Item>
                    <Button onClick={() => handledIncrementEfficiency()}>+</Button>
                    </div>
                </Col>
            </Row>
            
        </div>
        )
    }
    if(props.editUrl && isLoading)
    {
        return <LoadingOutlined></LoadingOutlined>
    }
    else{
    return (
            <Form className={'antenna_form'} style={{ color: "white" }} onFinish={onFinish} form={form} initialValues={props.data}>
                {newForm()}
                <Row className={'button_row'}>
                      <Button htmlType='submit'>ADD ANTENNA</Button>
                </Row>

            </Form>


        )
        }
    }
