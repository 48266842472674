// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MapConfig_Form__cYCHd {\n  padding: 15px 15px 15px 15px;\n}\n.MapConfig_formLabel__xOcWP {\n  color: #4a6e83;\n}\n.MapConfig_step_panel__g7hh8 {\n  color: #087b95;\n}\n.MapConfig_formmain__YVvfH {\n  background: none;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 10px;\n}\n.MapConfig_numWidth__2aOSb {\n  width: 200px;\n}\n.MapConfig_content__u0BTI {\n  padding-bottom: 10%;\n}\n.MapConfig_footer__TFXI4 {\n  background: #012032;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Training/MapConfig.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,YAAY;AACd;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".Form {\n  padding: 15px 15px 15px 15px;\n}\n.formLabel {\n  color: #4a6e83;\n}\n.step_panel {\n  color: #087b95;\n}\n.formmain {\n  background: none;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 10px;\n}\n.numWidth {\n  width: 200px;\n}\n.content {\n  padding-bottom: 10%;\n}\n.footer {\n  background: #012032;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": "MapConfig_Form__cYCHd",
	"formLabel": "MapConfig_formLabel__xOcWP",
	"step_panel": "MapConfig_step_panel__g7hh8",
	"formmain": "MapConfig_formmain__YVvfH",
	"numWidth": "MapConfig_numWidth__2aOSb",
	"content": "MapConfig_content__u0BTI",
	"footer": "MapConfig_footer__TFXI4"
};
export default ___CSS_LOADER_EXPORT___;
