export const columnMapper = [
    {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        width: '2%',
        hidden: true
      },
      {
        title: 'Manufacturer Name',
        dataIndex: 'name',
        editable: true,
       
        width: '48%',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        editable: true,
        width: '48%',
      },
      
    ].filter(item => !item.hidden);