import { Button, Form, Row } from 'antd';
import React from 'react';
import { buildFormItem as BuildFormItem } from '../../utils';
import classes from './AddPage.module.css';

export const UserPassChange = (props) => {
    const [partyId, setPartyId] = React.useState(null);
    const [form] = Form.useForm();
    const [ConfigData, setConfigData] = React.useState({

      });

    const onFinish=(values)=>{
        console.log(ConfigData)
        let PostData = {...values, ...ConfigData}

        return props.onFinish(PostData);
    }
  
    const setPostData=(field, type, label, value)=>{
        let newData = {};
        if(type == 'select'){
          if(value){
          if(Array.isArray(value)){
            let valueList = [];
            for(let i=0; i<value.length; i++){
              valueList.push(value[i].value);
            }
            newData[field] = valueList;
            newData[`${field}_combo`] = value;
          }
          else{
            newData[field] = value.value;
            newData[`${field}_combo`] = value;
          }
          }
          else{
          newData[field] = null;
          newData[`${field}_combo`] = null;
          }
        }
        else if(type == 'input-button'){
          if(value == 'plus'){
          const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
          const updatedValue = {};
          updatedValue[field] = newValue;
          stepForm.setFieldsValue(updatedValue)
          }
          else{
          const newValue = parseInt(stepForm.getFieldsValue()[field]|| 0) - 1;
          const updatedValue = {};
          updatedValue[field] = newValue;
          stepForm.setFieldsValue(updatedValue)
          }
        }
        else if(type == 'checkbox')
        {
          newData[field] = value;
        }
  
        setConfigData((prevVal)=>{
          return {...prevVal, ...newData}
        })
    }
    return (
        <Form style={{ color: "white" }} onFinish={onFinish} form={form}>
            <div className={classes.modalContent}>
            <Row>
                <BuildFormItem
                    name='id'
                    type='input'
                    formConfig={{
                        hidden : true
                    }}/>
                <BuildFormItem
                        name='new_password'
                        label='New Password'
                        type='input'
                        fieldConfig={{
                            type: "password"
                        }}
                        formConfig={{
                            required: true
                        }}
                />
                <BuildFormItem
                        name='re_password'
                        label='Re-enter Password'
                        type='input'
                        fieldConfig={{
                            type: "password"
                        }}
                        formConfig={{
                            required: true
                        }}
                />
            </Row>
            <Button type="primary" htmlType="submit">
                    {props.editUrl? 'Update':'Add'}
            </Button>
            </div>
        </Form>
    )
}