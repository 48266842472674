export const wrd_fibercore_column_mapper = [
  {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      // width: '2%',
      // hidden: true
    },
    {
      title: 'Core name',
      dataIndex: 'core_name',
      editable: true,
      width: '100',
      fixed: 'left'
    }, 
    {
      title: 'Core Color',
      dataIndex: 'core_color',
      editable: true,
      width: '100',
      fixed: 'left'
    }
    
  ].filter(item => !item.hidden);
