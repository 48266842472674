
import { DeleteOutlined, KeyOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Space, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalTitle } from '../../components/ModalTitle/ModalTitle';
import BaseList from "../../containers/BaseList/BaseList";
import * as http from '../../http';
import { columnMapper } from './columnMapper';
import { SearchFormMapper } from './SearchFormMapper';
import { UserAddPage } from './UserAddPage';
import { UserPassChange } from './UserPassChange';

class UserListPage extends BaseList {
  constructor(props){
    super(props);
  }

  
  
  getColumns(){
    const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
    const deleteClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';
    const user_data = JSON.parse(localStorage?.getItem('state'))?.auth?.user?.data
    if(user_data?.user_type == 0){
      return [
        ...columnMapper,
        {
          title: 'Action',
          key: 'action',
          fixed:'right',
          render: (record) => (
            <Space size="middle">
            <Button 
              key='passChangeButton'
              className={themeClass}
              icon={<KeyOutlined />}
              onClick={()=>this.showModal(`/api/users/change_password/${record.id}`)} />
              
              <Popconfirm 
              okType='default' 
              title="Are sure to Delete?" okText="Yes" onConfirm={() => this.handleDelete(record)}>
                <Button 
                    key='deleteButton'
                    className={deleteClass}
                    icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          )
        }
      ];
    }else{
      return [
        ...columnMapper,
      ]
    }
    
  }

  showModal = (url, msg) => {
    if(msg){
      this.setState(
        {
          isModalVisible: true,
          duplicate: msg,
          editUrl: url,
        }
      );
    }
    else if(url){
      this.setState(
        {
          isPassModalVisible: true,
          editUrl: url,
          duplicate: undefined

        }
      );
    }
  else{
    this.setState(
      {
        isPassModalVisible: false,
        isModalVisible: true,
        editable: false,
        editUrl: undefined
      }
    );
  }
  };

  handleDelete(record){
    // axios({
    //   url: `/api/users/${record.id}`,
    //   method: 'delete',
    //   type: 'json',
    //   }).then(res => {
    //     message.success("Deleted Successfully")
    //     this.fetch()
      
    // }).catch((err) => {
    //   message.error('Delete Failed');
    // });

    http.del(`/api/users/${record.id}`)
      .then((response)=> {
      if(response.status == 200){
        message.success('Deleted Successfully');
        this.fetch();
      }else{
        message.error('Deleted Not Successfully');
      }
    }).catch((err)=>{
      message.error('Delete Failed');
      this.errorCallback(err.response);
    })

  }

  getDataUrl(){
    return '/api/users/'; 
  }

  getPageConfig(){
    return {
      pageTitle:'User List',
      pageButtons: [
        <Button
        key='student-add'
        className={'create_btn'}
        onClick={()=>this.showModal()}
        type="primary"
        size="small"
        icon={<PlusOutlined />}>
          ADD NEW
        </ Button>
      ],
    }
  }

  getSearchFields(){
    return SearchFormMapper;
  }

  onAddSaveClick=(values)=>{
    console.log(values)
    delete values.id;
    http.post('/api/users', values)
      .then((response)=> {
      if(response.status == 201){
        this.setState({
          isModalVisible: false
        })
        message.success('User Created Successfully');
        this.fetch();
      }
    }).catch((err)=>{
      console.log(err)
      message.error('User Creation Failed');
      this.errorCallback(err.response);
    })
  }

  onPasswordSaveClick=(values)=>{
    console.log(values)
    if(values.new_password === values.re_password){
      http.post(`${this.state.editUrl}`, values)
        .then((response)=> {
        if(response.status == 201){
          this.setState({
            isPassModalVisible: false
          })
          message.success('User Password Changed Successfully');
          this.fetch();
        }
      }).catch((err)=>{
        message.error('User Password Changed Failed');
        this.errorCallback(err.response);
        
      })
    }else{
      message.error("Two password didn't match! Please re enter your password.");
    }
  }

  onPassCancel = () =>{
    this.setState({
      isPassModalVisible: false
    })
  }

  render(){
    const colorClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'customantmodal' : 'notfound';

    return (
      <>
        {super.render()}
        <Modal 
        title={<ModalTitle title='Add User'/>}
        visible={this.state.isModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        destroyOnClose={true}
        footer={null}
        centered={true}
        width={550}
        className={colorClass}
        >
          <UserAddPage 
            onFinish={this.onAddSaveClick}
            errors={this.state.errors}
          />
        </Modal>

        <Modal 
        title={<ModalTitle title='Change Password'/>}
        visible={this.state.isPassModalVisible}
        onOk={this.handleOk}
        onCancel={this.onPassCancel}
        destroyOnClose={true}
        footer={null}
        centered={true}
        width={550}
        className={colorClass}
        >
          <UserPassChange 
            onFinish={this.onPasswordSaveClick}
            errors={this.state.errors}
            editUrl={this.state.editUrl}
          />
        </Modal>

     
      </>
    );

    

  }
}
const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps)(withRouter(UserListPage));
