// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeviceConfig_Form__7HqRR {\n  padding: 15px 15px 15px 15px;\n}\n.DeviceConfig_formLabel__lyYuG {\n  color: #4a6e83;\n}\n.DeviceConfig_step_panel__EIdSt {\n  color: #087b95;\n}\n.DeviceConfig_formmain__BnJ3e {\n  background: none;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 20px;\n}\n.DeviceConfig_numWidth__oCZxR {\n  width: 200px;\n}\n.DeviceConfig_content__k8tiK {\n  padding: 5%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Training/DeviceConfig.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;AACb","sourcesContent":[".Form {\n  padding: 15px 15px 15px 15px;\n}\n.formLabel {\n  color: #4a6e83;\n}\n.step_panel {\n  color: #087b95;\n}\n.formmain {\n  background: none;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 20px;\n}\n.numWidth {\n  width: 200px;\n}\n.content {\n  padding: 5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": "DeviceConfig_Form__7HqRR",
	"formLabel": "DeviceConfig_formLabel__lyYuG",
	"step_panel": "DeviceConfig_step_panel__EIdSt",
	"formmain": "DeviceConfig_formmain__BnJ3e",
	"numWidth": "DeviceConfig_numWidth__oCZxR",
	"content": "DeviceConfig_content__k8tiK"
};
export default ___CSS_LOADER_EXPORT___;
