import { Table } from "antd";
import React from "react";

export const AssignedStudentList=(props)=>{
    const columns = [
        {
          title: 'Student',
          dataIndex: 'party_name',
          key: 'student_id',
        },
        {
          title: 'Rank',
          dataIndex: 'rank',
          key: 'rank',
        },
        {
          title: 'Department',
          dataIndex: 'department',
          key: 'department',
        },
        {
          title: 'Operation',
          dataIndex:'actionItems',
          key: 'action',
          fixed:'right',
        },
      ];
    return (
       <Table dataSource={props.data} columns={columns}/>
    )
}