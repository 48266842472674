import { Button, Col, Form, Row } from 'antd';
import React from 'react';
import { SelectField } from '../../../components/Fields/SelectField';
import { TimeField } from '../../../components/Fields/TimeField';

export const AddDayTimeSettings = (props) => {
    const [year, setYear] = React.useState(null);
    const [month, setMonth] = React.useState(null);
    const [from_time, setFromTime] = React.useState({});
    const [to_time, setToTime] = React.useState({});


    const [form] = Form.useForm();
    const onFinish = (values) => {
        let postData = { 'DayTimeSettings': [] }
        for (let i = 0; i < 5; i++) {
            let from_time_value = from_time[`${i}_fromTime`]
            console.log(from_time_value)
            let to_time_value = to_time[`${i}_toTime`]
            const data = {
                year: year.label,
                month: month.value,
                from_time: from_time_value,
                to_time: to_time_value,
                day_time_id: i,
                duration: 1
            }
            postData['DayTimeSettings'].push(data);

        }
        return props.onFinish(postData);

    };
    const handleYear = (value) => {

        setYear({ value: value.value, label: value.label });
    }
    const handleMonth = (value) => {

        setMonth({ value: value.value, label: value.label });
    }
    const handleToTime = (label, value) => {
        console.log(value)
        if (value) {
            const data = {}
            data[label] = value;
            setToTime((prevVal) => {
                return {
                    ...prevVal,
                    ...data

                }
            })

        }
    }
    const handleFromTime = (label, value) => {
        if (value) {
            console.log(value)
            const data = {}
            data[label] = value;
            setFromTime((prevVal) => {
                return {
                    ...prevVal,
                    ...data

                }
            })
        }

        // setFromTime({ value: value.value, label: value.label })
    }
    // debugger
    return (
        <Form className={'daytime_add_form'} style={{ color: "white" }} onFinish={onFinish} form={form}
            initialValues={props.data}>
            <Row className={'header_row'}>
                <div className={'header_main'}>
                    <div className={'header_main_sub'}>
                        <h4>Day Time Settings</h4>
                    </div>

                </div>
            </Row>


            <Row>
                <Col span={8} style={{marginTop:'5px', marginBottom:'5px'}}>
                    <Form.Item
                        name="year"
                        validateStatus={props.errors?.supply_type_id?.validateStatus}
                        help={props.errors?.supply_type_id?.errorMsg || null}
                        required={true}
                    >
                        <SelectField
                            placeholder="year"
                            url="api/combo/year" value={year}
                            onSelectChange={handleYear} />
                    </Form.Item>
                </Col>
                <Col className={'day_time_add_form_field'} span={9}>
                    <Form.Item name="month">
                        <SelectField
                            placeholder="month"
                            url="api/combo/month"
                            value={month}
                            onSelectChange={handleMonth}
                        />
                    </Form.Item>
                </Col>


            </Row>

            <Row>
                <Col span={8}><h4 className={'add_daytime_form_time_title'}>Day Time</h4></Col>
                <Col className={'day_time_add_form_field'} span={9} style={{ display: 'flex' }}>
                    <Form.Item
                        name='0_fromTime' 
                        style={{ marginRight: "20px" }}> 
                        <TimeField
                            onSelect={(e) => handleFromTime('0_fromTime', e)} 
                            placeholder='From Time' 
                            initialValue = {from_time["0_fromTime"]}
                            
                        /> 
                    </Form.Item>
                    <Form.Item name='0_toTime'> 
                        <TimeField
                            placeholder='To Time'
                            onSelect={(e) => handleToTime('0_toTime', e)}
                            initialValue = {to_time["0_toTime"]}
                        /> 
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={8} style={{ display: "flex" }}>
                    <h4 className={'add_daytime_form_time_title'}>Dusk Time</h4>
                </Col>
                <Col className={'day_time_add_form_field'} span={9} style={{ display: "flex" }}>
                    <Form.Item name='1_from-time' style={{ marginRight: "20px" }}> 
                        <TimeField
                            placeholder='From Time'
                            onSelect={(e) => handleFromTime('1_fromTime', e)}
                            initialValue = {from_time["1_fromTime"]} 
                        /> 
                    </Form.Item>
                    <Form.Item name='1_toTime'> 
                        <TimeField
                            placeholder='To Time'
                            onSelect={(e) => handleToTime('1_toTime', e)} 
                            initialValue = {to_time["1_toTime"]}
                        /> 
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={8}><h4 className={'add_daytime_form_time_title'}>Evening Time</h4></Col>
                <Col className={'day_time_add_form_field'} span={9} style={{ display: "flex" }}>

                    <Form.Item name='2_from-time' style={{ marginRight: "20px" }}> 
                        <TimeField
                            placeholder='From Time'
                            onSelect={(e) => handleFromTime('2_fromTime', e)} 
                            initialValue = {from_time["2_fromTime"]}
                        /> 
                    </Form.Item>
                    <Form.Item name='2_toTime'> 
                        <TimeField 
                            placeholder='To Time'
                            onSelect={(e) => handleToTime('2_toTime', e)} 
                            initialValue = {to_time["2_toTime"]}
                        /> 
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={8}><h4 className={'add_daytime_form_time_title'}>Night Time</h4></Col>
                <Col className={'day_time_add_form_field'} span={9} style={{ display: "flex" }}>
                    <Form.Item name='3_from-time' style={{ marginRight: "20px" }}> 
                        <TimeField
                            placeholder='From Time'
                            onSelect={(e) => handleFromTime('3_fromTime', e)}
                            initialValue = {from_time["3_fromTime"]} 
                        /> 
                    </Form.Item>
                    <Form.Item name='3_toTime'> 
                        <TimeField
                            placeholder='To Time'
                            onSelect={(e) => handleToTime('3_toTime', e)} 
                            initialValue = {to_time["3_toTime"]}
                        /> 
                    </Form.Item>
                </Col>

            </Row>
            <Row>
                <Col span={8}><h4 className={'add_daytime_form_time_title'}>Dawn Time</h4></Col>
                <Col className={'day_time_add_form_field'} span={9} style={{ display: "flex" }}>
                    <Form.Item name='4_from-time' style={{ marginRight: "20px" }}> 
                        <TimeField
                            placeholder='From Time'
                            onSelect={(e) => handleFromTime('4_fromTime', e)}
                            initialValue = {from_time["4_fromTime"]} 
                        /> 
                    </Form.Item>
                    <Form.Item name='4_toTime'> 
                        <TimeField
                            placeholder='To Time'
                            onSelect={(e) => handleToTime('4_toTime', e)} 
                            initialValue = {to_time["4_toTime"]}
                        /> 
                    </Form.Item>
                </Col>
            </Row>
            <Row className={'button_row'}>
                <Button htmlType='submit'>ADD Settings</Button>
            </Row>


        </Form>
    )

}