export const columnMapper = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        width: '2%',
        hidden: true
      },
      {
        title: 'Name',
        dataIndex: 'full_name',
        editable: true,
        width: '25%',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        editable: true,
        width: '25%',
      },
      {
        title: 'Batch',
        dataIndex: 'batch',
        editable: true,
        width: '25%',
      },
      {
        title: 'Rank',
        dataIndex: 'ArmyRank_text',
        editable: true,
        width: '25%',
      },
      {
        title: 'Crops',
        dataIndex: 'department_text',
        editable: true,
        width: '25%',
      },
      
    ].filter(item => !item.hidden);
