import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, message, Modal, Popconfirm, Space } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalTitle } from '../../components/ModalTitle/ModalTitle';
import BaseList from "../../containers/BaseList/BaseList";
import * as http from '../../http';
import { columnMapper } from './columnMapper';
import { AddPage } from './CourseAddPage';
import { SearchFormMapper } from './SearchFormMapper';
const copy = <FontAwesomeIcon icon={faCopy}/>

class CourseListPage extends BaseList {
  constructor(props){
    super(props)
  }
  getColumns(){
    const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
    const deleteClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';
    
    return [
      ...columnMapper,
      {
        title: 'Action',
        key: 'action',
        fixed:'right',
        render: (record) => (
          <Space size="middle">
          <Button 
            key='editButton'
            className={themeClass}
            icon={<EditOutlined />}
            onClick={()=>this.showModal(`/api/courses/${record.id}`)} 
          />
          <Button 
              key='duplicateButton'
              className={themeClass}
              icon={copy}
              style={{marginLeft:"-15px"}}
              onClick={()=>this.showModal(`/api/courses/${record.id}`, "duplicate")} 
          />
          <Popconfirm okType="default" title="Are sure to Delete?" okText="Yes" onConfirm={() => this.handleDelete(record.id)}>
                    <Button 
                      key='deleteButton'
                      className={deleteClass}
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
          </Space>
        )
      }
    ];
  }

  handleDelete(record){
    http.del(`/api/courses/${record}`).then(res => {
        message.success("Deleted Successfully")
        fetchData()
    })
  }

  getDataUrl(){
    return '/api/courses/'; 
  }

  getPageConfig(){
    return {
      pageTitle:'Course List',
      pageButtons: [
        <Button
          key='course-add'
          className={'create_btn'}
          onClick={() => this.showModal()}
          type="primary"
          size="small"
          icon={<PlusOutlined />}
        >
          ADD NEW
        </ Button>
      ],
    }
  }

  getSearchFields(){
    return SearchFormMapper;
  }

  onAddSaveClick = (values) => {
    delete values.Course.id;
    http.post('/api/courses', values.Course)
      .then((response) => {
        if (response.status == 201) {
          this.setState({
            isModalVisible: false,
            erros: {}
          })
          message.success('Course Created Successfully');
          this.fetch();
        }
      }).catch((err) => {
        message.error('Course Creation Failed');
        this.errorCallback(err.response);
      })
    console.log(values);
  }
  onUpdateClick = (values) => {
    console.log(values)
    http.put(`/api/courses/${values.Course.id}`, values.Course)
      .then((response) => {
        if (response.status == 200) {
          this.setState({
            isModalVisible: false,
            erros: {}
          })
          message.success('Course Updated Successfully');
          this.fetch();
        }
      }).catch((err) => {
        message.error('Course Update Failed');
        this.errorCallback(err.response);
      })
    console.log(values);
  }
  
  onFormFinish = (values) => {
    if (this.state.duplicate) {
      this.onAddSaveClick(values);
    }
    else if (this.state.editUrl) {
      this.onUpdateClick(values);
    }
    else {
      this.onAddSaveClick(values);
    }
  }
  
  render(){
    const colorClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'customantmodal' : 'notfound';

    return (
      <>
        {super.render()}
        <Modal
          title={<ModalTitle title='Add Course' />}
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          footer={null}
          centered={true}
          className={colorClass}
        >
          <AddPage
            onFinish={this.onFormFinish.bind(this)}
            editUrl={this.state.editUrl}
            errors={this.state.errors}
          />
        </Modal>
      </>
    );
  
  }
  


}
const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps)(withRouter(CourseListPage));
