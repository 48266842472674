import { Button, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import '../../App.css';
import { InputField } from '../../components/Fields/InputField';
import * as http from '../../http';
import { buildFormItem as BuildFormItem } from '../../utils';
import classes from './AddPage.module.css';

export const TeacherAddPage = (props) => {    
    const [form] = Form.useForm();
    const [rank, setRank] = React.useState(null);
    const [ConfigData, setConfigData] = React.useState({
        rank: null,
        type: 1,
        rank_combo: null,
        department_combo: null
    })

    useEffect(() => {
        if (props.editUrl) {
            http.get(`${props.editUrl}`, {
                method: 'get',
                type: 'json',
            }).then(data => {
                form.setFieldsValue({ 'id': data.data?.id });
                form.setFieldsValue({ 'full_name': data?.data.full_name });
                form.setFieldsValue({ 'code': data.data?.code });
                form.setFieldsValue({ 'batch': data.data?.batch });
                // form.setFieldsValue({ 'rank': data.data.rank });
                form.setFieldsValue({ 'department': data.data?.department });
                setConfigData({...ConfigData, ...{ 'rank_combo': data.data?.rank_combo,'department_combo': data.data?.department_combo }});
                setRank({ label: data.data.ArmyRank_text, value: data.data.rank })
            });
        }
    }, []);
    const onFinish = (values) => {
        const PostData = {...values, ...ConfigData}
        console.log(PostData)
        return props.onFinish(PostData);
    }
    const setPostData=(field, type, label, value)=>{
        let newData = {};
        if(type == 'select'){
          if(value){
          newData[field] = value.value;
          newData[`${field}_combo`] = value;
          }
          else{
          newData[field] = null;
          newData[`${field}_combo`] = null;
          }
        }
        else if(type == 'input-button'){
          if(value == 'plus'){
          const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
          const updatedValue = {};
          updatedValue[field] = newValue;
          stepForm.setFieldsValue(updatedValue)
          }
          else{
          const newValue = parseInt(stepForm.getFieldsValue()[field]|| 0) - 1;
          const updatedValue = {};
          updatedValue[field] = newValue;
          stepForm.setFieldsValue(updatedValue)
          }
        }
        else if(type == 'checkbox')
        {
          newData[field] = value;
        }
  
        setConfigData((prevVal)=>{
          return {...prevVal, ...newData}
        })
    }

    return (
        <Form style={{ color: "white" }} onFinish={onFinish} form={form}>
            {/* Not Found */}
            <div className={classes.modalContent}>

                <Row className={'d-none'}>
                    <Form.Item
                        name='id'
                        // Not Found
                        label={<p className={'formLabel'}>ID</p>}
                        labelCol={{ span: 24 }}
                        hidden>
                        <InputField />

                    </Form.Item>
                </Row>
                <Row>

                    <BuildFormItem 
                        name='full_name'
                        label='Name'
                        type='input'
                        errorObj={props.errors}
                        formConfig={{
                            required:true
                        }}
                    />
                </Row>
                <Row>
                    <BuildFormItem 
                        name='code'
                        label='ID No.'
                        type='input'
                        errorObj={props.errors}
                        formConfig={{
                            required:true
                        }}
                    />
                </Row>
                <Row>
                    <BuildFormItem 
                        name='batch'
                        label='Batch'
                        type='input'
                        errorObj={props.errors}
                        formConfig={{
                            required:true
                        }}
                    />
                </Row>
                <Row>
                    <BuildFormItem 
                        name='rank'
                        label='Rank'
                        type='select'
                        errorObj={props.errors}
                        fieldConfig={{
                            url: "/api/combo/army_rank",
                            onSelectChange: setPostData,
                            value: ConfigData.rank_combo
                        }}
                        formConfig={{
                            required:true
                        }}
                    />
                </Row>
                <Row>
                    <BuildFormItem 
                        name='department'
                        label='Crops'
                        type='select'
                        errorObj={props.errors}
                        fieldConfig={{
                            url: "/api/combo/crops_combo",
                            onSelectChange: setPostData,
                            value: ConfigData.department_combo
                        }}
                        formConfig={{
                            required:true
                        }}
                    />
                </Row>
            </div>
            {/* Not Found */}
            <div className={[classes.footer, 'button_row'].join(' ')}>
                <Button type="primary" htmlType="submit">
                    {props.editUrl ? 'Update' : 'Add'}
                </Button>
            </div>
        </Form>
    )
}