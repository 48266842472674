export const SearchFormMapper = [
    {
        label: '',
        name: 'name.like',
        type: 'input',
        rules: [],
        placeholder: 'Name',
    },
    {
        label: '',
        name: 'manufacturer',
        type: 'select',
        url:"/api/manufacturer/manufacturer_combo",
        rules: [],
        placeholder: 'Manufacturer Name',
    },
   
]