// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddPage_Form__SXuLx {\n  padding: 15px 15px 15px 15px;\n}\n\n.AddPage_formLabel__iy6Gz {\n  color: #4a6e83;\n}\n\n.AddPage_step_panel__gkcea\n{\n  color: #087b95;\n}\n\n.AddPage_formmain__Aja5c\n{\n  background: none;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 20px;\n}\n\n.AddPage_numWidth__qEmrM{\n  width: 200px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/WrdEquipments/Fibercores/AddPage.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,cAAc;AAChB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;;EAEE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".Form {\n  padding: 15px 15px 15px 15px;\n}\n\n.formLabel {\n  color: #4a6e83;\n}\n\n.step_panel\n{\n  color: #087b95;\n}\n\n.formmain\n{\n  background: none;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 20px;\n}\n\n.numWidth{\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": "AddPage_Form__SXuLx",
	"formLabel": "AddPage_formLabel__iy6Gz",
	"step_panel": "AddPage_step_panel__gkcea",
	"formmain": "AddPage_formmain__Aja5c",
	"numWidth": "AddPage_numWidth__qEmrM"
};
export default ___CSS_LOADER_EXPORT___;
