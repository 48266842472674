import { List } from 'antd';
import React from 'react';
import BasePage from '../../containers/BasePage/BasePage';

class CardBasedList extends BasePage{
  constructor(props){
    super(props);
    
    this.state = {
        data: []
    }
  }

  onItemClick=(item)=>{
    if(this.props.hasOwnProperty('onCardSelect')){
      this.props.onCardSelect(item);
    }
    else{
      console.log(item);
    }
  };
  render(){
      return (
              <List
              className={'list_title_desc'}
              loading={this.props.loading || false}
              itemLayout="horizontal"
              dataSource={this.props.data || this.state.data}
              renderItem={item => (
              <List.Item
                  onClick={()=>this.onItemClick(item)}
                  actions={item.actionItems?.map((item)=>item)}
              >
              <List.Item.Meta
              key={item.id}
              avatar={null}
              title={<p style={{color:'#8aa7c8'}}>{item?.title}</p>}
              description={<div className='cardDescription'>
                <p style={{color:'#ffa800'}}>{item?.description}</p>
              </div>}
              />
              </List.Item>
              )}
              pagination= {{
                // current: 1,
                pageSize: 20
              }}
            />
      )
  }
}

export default CardBasedList;