import { Tree,Button,message,TreeSelect,Row,Col } from 'antd';
import React from 'react';
import * as http from '../../http'
import { SelectField } from '../../components/Fields/SelectField';


const x = 3;
const y = 2;
const z = 1;
const gData = [];
const delValue = [];
let nodeKey 
let data 
const { TreeNode } = TreeSelect;
let menu ={}
class MenuBuilder extends React.Component {
    constructor(props){
        super(props);
        if(localStorage.menu){
          menu = JSON.parse(localStorage.menu)
        }
        else{
          menu = this.props.user?.data?.menu
        }
        this.state = {
            menuSelect: null,
            menuItem: null,
            gData: menu,
            expandedKeys: ['0'],
          };        
    }
  
  onDragEnter = info => {
    // console.log(info);
  };

  onDrop = info => {
    //   console.log(info)
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...this.state.gData];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, item => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    this.setState({
      gData: this.props.user.data.menu,
    });
  };
  
  onSavechanges = ()=>{
      
    const postData = this.props.user.data
    delete postData.user_type
    delete postData.username
    delete postData.user_type_text
    postData.menu = this.state.gData
    http.post('/api/user_menu', postData)
    .then((response)=> {
    if(response.status == 201){
      message.success('Menu Updated Successfully');
     
    }
    localStorage.setItem('menu',JSON.stringify(postData.menu))
 

  })
  }

  
  deleteData=(data, index, val, kk)=>{
    if(val.key == kk){
      data.splice(index, 1);
      this.setState({
        gData: data
      })
      // this.state.gData = data
      return true;
    }
    else if(val.children){
      val.children.map((v, i)=>{
        this.deleteData(val.children, i, v, kk)
      })
    }
}
  
  onRightClickChanges=(event, node)=>{
    if (confirm('Are you sure to Delete this menu')) {
      let data = [...this.state.gData]
  
      let nodeKey=node.key;
      data.map((value,key)=>{
        let res = this.deleteData(data, key, value, nodeKey);
      })
    } 
      else {
        // Do nothing!
      }
  // return (<Popconfirm title="Are You Sure?"> </Popconfirm>)
    
  }

  handleMenuChange=(value)=>{
    let lastKey = this.state.gData.slice(-1)
    lastKey = lastKey[0].key
    lastKey= lastKey.split('-')
    lastKey = lastKey.slice(-1)[0]
    const key = "0-"+(parseInt(lastKey)+1)
    const tmp = {label: value?.label, value: value?.value};
    this.setState({
      menuSelect: tmp
    })
    
    if(value){
      value.title = value.label
      value.url = value.value
      delete value.value
      value.icon = (value.label).toLowerCase().replace(" ","_")
      delete value.label
      value.key=key
      console.log(value)
      this.setState({
        menuItem:value
      })
    }
  }
  onAddChanges=()=>{
    const newData = [...this.state.gData]
    newData.push(this.state.menuItem)
    const postData = this.props.user.data
    delete postData.user_type
    delete postData.username
    delete postData.user_type_text
    postData.menu = newData
    http.post('/api/user_menu', postData)
    .then((response)=> {
    if(response.status == 201){
      message.success('Menu Added Successfully');
     
    }
    this.setState({
      gData:newData,
      menuSelect:null,
      menuItem: null
    })
    localStorage.setItem('menu',JSON.stringify(newData))
    })
    
  }
  render() {
    console.log(this.state.menuSelect)
    return (
    <>
    <br />
      <div className='menu_title'> Customized Menu Builder</div>
      <br/>
      <Tree
        className="draggable_tree"
        defaultExpandedKeys={this.state.expandedKeys}
        draggable
        blockNode
        onDragEnter={this.onDragEnter}
        onDrop={this.onDrop}
        treeData={this.state.gData}
        selectable
        onRightClick={({event, node})=>this.onRightClickChanges(event, node)}
      /> 
      
      <br />
      <Row className='draggable_tree'>
        <Col span={8} >
          <SelectField url="/api/combo/menu_combo" onSelectChange={(value)=>this.handleMenuChange(value)} value={this.state.menuSelect}/>
        </Col>
        <Col push={10}>
          <Button onClick={()=>this.onAddChanges()} className='tree_btn' style={{marginLeft:"10px"}}>ADD</Button>
        </Col>
      </Row>
      <br />
      
      <Button onClick={()=>this.onSavechanges()} className="tree_btn">
          Save Changes
      </Button>
    </>
    );
    
  }
  
}
export default MenuBuilder