// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddPage_Form__v3ZCX {\n  padding: 15px 15px 15px 15px;\n}\n.AddPage_formLabel__mFZWY {\n  color: #4a6e83;\n}\n.AddPage_step_panel__IQ0Dz\n{\n  color: #087b95;\n}\n.AddPage_formmain__7b9b9\n{\n  background: none;\n    padding-left: 40px;\n    padding-right: 40px;\n  padding-top: 20px;\n\n}\n.AddPage_numWidth__Bn9Go{\n  width: 200px;\n}", "",{"version":3,"sources":["webpack://./src/pages/WrdEquipments/Models/AddPage.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,cAAc;AAChB;AACA;;EAEE,cAAc;AAChB;AACA;;EAEE,gBAAgB;IACd,kBAAkB;IAClB,mBAAmB;EACrB,iBAAiB;;AAEnB;AACA;EACE,YAAY;AACd","sourcesContent":[".Form {\n  padding: 15px 15px 15px 15px;\n}\n.formLabel {\n  color: #4a6e83;\n}\n.step_panel\n{\n  color: #087b95;\n}\n.formmain\n{\n  background: none;\n    padding-left: 40px;\n    padding-right: 40px;\n  padding-top: 20px;\n\n}\n.numWidth{\n  width: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": "AddPage_Form__v3ZCX",
	"formLabel": "AddPage_formLabel__mFZWY",
	"step_panel": "AddPage_step_panel__IQ0Dz",
	"formmain": "AddPage_formmain__7b9b9",
	"numWidth": "AddPage_numWidth__Bn9Go"
};
export default ___CSS_LOADER_EXPORT___;
