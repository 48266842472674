import assert from 'assert';
import { formattedDateForDatePicker } from '../../components/Fields/DateField';
import * as http from '../../http';
import BasePage from '../BasePage/BasePage';
import ContentHeader from '../Layout/ContentHeader/ContentHeader';
import DataTable from './DataTable/DataTable';
import SearchBox from './SearchBox/SearchBox';
import SearchItem from './SearchBox/SearchItem';
class BaseList extends BasePage {
    constructor(props){
        super(props);
        this.tableConfig = this.getDataTableConfig();
        this.searchFields = this.getSearchFields();   
        this.pageConfig = this.getPageConfig();
        this.defaultQueryParams = this.getDefaultQueryParams();
        this.state = {
            pagination: this.getPaginationObject(),
            queryParams: {},
            loading: false,
            dataSource: [],
            isModalVisible: false,
            editable: false,
        }
    }
    onPageChange(current, pageSize){
      console.log(current, pageSize);
    }
    componentDidMount(){
        if(this.state.dataSource.length == 0 && this.tableConfig.staticDataSource == 0){
          this.fetch(this.defaultQueryParams) 
        }
    }

    getSearchFields(){
      return [];
    }

    getColumns(){
      assert(false, 'BaseList getColumns must be overridern!');
    }

    getDefaultQueryParams(){
      return {}
    }

    //we have to solve the pagination 
    getPaginationObject(){
      return {
        current: 1,
        pageSize: 20
        
      }
    }

    getStaticDataSource(){
      return []
    }

    getDataUrl(){
      return '';
    }

    getPageConfig(){
      return {
        pageTitle: 'List Page',
        pageButtons: [],
      }
    }

    getDataTableConfig(){
      return {
        columns: this.getColumns(),
        dataUrl: this.getDataUrl(),
        multiselect: false,
        staticDataSource: this.getStaticDataSource()
      }
    }

    fetch = (params = {}) => {
      let request_params = {...this.defaultQueryParams, ...this.state.queryParams, ...this.state.pagination, ...params}
      this.setState({ loading: true });
      http.get(this.tableConfig.dataUrl, {
        method: 'get',
        type: 'json',
        params: request_params
      }).then(data => {
        this.setState({
          loading: false,
          dataSource: data.data.rows,
          pagination: {
            ...this.state.pagination,
            total: data.data.records,
          },
          queryParams: {
            ...params
          }
        });
      });
    };

    handleTableChange = (pagination, filters, sorter) => {
      this.state.pagination = pagination
      this.state.dataSource? this.fetch({
        sortField: sorter?.field,
        sortOrder: sorter?.order,
        pagination,
        ...filters,
      }): this.setState({
        dataSource: this.state.dataSource
      });
      this.render()
    };

    onRowSelect(selectedRowKeys, selectedRows){
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }

    getFields(callback) {
        const children = [];
        for (let i = 0; i < this.searchFields.length; i++) {
          children.push(
              <SearchItem index={i} config={this.searchFields[i]} onFieldChange={callback} />
            // <Col span={8} key={i} style={{ display: 'block'}}>
            //
            // </Col>,
          );
        }
        return children;
      }
    
      getInitialValues(){
        const initialValues = {};
        for (let i = 0; i < this.searchFields.length; i++) {
          if (this.searchFields[i].hasOwnProperty('initialValue')) { 
            var value = this.searchFields[i].initialValue;
            var type = this.searchFields[i].type || 'input';
            if(type == 'date'){
              value = formattedDateForDatePicker(value);
            }
            initialValues[this.searchFields[i].name] = value;
          }
        }
        return initialValues;
      }
      onSearchClick(values){
          const queryData = this.buildQueryParams(values);
          this.fetch(queryData);
      }
      buildQueryParams(values){
        const queryParams = {};
        for (let i = 0; i < this.searchFields.length; i++) {
          var name = this.searchFields[i].name;
          var type = this.searchFields[i].type;
          if(values.hasOwnProperty(name)){
            if(type == 'input'){
              queryParams[name] = values[name];
            }
            else if(type == 'date'){
              queryParams[name] = values[name].format();
            }
            else if(type == 'select'){
              if(this.searchFields[i].hasOwnProperty('mode')){
              var selected_value = [];
              for(let j=0;j<values[name]?.length; j++){
                selected_value.push(values[name][j].value);
              }
              queryParams[name] = selected_value.toString();
            }
            else{
              queryParams[name] = values[name]?.value;
            }
            }
          }
        }
        return queryParams; 
      }

      resetSerach(){
        this.setState({
          queryParams: {}
        })
        setTimeout(()=>{
          this.fetch();
        }, 300)

      }
      showModal = (url, msg) => {
        if(msg){
          this.setState(
            {
              isModalVisible: true,
              duplicate: msg,
              editUrl: url,
            }
          );
        }
        else if(url){
          this.setState(
            {
              isModalVisible: true,
              editUrl: url,
              duplicate: undefined

            }
          );
        }
      else{
        this.setState(
          {
            isModalVisible: true,
            editable: false,
            editUrl: undefined
          }
        );
      }
      };
      
      handleOk = () => {
        this.setState({isModalVisible: false, errors: {}});
      };
    
      handleCancel = () => {
        this.setState({isModalVisible: false, errors: {}});
      };
      errorCallback=(error)=>{
        if(error?.status == 422){
          const errorData = {};
          for(var i=0; i<error.data.detail.length; i++){
              let field = error.data.detail[i].loc[1];
              let errorMessage = error.data.detail[i].msg;
              errorData[field] = {errorMsg: errorMessage, validateStatus:'error'}
          }
          this.setState({errors: errorData})
        }
      }
    
      getDatSource(){
        if(this.tableConfig.staticDataSource.length != 0){
          debugger;
          return this.tableConfig.staticDataSource;
        }
        else{
          return this.state.dataSource;
        }
      }
    render() {
      //console.log(this.state.pagination);
        return (
            <>
            <ContentHeader
              title={this.pageConfig.pageTitle}
              pageButtons={this.pageConfig.pageButtons} />
            
            <SearchBox 
                initialValues={this.getInitialValues.bind(this)}
                getFields={this.getFields.bind(this)}
                resetSerach={this.resetSerach.bind(this)}
                onSearchClick={this.onSearchClick.bind(this)}
            />
            <DataTable
                columns={this.tableConfig.columns}
                dataSource={this.getDatSource.bind(this)}
                multiselect={this.tableConfig.multiselect}
                onRowSelect={this.onRowSelect.bind(this)}
                handleTableChange={this.handleTableChange.bind(this)}
                loading={this.state.loading}
                pagination={this.state.pagination}
            />
            {this.state.modal}
            </>
        );
    }
}

export default BaseList;