// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchBox_SearchBox__JBn6e {\n  border: 1px solid rgb(125, 85, 39);\n  margin: 0px 0px 0px 0px !important;\n}\n.SearchBox_submitRow__DdfmC {\n  padding: 10px 0px 10px 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/BaseList/SearchBox/SearchBox.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,kCAAkC;AACpC;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".SearchBox {\n  border: 1px solid rgb(125, 85, 39);\n  margin: 0px 0px 0px 0px !important;\n}\n.submitRow {\n  padding: 10px 0px 10px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchBox": "SearchBox_SearchBox__JBn6e",
	"submitRow": "SearchBox_submitRow__DdfmC"
};
export default ___CSS_LOADER_EXPORT___;
