export const SearchFormMapper = [
    {
        label: '',
        name: 'name.like',
        type: 'input',
        rules: [],
        placeholder: 'Power Source Name',
    },
    {
        label: '',
        name: 'supply_type_id',
        type: 'select',
        url: '/api/combo/power_supply/power_supply_type_combo',
        rules: [],
        placeholder: 'Supply Type',
    },
    
]