import { Button, Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import * as http from '../../http';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../utils';
import classes from './MapConfig.module.css';

export const MapConfig=(props)=>{
    const [stepForm] = Form.useForm();
    const [errors, setErrorData] = React.useState({});
    const [ConfigData, setConfigData] = React.useState({
      density: null,
      density_combo: null,
      cable_combo:null,
      start_connector_combo:null,
      end_connector_combo:null,

    });

    let isMounted = useIsMounted();
      useEffect(() => {
        if(props?.configData?.configID){
        http.get(`/api/scenario/${props.configData.scenarioID}/${props.configData.configID}/get_map_config/`).then((data)=>{
          let newData = {};
          _.forOwn(data.data?.config, (value, key)=>{
              if(!ConfigData.hasOwnProperty(key)){
                const val = {};
                val[key] = value;
                stepForm.setFieldsValue(val);
              }
              else{
                newData[key] = value;
              }
          })
        if(isMounted.current){
          setConfigData((prevVal)=>{
            console.log(newData);
            return {...prevVal, ...newData}
          })
        }
        }).catch((err)=>{
          console.log(err);
        })
        }
        return () => { isMounted = true }; 
      }, [props.configData])

    const onFinish=(values)=>{
      window.frames[0].postMessage({
        data: {'config': {...values, ...ConfigData}},
        type: 'resolve',
        popupId: props.popupID
      })
    };
  
    const setPostData=(field, type, label, value)=>{
      let newData = {};
      if(type == 'select'){
        if(value){
            newData[field] = value.value;
            newData[`${field}_combo`] = value;
        }
        else{
            newData[field] = null;
            newData[`${field}_combo`] = null;
        }
    }
      else if(type == 'input-button'){
        if(value == 'plus'){
        const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
        const updatedValue = {};
        updatedValue[field] = newValue;
        stepForm.setFieldsValue(updatedValue)
        }
        else{
        const newValue = parseInt(stepForm.getFieldsValue()[field]|| 0) - 1;
        const updatedValue = {};
        updatedValue[field] = newValue;
        stepForm.setFieldsValue(updatedValue)
        }
      }
      else if(type == 'checkbox')
      {
        newData[field] = value;
      }

      setConfigData((prevVal)=>{
        return {...prevVal, ...newData}
      })
  };

  const PowerLineForm=()=>{
    return (
        <div className={classes.content}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <BuildFormItem
             name='voltage'
             label='Voltage'
             type='input'
            />
          </Col>
          <Col span={12}>
            <BuildFormItem
              name='area'
              label='Effected Distance(m)'
              type='input'
            />
          </Col>
        </Row>
        </div>
    )
};

const LineLayingForm=()=>{
  return (
      <div className={classes.content}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <BuildFormItem
           name='line_name'
           label='Line name'
           type='input'
           formConfig={{
            required: true,
            value: ConfigData.name,
          }}
          />
        </Col>
        <Col span={12}>
          <BuildFormItem
            name='length'
            label='Line Length(ft)'
            type='input'
            formConfig={{
              required: true,
              value: ConfigData.length,
            }}
          />
        </Col>
        
      </Row>
      <Row gutter={[16],[16]}>
      <Col span={6}></Col>
        <Col span={12}>
            <BuildFormItem
              name='cable'
              label='Cable'
              type='select'
              fieldConfig={{
                url:"/api/combo/cables",
                onSelectChange:setPostData,
                value: ConfigData.cable_combo
                }}
                formConfig={{
                  required: true
                }}
            />
          </Col>
      </Row>

      <Row gutter={[16],[16]}>
        <Col span={12}>
            <BuildFormItem
              name='start_connector'
              label='Start Connector'
              type='select'
              fieldConfig={{
                url:"/api/combo/connectors",
                onSelectChange:setPostData,
                value: ConfigData.start_connector_combo
                }}
                formConfig={{
                  required: true
                }}
            />
          </Col>

          <Col span={12}>
            <BuildFormItem
              name='end_connector'
              label='End Connector'
              type='select'
              fieldConfig={{
                url:"/api/combo/connectors",
                onSelectChange:setPostData,
                value: ConfigData.end_connector_combo
                }}
                formConfig={{
                  required: true
                }}
            />
          </Col>
      </Row>

      </div>
  )
};


const BuildingForm=()=>{
  return (
      <div className={classes.content}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <BuildFormItem
            name='height'
            label='Height(ft)'
          /> 
        </Col>
        <Col span={12}>
          <BuildFormItem
            name='area'
            label='Area(sq.m)'
          />
        </Col>
      </Row>
      </div>
  )
};
const MobileTower = () =>{
  return (
    <div className={classes.content}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <BuildFormItem
            name='height'
            label='Height(ft)'
          /> 
        </Col>
        <Col span={12}>
          <BuildFormItem
            name='area'
            label='Blocking Area(m)'
          />
        </Col>
      </Row>
      </div>
  )
}
const ForestForm=()=>{
  return (
      <div className={classes.content}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <BuildFormItem
            name='height'
            label='Avg. Height(ft)'
          /> 
        </Col>
        <Col span={12}>
          <BuildFormItem
            name='density'
            label='Density(tree/sq.m.)'
            type='select'
            fieldConfig={{
              data: [{label: 'Low', value: 1}, {label: 'Medium', value: 2}, {label: 'High', value: 3}],
              onSelectChange: setPostData,
              value: ConfigData.density_combo
            }}
          />
        </Col>
      </Row>
      </div>
  )
};

const RainForm=()=>{
  return (
      <div className={classes.content}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <BuildFormItem
            name='speed'
            label='Avg. Rain Speed'
          />
        </Col>
        <Col span={12}>
          <BuildFormItem
            name='density'
            label='Rain Type'
            type='select'
            fieldConfig={{
              data: [{label: 'Low', value: 1}, {label: 'Medium', value: 2}, {label: 'High', value: 3}],
              onSelectChange: setPostData,
              value: ConfigData.density_combo
            }}
          />
        </Col>
      </Row>
      </div>
  )
};

const FogForm=()=>{
  return (
      <div className={classes.content}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <BuildFormItem
            name='density'
            label='Fog Type'
            type='select'
            fieldConfig={{
              data: [{label: 'Low', value: 1}, {label: 'Medium', value: 2}, {label: 'High', value: 3}],
              onSelectChange: setPostData,
              value: ConfigData.density_combo
            }}
          />
        </Col>
      </Row>
      </div>
  )
};

const WaterBodyForm=()=>{
  return (
      <div className={classes.content}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <BuildFormItem
            name='density'
            label='Water Type'
            type='select'
            fieldConfig={{
              data: [{label: 'Sea Water', value: 1}, {label: 'Fresh Water', value: 2}],
              onSelectChange: setPostData,
              value: ConfigData.density_combo
            }}
          />
        </Col>
      </Row>
      </div>
  )
};

const RiverForm=()=>{
  return (
      <div className={classes.content}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
        <BuildFormItem
            name='name'
            label='River Name'
            type='input'
            formConfig={{
              required: true,
              value: ConfigData.name,
            }}
          />
        </Col>
      </Row>
      </div>
  )
};

const RoadForm=()=>{
  return (
      <div className={classes.content}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
        <BuildFormItem
            name='name'
            label='Road Name'
            type='input'
            formConfig={{
              required: true,
              value: ConfigData.name,
            }}
          />
        </Col>
      </Row>
      </div>
  )
};

const RailLineForm=()=>{
  return (
      <div className={classes.content}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
        <BuildFormItem
            name='name'
            label='Rail Line Name'
            type='input'
            formConfig={{
              required: true,
              value: ConfigData.name,
            }}
          />
        </Col>
      </Row>
      </div>
  )
};

const forms = {
  power_line: <PowerLineForm />,
  building: <BuildingForm />,
  forest: <ForestForm />,
  rain: <RainForm />,
  fog: <FogForm />,
  water_body: <WaterBodyForm />,
  mobile_tower: <MobileTower />,
  line_laying: <LineLayingForm/>,
  river: <RiverForm/>,
  road: <RoadForm/>,
  railline: <RailLineForm/>,

};
    const colorClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'customantmodal' : 'notfound';

    return (
      <div className={classes.formmain}>
      <Form form={stepForm} onFinish={onFinish}>

        {forms[props.formType]}
        <div className={[classes.footer, 'button_row'].join(' ')}>
            <Button type="primary" htmlType="submit">
                    {props?.configData?.configID? 'Update':'Add'}
            </Button>
            </div>
      </Form>
      </div>
  )
  
}