export const columnMapper = [
    {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        width: '2%',
        hidden: true
      },
      {
        title: 'Power Source Name',
        dataIndex: 'name',
        editable: true,
        width: 'auto',
      },
      {
        title: 'Usability Type',
        dataIndex: 'usability_type_text',
        editable: true,
       
        width: 'auto',
      },
      {
        title: 'Supply Type',
        dataIndex: 'ps_type_text',
        editable: true,
       
        width: 'auto',
      },
      {
        title: 'Supply Voltage (V)',
        dataIndex: 'supply_voltage',
        editable: true,
        width: 'auto',
      },
      {
        title: 'Supply Current (Ah)',
        dataIndex: 'supply_current',
        editable: true,
        width: 'auto',
      },
      {
        title: 'Frequency (Hz)',
        dataIndex: 'frequency',
        editable: true,
        width: 'auto',
      }
    //   {
    //     title: 'User Type',
    //     dataIndex: 'user_type',
    //     render: user_type => user_type==0?'Admin':user_type==1?'Teacher':'Student',
    //     width: '20%',
    //   }
    ].filter(item => !item.hidden);