export const SearchFormMapper = [
    {
        label: '',
        name: 'email.like',
        type: 'input',
        rules: [],
        placeholder: 'Username',
    },
    {
        label: '',
        name: 'user_type',
        type: 'select',
        rules: [],
        placeholder: 'Select User Type',
        data: [
            {label: 'Admin', value: "0"},
            {label: 'Instructor', value: "1"},
            {label: 'Student', value: "2"},
        ],
        mode: 'single'
    }
]