export const columnMapper = [
    {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        // width: '2%',
        hidden: true
      },
      {
        title: 'Name',
        dataIndex: 'name',
        editable: true,
        width: '100',
        fixed: 'left'
      },
      {
        title: 'Model',
        dataIndex: 'model',
        editable: true,
        width: '100',
        fixed: 'left'
      },
   
      {
        title: 'Manufacturer Name',
        dataIndex: 'manufacturer_name',
        editable: true,
        width: '100',
        fixed: 'left'
      },
      {
        title: 'Category',
        dataIndex: 'category_text',
        editable: true,
        // width: '5%',
      },
      
      {
        title: 'Frequency',
        dataIndex: 'frequency_text',
        editable: true,
        // width: '5%',
      },
      {
        title: 'Type',
        dataIndex: 'type_text',
        editable: true,
        // width: '5%',
      }, 
      {
        title: 'Installation Type',
        dataIndex: 'installation_type_text',
        editable: true,
        // width: '5%',
      },{
        title: 'NVIS',
        dataIndex: 'NVIS',
        editable: true,
        // width: '5%',
      },{
        title: 'Radiation Pattern',
        dataIndex: 'radiation_pattern_text',
        editable: true,
        // width: '5%',
      },
      {
        title: 'Station Type',
        dataIndex: 'station_type_text',
        editable: true,
        // width: '5%',
      },{
        title: 'Maximum Frequency (MHz)',
        dataIndex: 'max_freq',
        editable: true,
        // width: '5%',
      },{
        title: 'Minimum Frequency (MHz)',
        dataIndex: 'min_freq',
        editable: true,
        // width: '5%',
      },
      {
        title: 'Bandwidth (MHz)',
        dataIndex: 'bandwidth',
        editable: true,
        // width: '5%',
      },{
        title: 'Antenna Length (m)',
        dataIndex: 'antenna_length',
        editable: true,
        // width: '5%',
      },{
        title: 'Antenna Diameter (m)',
        dataIndex: 'antenna_diameter',
        editable: true,
        // width: '5%',
      },{
        title: 'Antenna Gain (dB)',
        dataIndex: 'antenna_gain',
        editable: true,
        // width: '5%',
      },{
        title: 'Impedence (Ohms)',
        dataIndex: 'impedence',
        editable: true,
        // width: '5%',
      },{
        title: 'vswr_max',
        dataIndex: 'vswr_max',
        editable: true,
        // width: '5%',
      },{
        title: 'vswr_min',
        dataIndex: 'vswr_min',
        editable: true,
        // width: '5%',
      },{
        title: 'Efficiency',
        dataIndex: 'efficiency',
        editable: true,
        // width: '5%',
      },{
        title: 'Polarization',
        dataIndex: 'polarization',
        editable: true,
        // width: '5%',
      },{
        title: 'Power Rating (Watt)',
        dataIndex: 'power_rating',
        editable: true,
        // width: '5%',
      },{
        title: 'Wind Rating (Km/h)',
        dataIndex: 'wind_rating',
        editable: true,
        // width: '100',
        // fixed:'right'
      },
      
    //   {
    //     title: 'User Type',
    //     dataIndex: 'user_type',
    //     render: user_type => user_type==0?'Admin':user_type==1?'Teacher':'Student',
    //     width: '20%',
    //   }
    ].filter(item => !item.hidden);