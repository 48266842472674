import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginUser } from '../../actions/authentication';
import pic from "../../assets/logo2.png";
import * as http from '../../http';
import class_name from './Login.module.css';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            errors: {},
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        const user = {
            username: this.state.email,
            password: this.state.password,
        }
        const accessTokenCredential = {
            'client_id': process.env.REACT_APP_CLIENT_ID,
            'client_secret': process.env.REACT_APP_CLIENT_SECRET
        }
        http.post('/o/accesstoken', accessTokenCredential)
                .then(res => {
                    const { token } = res.data;
                    this.props.loginUser(user, token);
                })
                .catch(err => {
                    this.setState({
                        errors: err.response?.data
                    })
                });
        
    }

    componentDidMount() {
        if(this.props.auth.isAuthenticated) {
            this.props.history.push('/home');
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.auth.isAuthenticated) {
            this.props.history.push('/home')
        }
        if(nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    render() {

        const {detail} = this.props.errors;

        // var theme = JSON.parse(localStorage.getItem("theme"));

        // if(theme === null)
        // {
        //     theme = "White";
        //     var vlu = JSON.stringify(theme);
        //     localStorage.setItem("theme", vlu);
        // }

        return(
        <div className={[class_name.css,class_name.main, 'container-fluid d-flex'].join(' ')}> {/* main div start */}
            <div className={[class_name.css, class_name.split, class_name.left_split, 'col-6 d-flex align-items-center'].join(' ')}>{/* split left div start */}
                <img className={[class_name.logo]} src={pic} alt=""/>
                <br />
                {/* <h2 className={[class_name.left_split_header]} style={{color:colors}}>Signal Training Center & School</h2>
                <p className={[class_name.left_split_sub_header]} style={{color:colors}}>Bangladesh Army</p> */}

                    {/* <button type="button" onClick={this.handleColor} style={{backgroundColor:colors, color:bgcolor, borderRadius:10}}>Mode Change</button> */}

                    <div className={[class_name.form_div].join(' ')}>
                        <header className={[class_name.form_head]}><h2 className={[class_name.form_head_font]}>Sign In</h2></header>
                        <form className={[class_name.form_main]}  onSubmit={ this.handleSubmit }>
                            <div className={[class_name.input_form, 'form-group'].join(' ')}>
                                <input
                                type="email"
                                placeholder="Email"
                                className={classnames(`form-control form-control-lg ${class_name.custom_input} `, {
                                    'is-invalid': detail
                                })}
                                name="email"
                                onChange={ this.handleInputChange }
                                value={ this.state.email }
                                />
                                {detail && (<div className="invalid-feedback">{detail}</div>)}
                            </div>
                            <div className={[class_name.input_form, 'form-group'].join(' ')}>
                                <input
                                type="password"
                                placeholder="Password"
                                className={classnames(`form-control form-control-lg ${class_name.custom_input} `, {
                                    'is-invalid': detail
                                })}
                                name="password"
                                onChange={ this.handleInputChange }
                                value={ this.state.password }
                                />
                                {detail && (<div className="invalid-feedback">{detail}</div>)}
                            </div>
                            <div className={[class_name.input_form, 'form-group'].join(' ')}>
                                <button className={[class_name.submit_button, class_name.input_form , 'btn btn-1'].join(' ')} type="submit">
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>

            </div> {/* split left div end */}

            <div className={[class_name.css, class_name.split, class_name.split_right ,'col-6'].join(' ')}>{/* split right div start */}
                <div className={[class_name.split_right_right]} style={{ height:'100%', width:'100%',background: 'rgb(0 0 0 / 14%)'}}>
                    <div className={[class_name.split_right_text].join(' ')}>
                        <h4 className={[class_name.split_right_header,'h4'].join(' ')}>Never Stop Learning, Because Life Never Stops Teaching.</h4>
                         <p className={[class_name.split_right_details,'P'].join(' ')}></p>
                    </div>
            </div>

            </div>

            {/* main div end */}
        </div>
        )
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    // errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
})

export  default connect(mapStateToProps, { loginUser })(withRouter(Login))
