// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddPage_footer__754Gv {\n  background: #012032;\n}\n.AddPage_formLabel__PhXdb {\n  color: #4a6e83;\n}\n\n/* White Mode Footer Start */\n.AddPage_whitemodeFooter__f6sr9 {\n  background: #F5F7FB;\n}\n/* White Mode Footer End */\n\n.AddPage_modalContent__H36Vz {\n  /* display: flex; */\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Students/AddPage.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;;AAEA,4BAA4B;AAC5B;EACE,mBAAmB;AACrB;AACA,0BAA0B;;AAE1B;EACE,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".footer {\n  background: #012032;\n}\n.formLabel {\n  color: #4a6e83;\n}\n\n/* White Mode Footer Start */\n.whitemodeFooter {\n  background: #F5F7FB;\n}\n/* White Mode Footer End */\n\n.modalContent {\n  /* display: flex; */\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "AddPage_footer__754Gv",
	"formLabel": "AddPage_formLabel__PhXdb",
	"whitemodeFooter": "AddPage_whitemodeFooter__f6sr9",
	"modalContent": "AddPage_modalContent__H36Vz"
};
export default ___CSS_LOADER_EXPORT___;
