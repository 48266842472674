import { Col, Form, Image, Row } from 'antd';
import React, { useEffect } from 'react';
import { StepPanel } from '../../../containers/StepPanel/StepPanel';
import * as http from '../../../http';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../../utils';
import classes from '../DeviceConfig.module.css';
import { RJ45Config } from './wrd_component/RJ45configure';

export const WiredLineConfig=(props)=>{
    console.log('In form')
    
    const scenario_id = props.configData.scenarioID
    const coordLength =  (props.configData.geometry.coordinates).length
    const first_device_coords = props.configData.geometry.coordinates[0]
    const second_device_coords = props.configData.geometry.coordinates[coordLength-1]
    const [toolsConfig, setToolsConfigData] = React.useState({
    });

    const [toolsCheckBox, setToolsCheckBoxData] = React.useState({
    });
    
    const [connectorConfigData, setConnectorConfigData] = React.useState({
        port1_combo:null, port2_combo:null,
        port3_combo:null,port4_combo:null,
        port5_combo:null,port6_combo:null,
        port7_combo:null,port8_combo:null,
    })

    const [lineConfigData, setConfigData] = React.useState({
        line_in_connectors_combo:null,
        line_out_connectors_combo:null,
        line_cable_combo: null,
        downlink_connectors_port_combo: null,
        uplink_connectors_port_combo:null,
        primary_resource_combo:null,
        line_crossing_combo: null,
        crossing_config: toolsConfig,
        cores: props?.configData?.wrd_equipment?.cores,
        downlink_connector_config: connectorConfigData,
        uplink_connector_config: connectorConfigData,
        wrd_equipment_id_combo:null,
        comm_equipment_type: props.configData?.comm_equipment_type,
        image_url: '',
        image_name: '',
    }); 
    
    const [stepForm] = Form.useForm();
    const [errors, setErrorData] = React.useState({});

    function calculateLineDistance(){
        let coordinates = props.configData.geometry.coordinates
        let val = {}
        var length = 0.0 
        // console.log(coordinates)
        for(let i=0; i<coordLength-1; i++){
            console.log(coordinates[i],coordinates[i+1])
            length = parseFloat(length) + parseFloat(getTwoPointsDistance(coordinates[i],coordinates[i+1]))
        }
        console.log('Total Length: '+ length)
        val['length'] = (length*1000).toFixed(2);
        stepForm.setFieldsValue(val);
    }

    function getTwoPointsDistance(star_coordinates, end_coordinates){
        if(star_coordinates != null && end_coordinates != null ){
            let earthRadiusKm = 6371.071;
        let dLat = degreesToRadians(
          end_coordinates[1] - star_coordinates[1]
        );
        let dLon = degreesToRadians(
          end_coordinates[0] - star_coordinates[0]
        );
    
        const lat1 = degreesToRadians(star_coordinates[1]);
        const lat2 = degreesToRadians(end_coordinates[1]);
    
        let a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        let result = 2 * earthRadiusKm * Math.asin(Math.sqrt(a));
        return result.toFixed(2);
        }else{
            return 0.0
        }
        
      }

    function degreesToRadians(degrees) {
        return degrees * (Math.PI / 180);
      }

    let isMounted = useIsMounted();
    useEffect(() => {  
        if(props?.configData?.configID){
            http.get(`/api/scenario/${props.configData.scenarioID}/${props.configData.configID}/get_config/`).then((data)=>{
                let newData = {};
                let newToolsData = {};
                let newToolBox = {};
                // console.log(data.data?.line_config?.uplink_connector_config)
            // _.forOwn(data.data?.line_config, (value, key)=>{ 
                _.forOwn(data.data?.line_config, (value, key)=>{
                    if(!lineConfigData.hasOwnProperty(key)){
                    const val = {};
                    val[key] = value;
                    stepForm.setFieldsValue(val);
                    }
                    else{
                    newData[key] = value;
                    }
                })
                _.forOwn(data.data, (value, key)=>{
                    if(key=='wrd_equipment_id_combo'){
                        newData[key] = value;
                    }
                    
                })
                _.forOwn(data.data?.line_config?.crossing_config, (value, key)=>{
                    if (value==key){
                        newToolBox[value] = key;
                    }
                    newToolsData[key] = value;
                })
                //for core config
                data.data?.core_config?.forEach((configs)=>{
                    _.forOwn(configs, (value, key)=>{
                        newData[configs.core_id+'_'+key] = value;
                    })
                })
                data.data?.core_config?.forEach((configs)=>{
                    _.forOwn(configs.config, (value, key)=>{
                        newData[configs.core_id+'_'+key] = value;
                    })
                })
                //for pair config
                data.data?.pairs_config?.forEach((configs)=>{
                    _.forOwn(configs, (value, key)=>{
                        newData[configs.pair_id+'_'+key] = value;
                    })
                })
                data.data?.pairs_config?.forEach((configs)=>{
                    _.forOwn(configs.config, (value, key)=>{
                        newData[configs.pair_id+'_'+key] = value;
                    })
                })
                
            if(isMounted.current){
                setConfigData((prevVal)=>{
                return {...prevVal, ...newData}
                })
                setToolsCheckBoxData((prevVal)=>{
                    return {...prevVal, ...newToolBox}
                })
                setToolsConfigData((prevVal)=>{
                    return {...prevVal, ...newToolsData}
                })
            }
            // console.log(toolsConfig)
            }).catch((err)=>{
                console.log(err);
            })
        }
        
        return () => { isMounted = true }; 
    }, [setConfigData, props.configData])
    
    const setPostData=(field, type, label, value)=>{
        let newData = {};
        let newToolsData = {};
        if(field == 'usability_type'){
            if(value && !props?.configData?.equipmentData?.usability_type.includes(value.value)){
                alert(`${props.configData?.equipmentData?.model} doesn't support ${value.label} installation`);
                return
            }
        }
        if(type == 'select'){
            if(value){
                newData[field] = value.value;
                newData[`${field}_combo`] = value;

                if (`${field}_combo` == 'line_crossing_combo'){
                    pull_all_tools(value.value);
                }
            }
            else{
                newData[field] = null;
                newData[`${field}_combo`] = null;
            }
            if (`${field}_combo` == 'wrd_equipment_id_combo'){
                getDeviceCores();
                getDeviceDetails();
            }
        }
        else if(type == 'input-button'){
            if(value == 'plus'){
                const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
                const updatedValue = {};
                updatedValue[field] = newValue;
                stepForm.setFieldsValue(updatedValue)
            }
            else{
                const newValue = parseInt(stepForm.getFieldsValue()[field]|| 0) - 1;
                const updatedValue = {};
                updatedValue[field] = newValue;
                stepForm.setFieldsValue(updatedValue)
            }
        }
        else if(type == 'checkbox')
        {
            newToolsData[field] = value;
        }

        setConfigData((prevVal)=>{
            return {...prevVal, ...newData}
        })

        setToolsConfigData((prevVal)=>{
            return {...prevVal, ...newToolsData}
            })

    }

    function getDeviceCores(){
        // console.log("Selected Device: "+lineConfigData?.wrd_equipment_id_combo?.value)
        let url =''
        if(props?.configData?.wrd_equipment?.id && !(lineConfigData?.wrd_equipment_id_combo?.value)){
            // url =`/api/wire_device_ports/by_config/${props.configData.configID}`
            if(lineConfigData.line_cable_combo!= null && lineConfigData.line_cable_combo.value == 3){
                url =`/api/wire_cables/tt_pairs/${props?.configData?.wrd_equipment?.id}/`
            }else{
                url =`/api/wire_cables/fiber-cores/${props?.configData?.wrd_equipment?.id}/`
            }
                
        }
        else{
            if(lineConfigData.line_cable_combo!= null && lineConfigData.line_cable_combo.value == 3){
                url =`/api/wire_cables/tt_pairs/${lineConfigData?.wrd_equipment_id_combo?.value}`
            }else{
                url =`/api/wire_cables/fiber-cores/${lineConfigData?.wrd_equipment_id_combo?.value}`
            }
           
        }
        http.get(url).then(
            (data)=>{
                // console.log(data.data)
                setConfigData((prevVal)=>{
                    return {...prevVal, ...{'cores':data?.data}}
                })
        })
        .catch((err)=>{
                console.log(err);
        })
    }

    function getDeviceDetails(){
        // console.log("Selected Device: "+routerConfigData?.wrd_equipment_id_combo?.value)
        let url =''
        let device_url =''
        if(props?.configData?.wrd_equipment?.id && !(lineConfigData?.wrd_equipment_id_combo?.value)){
            device_url =`/api/wire_comm_equipments/${props?.configData?.wrd_equipment?.id}/`
        }
        else{
            device_url =`/api/wire_comm_equipments/${lineConfigData?.wrd_equipment_id_combo?.value}`
        }
        http.get(device_url).then(
            (data)=>{
                // console.log(data.data) 
                setConfigData((prevVal)=>{
                    return {...prevVal, ...{'image_url':data?.data?.image_url, 'image_name':data?.data?.image_name}}
                })
        })
        .catch((err)=>{
                console.log(err);
        })
    }

    function pull_all_tools(id){
        // pull crossing tools
        http.get(`/api/wire_laying_tools/${id}/by_crossing/`).then(
            (data)=>{
            let newToolBox = {};
            let newToolData = {};
            setToolsCheckBoxData({});
            data.data.forEach((tools)=>{
                const val = {};
                let config_key = (tools?.name).toLowerCase();
                newToolBox[tools?.name] = tools?.name;
                newToolData[tools?.name] = tools?.name;
                if(!toolsConfig.hasOwnProperty(config_key)){
                    newToolData[config_key] = null;
                }else{
                    newToolData[config_key] = toolsConfig[`${config_key}`];
                    }
            })
            setToolsConfigData({});
            if(isMounted.current){
                setToolsCheckBoxData((prevVal)=>{
                return {...prevVal, ...newToolBox}
                })
                setToolsConfigData((prevVal)=>{
                    return {...prevVal, ...newToolData}
                    })
            }
            
        })
        .catch((err)=>{
                console.log(err);
        })
    }
    
    const onFinish=(values)=>{
        const postData = { ...values, ...({...lineConfigData, ...{crossing_config:toolsConfig}})};
        // console.log("On Finished: ")
        // console.log(postData)
        window.frames[0].postMessage({
            data: postData,
            type: 'resolve',
            popupId: props.popupID
        })
    }

    const Config2Form=()=>{
        return (
            <div className={classes.content}>
                <Row gutter={[16,16]}>
                    <Col span={props.configData?.scenarioData?.scenario_type == 2 || props.configData?.scenarioData?.scenario_type == 3? 6 :0}/>
                    <Col span={12}>
                        <BuildFormItem
                            name='length'
                            label='Length(m)'
                            type='input'
                            formConfig={{
                                required: true,
                                // value: lineConfigData.length,
                                value: calculateLineDistance(),
                            }}
                        />
                    </Col>
                
                    
                        <Col span={12}>
                            <BuildFormItem
                            name='primary_resource'
                            label='POP/Primary Device'
                            type='select'
                            fieldConfig={{
                                url:`/api/combo/wrd_primary_resource_combo/${scenario_id}`,
                                onSelectChange:setPostData,
                                value: lineConfigData.primary_resource_combo
                                }}
                            formConfig={{
                                required: true
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <BuildFormItem
                            name='line_cable'
                            label='Cable'
                            type='select'
                            fieldConfig={{
                                url:"/api/combo/cables",
                                onSelectChange:setPostData,
                                value: lineConfigData.line_cable_combo
                                }}
                            formConfig={{
                                required: true
                                }}
                            />
                        </Col>
                
                    <Col span={(lineConfigData.line_cable_combo != null)?(((lineConfigData.line_cable_combo.value == 2 || lineConfigData.line_cable_combo.value == 5) && (lineConfigData.line_in_connectors_combo!= null && lineConfigData.line_in_connectors_combo.value != 5)) || (lineConfigData.line_cable_combo.value == 3)? 6 : 12):6}>
                        <BuildFormItem
                        name='downlink_connectors_port'
                        label='Downlink Device Port'
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/wrd_nodedeviceports_combo/${first_device_coords[0]}/${first_device_coords[1]}/`,
                            onSelectChange:setPostData,
                            value: lineConfigData.downlink_connectors_port_combo
                            }}
                        formConfig={{
                            required: true,
                            hidden: (lineConfigData.line_cable_combo != null)?(((lineConfigData.line_cable_combo.value == 5) && (lineConfigData.line_in_connectors_combo!= null && lineConfigData.line_in_connectors_combo .value != 5)||lineConfigData.line_cable_combo.value == 2 ||lineConfigData.line_cable_combo.value == 3)? true : false):true
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                        name='line_in_connectors'
                        label='In/Downlink Connection'
                        type='select'
                        fieldConfig={{
                            url:"/api/combo/connectors",
                            onSelectChange:setPostData,
                            value: lineConfigData.line_in_connectors_combo
                            }}
                        formConfig={{
                            required: true,
                            hidden: (lineConfigData.line_cable_combo != null)?((lineConfigData.line_cable_combo.value == 2)? true : false):true
                            }}
                        />
                    </Col>
               
                    <Col span={(lineConfigData.line_cable_combo != null)?(((lineConfigData.line_cable_combo.value == 2 || lineConfigData.line_cable_combo.value == 5) && (lineConfigData.line_out_connectors_combo!= null && lineConfigData.line_out_connectors_combo .value != 5)) || (lineConfigData.line_cable_combo.value == 3)? 6 : 12):6}>
                        <BuildFormItem
                        name='uplink_connectors_port'
                        label='Uplink Device Port'
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/wrd_nodedeviceports_combo/${second_device_coords[0]}/${second_device_coords[1]}/`,
                            onSelectChange:setPostData,
                            value: lineConfigData.uplink_connectors_port_combo
                            }}
                        formConfig={{
                            required: true,
                            hidden: (lineConfigData.line_cable_combo != null)?(((lineConfigData.line_cable_combo.value == 5) && (lineConfigData.line_out_connectors_combo!= null && lineConfigData.line_out_connectors_combo .value != 5)||lineConfigData.line_cable_combo.value == 2 ||lineConfigData.line_cable_combo.value == 3)? true : false):true
                            }}
                        />
                    </Col>
                    <Col span={(lineConfigData.line_cable_combo != null)?((lineConfigData.line_cable_combo.value == 2)? 0 : 12):0}>
                        <BuildFormItem
                        name='line_out_connectors'
                        label='Out/Uplink Connection'
                        type='select'
                        fieldConfig={{
                            url:"/api/combo/connectors",
                            onSelectChange:setPostData,
                            value: lineConfigData.line_out_connectors_combo
                            }}
                        formConfig={{
                            required: true,
                            hidden: (lineConfigData.line_cable_combo != null)?((lineConfigData.line_cable_combo.value == 2)? true : false):true
                            }}
                        />
                    </Col>
                    <Col span={(lineConfigData.line_cable_combo != null)?((lineConfigData.line_cable_combo.value == 2)? 6 : 0):6}></Col>
                    <Col span={12}>
                        <BuildFormItem
                        name='line_crossing'
                        label='Lying Type'
                        type='select'
                        fieldConfig={{
                            url:"/api/combo/wrd_crossing_type_combo",
                            onSelectChange:setPostData,
                            value: lineConfigData.line_crossing_combo
                            }}
                        formConfig={{
                            required: true
                            }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    const Config1Form=()=>{
        return (
            <div className={classes.content}>
                <Row gutter={[16,16]}>
                <Col span={props.configData?.scenarioData?.scenario_type == 2 || props.configData?.scenarioData?.scenario_type == 3? 12 :0}>
                        <BuildFormItem
                        name={`wrd_equipment_id`}
                        label='Select Equipment'
                        index={props.index}
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/get_allocated_wrd_equipments/${props?.configData?.scenarioID}/${props?.configData?.equipment_type?.id}/`,
                            onSelectChange:setPostData,
                            value: lineConfigData.wrd_equipment_id_combo
                            }}
                            formConfig={{
                            required: true,
                            hidden: props.configData?.scenarioData?.scenario_type == 2 || props.configData?.scenarioData?.scenario_type == 3? false :true,
                            }}
                        />
                    </Col>
                    
                    <Col span={12}>
                        <BuildFormItem
                            name='line_name'
                            label='Line Name'
                            type='input'
                            formConfig={{
                                required: true,
                                value: lineConfigData.line_name,
                            }}
                        />
                    </Col> 
                    <Col span={4}></Col>
                    <Col span={16}>
                        <Image
                            height = {lineConfigData?.image_url!=''?300:0}
                            src={`${lineConfigData?.image_url}`}
                        />
                    </Col>
                    <Col span={4}/>
                    
                </Row>
                
            </div>
        )
    }

    const MakeCheckBox=(props)=>{
        return (
        <>
            <BuildFormItem
                name={`${props.item_key}`}
                label={`${props.item_title}`}
                key={`${props.item_key}`}
                type='checkbox'
                fieldConfig={{
                    style: {color: "white" },
                    checked:toolsConfig[`${props.item_key}`],
                    onChange: setPostData
                }}/>
        </>)
        
    }
    let boxes = []
    _.forOwn(toolsCheckBox, (value, key)=>{
        let data = {
            check_key: key.toLowerCase(),
            title: key,
        }
        boxes = boxes.concat(data)
    })

   
    const MappingRow = (props)=>{
        console.log(props)
        return(
            <div className={classes.content}>
                <Row gutter={[24],[24]}>
                    <Col span={6}>
                        <p style={{color:'white'}}>{ lineConfigData.line_cable_combo.value == 3? props?.core?.pair_name:props?.core?.core_name }</p>
                    </Col>
                    <Col span={6}>
                        <BuildFormItem
                        name={`${props.core.id}_primary_resource`}
                        label='Primary Device/Network'
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/wrd_primary_resource_combo/${scenario_id}`,
                            onSelectChange:setPostData,
                            value: lineConfigData[`${props.core.id}_primary_resource_combo`]
                            }}
                            formConfig={{
                            required: true
                            }}
                        />
                    </Col>
                    
                    <Col span={6}>
                        <BuildFormItem
                        name={`${props.core.id}_downlink_resource`}
                        label='Downlink Resource'
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/wrd_resource_combo/${first_device_coords[0]}/${first_device_coords[1]}/${second_device_coords[0]}/${second_device_coords[1]}/`,
                            onSelectChange:setPostData,
                            value: lineConfigData[`${props.core.id}_downlink_resource_combo`]
                            }}
                            formConfig={{
                            required: true
                            }}
                        />
                    </Col>

                    <Col span={lineConfigData.line_cable_combo.value == 3? 6:0}>
                        <BuildFormItem
                        name={`${props.core.id}_downlink_port`}
                        label='In/Downlink Port'
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/wrd_deviceports_combo/${lineConfigData[`${props.core.id}_downlink_resource_combo`]!=null?lineConfigData[`${props.core.id}_downlink_resource_combo`].value:0}/`,
                            onSelectChange:setPostData,
                            value: lineConfigData[`${props.core.id}_downlink_port_combo`]
                            }}
                        formConfig={{
                            required: true,
                            hidden: (lineConfigData.line_cable_combo != null)?((lineConfigData.line_cable_combo.value == 3)? false : true):true
                            }}
                        />
                    </Col>
                    <Col span={lineConfigData.line_cable_combo.value ==3? 12:0}></Col>
                    <Col span={6}>
                        <BuildFormItem
                        name={`${props.core.id}_uplink_resource`}
                        label='Uplink resource'
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/wrd_resource_combo/${first_device_coords[0]}/${first_device_coords[1]}/${second_device_coords[0]}/${second_device_coords[1]}/`,
                            onSelectChange:setPostData,
                            value: lineConfigData[`${props.core.id}_uplink_resource_combo`]
                            }}
                            formConfig={{
                            required: true
                            }}
                        />
                    </Col>

                    <Col span={lineConfigData.line_cable_combo.value == 3?6:0}>
                        <BuildFormItem
                        name={`${props.core.id}_uplink_port`}
                        label='Out/Uplink Port'
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/wrd_deviceports_combo/${lineConfigData[`${props.core.id}_uplink_resource_combo`]!=null?lineConfigData[`${props.core.id}_uplink_resource_combo`].value:0}/`,
                            onSelectChange:setPostData,
                            value: lineConfigData[`${props.core.id}_uplink_port_combo`]
                            }}
                        formConfig={{
                            required: true,
                            hidden: (lineConfigData.line_cable_combo != null)?((lineConfigData.line_cable_combo.value == 3)? false : true):true
                            }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
    const ToolsForm = (props)=>{
        return(
            <div className={classes.content}>
                <Row gutter={[16,16]}>
                {props.check_col!=null ?
                     <Col span={24}>
                        {props.check_col.map((item)=>(
                                <MakeCheckBox item_key={item.check_key} item_title = {item.title}/>
                        ))}
                        
                    </Col>: <Col span={0}></Col>}
                </Row>
            </div>
        )
    }
    const CoreMappingForm = (props)=>{ 
        return(<div className={classes.content}>
            {props.cores.map((item)=>(
                <MappingRow core = {item}/>
            ))
            }
        </div>)
    }

    const downlinkConnectorConfigSet= (configData)=>{
        let newConfig={...lineConfigData.downlink_connector_config,  ...configData}
        setConfigData((prevVal)=>{
            return {...prevVal,  ...{downlink_connector_config: newConfig}}
        })
    }

    const uplinkConnectorConfigSet= (configData)=>{
        let newConfig={...lineConfigData.uplink_connector_config,  ...configData}
        setConfigData((prevVal)=>{
            return {...prevVal,  ...{uplink_connector_config:newConfig}}
        })
    }

    let steps = [
        {
            title: "Configuration",
            content: <Config1Form />,
            step: 1
        },
        {
            title: "Configuration",
            content: <Config2Form />,
            step: 2
        },
        {
        title: "Select Laying Tools",
        content: <ToolsForm check_col={boxes}/>,
        step: 3
        }
        ]


    let cat_config_visibility = (lineConfigData.line_cable_combo != null)?((lineConfigData.line_cable_combo.value == 1)? true : false):false

    if (cat_config_visibility){
        let config_steps=[
            {
                title: "Downlink Connector",
                content: <RJ45Config check_col={boxes} connectorConfigSet={downlinkConnectorConfigSet} config={lineConfigData.downlink_connector_config}/>,
                step: 4
            },
            {
                title: "Uplink Connector",
                content: <RJ45Config check_col={boxes} connectorConfigSet={uplinkConnectorConfigSet} config={lineConfigData.uplink_connector_config}/>,
                step: 5
            }
        ]
        steps = steps.concat(config_steps)
    }
    if(lineConfigData.cores != null){
        let core_config_visibility = (lineConfigData.line_cable_combo != null)?((lineConfigData.line_cable_combo.value == 2 || lineConfigData.line_cable_combo.value == 3 || lineConfigData.line_cable_combo.value == 5)? true : false):false
        if (core_config_visibility){
            const core_mapping_form =[{
                title: lineConfigData.line_cable_combo.value != 3 ? "Core Mapping": "Pair Mapping",
                content: <CoreMappingForm cores={lineConfigData.cores}/>,
                step: 4
            }]
            steps = steps.concat(core_mapping_form)
        }
    }else{
            getDeviceCores()
        }
    
        if(lineConfigData.image_name==''){
            getDeviceDetails();
        }
    // steps = steps.concat(laying_form)
    
    return (
        <div className={classes.formmain}>
            <Form form={stepForm} onFinish={onFinish}>
                <StepPanel
                    className={classes.step_panel}
                    steps={steps}
                    direction='horizontal'/>
            </Form>
        </div>
    )

}