import CardBasedList from "../../components/CardBasedList/CardBasedList";

export const ActiveCourseList = (props) => {
  const onItemClick=(item)=>{
    props.onItemClick(item)
  }
    return (
      <>
        <CardBasedList data={props.data} auth={props.auth} onCardSelect={onItemClick} />
      </>
    )
}
