import { BellOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { faAngleDown, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Col, Divider, Dropdown, Layout, Menu, Row } from 'antd';
// import "antd/dist/antd.min.css";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { logoutUser } from '../../actions/authentication';
import pic from "../../assets/logo.png";
import Clock from '../../components/Clock/Clock';
import MenuBuilder from '../../components/dragableMenu/MenuBuilder';
import { PrivateRoute } from '../../components/PrivateRoute';
import { SiderMenu } from '../../components/SiderMenu/siderMenu';
import { ActiveCourseListView } from '../../pages/ActiveCourses/ActiveCourseListVew';

import ActiveStudentCourseList from '../../pages/ActiveStudentCourses/ActiveStudentCourseList';
import AntennasList from '../../pages/Antennas/AntennasList';
import CourseList from '../../pages/Courses/CourseList';
import DeviceManufacturerList from '../../pages/DeviceManufacturer/DeviceManufacturerList';
import HFListPage from '../../pages/DeviceSettings/HFRadioList';
import PermissionsList from '../../pages/Permissions/PermissionsList';
import PowerSourceLIst from '../../pages/PowerSource/PowerSourceLIst';
import { Settings } from '../../pages/Settings/Settings';
import StudentList from '../../pages/Students/StudentList';
import TeacherList from '../../pages/Teachers/TeacherList';
import { TrainingView } from '../../pages/Training/TrainingView';
import UserList from '../../pages/User/UserList';
import { UserPassChange } from '../../pages/User/UserPassChange';
////
//Wireless Equipments CRUD
import { EquipmentAdd } from '../../pages/WlEquipments/AddPage';
import WlEquipmentsList from '../../pages/WlEquipments/WlEquipmentsList';
import WlEquipmentsOutlinesList from '../../pages/WLEquipOutlines/WlEquipmentsOutlinesList';
////
// Wired Equipment CRUD
import { WrdBrandAdd } from '../../pages/WrdEquipments/Brands/WrdBrandAdd';
import WrdBrandList from '../../pages/WrdEquipments/Brands/WrdBrandList';
//
import { WrdCableAdd } from '../../pages/WrdEquipments/Cables/WrdCableAdd';
import WrdCableList from '../../pages/WrdEquipments/Cables/WrdCableList';
//
import { WrdConnectorAdd } from '../../pages/WrdEquipments/Connectors/WrdConnectorAdd';
import WrdConnectorList from '../../pages/WrdEquipments/Connectors/WrdConnectorList';
//
import { WrdEquipmentsAdd } from '../../pages/WrdEquipments/Equipments/WrdEquipmentsAdd';
import WrdEquipmentsList from '../../pages/WrdEquipments/Equipments/WrdEquipmentsList';
//
import { WrdModelAdd } from '../../pages/WrdEquipments/Models/WrdModelAdd';
import WrdModelList from '../../pages/WrdEquipments/Models/WrdModelList';
//
import { WrdToolsAdd } from '../../pages/WrdEquipments/WiredTools/WrdToolsAdd';
import WrdToolsList from '../../pages/WrdEquipments/WiredTools/WrdToolsList';

// Anik Change
import EquipmentListPage from '../../pages/Scenario_Equipment_List/EquipmentListPage';
import { WrdFiberCoreAdd } from '../../pages/WrdEquipments/Fibercores/WrdFibercoreAdd';
import WrdFiberCores from '../../pages/WrdEquipments/Fibercores/WrdFibercores';

// Scenario
import { AddScenario } from '../../pages/Scenarios/AddScenario';
import { ScenariosView } from '../../pages/Scenarios/ScenariosView';

// Anik Change
import UserThemeMode from '../../pages/AccountSettings/UserThemeMode.js';
import ReportListPage from '../../pages/comparision_report/Report';

// homes
import About from '../About/About';
import Home from '../Home/Home';
import classes from './Layout.module.css';

// UserPassChange.jsx


const { Content, Header, Footer, Sider } = Layout;
const { Item } = Menu;

const element = <FontAwesomeIcon icon={faCoffee} />
const arrowdown = <FontAwesomeIcon icon={faAngleDown} />

class BaseLayout extends Component {
    constructor(props){
      super(props);
      if(!this.props.auth.isAuthenticated){
        this.props.history.push('/');
      }
      this.state = {
        bgcolor: JSON.parse(localStorage.getItem("theme")) === 'Dark' ? 'black' : 'white',
        colors : JSON.parse(localStorage.getItem("theme")) === 'Dark' ? 'white' : 'black',
        arrowclr : JSON.parse(localStorage.getItem("theme")) === 'White' ? 'arrowclr' : 'notfound',
        sideSvg : JSON.parse(localStorage.getItem("theme")) === 'White' ? 'sideSvg' : 'notfound',
        sliderClass : JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteSlider' : 'blackSlider',
      }
    }
    onLogout(e) {
        e.preventDefault();
        this.props.logoutUser(this.props.history);
    }
    state = {
        collapsed: false,
    }
    toggle = () => {
        this.setState({
          collapsed: !this.state.collapsed,
        });
      };

    onThemeChange = (pram)=>{
      console.log("On theme change:");
      console.log(pram);
    }

    render() {
        const {isAuthenticated, user} = this.props.auth;
        const {bgcolor, colors, arrowclr, sideSvg, sliderClass} = this.state;

        if(this.props.bgcolor && this.props.colors && this.props.arrowclr && this.props.sideSvg && this.props.sliderClass)
        {
          bgcolor = this.props.bgcolor;
          colors = this.props.colors;
          arrowclr = this.props.arrowclr;
          sideSvg = this.props.sideSvg;
          sliderClass = this.props.sliderClass;
        }

        const menu = (
          <Menu>
            <Menu.Item key="1">
              <Button onClick={this.onLogout.bind(this)}>Logout</Button>
            </Menu.Item>
          </Menu>
        );

        const Footer_text=()=>{
          const d = new Date();
          let year = d.getFullYear();
          return(
            <div>
               <p>{`Copyright © ${year} ,Divine IT Limited-All right reserved`}</p>
            </div>
          )
        }

        return (

          <Layout>
          <Header className={[classes.site_header]} style={{ padding: 0, backgroundColor:bgcolor, color:colors }}>

          <Row justify='space-between' className={classes.header_mainrow} style={{backgroundColor:bgcolor, color:colors }}>

          <Col className={classes.logo_col}>
            <Link to="/">
              <div className={classes.logo} style={{backgroundColor:bgcolor, color:colors }}>
                <img  src={pic} alt="" />
                <div className={classes.logo_name}>
                    <h2 style={{backgroundColor:bgcolor, color:colors }}>{this.props.title}</h2>
                    <h3 style={{backgroundColor:bgcolor, color:colors }}>{this.props.sub_title}</h3>
                </div>
              </div>
            </Link>

          </Col>

          <Col className={classes.header_right}>
            <Row justify='space-between' className={classes.header_mainrow}>
            <Col className={classes.belloutline}>
            <BellOutlined style={{ color: colors, fontSize:'20px'}}/>
            <Divider className={classes.divider} type='vertical' style={{ fontWeight:'bold', backgroundColor:colors }} />
          </Col>
          
          <Col className={classes.clock_time}>
          <Clock />
             <Divider className={classes.divider} type='vertical' style={{ fontWeight:'bold', background: colors }} />
          </Col>
          <Col className={classes.dropdown_avater}>
          <Dropdown className={classes.dropdown_trigger}  overlay={menu} trigger={["click"]} placement="bottomLeft">
              <div onClick={e => e.preventDefault()}>
                <Avatar size="small" icon={<UserOutlined />} />
                  <div className={classes.user_data}>
                       <h5 style={{backgroundColor:bgcolor, color:colors }}>{this.props.auth.user?.data?.username}</h5>
                       <p style={{backgroundColor:bgcolor, color:colors }}>{this.props.auth.user?.data?.user_type_text}</p>

                        <span className={arrowclr}>{arrowdown}</span>
                        
                  </div>

              </div>
            </Dropdown>
          </Col>
      </Row>
      </Col>
      </Row>
        </Header>

      <Layout>
          <Sider trigger={null} collapsible collapsed={this.state.collapsed} className={classes.sidebar} style={{backgroundColor:bgcolor, color:colors }}>

              <div className={`${classes.search_toggle_bar} ${sideSvg}`}>
                  {this.state.collapsed ? <MenuUnfoldOutlined className= {classes.trigger} onClick={this.toggle}></MenuUnfoldOutlined> : <MenuFoldOutlined className= {classes.trigger} onClick={this.toggle}></MenuFoldOutlined>}
              </div>
              
                  <SiderMenu user={user} style={{backgroundColor:colors}}/>
          </Sider>

      <Layout className={classes['layout-site-layout']}>

        <Content className={[classes.main_content, sliderClass].join(' ')} style={{

              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              backgroundColor:bgcolor === 'white' ? "#F0F2F5" : "", 
              color:colors === "black" ? "#00F0FF" : "",
            }}>
            <PrivateRoute exact path="/trainers" component={ TeacherList } auth={this.props.auth} />
            <PrivateRoute exact path="/students" component={ StudentList } auth={this.props.auth} />
            <PrivateRoute exact path="/home" component={ Home } auth={this.props.auth} />
            <PrivateRoute exact path="/account_settings" component={UserThemeMode} auth={this.props.auth} onThemeChange = {this.onThemeChange}/>
            <PrivateRoute exact path="/about" component={ About } auth={this.props.auth} />
            {/* users */}
            <PrivateRoute exact path="/users" auth={this.props.auth} component={UserList} />
            <PrivateRoute exact path="/users/change_password/:id" auth={this.props.auth} component={UserPassChange} />
            

            <PrivateRoute exact path="/device_uses" auth={this.props.auth} component={EquipmentListPage} />

            {/* Comparision Report */}
            <PrivateRoute exact path="/comparision_report" auth={this.props.auth} component={ReportListPage} />
            
            <PrivateRoute exact path="/student_active_courses" component={ActiveStudentCourseList} auth={this.props.auth}/>
            <PrivateRoute exact path="/courses" auth={this.props.auth} component={CourseList} />
            <PrivateRoute exact path="/active_courses" component={ActiveCourseListView} auth={this.props.auth}/>
            <PrivateRoute exact path="/power_source" auth={this.props.auth} component={PowerSourceLIst} />
            <PrivateRoute exact path="/antennas" auth={this.props.auth} component={AntennasList} />
            {/* Wireless Equipment */}
            <PrivateRoute exact path="/wl_equipments" auth={this.props.auth} component={WlEquipmentsList} />
            <PrivateRoute exact path='/wl_equipments/add' component={EquipmentAdd} auth={this.props.auth}/>
            <PrivateRoute exact path='/wl_equipments/edit/:id' component={EquipmentAdd} auth={this.props.auth} edit={true}/>
            <PrivateRoute exact path='/wl_equipments/duplicate/:id' component={EquipmentAdd} auth={this.props.auth} duplicate={true}/>

            {/* Wired Equipments */}
            {/* cables */}
            <PrivateRoute exact path="/wire_tools" auth={this.props.auth} component={WrdToolsList} />
            <PrivateRoute exact path="/wire_tools/add" auth={this.props.auth} component={WrdToolsAdd} />
            <PrivateRoute exact path="/wire_tools/edit/:id" auth={this.props.auth} component={WrdToolsAdd} edit={true}/>
            <PrivateRoute exact path="/wire_tools/duplicate/:id" auth={this.props.auth} component={WrdToolsAdd} edit={true}/>

            {/* wire_brand */}
            <PrivateRoute exact path="/wire_brand" auth={this.props.auth} component={WrdBrandList} />
            <PrivateRoute exact path="/wire_brand/add" auth={this.props.auth} component={WrdBrandAdd} />
            <PrivateRoute exact path="/wire_brand/edit/:id" auth={this.props.auth} component={WrdBrandAdd} edit={true}/>
            <PrivateRoute exact path="/wire_brand/duplicate/:id" auth={this.props.auth} component={WrdBrandAdd} edit={true}/>

            {/* cables */}
            <PrivateRoute exact path="/wire_cables" auth={this.props.auth} component={WrdCableList} />
            <PrivateRoute exact path="/wire_cables/add" auth={this.props.auth} component={WrdCableAdd} />
            <PrivateRoute exact path="/wire_cables/edit/:id" auth={this.props.auth} component={WrdCableAdd} edit={true}/>
            <PrivateRoute exact path="/wire_cables/duplicate/:id" auth={this.props.auth} component={WrdCableAdd} edit={true}/>

            {/* Equipments */}
            <PrivateRoute exact path="/wire_comm_equipments" auth={this.props.auth} component={WrdEquipmentsList} />
            <PrivateRoute exact path='/wire_comm_equipments/add' component={WrdEquipmentsAdd} auth={this.props.auth}/>
            <PrivateRoute exact path='/wire_comm_equipments/edit/:id' component={WrdEquipmentsAdd} auth={this.props.auth}edit={true}/>
            <PrivateRoute exact path="/wire_comm_equipments/duplicate/:id" auth={this.props.auth} component={WrdEquipmentsAdd} edit={true}/>
            
            {/* Connectors  */}
            <PrivateRoute exact path="/wire_connectors" auth={this.props.auth} component={WrdConnectorList} />
            <PrivateRoute exact path="/wire_connectors/add" auth={this.props.auth} component={WrdConnectorAdd} />
            <PrivateRoute exact path="/wire_connectors/edit/:id" auth={this.props.auth} component={WrdConnectorAdd} edit={true}/>
            <PrivateRoute exact path="/wire_connectors/duplicate/:id" auth={this.props.auth} component={WrdConnectorAdd} edit={true}/>

            {/* wire_model */}
            <PrivateRoute exact path="/wire_model" auth={this.props.auth} component={WrdModelList} />
            <PrivateRoute exact path="/wire_model/add" auth={this.props.auth} component={WrdModelAdd} />
            <PrivateRoute exact path="/wire_model/edit/:id" auth={this.props.auth} component={WrdModelAdd} edit={true}/>
            <PrivateRoute exact path="/wire_model/duplicate/:id" auth={this.props.auth} component={WrdModelAdd} edit={true}/>
            
            {/* wire_model */}
            <PrivateRoute exact path="/wire_optical_fiber_core" auth={this.props.auth} component={WrdFiberCores} />
            <PrivateRoute exact path="/wire_optical_fiber_core/edit/:id" auth={this.props.auth} component={WrdFiberCoreAdd} edit={true}/>
            
            
            {/* Device related */}
            <PrivateRoute exact path="/device_manufacturer" component={DeviceManufacturerList} auth={this.props.auth}/>
            <PrivateRoute exact path="/settings" component={Settings} auth={this.props.auth} />
            <PrivateRoute exact path="/wl_comm_equip_outlines" component={WlEquipmentsOutlinesList} auth={this.props.auth} />
            <PrivateRoute exact path="/training" component={TrainingView} auth={this.props.auth} />
            <PrivateRoute exact path="/permissions" component={PermissionsList} auth={this.props.auth} />
            <PrivateRoute exact path="/hf_radio" component={HFListPage} auth={this.props.auth} />
            <PrivateRoute exact path="/menu_builder" component={MenuBuilder} auth={this.props.auth} user={user}/>

            {/* Scenario route */}
            <PrivateRoute exact path="/scenarios" component={ScenariosView} auth={this.props.auth} user={user}/>
            <PrivateRoute exact path="/scenarios/add_scenarios" component={AddScenario} auth={this.props.auth} user={user}/>
            <PrivateRoute exact path="/scenarios/edit/:id" component={AddScenario} auth={this.props.auth} user={user} edit={true}/>

            <Footer style={{ textAlign: isAuthenticated?'left':'center', backgroundColor:bgcolor, color:colors }} className={classes['site-footer']}>
             <Footer_text/>

            </Footer>
        </Content>

      </Layout>
      </Layout>
      </Layout>
        );
    }
}

BaseLayout.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(withRouter(BaseLayout));
