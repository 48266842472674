import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from 'react';
import * as http from '../../http';
import { buildFormItem as BuildFormItem } from '../../utils';
// import ListView from "../../components/ListView/ListView";
import { ModalTitle } from "../../components/ModalTitle/ModalTitle";
import SearchItem from "../../containers/BaseList/SearchBox/SearchItem";
import { AddScenario } from '../Scenarios/AddScenario';
import { MapConfig } from "./MapConfig";
import { ScenarioMapView } from "./ScenarioMapView";
import { SearchFormMapper } from "./SearchFormMapper";
import TrainingHeader from "./TrainingHeader";
import { CheckPointConfig } from "./WiredDevices/CheckPointConfig";
import { EDFAConfig } from "./WiredDevices/EDFAConfig";
import { EPONConfig } from "./WiredDevices/EPONConfig";
import { ExchangerConfig } from "./WiredDevices/ExchangerConfig";
import { FaultControlConfig } from "./WiredDevices/FaultControlConfig";
import { LANPhoneConfig } from "./WiredDevices/LANPhoneConfig";
import { MCConfig } from "./WiredDevices/MCConfig";
import { OLTConfig } from "./WiredDevices/OLTConfig";
import { OnuConfig } from "./WiredDevices/OnuConfig";
import { OpticalNodeConfig } from "./WiredDevices/OpticalNodeConfig";
import { RojetBoxConfig } from "./WiredDevices/RojetBoxConfig";
import { RouterConfig } from "./WiredDevices/RouterConfig";
import { SIPConfig } from "./WiredDevices/SIPConfig";
import { ServerConfig } from "./WiredDevices/ServerConfig";
import { SplitterConfig } from "./WiredDevices/SplitterConfig";
import { SwitchConfig } from "./WiredDevices/SwitchConfig";
import { TJConfig } from "./WiredDevices/TJConfig";
import { TNTLineConfig } from "./WiredDevices/TNTLineConfig";
import { TNTPhoneConfig } from "./WiredDevices/TNTPhoneConfig";
import { TVConfig } from "./WiredDevices/TVConfig";
import { TVNetworkConfig } from "./WiredDevices/TVNetworkConfig";
import { WiredDeviceConfig } from "./WiredDevices/WiredDeviceConfig";
import { WiredLineConfig } from "./WiredDevices/WiredLineConfig";

import HRRConfigPage from "./Hybrid/HRRConfigPage";

import classes from './TrainingHeader.module.css';
import { TrainingScenarioDetails } from "./TrainingScenarioDetails";
import { DeviceConfig } from "./WirelessDevices/DeviceConfig";
import { HFConfig } from "./WirelessDevices/HFConfig";
import { MicrowaveConfigPage } from "./WirelessDevices/MicrowaveConfigPage";
import RRConfigPage from "./WirelessDevices/RRConfigPage";
import { VhfDeviceConfig } from "./WirelessDevices/VhfDeviceConfig";

// import { faPlay } from '@fortawesome/fa4-play';
{/* <FontAwesomeIcon icon="fa-solid fa-play" /> */}

export const TrainingView=(props)=>{
    // console.log('Call Traning: ', props)
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [isScenarioTypeModalVisible, setScenarioTypeModalVisible] = React.useState(false);
    const [isDeviceConfigModalVisible, setDeviceConfigModalVisible] = React.useState(false);
    const [DeviceConfigModalTitle, setDeviceConfigModalTitle] = React.useState('Device Config');
    const [isConfigModalVisible, setConfigModalVisible] = React.useState(false);
    const [scenarioID, setScenarioID] = React.useState(null);
    const [configData, setConfigData] = React.useState({});
    const [eventData, setEventData] = React.useState(null);
    const [popupID, setpopupID] = React.useState(null);
    const [editUrl, setEditUrl] = React.useState(null);
    const [formType, setFormType] = React.useState(null);
    const [configTitle, setConfigTitle] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [select_data, setSelectData] = React.useState({
        select_scenario_id_combo:null,
        select_student_id_combo:null,
    });
    const [refreshState, setRefreshState] = useState(10); // integer state
    const [groupMashFlag, setGroupMashFlag] = useState(false); // integer state
    function fetchSelectList(url){
        http.get(url).then((data)=>{
            if( data?.data.length > 0){
                return data?.data[0]['name']
            }else{
                return null
            }
             
        })
    }
    useEffect(() => {
        let fn = ({data}) => {
            // console.log("Training Event data: ", data);
            let {type} = data;
            if (data?.popupId){
                setpopupID(data.popupId);
            }
            // setpopupID(data.popupId);
            
            switch(type) {
                case "feature.add": {
                    
                    if (data?.popupId){
                        setConfigData(
                            {
                                scenarioID: data?.scenarioID,
                                equipmentData: data?.equipment_data,
                                scenarioData: data?.scenario_data,
                                comm_equipment_type: data?.comm_equipment_type,
                                wrd_equipment: data?.wrd_equipment,
                                geometry:data?.model?.geometry,
                                equipment_type: data?.equipment_type,
                                event_type: type,
                                popupID: data?.popupId,
                            });
                        //for form title 
                        // console.log("Title: ", data.equipment_type?.comm_frequency, configData?.equipmentData?.comm_freq)
                        setDeviceConfigModalTitle(`${data.equipment_type?.comm_frequency? data.equipment_type?.comm_frequency : data.equipment_type?.wrd_equipment_type_name} Config`)
                        setDeviceConfigModalVisible(true);
                    }
                    break;
                }
                case "feature.update": {
                    if (data?.popupId){
                        setConfigData(
                            {
                                scenarioID: data.model.properties.scenario_id,
                                configID: data.model.properties.id,
                                equipmentData: data?.equipment_data,
                                scenarioData: data?.scenario_data,
                                comm_equipment_type: data?.comm_equipment_type,
                                wrd_equipment: data?.wrd_equipment,
                                geometry:data?.model?.geometry,
                                equipment_type: data?.equipment_type,
                                event_type: type,
                                popupID: data?.popupId,
                            }); 
                            
                        //for form title 
                        setDeviceConfigModalTitle(`${data.equipment_type?.comm_frequency? data.equipment_type?.comm_frequency : data.equipment_type?.wrd_equipment_type_name} Config`)
                        setDeviceConfigModalVisible(true);
                    }
                    break;
                }
                case "popupclose" :{
                    setDeviceConfigModalVisible(false);
                    setConfigModalVisible(false);
                    break;
                }
                case "line.add": {
                    setConfigTitle('Add Power Line')
                    setFormType('power_line');
                    setConfigModalVisible(true);
                    break;
                }
                case "line.update": {
                    setConfigTitle('Edit Power Line')
                    setFormType('power_line');
                    setConfigData({scenarioID: data.model.properties.scenario_id, configID: data.model.properties.id});
                    setConfigModalVisible(true);
                    break;
                }
                case "line_laying.add": {
                    // console.log("Create Signal: " + JSON.stringify(data, null, 4));
                    setConfigTitle('Add Line');
                    setFormType('line_laying');
                    setConfigModalVisible(true);
                    break;
                }
                case "line_laying.update": {
                    setConfigTitle('Update Line');
                    setFormType('line_laying');
                    setConfigData({scenarioID: data.model.properties.scenario_id, configID: data.model.properties.id});
                    setConfigModalVisible(true);
                    break;
                }
                case "building.add": {
                    setConfigTitle('Add Building/Tower')
                    setFormType('building');
                    setConfigModalVisible(true);
                    break;
                }
                case "building.update": {
                    setConfigTitle('Edit Building/Tower')
                    setFormType('building');
                    setConfigData({scenarioID: data.model.properties.scenario_id, configID: data.model.properties.id});
                    setConfigModalVisible(true);
                    break;
                }
                case "mobile_tower.add": {
                    setConfigTitle('Add Mobile Tower')
                    setFormType('mobile_tower');
                    setConfigModalVisible(true);
                    break;
                }
                case "mobile_tower.update": {
                    setConfigTitle('Edit Mobile Tower')
                    setFormType('mobile_tower');
                    setConfigData({scenarioID: data.model.properties.scenario_id, configID: data.model.properties.id});
                    setConfigModalVisible(true);
                    break;
                }
                case "Forest.add": {
                    setConfigTitle('Add Vegetation')
                    setFormType('forest');
                    setConfigModalVisible(true);
                    break;
                }
                case "Forest.update": {
                    setConfigTitle('Edit Vegetation')
                    setFormType('forest');
                    setConfigData({scenarioID: data.model.properties.scenario_id, configID: data.model.properties.id});
                    setConfigModalVisible(true);
                    break;
                }
                case "WaterBody.add": {
                    setConfigTitle('Add Water Body')
                    setFormType('water_body');
                    setConfigModalVisible(true);
                    break;
                }
                case "WaterBody.update": {
                    setConfigTitle('Edit Water Body')
                    setFormType('water_body');
                    setConfigData({scenarioID: data.model.properties.scenario_id, configID: data.model.properties.id});
                    setConfigModalVisible(true);
                    break;
                }
                case "River.add": {
                    setConfigTitle('Add River')
                    setFormType('river');
                    setConfigModalVisible(true);
                    break;
                }
                case "River.update": {
                    setConfigTitle('Edit River')
                    setFormType('river');
                    setConfigData({scenarioID: data.model.properties.scenario_id, configID: data.model.properties.id});
                    setConfigModalVisible(true);
                    break;
                }
                case "Road.add": {
                    setConfigTitle('Add Road')
                    setFormType('road');
                    setConfigModalVisible(true);
                    break;
                }
                case "Road.update": {
                    setConfigTitle('Edit Road')
                    setFormType('road');
                    setConfigData({scenarioID: data.model.properties.scenario_id, configID: data.model.properties.id});
                    setConfigModalVisible(true);
                    break;
                }
                case "RailLine.add": {
                    setConfigTitle('Add Rail Line')
                    setFormType('railline');
                    setConfigModalVisible(true);
                    break;
                }
                case "RailLine.update": {
                    setConfigTitle('Edit Rail Line')
                    setFormType('railline');
                    setConfigData({scenarioID: data.model.properties.scenario_id, configID: data.model.properties.id});
                    setConfigModalVisible(true);
                    break;
                }
                case "Rain.add": {
                    setConfigTitle('Add Rainy Area');
                    setFormType('rain');
                    setConfigModalVisible(true);
                    break;
                }
                case "Rain.update": {
                    setConfigTitle('Edit Rainy Area');
                    setFormType('rain');
                    setConfigData({scenarioID: data.model.properties.scenario_id, configID: data.model.properties.id});
                    setConfigModalVisible(true);
                    break;
                }
                case "Fog.add": {
                    setConfigTitle('Add Foggy Area');
                    setFormType('fog');
                    setConfigModalVisible(true);
                    break;
                }
                case "Fog.update": {
                    setConfigTitle('Edit Foggy Area');
                    setFormType('fog');
                    setConfigData({scenarioID: data.model.properties.scenario_id, configID: data.model.properties.id});
                    setConfigModalVisible(true);
                    break;
                }
                case "dessertBody.add": {
                    window.frames[0].postMessage({
                        data: {'config': {}},
                        type: 'resolve',
                        popupId: data.popupId
                        })
                }
                case "dessertBody.update": {
                    window.frames[0].postMessage({
                        data: {'config': {}},
                        type: 'resolve',
                        popupId: data.popupId
                        })
                }
            }
        };
        window.addEventListener("message", fn);
        return () => window.removeEventListener("message", fn);
    },[setEventData]);

    const getSearchFields=()=>{
        const children = [];
        for (let i = 0; i < SearchFormMapper.length; i++) {
            children.push(
                <SearchItem key={i} index={i} config={SearchFormMapper[i]}/>
            );
        }
        return children;
    }
    const getPageHeaderExtra=()=>{
        return [
            <HeaderMenu/>
        ]
    }

    const setChangeSelectData=(field, type, label, value)=>{
        let newData = {};
        if(type == 'header-select'){
            if(value){
                newData[field] = value.value;
                newData[`${field}_combo`] = value;
            }
            else{
                newData[field] = null;
                newData[`${field}_combo`] = null;
            }
        }
        
        setSelectData((prevVal)=>{
            let newValue={...prevVal, ...newData}
            return newValue
        })
        // console.log(newData);
    }
    const HeaderMenu=()=>{
        let clr = JSON.parse(localStorage.getItem("theme")) === 'White' ? '#132555' : '#ffffff';
        return(
            <>
            <Row gutter={16, 16}>
                    <Col className={classes.extrafakecls}>
                        <Button 
                        className={'run_btn'} 
                        key="pattern_scenario" 
                        onClick={()=>{ 
                            setGroupMashFlag(!groupMashFlag);
                            }}>{!groupMashFlag? "GROUP":"MASH"}
                        </Button>
                    </Col>
                    <Col className={classes.extrafakecls}>
                        <Button 
                        className={'run_btn'} 
                        key="run_scenario" 
                        onClick={()=>{ 
                            setRefreshState(value => value + 1);
                            }}>
                            RUN
                        </Button>
                    </Col>
                    <Col style={{width:'200px'}} className={classes.fakecls}>
                        <BuildFormItem
                            key={'select_scenario_id'}
                            name={`select_scenario_id`}
                            label={'Scenario'}
                            index={props.index}
                            type='header-select'
                            fieldConfig={{
                                url:`/api/combo/scenarios/`,
                                onSelectChange:setChangeSelectData,
                                value: select_data.select_scenario_id_combo,
                                filterSort: false,
                                placeholder: 'Scenario'
                                }}
                        />
                    </Col>
                    <Col style={{width: props.auth?.user?.data?.user_type==2? '0px' :'200px'}} className={classes.fakecls}>
                        <BuildFormItem
                            key={'select_student_id'}
                            label={'Student'}
                            name={`select_student_id`}
                            index={props.index}
                            type='header-select'
                            fieldConfig={{
                                url:`/api/combo/scenario_students/${select_data?.select_scenario_id_combo?.value!=null? select_data?.select_scenario_id_combo?.value:0}`,
                                onSelectChange:setChangeSelectData,
                                value: select_data.select_student_id_combo,
                                placeholder:'Student'
                                }}
                            formConfig={{
                                hidden: props.auth?.user?.data?.user_type==2? true : false
                            }}
                                
                        />
                    </Col>
                    
                    {/* <Col className={classes.extrafakecls}>
                        <Button className={'create_btn'} key="add_new_scenario" onClick={()=>setModalVisible(true)}>
                            Create New Scenario
                        </Button>
                    </Col> */}
            </Row>
            </>
        )
    }
    const onFormFinish=(values)=>{
        console.log(values);
    }
    const handleOk = () => {
        setModalVisible(false);
        setEditUrl(null);
    };

    const handleCancel = () => {
        setModalVisible(false);
        setEditUrl(null);
    };

    const handleDeviceConfigModalCancel=()=>{
        window.frames[0].postMessage({
            type: 'reject',
            popupId: popupID
        })
        setDeviceConfigModalVisible(false);
    }
    const handleConfigModalCancel=()=>{
        window.frames[0].postMessage({
            type: 'reject',
            popupId: popupID
        })
        setConfigModalVisible(false);
    };

    const onEditButtonClicked=(scenario_id)=>{
        setModalVisible(true);
        setEditUrl(`/api/scenario/${scenario_id}/`)
    };

    const BuildDeviceConfigPage=()=>{
        // debugger;
        // For Radio Devices
        // console.log("In React",configData?.equipmentData?.transmission_media, configData?.equipmentData?.comm_freq);
        if(configData?.scenarioData?.scenario_type==1){
            if(configData?.equipmentData?.transmission_media == 1 || configData?.equipmentData?.transmission_media == 4){
                // For HF Devices
                if(configData?.equipmentData?.comm_freq == 0) {
                    return <DeviceConfig popupID={popupID} configData={configData}/>
                }
                // For VHF
                else if(configData?.equipmentData?.comm_freq == 1) {
                    return <VhfDeviceConfig popupID={popupID} configData={configData}/>
                }
                // For RR
                else if(configData?.equipmentData?.comm_freq == 2){
                    return <RRConfigPage popupID={popupID} partial={true} configData={configData} auth={props.auth}/>;
                }
            }
            // For MicroWave
            else if(configData?.equipmentData?.transmission_media == 2){
                return <MicrowaveConfigPage popupID={popupID} partial={true} configData={configData} auth={props.auth}/>;
            }
        }else if(configData?.scenarioData?.scenario_type==2 || configData?.scenarioData?.scenario_type==3){
            let configPage ='';
            // console.log(configData)
            if (configData?.comm_equipment_type == 2){
                configPage = configData?.equipment_type?.wrd_equipment_type_name
            }else{
                configPage = configData?.equipment_type?.comm_frequency
            }
            // console.log("Only config")
            // console.log(configPage);
            // return <DeviceConfig popupID={popupID} configData={configData}/> Cable
            if (configPage =='Router'){
                // setDeviceConfigModalTitle('Router Config') 
                // return <RouterConfig popupID={popupID} configData={configData} refresh={refreshState} setRefreshState={setRefreshState}/>; // for auto refresh
                return <RouterConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'Cable'){
                return <WiredLineConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'ONU'){
                return <OnuConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'TJBox'){
                return <TJConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'SIP'){
                return <SIPConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'OLT'){
                return <OLTConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'TVNetwork'){
                return <TVNetworkConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'OpticalNode'){
                return <OpticalNodeConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'EDFA'){
                return <EDFAConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'EPON'){
                return <EPONConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'Switch'){
                return <SwitchConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'LANphone'){
                return <LANPhoneConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'TV'){
                return <TVConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'TNTphone'){
                return <TNTPhoneConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'TNTLine'){
                return <TNTLineConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'Exchanger'){
                return <ExchangerConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'FaultControl'){ 
                return <FaultControlConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'Splitter'){ 
                return <SplitterConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'CheckPoint'){ 
                return <CheckPointConfig popupID={popupID} configData={configData}/>;
            }
            else if (configPage == 'MC'){
                return <MCConfig popupID={popupID} configData={configData} />;
            }
            else if (configPage == 'RojetBox'){
                return <RojetBoxConfig popupID={popupID} configData={configData} />;
            }
            else if (configPage == 'Server'){
                return <ServerConfig popupID={popupID} configData={configData} />;
            }else if (configPage == 'UHF'){
                return <HRRConfigPage popupID={popupID} partial={true} configData={configData} auth={props.auth}/>;
            }else if (configPage == 'HF'){
                return <HFConfig popupID={popupID} partial={true} configData={configData} auth={props.auth}/>;
            }else if (configPage == 'VHF'){
                return <VhfDeviceConfig popupID={popupID} partial={true} configData={configData} auth={props.auth}/>;
            }
            else{
                return <WiredDeviceConfig popupID={popupID} configData={configData}/>
            }
            
        }
        
        return null;
    };

    const onTypeSelectFinish=(type)=>{
        // console.log("Type Selected")
    }

    const onDefaultScenarioSelection=(id)=>{
        // console.log("onDefaultScenarioSelection: ", id)
        setScenarioID(id)
    }

    return (
        <>
            {/* wireless scenario add page */}
            <Modal
                title={<ModalTitle title={editUrl ? 'Update Scenario': 'New Scenario Add'} />}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose={true}
                footer={null}
                centered={true}
                width={1000}
                className={'scenario_add_form'}
            >
                <AddScenario
                    onFinish={onFormFinish}
                    setModalVisible={setModalVisible}
                    editUrl={editUrl}
                    source = 'map'
                />
            </Modal>
            {/* Device config add page */}
            <Modal
                title={<ModalTitle title={DeviceConfigModalTitle} />}
                visible={isDeviceConfigModalVisible}
                onOk={() => setDeviceConfigModalVisible(false)}
                onCancel={handleDeviceConfigModalCancel}
                destroyOnClose={true}
                footer={null}
                centered={true}
                width={1000}
            >
                <BuildDeviceConfigPage />
            </Modal>
            {/* Map config add page. buildingform, waterbody,vegitation etc form comes from mapconfig file */}
            <Modal
                title={<ModalTitle title={configTitle} />}
                visible={isConfigModalVisible}
                onOk={() => setConfigModalVisible(false)}
                onCancel={handleConfigModalCancel}
                destroyOnClose={true}
                footer={null}
                centered={true}
                width={1000}
            >
                <MapConfig popupID={popupID} configData={configData} formType={formType} />
            </Modal>
            <TrainingHeader
                pageTitle='Training Environment'
                scenarioTitle = {select_data?.select_scenario_id_combo?.label? select_data?.select_scenario_id_combo?.label : fetchSelectList("/api/combo/scenarios/")}
                viewSpan={24}
                {...props}
                getPageHeaderExtra={getPageHeaderExtra}
            />
            <ScenarioMapView {...props} 
                scenarioID={select_data?.select_scenario_id_combo?.value} 
                studentID={select_data?.select_student_id_combo?.value} 
                refresh_flag={refreshState}
                groupMashFlag = {groupMashFlag}
                onDefaultScenarioSelection={onDefaultScenarioSelection}/>
            
            {/* <ScenarioMapView {...props} scenarioID={1} /> */}
            <TrainingScenarioDetails {...props} 
            scenarioID={scenarioID}
            refresh_flag={refreshState}
            />
        </>
    )
}