import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { logoutUser } from './actions/authentication';
import store from './store';

	let client = axios.create({
	baseURL: process.env.REACT_APP_GATEWAY,
	// withCredentials: true,
	});

	client.interceptors.request.use(config => {
		const JWT = localStorage.getItem('jwtToken')
		config.headers.Authorization = JWT ? `Bearer ${JWT}` : '';
		return config;
	});

	client.interceptors.response.use(
	(resp) => resp,
	(err) => {
		const errorResponse = err.response
		if (isTokenExpiredError(errorResponse)) {
			return resetTokenAndReattemptRequest(err)
		}
		return Promise.reject(err);
	}
	);

	function isTokenExpiredError(errorResponse) {
		// Your own logic to determine if the error is due to JWT token expired returns a boolean value
		if(errorResponse?.status == 401){
			return true;
		}
		return false;
	}
	let isAlreadyFetchingAccessToken = false;

	// This is the list of waiting requests that will retry after the JWT refresh complete
	let subscribers = [];
	
	async function resetTokenAndReattemptRequest(error) {
		console.log('Call HTTP')
		try {
		const { response: errorResponse } = error;
		const resetToken = localStorage.getItem('refreshToken'); // Your own mechanism to get the refresh token to refresh the JWT token
		if (!resetToken) {
			// We can't refresh, throw the error anyway
			return Promise.reject(error);
		}
		/* Proceed to the token refresh procedure
		We create a new Promise that will retry the request,
		clone all the request configuration from the failed
		request in the error object. */
		const retryOriginalRequest = new Promise(resolve => {
		/* We need to add the request retry to the queue
		since there another request that already attempt to
		refresh the token */
			addSubscriber(access_token => {
			errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
			resolve(axios(errorResponse.config));
			});
		});
		if (!isAlreadyFetchingAccessToken) {
			isAlreadyFetchingAccessToken = true;
			const response = await axios({
			method: 'post',
			url: '/o/refresh_token',
			data: {
				refreshtoken: resetToken
			}
			});
			if (!response.data) {
				// window.location.reload()
				store.dispatch(logoutUser());
				window.location.href = '/'
				return Promise.reject(error);
			}
			const newToken = response.data.access_token;
			const newRefreshToken = response.data.refresh_token;
			const decoded = jwt_decode(newToken);
			localStorage.setItem('jwtToken', newToken); // save the newly refreshed token for other requests to use
			localStorage.setItem('refreshToken', newRefreshToken); // save the newly refreshed token for other requests to use
			isAlreadyFetchingAccessToken = false;
			onAccessTokenFetched(newToken);
		}
		return retryOriginalRequest;
		} catch (err) {
			// window.location.reload()
			store.dispatch(logoutUser());
        	window.location.href = '/'
			return Promise.reject(err);
		}
	}
	
	function onAccessTokenFetched(access_token) {
		// When the refresh is successful, we start retrying the requests one by one and empty the queue
		subscribers.forEach(callback => callback(access_token));
		subscribers = [];
	}
	
	function addSubscriber(callback) {
		subscribers.push(callback);
	}


	export const get = async (url, config) => {
	let response = client.get(url, config);
	return response;
	};



	export const post = async (url, data) => {
	let response = client.post(url, data);
	return response;
	};

	export const put = async (url, data) => {
	let response =client.put(url, data);
	return response;
	};

	export const del = async (url) => {
		let response =client.delete(url);
		return response;
		};

	let w_client = axios.create({
	baseURL: 'https://api.openweathermap.org',
	// withCredentials: true,
	});

	// export const delete = async (url, data) => {
	//   return client.delete(url, data);
	// };
