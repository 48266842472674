import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class About extends Component {
    componentDidMount() {
        if(!this.props.auth.isAuthenticated) {
            this.props.history.push('/');
        }
    }
    render() {
        return (
            <div>
                About Component
            </div>
        );
    }
}
About.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps)(withRouter(About));