import { DownOutlined } from '@ant-design/icons';
import { TimePicker } from "antd";
import moment from 'moment';
import { useState } from 'react';
import classes from './TimeField.module.css';

export const TimeField=(props)=>{
    const handleValueChange=(value)=>{
        const timeString = moment(value).format("HH:mm");
        if(props?.hasOwnProperty('onSelect')){
            props.onSelect(timeString);
        }
    }
    const mode = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteTimeField : classes.TimeField;
return (
    <TimePicker
        className={mode}
        format="HH:mm"
        showNow={false}
        suffixIcon={<DownOutlined />}
        value={moment(props.initialValue || '00:00', "HH:mm")}
        onSelect={(value) => handleValueChange(value)}
        popupClassName={classes.timepicker}
        // {...props}
    />
)
}
