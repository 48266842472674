import { EditOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalTitle } from '../../components/ModalTitle/ModalTitle';
import BaseList from "../../containers/BaseList/BaseList";
import * as http from '../../http';
import { columnMapper } from './columnMapper';
import { SearchFormMapper } from './SearchFormMapper';
import { WlAddPage } from './WlEditPage';

class wlOutlinesListPage extends BaseList {
  constructor(props){
    super(props);
  }
  getColumns(){
    return [
      ...columnMapper,
      {
        title: 'Action',
        key: 'action',
        width: '100',
        fixed:'right',
        render: (text, record) => (
          <Space size="middle">
          <Button key='editButton' style={{fontSize:"20px",height:"auto" }} icon={<EditOutlined />} onClick={()=>this.showModal(`/api/wl_comm_equip_outlines/${record.id}`)}></Button>
          </Space>
        )
      }
    ];
  }

  getDataUrl(){
    return '/api/wl_comm_equip_outlines/'; 
  }

  getPageConfig(){
    return {
      pageTitle:'Wireless Comm Equipments Outline List',
      pageButtons: [],
    }
  }

  getSearchFields(){
    return SearchFormMapper;
  }
  onUpdateClick=(values)=>{
    console.log(values)
    http.put(`/api/wl_comm_equip_outlines/${values.WlEquipOutLines.id}`, values.WlEquipOutLines)
      .then((response)=> {
      if(response.status == 200){
        this.setState({
          isModalVisible: false,
        })
      }
      this.fetch();
    }).catch((err)=>{
      this.errorCallback(err.response);
    })
  }
  render(){
    const colorClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'customantmodal' : 'notfound';

    return (
      <>
      {super.render()}
      <Modal 
      title={<ModalTitle title="Edit Equipments Outlines" />}
      visible={this.state.isModalVisible}
      onOk={this.handleOk}
      onCancel={this.handleCancel}
      destroyOnClose={true}
      footer={null}
      centered={true}
      width={1000}
      className={colorClass}
      >
        <WlAddPage 
          onFinish={this.onUpdateClick}
          // errors={this.state.errors}

          editUrl={this.state.editUrl}
        />
      </Modal>
      </>
    );


  }

}
const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps)(withRouter(wlOutlinesListPage));
