import { Button, Col, Form, Row } from 'antd';
import React, { useState } from 'react';
import classes from './SearchBox.module.css';


const SearchBox = (props) =>{
  const searchTheme = JSON.parse(localStorage.getItem("theme")) === "White" ? 'whitesearch_form' : 'search_form';
  const searchButton = JSON.parse(localStorage.getItem("theme")) === "White" ? 'whitesearchform_btn' : 'searchform_btn';

  const [expand, setExpand] = useState(false);
  const [searchValues, setSearchValues] = useState({});
  const [form] = Form.useForm();

  const updateSearchValues=(name, type, value)=>{
    setSearchValues((prevVal)=>{
      const newData = {};
      newData[name] = value;
      return {...prevVal, ...newData}
    })
  }
  // To generate mock Form.Item
  const getFields = () => {
    const fields = props.getFields(updateSearchValues) || [];
    if(fields.length == 1)
    {
      return (
          <>


              <Col span={6}>
              {fields[0]}
          </Col>
          <Col span={6}>
               {fields[1]}
          </Col>

          </>
      )

    }

    if(fields.length == 2)
    {
      return (
          <>
          <Col span={6}>
              {fields[0]}
          </Col>
          <Col span={6}>
               {fields[1]}
          </Col>
          </>
      )

    }

    if(fields.length == 3)
    {
      return (
          <>
          <Col span={6}>
              {fields[0]}
          </Col>
          <Col span={6}>
               {fields[1]}
          </Col>
          </>
      )

    }

    if(fields.length == 4)
    {
      return (
          <>
          <Col span={6}>
              {fields[0]}
          </Col>
          <Col span={6}>
               {fields[1]}
          </Col>
          </>
      )

    }

    if(fields.length == 5)
    {
      return (
          <>
          <Col span={6}>
              {fields[0]}
          </Col>
          <Col span={6}>
               {fields[1]}
          </Col>
          </>
      )

    }
    return null;

  };

  const onFinish = (values) => {
    const searchData = {...values, ...searchValues}
    return props.onSearchClick(searchData);
  };

  const getInitialValues = ()=>{
    return props.initialValues() || {};
  }
  const resetFields = ()=>{
    form.resetFields();
    props.resetSerach();
  }
    return (
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form search_form_main"
        onFinish={onFinish}
        initialValues={getInitialValues()}
      >
        <Row gutter={[16, 16]} className={[classes.SearchBox, searchTheme].join(' ')}>
            {getFields()} 
        {getFields() && (<Col span={4} className={searchButton}>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              resetFields();
            }}
          >
            Clear
          </Button>
          {/* <Button
           style={{ margin: '0 8px' }}
           onClick={() => {
            setExpand(!expand);
           }}
          >
              {expand ? <UpOutlined /> : <DownOutlined />} Collapse
          </Button> */}
          {/* <a
            style={{ fontSize: 12 }}
            onClick={() => {
              setExpand(!expand);
            }}
          >
            {expand ? <UpOutlined /> : <DownOutlined />} Collapse
          </a> */}
        </Col>)}
        </Row>
        {/*<Row className={classes.submitRow}>*/}
        {/*<Col span={24} style={{ textAlign: 'right' }}>*/}
          {/*<Button type="primary" htmlType="submit">*/}
            {/*Search*/}
          {/*</Button>*/}
          {/*<Button*/}
            {/*style={{ margin: '0 8px' }}*/}
            {/*onClick={() => {*/}
              {/*resetFields();*/}
            {/*}}*/}
          {/*>*/}
            {/*Clear*/}
          {/*</Button>*/}
          {/*/!* <Button*/}
           {/*style={{ margin: '0 8px' }}*/}
           {/*onClick={() => {*/}
            {/*setExpand(!expand);*/}
           {/*}}*/}
          {/*>*/}
              {/*{expand ? <UpOutlined /> : <DownOutlined />} Collapse*/}
          {/*</Button> *!/*/}
          {/*/!* <a*/}
            {/*style={{ fontSize: 12 }}*/}
            {/*onClick={() => {*/}
              {/*setExpand(!expand);*/}
            {/*}}*/}
          {/*>*/}
            {/*{expand ? <UpOutlined /> : <DownOutlined />} Collapse*/}
          {/*</a> *!/*/}
        {/*</Col>*/}
      {/*</Row>*/}
      </Form>
    );
        }

export default SearchBox;
