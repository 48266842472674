import { Col, Row } from "antd";
import React, { useEffect } from 'react';
import * as http from '../../http';

export const TrainingScenarioDetails = (props) => {
    // console.log('refresh_flag: ', props.refresh_flag)
    const [scenarioData, setScenarioData] = React.useState(null);
    const [weatherData, setWeatherDataData] = React.useState(null);
    let scenarioId = props.scenarioID
    useEffect(() => {
        if (scenarioId) {
            http.get(`/api/scenario/${scenarioId}`)
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            setScenarioData(resp.data)
                            console.log('Scenario: ', resp.data)
                            let coordinates = resp.data.coordinates.split(', ')
                            // console.log(coordinates)
                            getWeather(coordinates[0],coordinates[1])
                        }
                    }
                ).catch((err) => {
                    console.log('Error: ', err)
                })
            
        }
    }, [scenarioId, setScenarioData]);

    let getWeather = (lat, lng) =>{
        const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&appid=23b14a24e9155828db8c5b425997464a`;
            // Fetch data from the OpenWeatherMap API
            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    console.log('Weather: ', data)
                    setWeatherDataData(data)
                })
                .catch(error => {
                    console.log('Error fetching data:', error);
                });
    }
    return (
        <div>
            <Row className={'Scenario_Details_title'}>
                <h3>Scenario Details</h3>
            </Row>
            <Row>
                <Col span={8}>
                    <div className={'ScenarioDetails_View'}>
                        <div className={'ScenarioCard'}>
                            <div className={'custom-border'}></div>
                            <p>Scenario Info</p>
                        </div>
                        <div className={'scenario_card_details'}>
                            <Row className={'Scenario_info_title_row'}>
                                <Col span={6}>Date <span>{scenarioData?.entry_date}</span></Col>
                                <Col span={6}>Time<span>{scenarioData?.scenario_time}</span> </Col>
                                <Col span={6}>Scenario <span>{scenarioData?.name}</span> </Col>
                            </Row>
                            <Row className={'Scenario_info_title_row'}>
                                <Col span={6}>Temperature <span>{scenarioData?.temperature}</span></Col>
                                <Col span={6}>Humidity <span>{scenarioData?.humidity}</span></Col>
                                <Col span={6}> Rain Type <span>{scenarioData?.rain_type}</span> </Col>
                            </Row>
                            <Row className={'Scenario_info_title_row'}>
                                <Col span={6}>Wind Speed <span>{scenarioData?.wind_speed}</span></Col>
                                <Col span={6}>Fog Type <span>{scenarioData?.fog_type}</span></Col>
                                <Col span={6}>Layer D Height <span>{scenarioData?.layer_d_height} </span></Col>
                            </Row>
                            <Row className={'Scenario_info_title_row'}>
                                <Col span={6}>Layer E Height <span>{scenarioData?.layer_e_height}  </span></Col>
                                <Col span={6}>Layer F Height <span>{scenarioData?.layer_f_height}  </span></Col>
                                <Col span={6}>Layer F1 Height <span>{scenarioData?.layer_f1_height}  </span></Col>
                            </Row>
                            <Row className={'Scenario_info_title_row'}>
                                <Col span={6}>Layer F2 Height <span>{scenarioData?.layer_f2_height}  </span></Col>
                                <Col span={6}>Layer D Density <span>{scenarioData?.layer_d_density}*10^11 </span></Col>
                                <Col span={6}>Layer E Density <span>{scenarioData?.layer_e_density}*10^11</span></Col>
                            </Row>
                            <Row className={'Scenario_info_title_row'}>
                                <Col span={6}>Layer F Density <span>{scenarioData?.layer_f_density}*10^11</span></Col>
                                <Col span={6}>Layer F1 Density <span>{scenarioData?.layer_f1_density}*10^11</span></Col>
                                <Col span={6}>Layer F2 Density <span>{scenarioData?.layer_f2_density}*10^11</span></Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col span={8}>
                    <div className={'ScenarioDetails_View'}>
                        <div className={'ScenarioCard'}>
                            <div className={'custom-border'}></div>
                            <p>Current Weather Info</p>
                        </div>
                        <div className={'scenario_card_details'}>

                            <Row className={'Scenario_info_title_row background-1'}>
                                <Col span={6}>Location</Col>
                                <Col span={6}>Temperature</Col>
                                <Col span={6}>Weather</Col>
                                <Col span={6}>Wind </Col>
                            </Row>

                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>{weatherData?.name? weatherData?.name : ''} </Col>
                                <Col span={6}>{(weatherData?.main?.temp? weatherData?.main?.temp - 273.15:0.0).toFixed(2)} C </Col>
                                <Col span={6}>{weatherData?.weather[0]?.description? weatherData?.weather[0]?.description : ''} </Col>
                                <Col span={6}>{weatherData?.wind?.speed? weatherData?.wind?.speed : ''} Km </Col>
                            </Row>

                        </div>



                    </div>
                </Col>
                <Col span={8}>
                    <div className={'ScenarioDetails_View'}>
                        <div className={'ScenarioCard'}>
                            <div className={'custom-border'}></div>
                            <p>Frequency Prediction</p>
                        </div>

                        <div className={'scenario_card_details'}>

                            <Row className={'Scenario_info_title_row background-1'}>
                                <Col span={6}>Time</Col>
                                <Col span={6}>Frequency</Col>
                                <Col span={6}>Time</Col>
                                <Col span={6}>Frequency</Col>
                            </Row>

                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 0</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_0}</Col>
                                <Col span={6}>Hour 1</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_1}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 2</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_2}</Col>
                                <Col span={6}>Hour 3</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_3}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 4</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_4}</Col>
                                <Col span={6}>Hour 5</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_5}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 6</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_6}</Col>
                                <Col span={6}>Hour 7</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_7}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 8</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_8}</Col>
                                <Col span={6}>Hour 9</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_9}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 10</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_10}</Col>
                                <Col span={6}>Hour 11</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_11}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 12</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_12}</Col>
                                <Col span={6}>Hour 12</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_12}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 13</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_13}</Col>
                                <Col span={6}>Hour 13</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_13}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 14</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_14}</Col>
                                <Col span={6}>Hour 15</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_15}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 16</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_16}</Col>
                                <Col span={6}>Hour 17</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_17}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 18</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_18}</Col>
                                <Col span={6}>Hour 19</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_19}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 20</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_20}</Col>
                                <Col span={6}>Hour 21</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_21}</Col>
                            </Row>
                            <Row className={'Scenario_info_table_data_row'}>
                                <Col span={6}>Hour 22</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_22}</Col>
                                <Col span={6}>Hour 23</Col>
                                <Col span={6}>{scenarioData?.time_frequency?.hour_23}</Col>
                            </Row>
                        </div>

                    </div>
                </Col>
            </Row>
        </div>
    )

}

