// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddPage_footer__oCno\\+ {\n  background: #012032;\n}\n.AddPage_formLabel__wJ51L {\n  color: #4a6e83;\n}\n.AddPage_modalContent__16qx2 {\n  /* display: flex; */\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DeviceManufacturer/AddPage.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".footer {\n  background: #012032;\n}\n.formLabel {\n  color: #4a6e83;\n}\n.modalContent {\n  /* display: flex; */\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "AddPage_footer__oCno+",
	"formLabel": "AddPage_formLabel__wJ51L",
	"modalContent": "AddPage_modalContent__16qx2"
};
export default ___CSS_LOADER_EXPORT___;
