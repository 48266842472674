// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Clock_clockTime__idTpL {\n  font-size: small;\n  color: turquoise;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Clock/Clock.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".clockTime {\n  font-size: small;\n  color: turquoise;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clockTime": "Clock_clockTime__idTpL"
};
export default ___CSS_LOADER_EXPORT___;
