import { Button, Col, Form, Image, message, Row } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { FileUploader } from '../../components/FileUploder.jsx';
import ContentHeader from '../../containers/Layout/ContentHeader/ContentHeader';
import { StepPanel } from "../../containers/StepPanel/StepPanel";
import * as http from '../../http';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../utils';
import classes from './AddPage.module.css';


  export const EquipmentAdd = (props) => {
    const themeChange = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitethm' : 'notfound' ;

    const [formPostData, setFormPostData] = React.useState({
      manufacturer: null,
      manufacturer_combo: null,
      comm_freq: null,
      comm_freq_combo: null,
      transmission_media: null,
      transmission_media_combo: null,
      usability_type: [],
      usability_type_combo: [],
      // ps_type: null,
      // ps_type_combo: null,
      power_source: [],
      power_source_combo: [],
      modulation: [],
      modulation_combo: [],
      commsec: null,
      encryption: null,
      antenna_type: [],
      antenna_type_combo: [],
      hopping: 0,
      frequency_hop_rate: null,
      frequency_hop_rate_combo: null,
      gps: 0,
      image_url:process.env.REACT_APP_GATEWAY+"/api/filesapi/getfile/11669711378.73759logo2.png",
      image_name:"",
    });
    const [stepForm] = Form.useForm();
    const [errors, setErrors] = React.useState({});
    const [outlineData, setOutlineData] = React.useState({});

    let isMounted = useIsMounted();
    let dataMounted = useIsMounted();
    const { id } = useParams();

    const getEquipmentData=()=>{
      http.get(`/api/wl_comm_equipments/${id}`).then((resp)=>{
        console.log(resp.data)
          _.forOwn(resp.data, (value, key)=>{
            if(!formPostData.hasOwnProperty(key)){
              const val = {};
              val[key] = value;
              stepForm.setFieldsValue(val);
              delete resp.data[key];
            }
          })
          if(dataMounted.current){
            setFormPostData((prevVal)=>{
              return {...prevVal, ...resp.data}
            })
          }
      }).catch((err)=>{
        console.log(err);
      })
    }

    useEffect(()=>{
      if(props.edit || props.duplicate){
        getEquipmentData();
      }
      
    return () => { dataMounted = false }; 
    }, [])

    useEffect(()=>{
      getoutlineData(formPostData.transmission_media, formPostData.comm_freq)
      return () => { isMounted = false }; 
    }, [formPostData.transmission_media, formPostData.comm_freq])

    const setPostData=(field, type, label, value)=>{
      let newData = {};
       let genericType = ['input', 'checkbox', 'date', 'time', 'checkbox-group'];
      if(type == 'select'){
        if(value){
        if(Array.isArray(value)){
          let valueList = [];
          for(let i=0; i<value.length; i++){
            if (value[i].value != null){
              console.log('Multi: ', value[i].value)
              valueList.push(value[i].value);
            }
            
          }
          newData[field] = valueList;
          newData[`${field}_combo`] = value;
        }
        else{
          newData[field] = value.value;
          newData[`${field}_combo`] = value;
        }
        }
        else{
        newData[field] = null;
        newData[`${field}_combo`] = null;
        }
      }
      else if(type == 'input-button'){
        if(value == 'plus'){
        const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
        const updatedValue = {};
        updatedValue[field] = newValue;
        stepForm.setFieldsValue(updatedValue)
        }
        else{
        const newValue = parseInt(stepForm.getFieldsValue()[field]|| 0) - 1;
        const updatedValue = {};
        updatedValue[field] = newValue;
        stepForm.setFieldsValue(updatedValue)
        }
      }
      // else if(type == 'checkbox')
      // {
      //   newData[field] = value;
      // }
      else if (genericType.includes(type)) {
            newData[field] = value;
        }

      setFormPostData((prevVal)=>{
        return {...prevVal, ...newData}
      })
  }
    const getoutlineData = (media, freq) => {
      if (media != null && freq != null) {
        http.get(`/api/wl_comm_equip_outlines/?transmission_media=${media}&comm_freq=${freq}`).then(resp => {
          if (resp.data.rows?.length >= 1) {
            if (isMounted.current){
            setOutlineData(resp.data.rows[0])
            }
          }
        });
      }
      else if (media && freq == null) {
        http.get(`/api/wl_comm_equip_outlines/?transmission_media=${media}`).then(resp => {
          if (resp.data.rows?.length >= 1) {
            if (isMounted.current){
            setOutlineData(resp.data.rows[0])
            }
          }
        });
      }
    }
    const Step1Form = () => {
      return (
        <div className={classes.Form}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                name='name'
                label='Name'
                type='input'
                errorObj={errors}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='model'
                label='Model'
                type='input'
                errorObj={errors}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                name='manufacturer'
                label='Manufacturer'
                type='select'
                fieldConfig={{
                  value:formPostData.manufacturer_combo,
                  url:"/api/manufacturer/manufacturer_combo/",
                  onSelectChange:setPostData
                }}
                errorObj={errors}
              />
            </Col >
            <Col span={12}>
              <BuildFormItem
                name='transmission_media'
                label='Transmission Media'
                type='select'
                fieldConfig={{
                  value:formPostData.transmission_media_combo,
                  url:"/api/combo/equipment_type",
                  onSelectChange:setPostData
                }}
                errorObj={errors}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                name='usability_type'
                label='Station Type'
                type='select'
                fieldConfig={{
                  value:formPostData.usability_type_combo,
                  url:"/api/combo/station_type_combo",
                  onSelectChange:setPostData,
                  mode: 'multiple'
                }}
                errorObj={errors}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='comm_freq'
                label='Frequency Type'
                type='select'
                fieldConfig={{
                  value:formPostData.comm_freq_combo,
                  url:"/api/combo/frequency_combo",
                  onSelectChange:setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden: (formPostData.transmission_media == 1 || formPostData.transmission_media == 4)?false:true
                }}
              />
            </Col>
          </Row>
        </div>
      );
    };

    const buildConfigForm = () => {
      return (
        <div className={classes.Form}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                name='tx_freq_max'
                label='Tx Frequency Max(MHz)'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.tx_freq_max
                }}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='tx_freq_min'
                label='Tx Frequency Min(MHz)'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.tx_freq_min
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                  name='rx_freq_max'
                  label='Rx Frequency Max(MHz)'
                  type='input-button'
                  fieldConfig={{
                    onBeforeButtonClicked: setPostData,
                    onAfterButtonClicked: setPostData
                  }}
                  errorObj={errors}
                  formConfig={{
                    hidden:!outlineData?.rx_freq_max
                  }}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='rx_freq_min'
                label='Rx Frequency Min(MHz)'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errrorObj={errors}
                formConfig={{
                  hidden:!outlineData?.rx_freq_min
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                name='bandwidth'
                label='Channel Bandwidth (MHz)'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.bandwidth
                }}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='no_of_channels'
                label='Channel Capacity'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.no_of_channels
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                name='modulation'
                label='Modulation'
                type='select'
                fieldConfig={{
                  value:formPostData.modulation_combo,
                  url:"/api/combo/modulation",
                  onSelectChange:setPostData,
                  mode: 'multiple'
                }}
                errorObj={errors}
                formConfig={{
                  hidden: !outlineData?.modulation
                }}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='receving_sensitivity'
                label='Receiving Sensitivity (dBm)'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.receving_sensitivity
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={2}>
              <BuildFormItem
                name='hopping'
                label='Hopping'
                type='checkbox'
                fieldConfig={{
                  style: {color: "white" },
                  checked:formPostData.hopping,
                  onChange: setPostData
                  }}
                errorObj={errors}
              />
            </Col>
            <Col span={10}>
              <BuildFormItem
                name='frequency_hop_rate'
                label='Frequency Hope Rate (Hope/Sec)'
                type='select'
                fieldConfig={{
                  value:formPostData.frequency_hop_rate_combo,
                  url:"/api/combo/frequency_hop_rate",
                  onSelectChange:setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden: !formPostData.hopping
                }}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='power_output'
                label='Base station Power Output (Watt)'
                type='input'
                fieldConfig={{
                  placeholder: "Low:10, Medium:20, High:30"
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.power_output
                }}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='power_output_mpack'
                label='Manpack Power Output (Watt)'
                type='input'
                fieldConfig={{
                  placeholder: "Low:10, Medium:20, High:30"
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.power_output_mpack
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                name='gps'
                label='GPS'
                type='checkbox'
                fieldConfig={{
                  style: {color: "white" },
                  checked:formPostData.gps,
                  onChange: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.gps
                }}
              />
            </Col>

            <Col span={12}>
              <BuildFormItem
                name='antenna_type'
                label='Antenna'
                type='select'
                fieldConfig={{
                  value:formPostData.antenna_type_combo,
                  url:`/api/combo/antenna/?transmission_media=${formPostData.transmission_media}&frequency=${formPostData.comm_freq}`,
                  onSelectChange:setPostData, 
                  mode: 'multiple'
                }}
                errorObj={errors}
                formConfig={{
                  hidden: !outlineData?.antenna_type
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                name='power_source'
                label='Power Source'
                type='select'
                fieldConfig={{
                  value:formPostData.power_source_combo,
                  url:'/api/combo/equipments/power_source',
                  onSelectChange:setPostData, 
                  mode: 'multiple'
                }}
                errorObj={errors}
                formConfig={{
                  hidden: !outlineData?.power_source
                }}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='antenna_impedance'
                label='Impedence (Ohms)'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.antenna_impedance
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                name='internal_input_voltage'
                label='Internal Input Voltage (V)'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.internal_input_voltage
                }}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='external_input_voltage'
                label='External Input Voltage (V)'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.external_input_voltage
                }}
              />
            </Col>

          </Row>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <BuildFormItem
                name='tx_power_consumption'
                label='Tx. Current Consumption Mode (Ah)'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.tx_power_consumption
                }}
              />
            </Col>

            <Col span={8}>
              <BuildFormItem
                name='rx_power_consumption'
                label='Rx. Current Consumption Mode (Ah)'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.rx_power_consumption
                }}
              />
            </Col>
            <Col span={8}>
              <BuildFormItem
                name='sb_power_consumption'
                label='Current Consumption Stand By (Ah)'
                type='input-button'
                fieldConfig={{
                  onBeforeButtonClicked: setPostData,
                  onAfterButtonClicked: setPostData
                }}
                errorObj={errors}
                formConfig={{
                  hidden:!outlineData?.sb_power_consumption
                }}
              />
            </Col>

          </Row>

        </div>
      )
    }

    const Step2Form = () => {
      return (
        buildConfigForm()
      );
    };

    const FileUpload = () => {
      return (
        <div className={classes.Form}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Image
                width={300}
                src={`${formPostData.image_url}`}
              />
            </Col>
            <Col span={2}>
              <FileUploader onFinished={onUploadFinished}/>
            </Col>
          </Row>
        </div>
      )
    }
    

    const errorCallback=(error)=>{
      if(error?.status == 422){
        const errorData = {};
        for(var i=0; i<error.data.detail.length; i++){
            let field = error.data.detail[i].loc[1];
            let errorMessage = error.data.detail[i].msg;
            errorData[field] = {errorMsg: errorMessage, validateStatus:'error'}
        }
        setErrors(errorData)
      }
    }
    
    const onFinish = (fieldsValue) => {
      const formData = stepForm.getFieldsValue();
      let postData = {...fieldsValue, ...formPostData}
      if(props.edit){
        http.put(`/api/wl_comm_equipments/${id}`, postData).then((response) => {
          if (response.status == 200) {
            setErrors({})
            message.success('Equipments Updated');
            stepForm.resetFields();
            props.history.push('/wl_equipments')

          }
        }).catch((err) => {
          message.error('Equipmets Creation Failed');
          errorCallback(err.response);
        })
    }
      else{
        http.post('/api/wl_comm_equipments/', postData).then((response) => {
          if (response.status == 201) {
            setErrors({})
            message.success('Equipments Added');
            stepForm.resetFields();
            props.history.push('/wl_equipments')

          }
        }).catch((err) => {
          message.error('Equipmets Creation Failed');
          // this.errorCallback(err.response);
        })
    }
    };

    const onUploadFinished = (response)=>{
      setFormPostData((prevVal)=>{
        return {...prevVal, ...{image_url:response?.url,image_name:response.name}}
      })
    }

    const steps = [
      {
        title: "Basic Information",
        content: <Step1Form />,
        step: 1
      },
      {
        title: "Config Information",
        content: <Step2Form />,
        step: 2
      },
      {
        title: "Upload Image",
        content: <FileUpload/>,
        step: 3
      },
    ];
    return (
      <>
        <ContentHeader
          title={props.edit? 'Update Equipment': 'Add Equipment'}
          pageButtons={[
          <Button
            key='equipment-list'
            className={'create_btn'}
            onClick={()=>props.history.push('/wl_equipments')}
            type="primary"
            size="small"
        >
          Equipment List
        </ Button>
          ]}
        ></ContentHeader>
        <div className={[classes.formmain, themeChange].join(' ')}>
          <Form form={stepForm} onFinish={onFinish} style={{ height: '90%' }} form={stepForm}>
            <StepPanel className={classes.step_panel} steps={steps} direction='vertical' />
          </Form>
        </div>
      </>
    );
  }
