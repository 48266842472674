// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectField_SelectField__GRVfF {\n  color: white;\n  font-size: 16px;\n  font-family: 'Exo 2', sans-serif;\n  font-weight: 800;\n\n}\n.SelectField_SelectField__GRVfF div span input\n{\n  color: #B2E4FF;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Fields/SelectField.module.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,eAAe;EACf,gCAAgC;EAChC,gBAAgB;;AAElB;AACA;;EAEE,cAAc;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700&display=swap');\n\n.SelectField {\n  color: white;\n  font-size: 16px;\n  font-family: 'Exo 2', sans-serif;\n  font-weight: 800;\n\n}\n.SelectField div span input\n{\n  color: #B2E4FF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectField": "SelectField_SelectField__GRVfF"
};
export default ___CSS_LOADER_EXPORT___;
