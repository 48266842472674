import { faCloudMoon, faCloudSunRain, faCogs, faPlus, faRainbow, faSortAmountDown, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { message, Modal, Tabs } from 'antd';
import 'antd/dist/antd.min.css';
import React, { useState } from 'react';
import ContentHeader from '../../containers/Layout/ContentHeader/ContentHeader';
import * as http from '../../http';

import { AddDayTimeSettings } from './AddPages/AddDayTimeSettings';
import { AddIonosphereSettings } from './AddPages/AddIonosphereSettings';
import { AddSeasonSettings } from './AddPages/AddSeasonSettings';
import { AddTimeFrequency } from './AddPages/AddTimeFrequency';

import { DayTimeSettings } from './DayTimeSettings';
import { GeneralSettings } from './GeneralSettings';
import { IonoSphereSettings } from './IonoSphereSettings';
import { PerformanceAddPage } from './performanceAttAddPage';
import classes from './Settings.module.css';
import { TimeFrequencySettings } from './TimeFrequencySettings';

const fatools = <FontAwesomeIcon icon={faTools} />;
const dayTimeIcon = <FontAwesomeIcon icon={faCloudMoon} />
const ionosphereIcon = <FontAwesomeIcon icon={faRainbow} />
const seasonIcon = <FontAwesomeIcon icon={faCloudSunRain} />
const performanceAttIcon = <FontAwesomeIcon icon={faSortAmountDown} />
const fontplusicon = <FontAwesomeIcon icon={faPlus} />
const faCogsicon = <FontAwesomeIcon icon={faCogs} />

const { TabPane } = Tabs;

export const Settings = () => {
    const [isModalVisible, setModalVisible] = useState(false);
    const [settingsName, setsettingsName] = useState('');
    const [data_id, setDataID] = useState('');
    const [reload, setReload] = useState(false);
    const [modalWidth, setModalWidth] = useState(2000);
    const handleOk = () => {
        setModalVisible(false)
    };

    const handleCancel = () => {
        setModalVisible(false)
    };

    const onAddSaveClick = (values) => {
        http.post('/api/month_wise_day_time', values.DayTimeSettings)
            .then((response) => {
                if (response.status == 201) {
                    setModalVisible(false)
                    setReload(!reload)
                    message.success("Day Time Settings Added")
                }
            }).catch((err)=>{
                message.error("Day Time Settings Creation Failed")
            })

    }
    const onAddSaveClickIonoSphere = (values) => {
        http.post('/api/ionosphere_settings', values.IonoSphereSettings)
            .then((response) => {
                if (response.status == 201) {
                    setModalVisible(false)
                    setReload(!reload)
                    message.success("Ionosphere Settings Added")
                }
            }).catch((err)=>{
                message.error("IonoSphere Settings Creation Failed")
            })

    }
    const onAddSaveClickSeasonSettings = (values) => {
        http.post('/api/season_settings', values.Season_Settings)
            .then((response) => {
                if (response.status == 201) {
                    setModalVisible(false)
                    setReload(!reload)
                    message.success("Season Settings Added")
                }
            }).catch((err)=>{
                message.error("Season Settings Creation Failed")
            })

    }
    const onAddSaveClickPerformanceAtt = (values) =>{
        http.post('/api/month_wise_performance_att', values)
        .then((response)=>{
            if(response.status ==201){
                setModalVisible(false)
                setReload(!reload)
                message.success("New Performance Atteneuation Added");
            }
        })
    
    }

    const onAddSaveClickTimeFrequency = (data) =>{
        if(data_id){
            putTimeFrequencyData(data)
        }else{
            postTimeFrequencyData(data)
        }
    }

    const postTimeFrequencyData = (data)=>{
        http.post('/api/time_frequency/', data).then((response) => {
            if (response.status == 201) {
                setModalVisible(false)
                setReload(!reload)
                message.success('Data save successfully!');
            }
          }).catch((err) => {
            message.error('Data Save Failed');
        })
    }

    const putTimeFrequencyData = (data) => {
        http.put(`/api/time_frequency/${data_id}`, data)
        .then((response) => {
            if (response.status == 200) {
                setModalVisible(false)
                setReload(!reload)
                message.success('mode change and save successfully');
            }
        }).catch((err) => {
          message.error('Color Update Failed');
          console.log(err);
      })
    }

    const settingsForm = (setting_name) => {
        if (setting_name == 'day_time_settings') {
            return (
                <AddDayTimeSettings
                    onFinish={onAddSaveClick}
                    
                />
            )
        }
        else if (setting_name == 'time_frequency_settings') {
            return (
                <AddTimeFrequency
                    onFinish={onAddSaveClickTimeFrequency}
                    data_id={data_id}
                />
            )
        }
        else if (setting_name == 'ionosphere_settings') {
            return (
                <AddIonosphereSettings
                    onFinish={onAddSaveClickIonoSphere}
                />
            )
        }
        else if (setting_name == 'performance_att') {
            return (
                <PerformanceAddPage
                    onFinish={onAddSaveClickPerformanceAtt}
                />
            )
        }
        else {
            return (
                <AddSeasonSettings
                    onFinish={onAddSaveClickSeasonSettings}
                />
            )
        }
    }

    return (
        <>
            <ContentHeader title='Settings' pageButtons={[]}></ContentHeader>

            <Modal title="Time Frequency Settings" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                destroyOnClose={true} footer={null} width={modalWidth} bodyStyle={{ height: "auto", background: '#14666b' }}
                centered={true}>

                {settingsForm(settingsName)}
            </Modal>
            <Tabs defaultActiveKey="1" className={classes.tab}>
                {
                    GeneralSettings({})
                }
                {
                    DayTimeSettings
                        ({ 
                            setModalVisible: setModalVisible, 
                            setsettingsName: setsettingsName, 
                            reload: reload, 
                            setModalWidth: setModalWidth 
                        })
                }{
                    TimeFrequencySettings
                        ({ 
                            setModalVisible: setModalVisible, 
                            setsettingsName: setsettingsName, 
                            reload: reload, 
                            setModalWidth: setModalWidth,
                            setDataID: setDataID 
                        })
                }
                {
                    IonoSphereSettings
                        ({
                            setModalVisible: setModalVisible,
                            setsettingsName: setsettingsName,
                            reload: reload,
                            setModalWidth: setModalWidth
                        })
                }
                {/* {
                    SeasonSettings
                        ({ 
                            setModalVisible: setModalVisible, 
                            setsettingsName: setsettingsName, 
                            reload: reload, 
                            setModalWidth: setModalWidth 
                        })
                }
                {
                    PerformanceAtteneuation
                        ({ 
                            setModalVisible: setModalVisible, 
                            setsettingsName: setsettingsName, 
                            reload: reload, 
                            setModalWidth: setModalWidth 
                        })
                } */}
                
            </Tabs>
        </>
    )
};
