	import { Button, Col, Form, message, Popconfirm, Row, Table } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../../App.css';
import { InputField } from '../../components/Fields/InputField';
import { SelectField } from '../../components/Fields/SelectField';
import { StepPanel } from '../../containers/StepPanel/StepPanel';
import * as http from '../../http';
import { buildFormItem as BuildFormItem, timeConversionSlicker, useIsMounted } from '../../utils';
import classes from './AddPage.module.css';

	export const AddScenario=(props)=> {
		const { id } = useParams();
		// // // // // console.log(id)
		const initialDate = new Date().toISOString().split('T')[0];
		const initialTime = timeConversionSlicker(new Date().toLocaleTimeString('en-US'));
		const [stepForm] = Form.useForm();
		const [equipment_id, setEquipment] = React.useState(null);
		const [communication_types, setCommunicationTypes] = React.useState(null);
		const [equipment_url, setEquipmentUrl] = React.useState('/api/combo/equipment/3/');
		const [resourceKey, setResourceKey] = React.useState(0);
		const [device_comm_type, setDeviceCommType] = React.useState(0);
		const [scenarioData, setScenarioData] = React.useState({
			season: null,
			rain_type: null,
			fog_type: null,
			season_combo: null,
			rain_type_combo: null,
			fog_type_combo: null,
			resources: [],
			status: 0,
			map: 1,
			map_combo: {label: 'Google Map', value: 1},
			scenario_time: initialTime,
			entry_date: initialDate,
			location_name: null,
			location_name_combo: null,
			communication_condition_combo: null,
			scenario_type_combo: {value:3},
			scenario_type:3,
		});
		let isMounted = useIsMounted();
		let isDataMounted = useIsMounted();
		const [columns, setColumns] = React.useState(
			[
				{
					title: 'Equipment',
					dataIndex: 'equipment_id',
					editable: false,
					hidden: true
				},
				{
					title: 'Equipment',
					dataIndex: 'equipment_text',
					editable: false,
				},
				{
					title: 'Equipment Type',
					dataIndex: 'comm_equipment_type',
					editable: false,
				},
				{
					title: 'Quantity',
					dataIndex: 'quantity',
					editable: true,
				},
				{
					title: ' ',
					dataIndex: 'operation',
					render: (_, record) =>
						<Popconfirm okType='default' title="Confirm Delete?" onConfirm={() => handleDelete(record.key)}>
							<a>Delete</a>
						</Popconfirm>
				},
			].filter(item => !item.hidden)
		);

		const [errors, setErrorData] = React.useState({});

		useEffect(()=>{
				if (id) {
				http.get(`/api/scenario/${id}`, {
					method: 'get',
					type: 'json',
				}).then(data => {
					if (isDataMounted.current) {
						setScenarioData((prev_val) => {
							let scData = {...prev_val};
							if (data?.data?.rain_type != null || data?.data?.rain_type != undefined) {
								scData['rain_type_combo'] = {
									'label': data?.data?.rain_type_text,
									'value': data?.data?.rain_type
								};
								scData['rain_type'] = data?.data?.rain_type;
							}
							else {
								scData['rain_type_combo'] = null;
								scData['rain_type'] = null;
							}
							if (data?.data?.fog_type != null || data?.data?.fog_type != undefined) {
								scData['fog_type_combo'] = {
									'label': data?.data?.fog_type_text,
									'value': data?.data?.fog_type
								}
								scData['fog_type'] = data?.data?.fog_type;
							}
							else {
								scData['fog_type_combo'] = null;
								scData['fog_type'] = null;
							}
							if (data?.data?.season != null || data?.data?.season != undefined) {
								scData['season_combo'] = {'label': data?.data?.season_text, 'value': data?.data?.season};
								scData['season'] = data?.data?.season;
							}
							else {
								scData['season_combo'] = null;
								scData['season'] = null;
							}
							// if (data?.data?.location_name != null || data?.data?.location_name != undefined) {
							//   scData['location_name_combo'] = {'label': data?.data?.location_name_text, 'value': data?.data?.location_name};
							//   scData['location_name'] = data?.data?.season;
							// }else {
							//       scData['location_name_combo'] = null;
							//       scData['location_name'] = null;
							//   }

							if (data?.data?.location_name_combo != null || data?.data?.location_name_combo != undefined) {
								scData['location_name_combo'] = data?.data?.location_name_combo
								scData['location_name'] = data?.data?.location_name
							}else {
									scData['location_name_combo'] = null;
									scData['location_name'] = null;
								}

							if (data?.data?.scenario_type_combo != null || data?.data?.scenario_type_combo != undefined) {
								scData['scenario_type_combo'] = data?.data?.scenario_type_combo
							}else {
									scData['scenario_type_combo'] = null;
								}

								if (data?.data?.communication_condition_combo != null || data?.data?.communication_condition_combo != undefined) {
								scData['communication_condition_combo'] = data?.data?.communication_condition_combo
								}else {
									scData['communication_condition_combo'] = null;
								}

							scData['resources'] = data?.data?.resources;
							scData['entry_date'] = data?.data?.entry_date;
							scData['scenario_time'] = data?.data?.scenario_time;
							scData['time_scale'] = data?.data?.time_scale;
							// // // // // console.log("Scenario Data: ", scData);
							return scData;
					});
					}
					_.forOwn(data.data, (value, key) => {
						if (!scenarioData.hasOwnProperty(key)) {
							const val = {};
							val[key] = value;
							stepForm.setFieldsValue(val);
						}
					})
				}).catch((err) => {
					message.error(err?.respons?.data)
				});
			}
		}, []);

		const getScenarioData = () => {
			const scenarioDate = scenarioData.entry_date;
			const time = scenarioData.scenario_time;

			if (scenarioDate && time) {
				const url = `/api/scenario/scenario_initial_data/?entry_date=${scenarioDate}&scenario_time=${time}`
				http.get(url, {
					method: 'get',
					type: 'json',
				}).then(data => {
					if (isMounted.current) {
						setScenarioData((prev_val) => {
							let scData = {...prev_val}
							if (data?.data?.rain_type != null || data?.data?.rain_type != undefined) {
								scData['rain_type_combo'] = {
									'label': data?.data?.rain_type_text,
									'value': data?.data?.rain_type
								}
							}
							else {
								scData['rain_type_combo'] = null;
							}
							if (data?.data?.fog_type != null || data?.data?.fog_type != undefined) {
								scData['fog_type_combo'] = {
									'label': data?.data?.fog_type_text,
									'value': data?.data?.fog_type
								}
							}
							else {
								scData['fog_type_combo'] = null;
							}
							if (data?.data?.season != null || data?.data?.season != undefined) {
								scData['season_combo'] = {'label': data?.data?.season_text, 'value': data?.data?.season}
							}
							else {
								scData['season_combo'] = null;
							}
							// // // // // console.log("Recource Data: ", scData);
							return scData;
						});
					}
					_.forOwn(data.data, (value, key) => {
						if (!scenarioData.hasOwnProperty(key)) {
							const val = {};
							val[key] = value;
							stepForm.setFieldsValue(val);
							delete data.data[key];
						}
					})
				}).catch((err) => {
					message.error(err?.respons?.data)
				});
			}
			return () => {
				isMounted = false
			};

		};

		useEffect(() => {
			getScenarioData();
			return () => {
				isMounted = false
			};
		}, [scenarioData.entry_date, scenarioData.scenario_time]);

		const setPostData = (field, type, label, value) => {
			let newData = {};
			let genericType = ['input', 'checkbox', 'date', 'time', 'checkbox-group']
			if (type == 'select') {
				if (value) {
					if (Array.isArray(value)) {
						let valueList = [];
						for (let i = 0; i < value.length; i++) {
							valueList.push(value[i].value);
						}
						newData[field] = valueList;
						newData[`${field}_combo`] = value;
					}
					else {
						newData[field] = value.value;
						newData[`${field}_combo`] = value;
					}

					// if(`${field}_combo`=='scenario_type_combo'){
					//   handleEquipmentUrl(value)
					// }
				}
				else {
					newData[field] = null;
					newData[`${field}_combo`] = null;
				}
			}
			else if (type == 'input-button') {
				if (value == 'plus') {
					const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
					const updatedValue = {};
					updatedValue[field] = newValue;
					stepForm.setFieldsValue(updatedValue)
				}
				else {
					const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) - 1;
					const updatedValue = {};
					updatedValue[field] = newValue;
					stepForm.setFieldsValue(updatedValue)
				}
			}
			else if (genericType.includes(type)) {
				newData[field] = value;
			}

			setScenarioData((prevVal) => {
				return {...prevVal, ...newData}
			})
		}
		const location_info = (value) =>{
		// // // // console.log(value)
		}
		const onFinish = (values) => {
			const postData = {...values, ...scenarioData};
			if (id) {
			http.put(`/api/scenario/${id}`, postData).then(
					(resp) => {
						if (resp.status == 200) {
							message.success('Scenario Updated Successfully');
							// props.setModalVisible(false);
							// window.frames[0].location.reload();
							stepForm.resetFields();
							props.history.push('/scenarios')
						}else{
						message.error('Scenario Update Failed, Please Correct Following Errors!');
						}
					}
				).catch((err) => {
					errorCallback(err.response);
					message.error('Scenario Update Failed, Please Correct Following Errors!');
				})

			}
			else {
				http.post('/api/scenario/', postData).then((resp) => {
				console.log(resp)
				if (resp.status == 201) {
					if(props?.source != 'map'){
						message.success('Scenario Created Successfully')
						stepForm.resetFields();
						props.history.push('/scenarios')
					}else{
						message.success('Scenario Created Successfully')
						props.setModalVisible(false);
						window.frames[0].location.reload();
					}
					}else{
					message.error('Scenario Creation Failed, Please Correct Following Errors!');
					}
				}
				).catch((err) => {
					errorCallback(err.response)
					message.error('Scenario Creation Failed, Please Correct Following Errors!');
				})
			}
		}

	const errorCallback=(error)=>{
		if(error?.status == 422){
			const errorData = {};
			for(var i=0; i<error.data.detail.length; i++){
				let field = error.data.detail[i].loc[1];
				let errorMessage = error.data.detail[i].msg;
				errorData[field] = {errorMsg: errorMessage, validateStatus:'error'}
			}
			setErrorData(errorData)
		}
		}
		const handleEquipmentUrl=(value)=>{
		if(value!=null){
			setDeviceCommType(value.key);
			setCommunicationTypes(value);
			setEquipmentUrl('/api/combo/equipment/'+value.key+'/');
		}else{
			setEquipmentUrl('/api/combo/equipment/1/');
			setDeviceCommType(1);
			setCommunicationTypes(null);
		} 
		}

		const handleCommunicationTypeComboChange=(value)=>{
		if(value!=null){
			setDeviceCommType(value.key);
			setCommunicationTypes(value);
			setEquipmentUrl('/api/combo/equipment/'+value.key+'/');
		}else{
			setEquipmentUrl('/api/combo/equipment/1/');
			setDeviceCommType(1);
			setCommunicationTypes(null);
		}
			
		}

		const handleResourceAdd=()=>{
			console.log('Add: ', equipment_id)
			const eqip_id = equipment_id?.value;
			const equipment_name = equipment_id?.label;
			const quantity = stepForm.getFieldValue('quantity');
			if(! equipment_id?.value && !quantity){
				message.error('Equipment and Quantity is Needed')
			}
			else{
			let key = resourceKey;
			let data;
			// // // console.log(scenarioData?.scenario_type_combo.value);
			if (scenarioData?.scenario_type_combo.value == 1){
				data = {
				key: key,
				equipment_id: eqip_id, 
				wrd_equipment_id: null, 
				comm_equipment_type: scenarioData?.scenario_type_combo.value, 
				quantity: quantity,
				equipment_text: equipment_name
				}
			}else if (scenarioData?.scenario_type_combo?.value == 2){
				data = {
				key: key,
				equipment_id: null,
				wrd_equipment_id: eqip_id, 
				comm_equipment_type: scenarioData?.scenario_type_combo.value, 
				quantity: quantity,
				equipment_text: equipment_name
				}
			}else if (scenarioData?.scenario_type_combo?.value == 3){
				const type = equipment_name.split(':')
				// // // // console.log(type)
				if(type[0]=="Wrd"){
				data = {
					key: key,
					equipment_id: null,
					wrd_equipment_id: eqip_id,
					comm_equipment_type: 2, 
					quantity: quantity,
					equipment_text: equipment_name
				}
				}else if(type[0]=="Wl"){
				data = {
					key: key,
					equipment_id: eqip_id,
					wrd_equipment_id: null,
					comm_equipment_type: 1, 
					quantity: quantity,
					equipment_text: equipment_name
				}
				}
				
			}

			
			setResourceKey((prevVal)=>{
			return prevVal+1;
			})
			setScenarioData((prev_val)=>{
				// console.log(prev_val.resources, data);
				for(let i=0; i<prev_val.resources.length; i++){
				if (prev_val.resources[i].equipment_text == data.equipment_text){
					console.log('Matched!!', prev_val.resources[i].quantity, data.quantity)
					prev_val.resources[i].quantity = parseInt(prev_val.resources[i].quantity)+parseInt(data.quantity)
					return prev_val
				}
				}
				const resourceData = [...prev_val.resources, data];
				return {...prev_val, resources: resourceData}
			})
			// // // // console.log("At resource add: "+scenarioData)
		}
		}
		
		const handleDelete=(key)=>{
			setScenarioData((prev_val)=>{
			const dataSource = prev_val.resources;
			const newResources = dataSource.filter((item) => item.key !== key)
			return{...prev_val, resources: newResources}
			})
		}

		const BasicForm = ()=>{
		return (
			<div>
				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='name'
					label='Name'
					errorObj={errors}
					formConfig={{
						required: true,
					}}
					/>
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='map'
					label='Map'
					type='select'
					errorObj={errors}
					fieldConfig={{
						data: [{label: 'Google Map', value: 1}],
						value: {label: 'Google Map', value: 1},
						onSelectChange: setPostData
					}}
					/>
				</Col>
				</Row>
				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='location_name'
					label='District/Center Name'
					type='select'
					errorObj={errors}
					fieldConfig={{
						url: '/api/combo/districts',
						value: scenarioData.location_name_combo,
						onSelectChange: setPostData
					}}
					/> 
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='coordinates'
					label='District/Center Coordinates'
					errorObj={errors}
					fieldConfig={{
						placeholder: 'Enter Comma Separated Lat, Long',
					}}
					formConfig={{
						hidden: scenarioData.location_name,
						required: scenarioData?.location_name_combo==null? true:false,
					}}
					/>
				</Col>
				</Row>
				{<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='communication_condition'
					label='Select Preset-condition for communication'
					type='select'
					errorObj={errors}
					fieldConfig={{
						url: '/api/combo/communicationcondition',
						value: scenarioData.communication_condition_combo,
						onSelectChange: setPostData
					}}
					/> 
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='zone_radius'
					label='Zone Radius(m)'
					errorObj={errors}
					fieldConfig={{
						placeholder: 'Enter Communication zone radius'
					}}
					/>
				</Col>
				</Row> }

				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='entry_date'
					label='Entry Date'
					type='date'
					errorObj={errors}
					fieldConfig={{
						initialValue: scenarioData.entry_date,
						onChange: setPostData
					}}
					formConfig={{
						required: scenarioData?.scenario_type_combo?.value==1 || scenarioData?.scenario_type_combo?.value==3?true:false,
					}}
					/>
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='scenario_time'
					label='Scenario Time'
					type='time'
					errorObj={errors}
					fieldConfig={{
						initialValue: scenarioData.scenario_time,
						onChange: setPostData
					}}
					formConfig={{
						required: scenarioData?.scenario_type_combo?.value==1 || scenarioData?.scenario_type_combo?.value==3?true:false,
					}}
					/> 
				</Col>
				</Row>
				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='time_scale'
					label='Time Scale'
					errorObj={errors}
					/>
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='zoom'
					label='Zoom Level'
					errorObj={errors}
					formConfig={{
						required: true,
					}}
					/>
				</Col>
				</Row>
				{/* {<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='scenario_type'
					label='Select Scenario type'
					type='select'
					errorObj={errors}
					fieldConfig={{
						url: '/api/combo/scenario_types',
						value: scenarioData.scenario_type_combo,
						onSelectChange: setPostData
					}}
					formConfig={{
						required: true,
					}}
					/> 
				</Col>
				</Row> } */}
			</div>
		)
		}
		const Step1Form=()=>{
			return (
				<div className={classes.content}>
				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='layer_d_height'
					label='Layer D Height'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/>
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='layer_d_density'
					label='Layer D Density'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/>
				</Col>
				</Row>
				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='layer_e_height'
					label='Layer E Height'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/>
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='layer_e_density'
					label='Layer E Density'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/> 
				</Col>
				</Row>
				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='layer_f_height'
					label='Layer F Height'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/> 
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='layer_f_density'
					label='Layer F Density'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/>  
				</Col>
				</Row>
				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='layer_f1_height'
					label='Layer F1 Height'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/> 
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='layer_f1_density'
					label='Layer F1 Density'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/>  
				</Col>
				</Row>
				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='layer_f2_height'
					label='Layer F2 Height'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/> 
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='layer_f2_density'
					label='Layer F2 Density'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/>
				</Col>
				</Row>
				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='season'
					label='Season'
					type='select'
					errorObj={errors}
					fieldConfig={{
						url: '/api/combo/season',
						value: scenarioData.season_combo,
						onSelectChange: setPostData
					}}
					/>
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='temperature'
					label='Temperature'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 25
					}}
					/>
				</Col>
				</Row>
				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='humidity'
					label='Humidity'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/>
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='rain_type'
					label='Rain'
					type='select'
					errorObj={errors}
					fieldConfig={{
						url: '/api/combo/rain_type',
						value: scenarioData.rain_type_combo,
						onSelectChange: setPostData
					}}
					/> 
				</Col>

				</Row>
				<Row gutter={[16, 16]}>
				<Col span={12}>
					<BuildFormItem
					name='wind_speed'
					label='Wind Speed'
					errorObj={errors}
					fieldConfig={{
						defaultValue: 0
					}}
					/>
				</Col>
				<Col span={12}>
					<BuildFormItem
					name='fog_type'
					label='Fog Type'
					type='select'
					errorObj={errors}
					fieldConfig={{
						url: '/api/combo/fog_type',
						value: scenarioData.fog_type_combo,
						onSelectChange: setPostData
					}}
					/> 
				</Col>
				</Row>
				</div>
			)
		}
		const handleEquipmentComboChange=(value)=>{
			setEquipment(value);
		}
		const Step2Form=()=>{
			console.log()
			return (
				<div className={[classes.content, 'step2_form'].join(' ')}>
				<Row className={'step-form-row'} gutter={[16, 16]}>
					<Col span={4}>
						<Form.Item label={<p className={'formLabel'}>Equipment</p>} labelCol={{span: 24}}name='communication_types_id'>
						<SelectField url='/api/combo/communication_types' 
							onSelectChange={handleCommunicationTypeComboChange}
							value={communication_types}/> </Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item 
							label={<p className={'formLabel'}>Equipment</p>} 
							labelCol={{span: 24}}>
							{/* <SelectField url={'/api/combo/equipment/1/'} onSelectChange={handleEquipmentComboChange} value={equipment_id}/> */}
							<SelectField url={equipment_url} onSelectChange={handleEquipmentComboChange} value={equipment_id}/> 
						</Form.Item>
					</Col>

					<Col span={4}>
						<Form.Item
						label={<p className={'formLabel'}>Quantity</p>}
						labelCol={{span: 24}}
						name='quantity'>
							<InputField />
						</Form.Item> 
					</Col>

					<Col span={4} className={'step-2-button-col d-flex justify-content-center'}>
					<Button
						className={'create_btn height-1'}
						onClick={handleResourceAdd}
						type="primary">
						Allocate
					</Button>
					</Col>
				</Row>

				<Row gutter={[16, 16]}>
					<Col span={24}>
					<Table className={'scenario_add_page_table'}
						dataSource={scenarioData.resources}
						// dataSource={resource_data}
						columns={columns}
						pagination={null}
					/>
					</Col>
				</Row>
				</div>
			)
		}
		
		const steps = [
			{
				title: "Basic Information",
				content: <BasicForm />,
				step: 1
			},
			{
			title: "Effect Config",
			content: <Step1Form />,
			step: 2
			},
			{
			title: "Resource Allocation",
			content: <Step2Form />,
			step: 3
			}
		];
		return (
			<div className={classes.formmain}>
			<Form
				className={'stepform'}
				form={stepForm}
				onFinish={onFinish}
				style={{height: '90%'}}
				>
				<StepPanel className={classes.step_panel} steps={steps} direction='horizontal' />
			</Form>
			</div>
		)
	
	}