export const columnMapper = [
    {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        width: '2%',
        key: 'id',

        hidden: true
    },
    {
        title:'User Type',
        dataIndex:'user_type_text',
        key: 'user_type',
        editable: true,
        width: 'auto',
    },
    {
        title:'Roles',
        dataIndex:'roles',
        key: 'roles',
        editable: true,
        width: 'auto',
    }
      
    ].filter(item => !item.hidden);