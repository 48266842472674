export const SearchFormMapper = [
    {
        label: 'Course Name',
        name: 'name.like',
        type: 'input',
        rules: [],
        placeholder: 'Course Name',
    },
    {
        label: 'Course Code',
        name: 'code',
        type: 'input',
        rules: [],
        placeholder: 'Course Code',
    }
]