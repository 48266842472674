import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

// persist store code
const loadState = () => {
        try {
          const serializedState = localStorage.getItem('state');
          if(serializedState === null) {
            return undefined;
          }
          return JSON.parse(serializedState);
        } catch (e) {
          return undefined;
        }
      };
      
      const saveState = (state) => {
        try {
          const serializedState = JSON.stringify(state);
          localStorage.setItem('state', serializedState);
        } catch (e) {
          // Ignore write errors;
        }
      };
      
const persistedState = loadState();
const inititalState = {};

const store = createStore(
        rootReducer, 
        persistedState ,
        applyMiddleware(thunk)
)
store.subscribe(() => {
        saveState(store.getState());
      });
      
export default store;