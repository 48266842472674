import { Button, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import '../../App.css';
import { InputField } from '../../components/Fields/InputField';
import { SelectField } from '../../components/Fields/SelectField';
import * as http from '../../http';
import classes from './AddPage.module.css';

export const StudedentAddPage = (props) => {
    const [rank, setRank] = React.useState(null);
    const [department, setDepartment] = React.useState(null);
    const [form] = Form.useForm();
    useEffect(() => {
        // console.log(rank)
        if (props.editUrl) {
            http.get(`${props.editUrl}`, {
                method: 'get',
                type: 'json',
            }).then(data => {
                form.setFieldsValue({ 'id': data.data.id });
                form.setFieldsValue({ 'name': data.data.full_name });
                form.setFieldsValue({ 'code': data.data.code });
                form.setFieldsValue({ 'batch': data.data.batch });
                form.setFieldsValue({ 'rank': data.data.rank });
                form.setFieldsValue({ 'department': data.data.department });
                
                setRank({label: data.data.ArmyRank_text, value:data.data.rank})
                setDepartment({label: data.data.department_text, value:data.data.department})

            });
        }
    }, []);
    const onFinish=(values)=>{
        const PostData = {Student: {}};
        PostData['Student']['id'] = values.id;
        PostData['Student']['full_name'] = values.name;
        PostData['Student']['code'] = values.code;
        PostData['Student']['rank'] = rank?.value;
        PostData['Student']['department'] = department?.value;
        // if(values.department!=''){
        
        //     console.log(typeof(values.department))
        //     PostData['Student']['department'] = values.department;
        // }
        
        PostData['Student']['batch'] = values.batch;
        PostData['Student']['type'] = 2;
        return props.onFinish(PostData);
    }
    const handleRank = (value) => {
        setRank({ value: value?.value, label: value?.label });
    }
    const handleDepartment = (value) => {
        setDepartment({ value: value?.value, label: value?.label });
    }

    return (
        <Form style={{ color: "white" }} onFinish={onFinish} form={form}>
            <div className={classes.modalContent}>
            <Row>
            <Form.Item 
            name='id'
            label={<p className={'formLabel'}>ID</p>}
            labelCol={{span: 24}}
            hidden>
            <InputField />
            </Form.Item>
            <Form.Item name='name' label={<p className={'formLabel'}>Name</p>} 
            labelCol={{span: 24}}
            validateStatus={props.errors?.full_name?.validateStatus}
            help={props.errors?.full_name?.errorMsg || null}
            required={true}
            >
                <InputField />
            </Form.Item>
            </Row>
            <Row>
            <Form.Item name='code' label={<p className={'formLabel'}>ID No.</p>} 
            labelCol={{span: 24}}
            validateStatus={props.errors?.code?.validateStatus}
            help={props.errors?.code?.errorMsg || null}
            required={true}
            >
                <InputField />
            </Form.Item>
            </Row>
            <Row>
            <Form.Item name='batch' label={<p className={'formLabel'}>Batch</p>} 
            labelCol={{span: 24}}
            validateStatus={props.errors?.batch?.validateStatus}
            help={props.errors?.batch?.errorMsg || null}
            required={true}
            >
                <InputField />
            </Form.Item>
            </Row>
            <Row>

            <Form.Item 
            name='rank' 
            label={<p className={'formLabel'}>Rank</p>} 
            labelCol={{span: 24}}
            validateStatus={props.errors?.rank?.validateStatus}
            help={props.errors?.rank?.errorMsg || null}
            required={true}>
                <SelectField url="api/combo/army_rank" value={rank} onSelectChange={handleRank}/> </Form.Item>
            </Row>

            <Row>
            <Form.Item 
            name='rank' 
            label={<p className={'formLabel'}>Crops</p>} 
            labelCol={{span: 24}}
            validateStatus={props.errors?.department?.validateStatus}
            help={props.errors?.department?.errorMsg || null}
            required={true}>
                <SelectField url="api/combo/crops_combo" value={department} onSelectChange={handleDepartment}/> </Form.Item>
            </Row>
            
            </div>
            <div className={[classes.footer, 'button_row'].join(' ')}>
            <Button type="primary" htmlType="submit">
                    {props.editUrl? 'Update':'Add'}
            </Button>
            </div>
        </Form>
    )
}
