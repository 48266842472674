export const SearchFormMapper = [
    {
        label: 'Model',
        name: 'model.like',
        type: 'input',
        rules: [],
        placeholder: 'Model',
    },
    {
        label: 'Manufacturer Name',
        name: 'manufacturer',
        type: 'select',
        url: '/api/manufacturer/manufacturer_combo/',
        rules: [],
        placeholder: 'Manufacturer Name',
    }
]
