export const wrd_model_column_mapper = [
  {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      // width: '2%',
      // hidden: true
    },
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      width: '100',
      fixed: 'left'
    }, 
    {
      title: 'Brand',
      dataIndex: 'brand_name',
      editable: true,
      width: '100',
      fixed: 'left'
    },
    {
      title: 'Device Type',
      dataIndex: 'equipment_type_name',
      editable: true,
      width: '100',
      fixed: 'left'
    },
    
  ].filter(item => !item.hidden);