import { Col, Form, Radio, Row } from 'antd';
import React, { useEffect } from 'react';
import { StepPanel } from '../../../containers/StepPanel/StepPanel';
import * as http from '../../../http';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../../utils';
import classes from '../DeviceConfig.module.css';

export const VhfDeviceConfig=(props)=>{
    const [resourceConfigData, setConfigData] = React.useState({
        form_data: true,
        comm_equipment_type: props.configData?.comm_equipment_type,
        is_control: 0,
        usability_type_combo: null,
        usability_type: null,
        ps_type: null,
        ps_type_combo: null,
        ps_id: null,
        ps_id_combo: null,
        is_secure: 0,
        security_method: null,
        security_method_combo: null,
        sq150: 0,
        direct_call: 0,
        modulation: null,
        modulation_combo: null,
        antenna_id: null,
        antenna_id_combo: null,
        installation_type: null,
        installation_type_combo: null,
        radiation_pattern: null,
        radiation_pattern_combo: null,
        radiation_side: null,
        radiation_side_combo: null,
        polarization: null,
        polarization_combo: null,
        selective: null,
        selective_combo: null,
        ionosphere_layer_combo: null,
        ionosphere_layer: null,
        power: 1,
        equipment_id_combo: null,
        equipmentData : null,

    });
    const [stepForm] = Form.useForm();
    const [errors, setErrorData] = React.useState({});
    let isMounted = useIsMounted();
    const coordinates_val={}
    coordinates_val['lat'] = props?.configData?.geometry?.coordinates[1].toFixed(6),
    stepForm.setFieldsValue(coordinates_val);
    coordinates_val['long'] = props?.configData?.geometry?.coordinates[0].toFixed(6),
    stepForm.setFieldsValue(coordinates_val);
    
    useEffect(() => {
        if(props?.configData?.configID){
            http.get(`/api/scenario/${props.configData.scenarioID}/${props.configData.configID}/get_config/`).then((data)=>{
                if((data?.data?.usability_type || data?.data?.usability_type == 0)  && data?.data?.usability_type_text){
                    data.data['usability_type_combo'] = {};
                    data.data.usability_type_combo.label = data.data.usability_type_text;
                    data.data.usability_type_combo.value = data.data.usability_type;
                }
                if((data?.data?.ps_type || data?.data?.ps_type == 0) && data?.data?.ps_type_text){
                    data.data['ps_type_combo'] = {};
                    data.data.ps_type_combo.label = data.data.ps_type_text;
                    data.data.ps_type_combo.value = data.data.ps_type;
                }
                if(data?.data?.ps_id && data?.data?.power_supply_text){
                    data.data['ps_id_combo'] = {};
                    data.data.ps_id_combo.label = data.data.power_supply_text;
                    data.data.ps_id_combo.value = data.data.ps_id;
                }
                if(data?.data?.ionosphere_layer && data?.data?.ionosphere_layer_text){
                    data.data['ionosphere_layer_combo'] = {};
                    data.data.ionosphere_layer_combo.label = data.data.ionosphere_layer_text;
                    data.data.ionosphere_layer_combo.value = data.data.ionosphere_layer;
                }
                if(data?.data?.modulation && data?.data?.modulation_text){
                    data.data['modulation_combo'] = {};
                    data.data.modulation_combo.label = data.data.modulation_text;
                    data.data.modulation_combo.value = data.data.modulation;
                }
                if(data?.data?.antenna_config?.antenna_id && data?.data?.antenna_config?.antenna_name){
                    data.data['antenna_id_combo'] = {};
                    data.data.antenna_id_combo.label = data.data.antenna_config.antenna_name;
                    data.data.antenna_id_combo.value = data.data.antenna_config.antenna_id;
                    data.data.antenna_id = data.data.antenna_config.antenna_id;
                    delete data.data.antenna_config.antenna_id;
                    delete data.data.antenna_config.antenna_name;
                }
                if((data?.data?.antenna_config?.installation_type || data?.data?.antenna_config?.installation_type == 0) && data?.data?.antenna_config?.installation_type_text){
                    data.data['installation_type_combo'] = {};
                    data.data.installation_type_combo.label = data?.data?.antenna_config.installation_type_text;
                    data.data.installation_type_combo.value = data?.data?.antenna_config.installation_type;
                    data.data.installation_type = data?.data?.antenna_config.installation_type;
                    delete data.data.antenna_config.installation_type;
                    delete data.data.antenna_config.installation_type_text;
                }
                if((data?.data?.antenna_config?.radiation_pattern || data?.data?.antenna_config?.radiation_pattern == 0) && data?.data?.antenna_config?.radiation_pattern_text){
                    data.data['radiation_pattern_combo'] = {};
                    data.data.radiation_pattern_combo.label = data?.data?.antenna_config.radiation_pattern_text;
                    data.data.radiation_pattern_combo.value = data?.data?.antenna_config.radiation_pattern;
                    data.data.radiation_pattern = data?.data?.antenna_config.radiation_pattern;
                    delete data.data.antenna_config.radiation_pattern;
                    delete data.data.antenna_config.radiation_pattern_text;
                }
                if(data?.data?.antenna_config?.radiation_side && data?.data?.antenna_config?.radiation_side_text){
                    data.data['radiation_side_combo'] = {};
                    data.data.radiation_side_combo.label = data?.data?.antenna_config.radiation_side_text;
                    data.data.radiation_side_combo.value = data?.data?.antenna_config.radiation_side;
                    data.data.radiation_side = data?.data?.antenna_config.radiation_side;
                    delete data.data.antenna_config.radiation_side;
                    delete data.data.antenna_config.radiation_side_text;
                }
                if(data?.data?.antenna_config?.polarization){
                    data.data['polarization_combo'] = {};
                    data.data.polarization_combo.label = data?.data.antenna_config.polarization === 1? 'Vertical' : 'Horizontal';
                    data.data.polarization_combo.value = data.data.antenna_config.polarization;
                    data.data.polarization = data.data.antenna_config.polarization;
                    delete data.data.antenna_config.polarization;
                }

                if(data?.data?.equipment_id){
                    data.data['equipment_id_combo'] = {};
                    data.data.equipment_id_combo.label = `${data?.data?.equipment?.name}[${data?.data?.equipment?.model}]`;
                    data.data.equipment_id_combo.value = data.data.equipment_id
                }
                let antennaConfig = {};
                if(data.data?.antenna_config){
                    antennaConfig = data.data?.antenna_config;
                    delete data.data.antennaConfig;
                }
                _.forOwn(data.data, (value, key)=>{
                    if(!resourceConfigData.hasOwnProperty(key) && !['ionosphere_height', 'ionosphere_density'].includes(key)){
                        const val = {};
                        val[key] = value;
                        stepForm.setFieldsValue(val);
                        delete data.data[key];
                    }
                })
                _.forOwn(antennaConfig, (value, key)=>{
                    if(!resourceConfigData.hasOwnProperty(key)){
                        const val = {};
                        val[key] = value;
                        stepForm.setFieldsValue(val);
                    }
                })
                for(let i =0; i < props?.configData?.equipmentData.length; i++){
                    // console.log(i, props?.configData?.equipmentData[i]?.equipment_id)
                    if(props?.configData?.equipmentData[i]?.equipment_id == data?.data?.equipment_id_combo.value){
                        setConfigData((prevVal)=>{
                            return {...prevVal, ...{equipmentData:props?.configData?.equipmentData[i]?.equipment}}
                        })
                    }
                }

                if (isMounted.current){
                    setConfigData((prevVal)=>{
                        return {...prevVal, ...data?.data}
                    })
                }
            }).catch((err)=>{
                console.log(err);
            })
        }
        stepForm.setFieldsValue({'ionosphere_height': props.configData.scenarioData.layer_data.height});
        stepForm.setFieldsValue({'ionosphere_density': props.configData.scenarioData.layer_data.electron_density});
        setConfigData((prevVaVal) => {
        return {
                ...prevVaVal,
                ionosphere_layer_combo: props.configData.scenarioData.layer_data.layer_combo ? props.configData.scenarioData.layer_data.layer_combo : null,
                ionosphere_layer: props.configData.scenarioData.layer_data.layer_combo?.value
            }
        });

        return () => { isMounted = false };
    }, [setConfigData, props.configData]);

    const calculateAntennaProperties =()=>{

        if(!resourceConfigData.antenna_id){
            alert('Select Antenna First');
            return;
        }
        let tx_frequency = stepForm.getFieldValue('antenna_frequency');
        tx_frequency = parseFloat(tx_frequency)
        let antenna_wavelength = (300000000/(parseFloat(tx_frequency)*1000000));

        if(tx_frequency == 0 || !tx_frequency){
            antenna_wavelength = 0
        }

        const {antenna_data} = resourceConfigData?.equipmentData;
        // const antenna_length = antenna_data[value.value].length;
        let antenna_height_from_ground = 0;
        let minimum_antenna_length = 0;
        if(antenna_data[resourceConfigData.antenna_id].category_id == 1) {  // Half Wave Dipole
            minimum_antenna_length = antenna_wavelength / 2;
            antenna_height_from_ground = antenna_wavelength * 0.25;
        }
        else{
           minimum_antenna_length = antenna_data[resourceConfigData.antenna_id].length;
           antenna_height_from_ground = antenna_wavelength * 0.25;
        }
        // let take_off_angle = Math.asin(0.25/antenna_height_from_ground);
        let take_off_angle_degree = Math.acos(0.25 / antenna_height_from_ground);
        if(tx_frequency == 0 || !tx_frequency){
            take_off_angle_degree = 0
        }


        const ionosphere_height = parseFloat(stepForm.getFieldValue('ionosphere_height') || '0');
        const ionosphere_density = parseFloat(stepForm.getFieldValue('ionosphere_density') || '0');
        const critical_frequency = (9 * Math.sqrt(parseFloat(ionosphere_density)*100000000000))/1000000;
        // const muf = critical_frequency / Math.cos(take_off_angle);
        // debugger
        let antenna_height_value = stepForm.getFieldsValue().antenna_height
        if(!antenna_height_value){
            alert("Plese Enter Antenna Height")
            return;
        }
        else{
            antenna_height_value = Math.floor(antenna_height_value)
            const antenna_height_from_ground_value = Math.floor(antenna_height_from_ground)
            if(antenna_height_value != antenna_height_from_ground_value){
                alert("You Have Enter Wrong Antenna Height.")
                return
            }
            

        }

        const muf = critical_frequency + 0.98;
        const ouf = muf * 0.9;  // 90% of MUF
        const skip_distance = 2 * ionosphere_height * Math.sqrt(Math.pow((muf/critical_frequency), 2)-1);
        stepForm.setFieldsValue({wavelength: antenna_wavelength.toFixed(3)});
        stepForm.setFieldsValue({antenna_height: antenna_height_from_ground.toFixed(3)});
        stepForm.setFieldsValue({antenna_angle: take_off_angle_degree.toFixed(3)});
        // stepForm.setFieldsValue({ionosphere_height: parseFloat(ionosphere_height).toFixed(3)});
        // stepForm.setFieldsValue({ionosphere_density: parseFloat(ionosphere_density)});
        stepForm.setFieldsValue({max_usable_freq: muf.toFixed(3)});
        stepForm.setFieldsValue({critical_frequency: critical_frequency.toFixed(3)});
        stepForm.setFieldsValue({opt_workable_freq: ouf.toFixed(3)});
        stepForm.setFieldsValue({skip_distance: skip_distance.toFixed(3)});
        stepForm.setFieldsValue({antenna_length: minimum_antenna_length.toFixed(3)});

    };

    const setPostData=(field, type, label, value)=>{
        let newData = {};
        
        if(type == 'select'){
            if(value){
                newData[field] = value.value;
                newData[`${field}_combo`] = value;
                if(field == 'power_output'){
                    stepForm.setFieldsValue({'power_output': value.value})
                }
            }
            else{
                newData[field] = null;
                newData[`${field}_combo`] = null;
            }
            if(`${field}_combo` == 'equipment_id_combo'){
                for(let i =0; i < props?.configData?.equipmentData.length; i++){
                    if(props?.configData?.equipmentData[i]?.equipment_id == value.value){
                        setConfigData((prevVal)=>{
                            return {...prevVal, ...{equipmentData:props?.configData?.equipmentData[i]?.equipment}}
                        })
                    }
                }
            }
        }
        else if(type == 'input-button'){
            if(value == 'plus'){
                const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
                const updatedValue = {};
                updatedValue[field] = newValue;
                stepForm.setFieldsValue(updatedValue)
            }
            else{
                const newValue = parseInt(stepForm.getFieldsValue()[field]|| 0) - 1;
                const updatedValue = {};
                updatedValue[field] = newValue;
                stepForm.setFieldsValue(updatedValue)
            }
        }
        else if(type == 'checkbox')
        {
            newData[field] = value;
        }

        if(field == 'usability_type'){
            if(value && !resourceConfigData.equipmentData.usability_type.includes(value.value)){
                alert(`${resourceConfigData.equipmentData?.model} doesn't support ${value.label} installation`);
                return
            }
        }

        setConfigData((prevVal)=>{
            return {...prevVal, ...newData}
        })
    }

    const onPowerChange=(e)=>{
        const newData={};
        const value = e.target.value;
        newData['power'] = value;
        let ps_id = resourceConfigData.ps_id;
        if(!ps_id){
            alert('Select Power Source First');
            return
        }
        const equipmentData = resourceConfigData.equipmentData;
        const device_power_output = equipmentData.power_output.split(',');

        // Calculate Output Power for Low Voltage
        // if(value == 2){
        //     stepForm.setFieldsValue({'power_output': device_power_output[0]})
        // }

        // // Calculate Output Power for Medium Voltage
        // if(value == 3){
        //     stepForm.setFieldsValue({'power_output': device_power_output[1]})
        // }

        // // Calculate Output Power for High Voltage
        // if(value == 4){
        //     stepForm.setFieldsValue({'power_output': device_power_output[2]})
        // }
        // if(value == 5){
        //     stepForm.setFieldsValue({'power_output': device_power_output[3]})
        // }
        setConfigData((prevVal)=>{
            return {...prevVal, ...newData}
        })
    };

    

    const onFinish=(values)=>{
        const postData = {...values, ...resourceConfigData};
        if(postData.lat && postData.long && postData.equipment_id ){
            console.log('Finisdhed')
            window.frames[0].postMessage({
                data: postData,
                type: 'resolve',
                popupId: props.popupID
            })
        }else{
            alert("Some Data missing!");
            window.frames[0].postMessage({
                data: postData,
                type: 'failed',
                popupId: props.popupID
            })
        }
    }

//   const errorCallback=(error)=>{
//       if(error?.status == 422){
//         const errorData = {};
//         for(var i=0; i<error.data.detail.length; i++){
//             let field = error.data.detail[i].loc[1];
//             let errorMessage = error.data.detail[i].msg;
//             errorData[field] = {errorMsg: errorMessage, validateStatus:'error'}
//         }
//         setErrorData(errorData)
//       }
//     }


    const InstallationForm=()=>{
        return (
            <div className={classes.content}>
                <Row gutter={[16,16]} >
                    <Col span={8}>
                        <BuildFormItem
                            name='equipment_id'
                            label='Select Equipment'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.equipment_id_combo,
                                url:`/api/combo/get_allocated_rr_equipments/${props.configData.scenarioID}/1/`,
                                onSelectChange:setPostData
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <BuildFormItem
                            name='lat'
                            label='Latitude'
                            formConfig={{
                                required: true
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <BuildFormItem
                            name='long'
                            label='Longitude'
                            formConfig={{
                                required: true
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <BuildFormItem
                            name='is_control'
                            label='Is Control'
                            type='checkbox'
                            fieldConfig={{
                                style: {color: "white" },
                                checked:resourceConfigData.is_control,
                                onChange: setPostData
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <BuildFormItem
                            name='nis'
                            label='NIS'
                            fieldConfig={{
                                disabled: !resourceConfigData.is_control
                            }}
                            formConfig={{
                                required: resourceConfigData.is_control
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <BuildFormItem
                            name='usability_type'
                            label='Platform'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.usability_type_combo,
                                url:"/api/combo/station_type_combo",
                                onSelectChange:setPostData
                            }}
                            formConfig={{
                                required: true
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    {/* <Col span={8}>
                        <BuildFormItem
                            name='control'
                            label='Control'
                            fieldConfig={{
                                disabled: resourceConfigData.is_control
                            }}
                            formConfig={{
                                hidden: resourceConfigData.is_control
                            }}
                        />
                    </Col> */}
                    <Col span={8}>
                        <BuildFormItem
                            name='comm_with'
                            label='Control/Communication With'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.comm_with_combo,
                                url:`/api/combo/control_combo/${props.configData.scenarioID}/1/`,
                                onSelectChange:setPostData
                            }}
                            formConfig={{
                                // hidden: resourceConfigData.is_control
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <BuildFormItem
                            name='control_call_sign'
                            label='Control Call Sign'
                            fieldConfig={{
                                disabled: resourceConfigData.is_control
                            }}
                            formConfig={{
                                hidden: resourceConfigData.is_control
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <BuildFormItem
                            name='call_sign'
                            label='Call Sign'
                            fieldConfig={{
                                disabled: resourceConfigData.is_control
                            }}
                            formConfig={{
                                hidden: resourceConfigData.is_control
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <BuildFormItem
                            name='ps_type'
                            label='Power System'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.ps_type_combo,
                                data:[{label: 'AC', value:1}, {label: 'DC', value: 2}],
                                onSelectChange:setPostData
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                            name='ps_id'
                            label='Power Source'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.ps_id_combo,
                                url:`/api/combo/equipments/power_source/?supply_type=${resourceConfigData.ps_type}&equipment_id=${resourceConfigData?.equipmentData?.id}`,
                                onSelectChange:setPostData
                            }}
                        />
                    </Col>
                </Row>
                <div style={{display: 'flex', justifyContent:'center', marginTop: '15px'}}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label={<p>Power</p>}
                                name='power'
                                validateStatus={errors.hasOwnProperty(`power`) ? errors['power']?.validateStatus:null}
                                help={errors.hasOwnProperty(`power`) ? errors[power]?.errorMsg:null}
                            >
                                <Radio.Group onChange={onPowerChange} value={resourceConfigData.power} defaultValue={resourceConfigData.power}>
                                    <Radio value={1}><p style={{color: 'red', fontWeight: 20}}>Off</p></Radio>
                                    {/* <Radio value={2}><p style={{color: 'yellow', fontWeight: 20}}>On</p></Radio>
                                    <Radio value={3}><p style={{color: 'orange', fontWeight: 20}}>Medium</p></Radio>
                                    <Radio value={4}><p style={{color: 'green', fontWeight: 20}}>High</p></Radio> */}
                                    <Radio value={5}><p style={{color: 'green', fontWeight: 20}}>On</p></Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    const ConfigForm=()=>{
        return (
            <div className={classes.content}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <BuildFormItem
                            name='device_id'
                            label='Device ID'
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                            name='channel_no'
                            label='Channel No'
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <BuildFormItem
                            name='tx_freq'
                            label='Tx Frequency'
                            type='input-button'
                            fieldConfig={{
                                onBeforeButtonClicked: setPostData,
                                onAfterButtonClicked: setPostData
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                            name='rx_freq'
                            label='Rx Frequency'
                            type='input-button'
                            fieldConfig={{
                                onBeforeButtonClicked: setPostData,
                                onAfterButtonClicked: setPostData
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={6}>
                        <BuildFormItem
                            name='sq150'
                            label='SQ150'
                            type='checkbox'
                            fieldConfig={{
                                style: {color: "white" },
                                checked:resourceConfigData.sq150,
                                onChange: setPostData
                            }}
                        />
                    </Col>
                    {/* <Col span={12}>
                        <BuildFormItem
                            name='selective'
                            label='Selective'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.selective_combo,
                                url:"/api/combo/selective_combo/",
                                onSelectChange:setPostData
                            }}
                        />
                    </Col> */}
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <BuildFormItem
                            name='modulation'
                            label='Modulation'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.modulation_combo,
                                url:"/api/combo/modulation/",
                                onSelectChange:setPostData
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <BuildFormItem
                            name='power_output'
                            label='Output Power'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.power_output_combo,
                                url:`/api/combo/output_power_combo/${resourceConfigData?.equipment_id_combo?.value? resourceConfigData?.equipment_id_combo?.value:0}/${resourceConfigData?.usability_type_combo?.value? resourceConfigData?.usability_type_combo?.value:0}/`,
                                onSelectChange:setPostData
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <BuildFormItem
                            name='power_output'
                            label='Power Output(w)'
                            type='input'
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    {/* <Col span={8}>
                            <BuildFormItem
                                name='gc_for_aircraft'
                                label='GC For Aircraft'
                                type='checkbox'
                                fieldConfig={{
                                    style: {color: "white" },
                                    checked:resourceConfigData.is_control,
                                    onChange: setPostData
                                }}
                            />
                    </Col> */}
                </Row>
            </div>
        )
    }

    const AntennaForm=()=>{
        return (
            <div className={classes.content}>
                <Row gutter={[16, 16]}>
                    <Col span={8} push={4}>
                        <BuildFormItem
                            name='antenna_id'
                            label='Select Antenna'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.antenna_id_combo,
                                url:`/api/combo/antenna/?equipment_id=${resourceConfigData?.equipmentData?.id}`,
                                onSelectChange:setPostData
                            }}
                        />
                    </Col>
                    <Col span={8} push={4}>
                        <BuildFormItem
                            name='radiation_pattern'
                            label='Radiation Pattern'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.radiation_pattern_combo,
                                url:"/api/combo/antenna/radition_pattern_combo/",
                                onSelectChange:setPostData
                            }}
                        />
                    </Col>
                    {/* <Col span={8} push={4}>
                        <BuildFormItem
                            name='installation_type'
                            label='Installation Type'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.installation_type_combo,
                                url:"/api/combo/antenna/installation_type/",
                                onSelectChange:setPostData
                            }}
                        />
                    </Col> */}
                </Row>

                <Row gutter={[16, 16]}>
                    {/* <Col span={8} push={resourceConfigData.radiation_pattern == 2? 8:4}>
                        <BuildFormItem
                            name='radiation_pattern'
                            label='Radiation Pattern'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.radiation_pattern_combo,
                                url:"/api/combo/antenna/radition_pattern_combo/",
                                onSelectChange:setPostData
                            }}
                        />
                    </Col> */}
                    <Col span={8} push={4}>
                        <BuildFormItem
                            name='radiation_side'
                            label='Radiation Side'
                            type='select'
                            fieldConfig={{
                                value:resourceConfigData.radiation_side_combo,
                                url:"/api/combo/radation_side_combo/",
                                onSelectChange:setPostData
                            }}
                            formConfig={{
                                hidden: resourceConfigData.radiation_pattern == 2,

                            }}
                        />
                    </Col>
                 
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8} push={4}>
                            <BuildFormItem
                                name='antenna_angle'
                                label='Azimuth/Bearing(Degree)'
                                type='input'
                                fieldConfig={{
                                    onBeforeButtonClicked: setPostData,
                                    onAfterButtonClicked: setPostData,
                                }}
                                formConfig={{
                                    hidden: resourceConfigData.radiation_pattern == 2,
    
                                }}
                            />
                    </Col>
                    <Col span={8} push={4}>
                            <BuildFormItem
                                name='altitude_degree'
                                label='Altitude/Elevation Angle(Degree)'
                                type='input'
                                fieldConfig={{
                                    onBeforeButtonClicked: setPostData,
                                    onAfterButtonClicked: setPostData,
                                }}
                                formConfig={{
                                    hidden: resourceConfigData.radiation_pattern == 2,
    
                                }}
                            />
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {/* <Col span={8} push={4}>
                            <BuildFormItem
                                name='antenna_length'
                                label='Antenna Length(m)'
                                type='input'
                                fieldConfig={{
                                    onBeforeButtonClicked: setPostData,
                                    onAfterButtonClicked: setPostData,
                                }}
                                formConfig={{
                                    // hidden: resourceConfigData.radiation_pattern == 2,
    
                                }}
                            /> 
                    </Col> */}
                    <Col span={16} push={4}>
                            <BuildFormItem
                                name='antenna_height'
                                label='Antenna Height(m)'
                                type='input'
                                fieldConfig={{
                                    onBeforeButtonClicked: setPostData,
                                    onAfterButtonClicked: setPostData,
                                }}
                                formConfig={{
                                    // hidden: resourceConfigData.radiation_pattern == 2,
                                }}
                            />
                    </Col> 
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={8} push={4}>
                            <BuildFormItem
                                name='land_elevation'
                                label='Land Elevation(m)'
                                type='input'
                                fieldConfig={{
                                    onBeforeButtonClicked: setPostData,
                                    onAfterButtonClicked: setPostData,
                                }}
                                formConfig={{
                                    hidden: resourceConfigData.radiation_pattern == 2,
    
                                }}
                            />
                    </Col>
                </Row>

                
            </div>
        )
    }
   

    const steps = [
        {
            title: "Installation",
            content: <InstallationForm />,
            step: 1
        },
        {
            title: "Antenna",
            content: <AntennaForm />,
            step: 2
        },
       
        {
            title: "Device Config",
            content: <ConfigForm />,
            step: 3
        }
    ];
    
    return (
        <div className={classes.formmain}>
            <Form form={stepForm} onFinish={onFinish}>
                <StepPanel
                    className={classes.step_panel}
                    steps={steps}
                    direction='horizontal'
                />
            </Form>
        </div>
    )

}