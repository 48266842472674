import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Space, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalTitle } from '../../components/ModalTitle/ModalTitle';
import BaseList from "../../containers/BaseList/BaseList";
import * as http from '../../http';
import { SearchFormMapper } from './SearchFormMapper';
import { StudedentAddPage } from './StudentAddPage';
import { columnMapper } from './columnMapper';


class StudentsListPage extends BaseList {
  constructor(props){
    super(props)
  }

  getColumns(){
    const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
    const deleteClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';

    return [
      ...columnMapper,
      {
        title: 'Action',
        key: 'action',
        fixed:'right',
        render: (record) => (
          <Space size="middle">
          <Button 
            key='editButton'
            className={themeClass}
            icon={<EditOutlined />}
            onClick={()=>this.showModal(`/api/students/${record.id}`)} />
            
             <Popconfirm okType='default' title="Are sure to Delete?" okText="Yes" onConfirm={() => this.handleDelete(record)}>
                <Button 
                  key='deleteButton'
                  className={deleteClass}
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
          </Space>
          
          
        )
      }
    ];
  }

  getDataUrl(){
    return '/api/students/'; 
  }

  getPageConfig(){
    return {
      pageTitle:'Student List',
      pageButtons: [
        <Button
          key='student-add'
          className={'create_btn'}
          onClick={()=>this.showModal()}
          type="primary"
          size="small"
          icon={<PlusOutlined />}
        >
          ADD NEW
        </ Button>
      ],
    }
  }

  getSearchFields(){
    return SearchFormMapper;
  }
  
  onAddSaveClick=(values)=>{
    delete values.Student.id;
    http.post('/api/students', values.Student)
      .then((response)=> {
      if(response.status == 201){
        this.setState({
          isModalVisible: false,
          erros: {}
        })
        message.success('Student Created Successfully');
        this.fetch();
      }
    }).catch((err)=>{
      message.error('Student Creation Failed');

      this.errorCallback(err.response);
    })
  }
  onUpdateClick=(values)=>{
    http.put(`/api/students/${values.Student.id}`, values.Student)
      .then((response)=> {
      if(response.status == 200){
        this.setState({
          isModalVisible: false,
        })
        message.success('Student Updated Successfully');
        this.fetch();
      }
    }).catch((err)=>{
      message.error('Student Update Failed');
      this.errorCallback(err.response);
    })
  }

  onFormFinish=(values)=>{
    if(this.state.editUrl){
      this.onUpdateClick(values);
    }
    else{
      this.onAddSaveClick(values);
    }
  }
  handleDelete(record){
    // axios({
    //   url: `/api/students/${record.id}`,
    //   method: 'delete',
    //   type: 'json',
    //   })
      
    //   message.success('Deleted Successfully');
    //   this.fetch()

    http.del(`/api/students/${record.id}`)
      .then((response)=> {
      if(response.status == 200){
        message.success('Deleted Successfully');
        this.fetch();
      }else{
        message.error('Deleted Not Successfully');
      }
    }).catch((err)=>{
      message.error('Delete Failed');
      this.errorCallback(err.response);
    })

  }

  render(){
    const colorClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'customantmodal' : 'notfound';

    return (
      <>
      {super.render()}
      <Modal 
      title={<ModalTitle title="Add Student"/>}
      visible={this.state.isModalVisible}
      onOk={this.handleOk}
      onCancel={this.handleCancel}
      destroyOnClose={true}
      footer={null}
      centered={true}
      className={colorClass}
      >
        <StudedentAddPage 
          onFinish={this.onFormFinish.bind(this)}
          editUrl={this.state.editUrl}
          errors={this.state.errors}
        />
      </Modal>
      </>
    );

  }

}
const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps)(withRouter(StudentsListPage));
