import { Button, Col, Form, message, Popconfirm, Row, Table } from 'antd';
import axios from 'axios';
import React, { useEffect } from 'react';
import '../../App.css';
import { DateField } from '../../components/Fields/DateField';
import { InputField } from '../../components/Fields/InputField';
import { SelectField } from '../../components/Fields/SelectField';
import { TimeField } from '../../components/Fields/TimeField';
import { StepPanel } from '../../containers/StepPanel/StepPanel';
import classes from '../Courses/AddPage.module.css';

export const AddPage = (props) => {
    const [form] = Form.useForm();
    const initialDate = new Date().toISOString().split('T')[0];
    const [start, setStartTime] = React.useState(null);
    const [end, setEndTime] = React.useState(null);
    const [startDate, setStartDate] = React.useState(initialDate);
    const [endDate, setEndDate] = React.useState(initialDate);
    const [course, setCourse] = React.useState(null);
    const [teacher, setTeacher] = React.useState(null);
    const [student, setStudent] = React.useState(null);
    const [resourceKey, setResourceKey] = React.useState(0);
    const [stepForm] = Form.useForm();
    const [studentData, setStudentData] = React.useState({
        active_course_students: []
    });
    const handleTime = (label, value) => {
        if (value && label=="start") {
            setStartTime(value)
        }
        else setEndTime(value)
    }
    const handleDate = (label,value) =>{
        console.log(value)
            if (value && label=="start") {
            setStartDate(value)

        }
        else setEndDate(value)

    }

    const handleCourse=(value)=>{
        setCourse(value)
    }
    const handleTeacher=(value)=>{
        setTeacher(value)
    }
    const handleStudent=(value)=>{
        setStudent(value)
    }
    const handleStudentAdd=()=>{
        const student_id = student.value
        const teacher_id = teacher.value
        const teacher_name = teacher.value
        const student_name = student.label
        if(!student_id){
            message.error('Student Needed')
        }
        else{
        let key = resourceKey;
        const data = {
            key: key,
            student_id : student.value,
            student_name : student.label,
            teacher_id : teacher.value,     
            teacher_name : teacher.label,     
        }

        setResourceKey((prevVal)=>{
			return prevVal+1;
		})

        setStudentData((prev_val)=>{
            const activeCourseStudetData = [...prev_val.active_course_students, data];
            return {...prev_val, active_course_students: activeCourseStudetData}
        })
      }
    }

    const [columns, setColumns] = React.useState(
        [
            {
                title: 'Student Id',
                dataIndex: 'student_id',
                editable: false,
                hidden: true
              },
            {
              title: 'Student Name',
              dataIndex: 'student_name',
              editable: false,
              width: 'auto'
            },
            {
                title: ' ',
                dataIndex: 'operation',
                render: (_, record) =>
                    <Popconfirm okType='default' title="Confirm Delete?" onConfirm={() => handleDelete(record.key)}>
                      <a>Delete</a>
                    </Popconfirm>
              },
            
            
          ].filter(item=>!item.hidden)
    )

    const handleDelete=(key)=>{
        setStudentData((prevVal)=>{
                const dataSource = [...prevVal.active_course_students];
                const newStudents = dataSource.filter((item) => item.key !== key)
                return {...prevVal, active_course_students: newStudents}
            });
        }

    useEffect(() => {
        if (props.editUrl) {
            axios({
                url: props.editUrl,
                method: 'get',
                type: 'json',
            }).then(data => {
                console.log((data.data))
                form.setFieldsValue({ 'id': data.data.id });
                form.setFieldsValue({ 'start': data.data.start });
                // form.setFieldsValue({ 'code': data.data.code });
            });
        }
    }, []);
    
    const onFinish=(values)=>{
        
        const PostData = {...values, ...studentData,};
        PostData['id'] = values.id;
        PostData['course_id'] = course?.value;
        PostData['teacher_id'] = teacher?.value;
        PostData['start'] = startDate;
        PostData['end'] = endDate;
        PostData['from_time'] = start
        PostData['to_time'] = end
        props.onFinish(PostData);
    
    }
    const Step1Form = () =>{
        return(
            <div className={classes.content}>
                <Row className={'justify-content-space-evenly'}>
                <Form.Item 
                    name='id'
                    label={<p className={'formLabel'}>ID</p>}
                    labelCol={{span: 24}}
                    hidden
                >
                <InputField />
                </Form.Item>
                <Col span={10}>
                <Form.Item
                    label={<p className={'formLabel'}>Course Name</p>}
                    setFieldsValue  labelCol={{span: 24}}
                    validateStatus={props.errors?.course_name?.validateStatus}
                    help={props.errors?.course_name?.errorMsg || null}
                    required={true}
                    name="course_name"
                > <SelectField url="api/combo/courses" value={course} onSelectChange={handleCourse} /> </Form.Item>
                </Col>
                <Col span={10}>
                <Form.Item
                label={<p className={'formLabel'}>Teacher Name</p>}
                labelCol={{span: 24}}
                validateStatus={props.errors?.teacher_name?.validateStatus}
                help={props.errors?.teacher_name?.errorMsg || null}
                required={true}
                >
                <SelectField url="api/combo/teachers"  value={teacher} onSelectChange={handleTeacher} /> </Form.Item>
                </Col>
                
                </Row>
                <Row className={'justify-content-space-evenly'}>
                    <Col span={10}>
                    <Form.Item
                    label={<p className={'formLabel'}>Start Date</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.start?.validateStatus}
                    help={props.errors?.start?.errorMsg || null}
                    required={true}
                    
                    > <DateField onChange={(e) => handleDate('start', e)} initialValue={startDate}/> </Form.Item>
                    </Col>
                    <Col span={10}>
                    <Form.Item
                    label={<p className={'formLabel'}>End Date</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.end?.validateStatus}
                    help={props.errors?.end?.errorMsg || null}
                    required={true}
                    > <DateField onChange={(e) => handleDate('end', e)} initialValue={endDate}/> </Form.Item>
                    </Col>

                </Row>
                <Row className={'justify-content-space-evenly'}>
                    <Col span={10}>
                    <Form.Item
                    label={<p className={'formLabel'}>From Time</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.from_time?.validateStatus}
                    help={props.errors?.from_time?.errorMsg || null}
                    required={false}
                    name = 'from_time'
                    >
                    <TimeField onSelect={(e) => handleTime('start', e)} initialValue={start}/>
                    </Form.Item>
                    </Col>
                    <Col  span={10}>
                    <Form.Item
                    label={<p className={'formLabel'}>To Time</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.to_time?.validateStatus}
                    help={props.errors?.to_time?.errorMsg || null}
                    required={false}
                    >
                    <TimeField onSelect={(e) => handleTime('end', e)} initialValue={end}/>
                </Form.Item>
                    </Col>

                </Row>

            </div>
        )
    }

    const Step2Form = () =>{
        return(
            
            <div className={classes.modalContent}>
                
               <Row className={'custom-row-1'}>
                   <Col>
                   
                <Form.Item
                    label={<p className={'formLabel'}>Student Name</p>}
                    labelCol={{span: 24}}
                    validateStatus={props.errors?.full_name?.validateStatus}
                    help={props.errors?.full_name?.errorMsg || null}
                    required={true}
                > 
                <SelectField url="api/combo/students" value={student} onSelectChange={handleStudent} /> </Form.Item>
                </Col>
                <Col className={'justify-content-end'} style={{marginLeft:'5px'}}>
                    <Button onClick={handleStudentAdd} type='primary'>Add </Button>
                </Col>
                </Row>
                <Row gutter={[16, 16]}>
                <Col span={24}>
                <Table
                    dataSource={studentData.active_course_students}
                    columns={columns}
                    pagination={null}
                />
                </Col>
            </Row>
            </div>
        )
    }
    const steps = [
        {
            title: "Course Info",
            content: <Step1Form />,
            step: 1
        },
        {
            title: "Students Info",
            content: <Step2Form />,
            step: 2
        }
    ]
 
    return (
        <div className={[classes.formmain, 'formmain'].join(' ')}>
        <Form className={'stepform'}  onFinish={onFinish} style={{height: '90%'}}>
            <StepPanel className={classes.step_panel} steps={steps} direction='horizontal' />
        </Form>
        </div>
    )
}