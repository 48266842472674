export const columnMapper = [
    {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        width: '2%',
        hidden: true
      },
      {
        title: 'Email',
        dataIndex: 'email',
        editable: true,
        // filters: [
        //   { text: 'Male', value: 'male' },
        //   { text: 'Female', value: 'female' },
        // ],F
        width: 'auto',
      },
      {
        title: 'User Type',
        dataIndex: 'user_type',
        render: user_type => user_type==0?'Admin':user_type==1?'Instructor':'Student',
        width: 'auto',
      },{
        title: 'Name',
        dataIndex: 'party_name',
        width: 'auto',
      },
      {
        title: 'Roles',
        dataIndex: 'roles_combo',
        width: 'auto',
      }
    ].filter(item => !item.hidden);