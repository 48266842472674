import { Card } from 'antd';
import classes from './InfoCard.module.css';

export const InfoCard=(props)=>{
    const bgcolor = JSON.parse(localStorage.getItem("theme")) === 'Dark' ? 'black' : '#FFFFFF';
    const colors = JSON.parse(localStorage.getItem("theme")) === 'Dark' ? '#00F0FF' : 'black';
    const brdr = bgcolor === 'black' ? "#0C3758" : "#FFFFFF";
    const avbg = bgcolor === 'black' ? "black" : "rgba(13, 158, 62, 0.1)";

    const cls = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'white' : 'notfound';

    // "#00F0FF"

    return (
        <Card style={{ width: 300, height: 103, margin: 16, border: `1px solid ${brdr}`, borderRadius: "5px", backgroundColor:bgcolor, color: colors }} className={[classes.ant_card_body, classes.ant_card_meta_title].join(' ')}>
                <Card.Meta
                  avatar={
                    <div className={`${classes.ant_card_meta_avatar} ${cls} `} style={{backgroundColor:avbg}}>{props.icon}</div>
                  }
                  title={
                    <div className={classes.ant_card_meta_title} style={{backgroundColor:bgcolor, color:colors}}>{props.title}</div>
                  }
                  description={
                    <div className={classes.ant_card_meta_description} style={{backgroundColor:bgcolor, color:colors}}>{props.description}</div>
                  }

                />
              </Card>
    )
}
