import { Col, Form, Image, Row } from 'antd';
import React, { useEffect } from 'react';
import { StepPanel } from '../../../containers/StepPanel/StepPanel';
import * as http from '../../../http';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../../utils';
import classes from '../DeviceConfig.module.css';

export const LANPhoneConfig=(props)=>{
    
    const [routerConfigData, setConfigData] = React.useState({
        device_uplink_connector_combo:null,
        device_downlink_connector_combo:null,
        is_primary:0,
        ports: props.configData?.wrd_equipment?.ports,
        wrd_equipment_id_combo:null,
        comm_equipment_type: props.configData?.comm_equipment_type,
        image_url: '',
        image_name: '',
    });

    
    
    const [stepForm] = Form.useForm();
    const [errors, setErrorData] = React.useState({});
    // const[allSteps, setAllSteps] = React.useState({});
    const[steps, setSteps] = React.useState({});

    let isMounted = useIsMounted();

    
    useEffect(() => {
      if(props?.configData?.configID){
      http.get(`/api/scenario/${props.configData.scenarioID}/${props.configData.configID}/get_config/`).then((data)=>{
        // console.log("Incoming: ");
        // console.log(data);
        let newData = {};
        _.forOwn(data.data?.network_config, (value, key)=>{
            if(!routerConfigData.hasOwnProperty(key)){
              const val = {};
              val[key] = value;
              stepForm.setFieldsValue(val);
            }
            else{
              newData[key] = value;
            }
        })

        _.forOwn(data.data?.config, (value, key)=>{
            if(!routerConfigData.hasOwnProperty(key)){
              const val = {};
              val[key] = value;
              stepForm.setFieldsValue(val);
            }
            else{
              newData[key] = value;
            }
        })

        _.forOwn(data.data, (value, key)=>{
            if(!routerConfigData.hasOwnProperty(key)){
              const val = {};
              val[key] = value;
              stepForm.setFieldsValue(val);
            }
            else{
              newData[key] = value;
            }
        })
        

        data.data.port_config.forEach((port_config)=>{
            _.forOwn(port_config, (value, key)=>{
                if(!routerConfigData.hasOwnProperty(key+'_'+port_config.port_id)){
                    const val = {};
                    // console.log(key+'_'+port_config.port_id)
                    val[key+'_'+port_config.port_id] = value;
                    stepForm.setFieldsValue(val);
                    if(key=='is_dhcp')
                        newData[key+'_'+port_config.port_id] = value;
                }else{
                    newData[key+'_'+port_config.port_id] = value;
                }
            });
        });
        
      if(isMounted.current){
        setConfigData((prevVal)=>{
          return {...prevVal, ...newData}
        })
      }
      console.log(routerConfigData)
      }).catch((err)=>{
        console.log(err);
      })
      }
      return () => { isMounted = true }; 
    }, [setConfigData, props.configData])
    
    const setPostData=(field, type, label, value)=>{
        let newData = {};
        // console.log("Type: "+type);
        
        if(field == 'usability_type'){
            if(value && !props?.configData?.equipmentData?.usability_type.includes(value.value)){
                alert(`${props.configData?.equipmentData?.model} doesn't support ${value.label} installation`);
                return
            }
        }
        if(type == 'select'){
            if(value){
                newData[field] = value.value;
                newData[`${field}_combo`] = value;
            }
            else{
                newData[field] = null;
                newData[`${field}_combo`] = null;
            }
            if (`${field}_combo` == 'wrd_equipment_id_combo'){
                getDevicePorts();
            }
        }
        else if(type == 'input-button'){
            if(value == 'plus'){
                const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
                const updatedValue = {};
                updatedValue[field] = newValue;
                stepForm.setFieldsValue(updatedValue)
            }
            else{
                const newValue = parseInt(stepForm.getFieldsValue()[field]|| 0) - 1;
                const updatedValue = {};
                updatedValue[field] = newValue;
                stepForm.setFieldsValue(updatedValue)
            }
        }
        else if(type == 'checkbox')
        {   
            newData[field] = value;
        }
        

        setConfigData((prevVal)=>{
            let newValue={...prevVal, ...newData}
            return newValue
        })
        // console.log(newData);
    }

    // const setUplinkConfigPage = (field, type, label, value)=>{
    //     console.log(routerConfigData.number_of_dev_uplink);
    // }

    function getDevicePorts(){
        let url =''
        let device_url =''
        if(props?.configData?.wrd_equipment?.id && !(routerConfigData?.wrd_equipment_id_combo?.value)){
            device_url =`/api/wire_comm_equipments/${props?.configData?.wrd_equipment?.id}/`
        }
        else{
            device_url =`/api/wire_comm_equipments/${routerConfigData?.wrd_equipment_id_combo?.value}`
        }
        http.get(device_url).then(
            (data)=>{
                console.log(data.data)
                
                setConfigData((prevVal)=>{
                    return {...prevVal, ...{'ports':data?.data.ports,'image_url':data?.data?.image_url, 'image_name':data?.data?.image_name}}
                })
        })
        .catch((err)=>{
                console.log(err);
        })
    }
    
    const onFinish=(values)=>{
        const postData = {...values, ...routerConfigData};
        window.frames[0].postMessage({
            data: postData,
            type: 'resolve',
            popupId: props.popupID
        })
    }

    const ConfigForm=()=>{
        return (
            <div className={classes.content}>
                <Row gutter={[16,16]}>
                <Col span={24}></Col>
                    <Col span={12}>
                        <BuildFormItem
                        name={`wrd_equipment_id`}
                        label='Select Equipment'
                        index={props.index}
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/get_allocated_wrd_equipments/${props?.configData?.scenarioID}/${props?.configData?.equipment_type?.id}/`,
                            onSelectChange:setPostData,
                            value: routerConfigData.wrd_equipment_id_combo
                            }}
                            formConfig={{
                            required: true,
                            hidden: props.configData?.scenarioData?.scenario_type == 2 || props.configData?.scenarioData?.scenario_type == 3? false :true,
                            }}
                        />
                    </Col>

                    <Col span={props.configData?.scenarioData?.scenario_type == 2 || props.configData?.scenarioData?.scenario_type == 3? 12 :24}>
                        <BuildFormItem
                            name='device_name'
                            label='Resource Name'
                            type='input'
                            formConfig={{
                                required: true,
                                value: routerConfigData.device_id,
                            }}
                        />
                    </Col> 

                    <Col span={4}>
                        <BuildFormItem
                            name='is_primary'
                            label='Is Primary'
                            type='checkbox'
                            fieldConfig={{
                                style: {color: "white" },
                                checked:routerConfigData.is_primary,
                                onChange: setPostData
                            }}
                        />
                    </Col>
                    <Col span={16}>
                        <Image
                            height = {routerConfigData?.image_url!=''?300:0}
                            src={`${routerConfigData?.image_url}`}
                        />
                    </Col>
                    <Col span={4}/>
                </Row>
            </div>
        )
    }

    const PortsConfigForm=(props)=>{
        let index = props.index
        let id = routerConfigData.ports[index].id;
        return (
            <div className={classes.content}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <BuildFormItem
                            name={'is_dhcp_'+id} 
                            label='Is DHCP'
                            type='checkbox'
                            fieldConfig={{
                                style: {color: "white" },
                                checked:routerConfigData['is_dhcp_'+id],
                                onChange: setPostData
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                <Col span={6}></Col>
                <Col span={12}>
                        <BuildFormItem
                            name={'port_ip_'+id}
                            label='Device IP'
                            type='input'
                            formConfig={{
                                required: true,
                                value: props.portData.value,
                                hidden: routerConfigData['is_dhcp_'+id]
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <BuildFormItem
                            name={'port_subnet_mask_'+id}
                            label='Subnet Mask'
                            type='input'
                            formConfig={{
                                required: true,
                                value: routerConfigData.device_subnet_mask,
                                hidden: routerConfigData['is_dhcp_'+id]
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name={'port_gateway_'+props.portData.id}
                            label='Gateway'
                            type='input'
                            formConfig={{
                                required: true,
                                value: routerConfigData.device_gateway,
                                hidden: routerConfigData['is_dhcp_'+id]
                            }}
                        />
                    </Col>
                </Row> 
            </div>
        )
    }

    const SIPConfigForm=()=>{

        return (
            <div className={classes.content}>
                <Row gutter={[16, 16]}>
                <Col span={6}></Col>
                    <Col span={12}>
                        <BuildFormItem
                            name='extension_name'
                            label='Extension Name'
                            type='input'
                            formConfig={{
                                required: true,
                                value: routerConfigData.extension_name,
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                
                <Col span={12}>
                        <BuildFormItem
                            name='extension_number'
                            label='Extension Number'
                            type='input'
                            formConfig={{
                                required: true,
                                value: routerConfigData.extension_number,
                            }}
                        />
                    </Col>
                
                <Col span={12}>
                        <BuildFormItem
                            name='extension_password'
                            label='Password'
                            type='input'
                            formConfig={{
                                required: true,
                                value: routerConfigData.extension_password,
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <BuildFormItem
                            name='sip_server_ip'
                            label='SIP Server IP'
                            type='input'
                            formConfig={{
                                required: true,
                                value: routerConfigData.sip_server_ip,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='sip_server_port'
                            label='Server Port'
                            type='input'
                            formConfig={{
                                required: true,
                                value: routerConfigData.sip_server_port,
                            }}
                        />
                    </Col>
                </Row> 
            </div>
        )
    }

    let count=0;
    let portConfigsForms=[];
    if(routerConfigData.ports!=null){
        (routerConfigData.ports).forEach((port)=>{
            let newFrom =[
                {
                    title: port.name,
                    content: <PortsConfigForm portData={port} index={count}/>,
                    step: count+3
                }
            ];
            count++;
            portConfigsForms=portConfigsForms.concat(newFrom)
        }) 
    }else{
        getDevicePorts()
    }

   let step=[
       {
            title: "Configuration",
            content: <ConfigForm />,
            step: 1
        },
        {
            title: "SIP Connection Configuration",
            content: <SIPConfigForm />,
            step: 2
        }
    ]

    step= step.concat(portConfigsForms);
    return (
        <div className={classes.formmain}>
            <Form form={stepForm} onFinish={onFinish}>
                <StepPanel
                    className={classes.step_panel}
                    steps={step}
                    direction='horizontal'
                    />
            </Form>
        </div>
    )

}