import { Layout, Row } from 'antd';
import classes from './ContentHeader.module.css';
const { Content, Header, Footer, Sider } = Layout;

const ContentHeader=(props)=>
{
  var class1 = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitelistheader : classes['list-header'];
  var class2 = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitelistheadertitle : classes['list-header-title'];

  return <div className={class1}>
  <Row justify='space-between'>
    <h5 className={class2}>{props.title}</h5>
    <div className={classes['header-add-button-div']}>
    {props.pageButtons?.map(function(elem, index){
                    return elem;
                  })}
    </div>
  </Row>
  </div>;
}
 
export default ContentHeader;
