import { faBook, faBookOpen, faChalkboardTeacher, faThLarge, faUserGraduate, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { InfoCard } from "../../components/InfoCard/InfoCard";
import * as http from '../../http';
const user = <FontAwesomeIcon icon={faUsers} />
const book = <FontAwesomeIcon icon={faBook} />
const openBook = <FontAwesomeIcon icon={faBookOpen} />
const student = <FontAwesomeIcon icon={ faUserGraduate} />
const teacher = <FontAwesomeIcon icon={ faChalkboardTeacher} />
const tools = <FontAwesomeIcon icon={ faThLarge} />

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_color: null,
    }
  }

  reload() {
    history.go(1);
    location.reload(true);
  }

  // get_user_theme(){
  //   http.get(`/api/user_theme/`).then((resp)=>{
  //     if(resp?.data?.user_id != null){
  //       this.setState({
  //       user_color: resp?.data?.color
  //     })}
  //     }).catch((err)=>{
  //       console.log(err);
  //   })
  // }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
    http.get('api/dashboard_details')
    .then((res) => {
      if (res.status == 200) {
        this.setState({
          users: res.data[0].users,
          students: res.data[0].students,
          instructors: res.data[0].instructors,
          courses: res.data[0].courses,
          activeCourses: res.data[0].active_courses,
          wl_equip: res.data[0].wl_equip,
          wrd_equip: res.data[0].wrd_equip,
        })
      }

    });

    http.get(`/api/user_theme/`).then((resp)=>{
      if(resp?.data?.user_id != null){
        this.setState({
        user_color: resp?.data?.color
      })}
      }).catch((err)=>{
        console.log(err);
    });
  }
  render() {
    const { Meta } = Card;
    
    // this.get_user_theme(this);

    const {user_color} = this.state;
    console.log(user_color);

    var theme = JSON.parse(localStorage.getItem("theme"));

    if(user_color != null)
    {
      theme = user_color;
      var vlu = JSON.stringify(theme);
      localStorage.setItem("theme", vlu);
      this.setState({
        user_color: null
      });
      if(user_color === null)
        this.reload();
      // window.location.reload();
    }
    else if(theme === null)
    {
        theme = "White";
        var vlu = JSON.stringify(theme);
        localStorage.setItem("theme", vlu);
        theme = null;
        if(theme === null)
          this.reload();
    }

    const user_type = JSON.parse(localStorage.state).auth.user.data.user_type;

    const colors = JSON.parse(localStorage.getItem("theme")) === 'Dark' ? 'white' : 'black';
   
    if(user_type == 0){
      return (
        <>
            <Row gutter={[16, 16]} >
              <Col span={6} >
                <InfoCard title="Total Users" icon={user} description={this.state.users} />
              </Col>
              <Col span={6}>
                <InfoCard title="Total Instructors" icon={teacher} description={this.state.instructors}/>
              </Col>
              <Col span={6}>
                <InfoCard title="Total Students" icon={student} description={this.state.students}/>
              </Col>
              <Col span={6}>
                <InfoCard title="Wireless Equipments" icon={tools} description={this.state.wl_equip}/>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
            <Col span={6}>
                <InfoCard title="Wired Equipments" icon={tools} description={this.state.wrd_equip}/>
              </Col>
            <Col span={6}>
                <InfoCard title="Total Courses" icon={book} description={this.state.courses}/>
              </Col>
              <Col span={6}>
                <InfoCard title="Total Active Courses" icon={openBook} description={this.state.activeCourses}/>
              </Col>
            </Row>
        </>

      )

    }
    else if(user_type == 1){
      return(
        <>
          <Row gutter={[16, 16]} >
              <Col span={8}>
                <InfoCard title="Total Students" icon={student} description={this.state.students}/>
              </Col>
              <Col span={8}>
                <InfoCard title="Wireless Equipments" icon={tools} description={this.state.wl_equip}/>
              </Col>
              <Col span={8}>
                <InfoCard title="Wired Equipments" icon={tools} description={this.state.wrd_equip}/>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
            <Col span={8}>
                <InfoCard title="Total Courses" icon={book} description={this.state.courses}/>
              </Col>
              <Col span={8}>
                <InfoCard title="Total Active Courses" icon={openBook} description={this.state.activeCourses}/>
              </Col>
            </Row>
        </>
      )
    }
    else{
      return(
        <>
            <Row gutter={[16, 16]} >
              <Col span={6}>
                <InfoCard title="Wireless Equipments" icon={tools} description={this.state.wl_equip}/>
              </Col>
              <Col span={6}>
                <InfoCard title="Wired Equipments" icon={tools} description={this.state.wrd_equip}/>
              </Col>
            </Row>
        </>
      )
    }
      
  }
}
Home.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})
export default connect(mapStateToProps)(withRouter(Home));
