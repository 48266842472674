import { Table } from 'antd';
import { Component } from 'react';
import classes from './DataTable.module.css';

class DataTable extends Component {
    constructor(props){
      super(props);
      console.log("Pagination: ", this.props.pagination)
    }
      handleTableChange = (pagination, filters, sorter) => {
        this.props.handleTableChange(pagination, filters, sorter);
      };
      
      rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          this.props.onRowSelect(selectedRowKeys, selectedRows);
        }
      };
    
    getDataSource(){
        return typeof(this.props.dataSource) === 'function'? this.props.dataSource():this.props.dataSource;
    }

    render() {
      const {multiselect} = this.props;

      const tableClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteDataTable' : classes.DataTable;

       return (
           <div className={classes.table_main}>
                <Table
                className={tableClass}
                rowKey={record => record.id}
                columns={this.props.columns}
                dataSource={this.getDataSource()}
                pagination={this.props.pagination}
                // pagination={showSizeChanger}
                loading={this.props.loading}
                onChange={this.handleTableChange}
                {...(multiselect ? {
                  rowSelection: {
                  type: 'checkbox',
                  ...this.rowSelection,
                    }
                  } : {})
                }
                scroll={{ x: "max-content"}}
            />
           </div>

        );
    }
}

export default DataTable;