import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Space, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalTitle } from '../../components/ModalTitle/ModalTitle';
import BaseList from "../../containers/BaseList/BaseList";
import * as http from '../../http';
import { SearchFormMapper } from './SearchFormMapper';
import { TeacherAddPage } from './TeacherAddPage';
import { columnMapper } from './columnMapper';

class TeachersListPage extends BaseList {
  constructor(props){
    super(props);
  }
  getColumns(){
    const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
    const deleteClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';
    return [
      ...columnMapper,
      {
        title: 'Action',
        key: 'action',
        fixed:'right',
        render: (record) => (
          <Space size="middle">
          <Button 
            key='editButton'
            className={themeClass}
            icon={<EditOutlined />}
            onClick={()=>this.showModal(`/api/teachers/${record.id}`)} />

            <Popconfirm okType='default' title="Are sure to Delete?" okText="Yes" onConfirm={() => this.handleDelete(record)}>
              <Button 
                  key='deleteButton'
                  className={deleteClass}
                  icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        )
      }
    ];
  }

  getDataUrl(){
    return '/api/teachers/'; 
  }

  getPageConfig(){
    return {
      pageTitle:'Instructor List',
      pageButtons: [
        <Button
          key='teacher-add'
          className={'create_btn'}
          onClick={()=>this.showModal()}
          type="primary"
          size="small"
          icon={<PlusOutlined />}
        >
          ADD NEW
        </ Button>
      ],
    }
  }

  getSearchFields(){
    return SearchFormMapper;
  }
  onAddSaveClick=(values)=>{
    delete values.id;
    console.log(values)
    http.post('/api/teachers', values)
      .then((response)=> {
      if(response.status == 201){
        this.setState({
          isModalVisible: false,
          erros: {}
        })
        message.success('Trainer Created Successfully');
        this.fetch();
      }
    }).catch((err)=>{
      message.error('Trainer Creation Failed');
      if(err.message == 'Network Error'){
        return
      }
      else{
      this.errorCallback(err.response);
      }
    })
    console.log(values);
  }
  onUpdateClick=(values)=>{
    http.put(`/api/teachers/${values.id}`, values)
      .then((response)=> {
      if(response.status == 200){
        this.setState({
          isModalVisible: false,
          erros: {}
        })
        message.success('Trainer Updated Successfully');
        this.fetch();
      }
    }).catch((err)=>{
      message.error('Trainer Update Failed');
      this.errorCallback(err.response);
    })
    console.log(values);
  }

  onFormFinish=(values)=>{
    if(this.state.editUrl){
      this.onUpdateClick(values);
    }
    else{
      this.onAddSaveClick(values);
    }
  }
  handleDelete(record){
    
    // axios({
    //   url: `/api/teachers/${record.id}`,
    //   method: 'delete',
    //   type: 'json',
    //   }).then(res => {
    //     message.success("Deleted Successfully")
    //     this.fetch()
      
    // }).catch((err) => {
    //   message.error('Delete Failed');
    // });

    http.del(`/api/teachers/${record.id}`)
      .then((response)=> {
      if(response.status == 200){
        message.success('Deleted Successfully');
        this.fetch();
      }else{
        message.error('Deleted Not Successfully');
      }
    }).catch((err)=>{
      message.error('Delete Failed');
      this.errorCallback(err.response);
    })
  }
  render(){
    const colorClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'customantmodal' : 'notfound';

    return (
      <>
      {super.render()}
      <Modal 
        title={<ModalTitle title="Add Trainer" />}
        visible={this.state.isModalVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        destroyOnClose={true}
        footer={null}
        centered={true}
        className={colorClass}
      >
      <TeacherAddPage 
          onFinish={this.onFormFinish.bind(this)}
          editUrl={this.state.editUrl}
          errors={this.state.errors}
      />
      </Modal>
      </>
    );

  }

}
const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps)(withRouter(TeachersListPage));
