export const columnMapper = [
  {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      // width: '2%',
      hidden: true
    },
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      width: '100',
      fixed: 'left'
    },
    {
      title: 'Code',
      dataIndex: 'code',
      editable: true,
      width: '100',
      fixed: 'left'
    },
 
   
    {
      title: 'Communication Frequency',
      dataIndex: 'comm_freq',
      editable: true,
      // width: '5%',
    },
    
    {
      title: 'Transmission Media',
      dataIndex: 'transmission_media',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Tx Maximum Frequency (MHz)',
      dataIndex: 'tx_freq_max',
      editable: true,
      // width: '5%',
    }, 
    {
      title: 'Tx Minimum Frequency (MHz)',
      dataIndex: 'tx_freq_min',
      editable: true,
      // width: '5%',
    },{
      title: 'Rx Maximum Frequency (MHz)',
      dataIndex: 'rx_freq_max',
      editable: true,
      // width: '5%',
    },{
      title: 'Rx Minimum Frequency (MHz)',
      dataIndex: 'rx_freq_min',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Power Supply Type',
      dataIndex: 'ps_type',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Power Source (Watt)',
      dataIndex: 'power_source',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Base station Power Output (Watt)',
      dataIndex: 'power_output',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Manpack Power Output (Watt)',
      dataIndex: 'power_output_mpack',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Modulation',
      dataIndex: 'modulation',
      editable: true,
      // width: '5%',
    },{
      title: 'Commsec',
      dataIndex: 'commsec',
      editable: true,
      // width: '5%',
    },{
      title: 'Encryption',
      dataIndex: 'encryption',
      editable: true,
      // width: '5%',
    },{
      title: 'Antenna Type',
      dataIndex: 'antenna_type',
      editable: true,
      // width: '5%',
    },{
      title: 'Bandwidth (MHz)',
      dataIndex: 'bandwith',
      editable: true,
      // width: '5%',
    },{
      title: 'No of Channels',
      dataIndex: 'no_of_channels',
      editable: true,
      // width: '5%',
    },{
      title: 'Voice Encoding',
      dataIndex: 'voice_encoding',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Tent Coverage',
      dataIndex: 'tent_coverage',
      editable: true,
      // width: '5%',
    },
    
    {
      title: 'Receving Sensitivity (dBm)',
      dataIndex: 'receving_sensitivity',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Antrenna Impedance (Ohms)',
      dataIndex: 'antenna_impedance',
      editable: true,
      // width: '5%',
    }, 
    {
      title: 'Internal Input Voltage (V)',
      dataIndex: 'internal_input_voltage',
      editable: true,
      // width: '5%',
    },
    {
      title: 'External Input Voltage (V)',
      dataIndex: 'external_input_voltage',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Tx Current Consumption (Ah)',
      dataIndex: 'tx_power_consumption',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Rx Current Consumption (Ah)',
      dataIndex: 'rx_power_consumption',
      editable: true,
      // width: '5%',
    },
    
    {
      title: 'Sb Current Consumption  (Ah)',
      dataIndex: 'sb_power_consumption',
      editable: true,
      // width: '100',
      // fixed:'right'
    },
    {
      title: 'Frequency Hope Rate (Hop/Sec)',
      dataIndex: 'frequency_hop_rate',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Hoping',
      dataIndex: 'hopping',
      editable: true,
      // width: '5%',
    },
    {
      title: 'Gps',
      dataIndex: 'gps',
      editable: true,
      // width: '5%',
    },
    
  //   {
  //     title: 'User Type',
  //     dataIndex: 'user_type',
  //     render: user_type => user_type==0?'Admin':user_type==1?'Teacher':'Student',
  //     width: '20%',
  //   }
  ].filter(item => !item.hidden);