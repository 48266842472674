export const columnMapper = [
    {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        width: '2%',
        hidden: true
      },
      {
        title: 'Course Name',
        dataIndex: 'name',
        editable: true,
        width: '50%',
      },
      {
        title: 'Course Code',
        dataIndex: 'code',
        editable: true,
        width: '50%',
      },
    ].filter(item => !item.hidden);