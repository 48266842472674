export const columnMapper = [
    {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        width: '2%',
        hidden: true
      },
      {
        title: 'Course Name',
        dataIndex: 'course_name',
        editable: true,
        width: '20%',
        fixed: 'left',
      },
      {
        title: 'Teacher Name',
        dataIndex: 'teacher_name',
        editable: true,
        width: '20%',
        fixed: 'left',
      },
      {
        title: 'Student Name',
        dataIndex: 'student_name',
        editable: true,
      },
      {
        title: 'Batch',
        dataIndex: 'student_batch',
        editable: true,
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        editable: true,
      },
      {
        title: 'End Date',
        dataIndex: 'end_date',
        editable: true,
      },
    ].filter(item => !item.hidden);