import { Button, Col, Form, Row } from 'antd';
import React from 'react';
import { InputBtnfield } from '../../../components/Fields/InputBtnField';
import { SelectField } from '../../../components/Fields/SelectField';

export const AddIonosphereSettings = (props) => {
    const [year, setYear] = React.useState(null);
    const [month, setMonth] = React.useState(null);
    const [layerD, setLayerD] = React.useState({})
    const [layerE, setLayerE] = React.useState({})
    const [layerF, setLayerF] = React.useState({})
    const [layerF1, setLayerF1] = React.useState({})
    const [layerF2, setLayerF2] = React.useState({})
    const [form] = Form.useForm();
    

    const onFinish = (values) => {
        let valData = values
        delete valData['year']
        delete valData['month']
        let postData = {}
        postData['IonoSphereSettings'];
        let layerDData = {};
        let layerEData = {};
        let layerFData = {};
        let layerF1Data = {};
        let layerF2Data = {};
        _.forOwn(valData, (value, key)=>{
            console.log(value)
            let arr = key.split('_');
            let splitted_data = [...arr.splice(0, 2), arr.join('_')];
            let [layer, day_time_id, name] = splitted_data;
         
            if(layer == 'D'){
                if(layerDData.hasOwnProperty(day_time_id)){
                    layerDData[day_time_id][name] = value
                }
                else{
                    layerDData[day_time_id] = {}
                    layerDData[day_time_id][name] = value
                }
                
            }
            else if(layer == 'E'){
                if(layerEData.hasOwnProperty(day_time_id)){
                    layerEData[day_time_id][name] = value
                }
                else{
                    layerEData[day_time_id] = {}
                    layerEData[day_time_id][name] = value
                }
                
            }
            else if(layer == 'F'){
                if(layerFData.hasOwnProperty(day_time_id)){
                    layerFData[day_time_id][name] = value
                }
                else{
                    layerFData[day_time_id] = {}
                    layerFData[day_time_id][name] = value
                }
                
            }
            else if(layer == 'F1'){
                if(layerF1Data.hasOwnProperty(day_time_id)){
                    layerF1Data[day_time_id][name] = value
                }
                else{
                    layerF1Data[day_time_id] = {}
                    layerF1Data[day_time_id][name] = value
                }
                
            }
            else if(layer == 'F2'){
                if(layerF2Data.hasOwnProperty(day_time_id)){
                    layerF2Data[day_time_id][name] = value
                }
                else{
                    layerF2Data[day_time_id] = {}
                    layerF2Data[day_time_id][name] = value
                }
                
            }
        })
        console.log(layerDData)
        console.log(month)
        const data = {
            0: {
                ...layerDData,
                ...layerD,
                year: year.label,
                month: month.value,
            },
            1: {
                ...layerEData,
                ...layerE,
                
                year: year.label,
                month: month.value,
            },
            2: {
                ...layerFData,
                ...layerF,
                year: year.label,
                month: month.value,
            },
            3: {
                ...layerF1Data,
                ...layerF1,
                year: year.label,
                month: month.value,
            },
            4: {
                ...layerF2Data,
                ...layerF2,
                year: year.label,
                month: month.value,
            },

        }
        postData['IonoSphereSettings'] = data
        console.log(data)
        console.log(postData)
        return props.onFinish(postData);

    }

    const handleYear = (value) => {

        setYear({ value: value.value, label: value.label });
    }
    const handleMonth = (value) => {
        // console.log(month)

        setMonth({ value: value.value, label: value.label });
    }


    const handleLayerDData = (day_time_id, label, value) => {
        console.log(label)
        console.log(value)
        const dataVal = {}
        dataVal[day_time_id] = {}
        dataVal[day_time_id][label] = value
        setLayerD((prevVal) => {
            if (prevVal.hasOwnProperty(day_time_id)) {
                dataVal[day_time_id] = { ...prevVal[day_time_id], ...dataVal[day_time_id] }
                return { ...prevVal, ...dataVal }
            }

            return { ...prevVal, ...dataVal }


        })
    }

    const handleLayerEData = (day_time_id, label, value) => {
        const dataVal = {}
        dataVal[day_time_id] = {}
        dataVal[day_time_id][label] = value
        setLayerE((prevVal) => {
            if (prevVal.hasOwnProperty(day_time_id)) {
                dataVal[day_time_id] = { ...prevVal[day_time_id], ...dataVal[day_time_id] }
                return { ...prevVal, ...dataVal }
            }

            return { ...prevVal, ...dataVal }


        })
    }
    const handleLayerFData = (day_time_id, label, value) => {
        const dataVal = {}
        dataVal[day_time_id] = {}
        dataVal[day_time_id][label] = value
        setLayerF((prevVal) => {
            if (prevVal.hasOwnProperty(day_time_id)) {
                dataVal[day_time_id] = { ...prevVal[day_time_id], ...dataVal[day_time_id] }
                return { ...prevVal, ...dataVal }
            }

            return { ...prevVal, ...dataVal }


        })
    }


    const handleLayerF1Data = (day_time_id, label, value) => {
        const dataVal = {}
        dataVal[day_time_id] = {}
        dataVal[day_time_id][label] = value
        setLayerF1((prevVal) => {
            if (prevVal.hasOwnProperty(day_time_id)) {
                dataVal[day_time_id] = { ...prevVal[day_time_id], ...dataVal[day_time_id] }
                return { ...prevVal, ...dataVal }
            }

            return { ...prevVal, ...dataVal }


        })
    }
    const handleLayerF2Data = (day_time_id, label, value) => {
        const dataVal = {}
        dataVal[day_time_id] = {}
        dataVal[day_time_id][label] = value
        setLayerF2((prevVal) => {
            if (prevVal.hasOwnProperty(day_time_id)) {
                dataVal[day_time_id] = { ...prevVal[day_time_id], ...dataVal[day_time_id] }
                return { ...prevVal, ...dataVal }
            }

            return { ...prevVal, ...dataVal }


        })
    }
    const onBeforeButtonClicked = (field) => {
        let arr = field.split('_');
        let splitted_data = [...arr.splice(0, 2), arr.join('_')];
        let [layer, day_time, name] = splitted_data;
        let new_val = 0;
        if (layer == 'D') {
            if (layerD.hasOwnProperty(day_time)) {
                let prev_value = layerD[day_time][name] || 0;
                new_val = prev_value - 1;
                handleLayerDData(day_time, name, new_val)
            }
            else {
                new_val = 0;
                handleLayerDData(day_time, name, new_val);
            }
        }
        else if (layer == 'E') {
            if (layerE.hasOwnProperty(day_time)) {
                let prev_value = layerE[day_time][name] || 0
                new_val = prev_value - 1
                handleLayerEData(day_time, name, new_val)

            }
            else {
                new_val = 0;
                handleLayerEData(day_time, name, new_val);
            }
        }
        else if (layer == 'F') {
            
            if (layerF.hasOwnProperty(day_time)) {
                let prev_value = layerF[day_time][name] || 0
                new_val = prev_value - 1
                handleLayerFData(day_time, name, new_val)

            }
            else {
                new_val = 0;
                handleLayerFData(day_time, name, new_val);
            }
        }
        else if (layer == 'F1') {
            if (layerF1.hasOwnProperty(day_time)) {
                let prev_value = layerF1[day_time][name] || 0
                new_val = prev_value - 1
                handleLayerF1Data(day_time, name, new_val)

            }
            else {
                new_val = 0;
                handleLayerF1Data(day_time, name, new_val);
            }
        }
        else if (layer == 'F2') {
            if (layerF2.hasOwnProperty(day_time)) {
                let prev_value = layerF2[day_time][name] || 0
                new_val = prev_value - 1
                handleLayerF2Data(day_time, name, new_val)

            }
            else {
                new_val = 0;
                handleLayerF2Data(day_time, name, new_val);
            }
        }

        const data = {}
        data[field] = new_val;
        form.setFieldsValue(data);
    }
    const onAfterButtonClicked = (field) => {
        let arr = field.split('_');
        let splitted_data = [...arr.splice(0, 2), arr.join('_')];
        let [layer, day_time, name] = splitted_data;
        let new_val = 0;
        if (layer == 'D') {
            if (layerD.hasOwnProperty(day_time)) {
                let prev_value = layerD[day_time][name] || 0;
                new_val = prev_value + 1;
                handleLayerDData(day_time, name, new_val)
            }
            else {
                new_val = 0;
                handleLayerDData(day_time, name, new_val);
            }
        }
        else if (layer == 'E') {
            if (layerE.hasOwnProperty(day_time)) {
                let prev_value = layerE[day_time][name] || 0;
                new_val = prev_value + 1;
                handleLayerEData(day_time, name, new_val)
            }
            else {
                new_val = 0;
                handleLayerEData(day_time, name, new_val);
            }
        }
        else if (layer == 'F') {
            if (layerF.hasOwnProperty(day_time)) {
                let prev_value = layerF[day_time][name] || 0;
                new_val = prev_value + 1;
                handleLayerFData(day_time, name, new_val)
            }
            else {
                new_val = 0;
                handleLayerFData(day_time, name, new_val);
            }
        }
        else if (layer == 'F1') {
            if (layerF1.hasOwnProperty(day_time)) {
                let prev_value = layerF1[day_time][name] || 0;
                new_val = prev_value + 1;
                handleLayerF1Data(day_time, name, new_val)
            }
            else {
                new_val = 0;
                handleLayerF1Data(day_time, name, new_val);
            }
        }
        else if (layer == 'F2') {
            if (layerF2.hasOwnProperty(day_time)) {
                let prev_value = layerF2[day_time][name] || 0;
                new_val = prev_value + 1;
                handleLayerF2Data(day_time, name, new_val)
            }
            else {
                new_val = 0;
                handleLayerF2Data(day_time, name, new_val);
            }
        }
        const data = {}
        data[field] = new_val;
        form.setFieldsValue(data);
    }
    return (

        <Form className={'season_setting_add_form'} style={{ color: "white", paddingBottom: '30px', margin: '20px' }}
            onFinish={onFinish} form={form}
            initialValues={props.data}>
            <Row className={'header_row'}>
                <div className={'header_main'}>
                    <div className={'header_main_sub'}>
                        <h4>Ionosphere Settings</h4>
                    </div>

                </div>
            </Row>
            <Row className={'justify-content-space-around mb-2'}>

                <Col span={11}>
                    <Form.Item
                        name="year"
                    > <SelectField url="api/combo/year" placeholder="Select Year" value={year}
                        onSelectChange={handleYear} /> </Form.Item>

                </Col>
                <Col span={11}>
                    <Form.Item
                        name="month"
                    > <SelectField url="api/combo/month" placeholder="Select Month" value={month}
                        onSelectChange={handleMonth} /> </Form.Item>

                </Col>
            </Row>
            <div>
                <br />
                <Row>
                    <Col span={6}>
                    </Col>
                    <Col span={6}>
                        <p className={'font-1'}>Max Height (km)</p>
                    </Col>
                    <Col span={6}>
                        <p className={'font-1'}>Min Height (km)</p>
                    </Col>
                    <Col span={6}>
                        <p className={'font-1'}>Electron Density (e/m^3)</p>
                    </Col>

                </Row>
               
                <Row>
                    <p className={'font-1'}>Layer D</p>
                </Row>
                <Row>
                    <Col span={24}>
                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}><p className={'form_title_p'}>Day Time</p></Col>
                            <Col>
                                <Form.Item
                                    name="D_0_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },]}>
                                    <InputBtnfield
                                        placeholder="Enter Max Height"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('D_0_max_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('D_0_max_height')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="D_0_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield 
                                    placeholder="Enter Min Height" 
                                    onBeforeButtonClicked={() => onBeforeButtonClicked('D_0_min_height')} 
                                    onAfterButtonClicked={() => onAfterButtonClicked('D_0_min_height')} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="D_0_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield 
                                        placeholder="Enter Electron Density"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('D_0_electron_density')} 
                                        onAfterButtonClicked={() => onAfterButtonClicked('D_0_electron_density')}
                                    />
                                </Form.Item>
                            </Col>


                        </Row>

                    </Col>

                </Row>
            </div>


            <div>

                <Row>
                    <Col span={24}>
                        <Row>
                            <p className={'font-1'}> Layer E</p> <br />

                        </Row>
                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}><p className={'form_title_p'}>Day Time</p></Col>
                            <Col>
                                <Form.Item
                                    name="E_0_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield placeholder="Enter Max Height" onBeforeButtonClicked={() => onBeforeButtonClicked('E_0_max_height')} onAfterButtonClicked={() => onAfterButtonClicked('E_0_max_height')} />
                                    </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="E_0_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Min Height" onBeforeButtonClicked={() => onBeforeButtonClicked('E_0_min_height')} onAfterButtonClicked={() => onAfterButtonClicked('E_0_min_height')} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="E_0_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Electron Density" onBeforeButtonClicked={() => onBeforeButtonClicked('E_0_electron_density')} onAfterButtonClicked={() => onAfterButtonClicked('E_0_electron_density')} />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Col>
                    <Col span={24}>
                        <Row span={2}>

                        </Row>
                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}>
                                <p className={'form_title_p'}>Dusk Time</p>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="E_1_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield placeholder="Enter Max Height" onBeforeButtonClicked={() => onBeforeButtonClicked('E_1_max_height')} onAfterButtonClicked={() => onAfterButtonClicked('E_1_max_height')} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="E_1_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Min Height" onBeforeButtonClicked={() => onBeforeButtonClicked('E_1_min_height')} onAfterButtonClicked={() => onAfterButtonClicked('E_1_min_height')} /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="E_1_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Electron Density" onBeforeButtonClicked={() => onBeforeButtonClicked('E_1_electron_density')} onAfterButtonClicked={() => onAfterButtonClicked('E_1_electron_density')} /></Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>

                        </Row>
                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}><p className={'form_title_p'}>Evening Time</p></Col>
                            <Col>
                                <Form.Item
                                    name="E_2_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Max Height"
                                    onBeforeButtonClicked={() => onBeforeButtonClicked('E_2_max_height')} onAfterButtonClicked={() => onAfterButtonClicked('E_2_max_height')} /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="E_2_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Min Height"
                                    onBeforeButtonClicked={() => onBeforeButtonClicked('E_2_min_height')} onAfterButtonClicked={() => onAfterButtonClicked('E_2_min_height')} /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="E_2_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Electron Density"
                                     onBeforeButtonClicked={() => onBeforeButtonClicked('E_2_electron_density')} onAfterButtonClicked={() => onAfterButtonClicked('E_2_electron_density')} /></Form.Item>

                            </Col>


                        </Row>

                    </Col>
                    <Col span={24}>
                        <Row span={24}>

                        </Row>
                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}><p className={'form_title_p'}> Night Time</p></Col>
                            <Col>
                                <Form.Item
                                    name="E_3_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Max Height"
                                    onBeforeButtonClicked={() => onBeforeButtonClicked('E_3_max_height')} onAfterButtonClicked={() => onAfterButtonClicked('E_3_max_height')} /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="E_3_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Min Height"
                                     onBeforeButtonClicked={() => onBeforeButtonClicked('E_3_min_height')} onAfterButtonClicked={() => onAfterButtonClicked('E_3_min_height')} /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="E_3_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Electron Density"
                                     onBeforeButtonClicked={() => onBeforeButtonClicked('E_3_electron_density')} onAfterButtonClicked={() => onAfterButtonClicked('E_3_electron_density')} /></Form.Item>
                            </Col>


                        </Row>
                    </Col>
                    <Col span={24}>


                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}><p className={'form_title_p'}>Dawn Time</p></Col>
                            <Col>
                                <Form.Item
                                    name="E_4_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Max Height"
                                     onBeforeButtonClicked={() => onBeforeButtonClicked('E_4_max_height')} onAfterButtonClicked={() => onAfterButtonClicked('E_4_max_height')} /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="E_4_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Min Height"
                                    onBeforeButtonClicked={() => onBeforeButtonClicked('E_4_min_height')} onAfterButtonClicked={() => onAfterButtonClicked('E_4_min_height')} /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="E_4_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Electron Density"
                                    onBeforeButtonClicked={() => onBeforeButtonClicked('E_4_electron_density')} onAfterButtonClicked={() => onAfterButtonClicked('E_4_electron_density')} /></Form.Item>
                            </Col>


                        </Row>


                    </Col>
                </Row>
                {/*Layer E end*/}
            </div>

            <div>
                <Row>
                    <p className={'font-1'}>Layer F</p>
                </Row>

                <Row>
                    <Col span={24}>
                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}><p className={'form_title_p'}>Evening Time</p></Col>
                            <Col>
                                <Form.Item
                                    name="F_2_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },]}

                                >
                                    <InputBtnfield
                                        placeholder="Enter Max Height"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F_2_max_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F_2_max_height')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F_2_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Min Height"
                                    onBeforeButtonClicked={() => onBeforeButtonClicked('F_2_min_height')} onAfterButtonClicked={() => onAfterButtonClicked('F_2_min_height')} /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F_2_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Electron Density"
                                    onBeforeButtonClicked={() => onBeforeButtonClicked('F_2_electron_density')} onAfterButtonClicked={() => onAfterButtonClicked('F_2_electron_density')} /></Form.Item>

                            </Col>
                        </Row>

                    </Col>
                    <Col span={24}>
                        <Row className={'justify-content-space-around'}>

                            <Col className={'ionoshpare_add_page_title'}><p className={'form_title_p'}> Night Time</p></Col>
                            <Col>
                                <Form.Item
                                    name="F_3_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Max Height"
                                    onBeforeButtonClicked={() => onBeforeButtonClicked('F_3_max_height')} onAfterButtonClicked={() => onAfterButtonClicked('F_3_max_height')} /></Form.Item>

                            </Col>
                            <Col>
                                <Form.Item
                                    name="F_3_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield placeholder="Enter Min Height"
                                 onBeforeButtonClicked={() => onBeforeButtonClicked('F_3_min_height')} onAfterButtonClicked={() => onAfterButtonClicked('F_3_min_height')} /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F_3_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield
                                        placeholder="Enter Electron Density"
                                        
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F_3_electron_density')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F_3_electron_density')} /></Form.Item>
                            </Col>


                        </Row>
                        <Row>


                        </Row>

                    </Col>
                    <Col span={24}>
                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}><p className={'form_title_p'}>Dawn Time</p></Col>
                            <Col>
                                <Form.Item
                                    name="F_4_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield
                                        placeholder="Enter Max Height"
                
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F_4_max_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F_4_max_height')}
                                    /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F_4_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield
                                        placeholder="Enter Min Height"
                                       
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F_4_min_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F_4_min_height')}
                                    /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F_4_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield
                                        placeholder="Enter Electron Density"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F_4_electron_density')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F_4_electron_density')}
                                    /></Form.Item>

                            </Col>


                        </Row>

                    </Col>
                </Row>
            </div>

            <div>
                <Row>
                    <p className={'font-1'}> Layer F1</p>
                </Row>

                <Row>

                    <Col span={24}>
                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}><p className={'form_title_p'}>Day Time</p></Col>

                            <Col>
                                <Form.Item
                                    name="F1_0_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield
                                        placeholder="Enter Max Height"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F1_0_max_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F1_0_max_height')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F1_0_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield
                                        placeholder="Enter Min Height"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F1_0_min_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F1_0_min_height')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F1_0_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield
                                        placeholder="Enter Electron Density"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F1_0_electron_density')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F1_0_electron_density')}
                                    />
                                </Form.Item>
                            </Col>


                        </Row>

                    </Col>
                    <Col span={24}>

                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}>
                                <p className={'form_title_p'}>Dusk Time</p>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F1_1_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield
                                        placeholder="Enter Max Height"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F1_1_max_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F1_1_max_height')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F1_1_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                ><InputBtnfield
                                        placeholder="Enter Min Height"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F1_1_min_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F1_1_min_height')}
                                    /></Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F1_1_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield
                                        placeholder="Enter Electron Density"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F1_1_electron_density')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F1_1_electron_density')}
                                    />
                                </Form.Item>
                            </Col>


                        </Row>

                    </Col>

                </Row>
            </div>

            <div>
                <Row className={'border-1'}>
                    <p className={'font-1'}>Layer F2</p>
                </Row>

                <Row>

                    <Col span={24}>
                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}><p className={'form_title_p'}>Day Time</p></Col>
                            <Col>
                                <Form.Item
                                    name="F2_0_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield
                                        placeholder="Enter Max Height"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F2_0_max_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F2_0_max_height')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F2_0_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield
                                        placeholder="Enter Min Height"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F2_0_min_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F2_0_min_height')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F2_0_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield
                                        placeholder="Enter Electron Density"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F2_0_electron_density')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F2_0_electron_density')}
                                    />
                                </Form.Item>

                            </Col>
                        </Row>

                    </Col>
                    <Col span={24}>
                        <Row className={'justify-content-space-around'}>
                            <Col className={'ionoshpare_add_page_title'}><p className={'form_title_p'}>Dusk Time</p></Col>
                            <Col>
                                <Form.Item
                                    name="F2_1_max_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield
                                        placeholder="Enter Max Height"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F2_1_max_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F2_1_max_height')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="F2_1_min_height"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield
                                        placeholder="Enter Min Height"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F2_1_min_height')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F2_1_min_height')}
                                    />
                                </Form.Item>

                            </Col>
                            <Col>
                                <Form.Item
                                    name="F2_1_electron_density"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please! Enter a Number',
                                        },
                                    ]}
                                >
                                    <InputBtnfield
                                        placeholder="Enter Electron Density"
                                        onBeforeButtonClicked={() => onBeforeButtonClicked('F2_1_electron_density')}
                                        onAfterButtonClicked={() => onAfterButtonClicked('F2_1_electron_density')}
                                    />
                                </Form.Item>
                            </Col>


                        </Row>
                    </Col>

                </Row>
            </div>

            <Row className={'button_row'}>
                <Button htmlType='submit'> Add Settings</Button></Row>

        </Form>
    )

}