import 'bootstrap/dist/css/bootstrap.min.css';
import jwt_decode from 'jwt-decode';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { refreshJWT, setCurrentUser } from './actions/authentication';
import './App.css';
import BaseLayout from './containers/Layout/Layout';
import Login from './containers/Login/Login';
import * as http from './http';
import setAuthToken from './setAuthToken';
import store from './store';


class App extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  componentDidMount(){
    http.get('/api/general_settings')
    .then((response)=> {
    if(response.status === 200){
      this.setState({
        title: response.data.rows[0].title,
        sub_title: response.data.rows[0].sub_title
      })
    }

  }).catch((err)=>{
    console.log(err)
  })
    if(localStorage.jwtToken) {
      setAuthToken(localStorage.jwtToken);
      const decoded = jwt_decode(localStorage.jwtToken);
      store.dispatch(setCurrentUser(decoded));
      const currentTime = Date.now() / 1000;
      if(decoded.exp < currentTime) {
        store.dispatch(refreshJWT());
        // store.dispatch(logoutUser());
        // window.location.href = '/'
      }
    }
  }
  render() {
    return (
        <Provider store = { store }>
        <Router>
          <Switch>
              <Route exact path="/" component={ Login } />
              <BaseLayout title={this.state.title} sub_title={this.state.sub_title}/>
            </Switch>
          </Router>
        </Provider>
    );
  }
}

export default App;
