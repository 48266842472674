import { Button, Form, Row } from 'antd';
import axios from 'axios';
import { useEffect } from 'react';
import { InputField } from '../../components/Fields/InputField';
import { SelectField } from '../../components/Fields/SelectField';
import React from 'react';
import { set } from 'lodash';

export const AddPage = (props) =>{
    const [form] = Form.useForm();
    const [userType,setUserType]=React.useState(null)
    const onFinish = (values) =>{
        const PostData = {Permissions: {}};
        PostData['Permissions']['id']=values.id;
        PostData['Permissions']['user_type']=userType.value;
        PostData['Permissions']['roles']=values.roles;
        
        return props.onFinish(PostData);
        
    }
    useEffect(() => {
        if (props.editUrl) {
            axios({
                url: props.editUrl,
                method: 'get',
                type: 'json',
            }).then(data => {
                form.setFieldsValue({ 'id': data.data.id });
                form.setFieldsValue({ 'roles': data.data.roles });
                setUserType({ value: data.data.user_type, label: data.data.user_type_text });

            });
        }
    }, []);

    const handleUserType = (value) =>{
        setUserType({ value: value?.value, label: value?.label });
    }
    return(
        <Form onFinish={onFinish} form={form}>
            <div>
            <Form.Item name="id" hidden><InputField /></Form.Item>

            <Form.Item
                name="user_type"
                label={<p className={'formLabel'}>User Type</p>}
                labelCol={{span: 24}}
            >
                <SelectField value={userType} placeholder="Select User Type" url="/api/combo/user_type/?exclude_student=true" onSelectChange={handleUserType}/> </Form.Item> <br />
            <Form.Item  
                name="roles"
                label={<p className={'formLabel'}>User Roles</p>}
                labelCol={{span: 24}}
            >
            <InputField value placeholder="roles"/>
            </Form.Item>
            </div> <br />
            <div  className={'button_row'}>
            <Button type="primary" htmlType="submit">
                    {props.editUrl? 'Update':'Add'}
            </Button>
            </div>
            
        </Form>
    )
}