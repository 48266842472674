import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import * as http from '../../http';
import { useIsMounted } from '../../utils';
import classes from './SelectField.module.css';

function DebounceSelect({ fetchOptions, debounceTimeout = 100, ...props }) {
	const [fetching, setFetching] = React.useState(false);
	const [options, setOptions] = React.useState(props.data);
	const fetchRef = React.useRef(0);
	const url = props.url;
	const onSelectChange = props.onSelectChange;
	let isMounted = useIsMounted();
	if(props.hasOwnProperty('onSelectChange')){
		delete props.onSelectChange;
	}
	useEffect(() => {
		if(url && url != ''){
			// console.log('DebounceSelect: ', url)
			http.get(url, {
			method: 'get',
			type: 'json',
			}).then(data => {
			if(data.data){
			const defaultOptions = [];
			for(var i=0; i<data?.data?.length; i++){
				defaultOptions.push({
					label: data.data[i]?.name,
					value: data.data[i]?.value,
				})
			}
			if(isMounted.current){
			setOptions(defaultOptions);
			}
			}
    	});
	}
	return () => { isMounted = false }; 
	}, [setOptions, url]);

	const debounceFetcher = React.useMemo(() => {
		const loadOptions = (value) => {
		fetchRef.current += 1;
		const fetchId = fetchRef.current;
		setOptions([]);
		setFetching(true);
		fetchOptions(value, props).then((newOptions) => {
			if (fetchId !== fetchRef.current) {
				return;
			}
			setOptions(newOptions);
			setFetching(false);
			// console.log('newOptions',newOptions)
		});
		};
		
		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout]);

	const onselectchange=(value)=>{
		if(onSelectChange){
			onSelectChange(value);
			console.log('Selected: ',value)
		}
		else{
		}
	}
	if (url) {
		return (
			<Select
			labelInValue
			loading={fetching}
			allowClear={true}
			onSearch={debounceFetcher}
			notFoundContent={fetching ? <Spin size="small" /> : null}
			optionFilterProp="children"
			filterOption={(input, option) => { 
				let value = (option?.label?.toString().toLowerCase() ?? '').includes(input.toString().toLowerCase())
				return value
			}
			}
			filterSort= {(optionA, optionB) => (optionA?.label.toString() ?? '').toLowerCase().localeCompare((optionB?.label.toString() ?? '').toLowerCase())}
			{...props}
			options={options}
			className={classes.SelectField}
			// onSelect={onselectchange}
			onChange={onselectchange}
		/>
		);
	}
	else {
		// console.log('Else options: ', options)
		return (
		<Select
			labelInValue
			allowClear={true}
			optionFilterProp="children"
			filterOption={(input, option) => (option?.label?.toString().toLowerCase() ?? '').includes(input.toString().toLowerCase())}
			filterSort= {(optionA, optionB) => (optionA?.label.toString() ?? '').toLowerCase().localeCompare((optionB?.label.toString() ?? '').toLowerCase())}
			{...props}
			options={options}
			className={classes.SelectField}
			onChange={onselectchange}
		/>
		);
	}
} // Usage of DebounceSelect

async function fetchUserList(value, props) {
	const search_field = props.searchField || 'name';
	const query_params = {};
	query_params[search_field] = value;
	const url = props.url;
	return http.get(url, {
		method: 'get',
		type: 'json',
		params: { ...query_params }
	}).then(data => {
		return data?.data?.map((data) => (
		{
			label: `${data.name}`,
			value: `${data.value}`,
		}
		)
		)
	}
	);
}

export const SelectField = (props) => {
	const [value, setValue] = React.useState([]);
	function getSelectedValue(newValue) {
		console.log(newValue);
	}
	const propData = {
		maxTagCount: 2,
		maxTagTextLength: 8,
		showSearch: true,
		value: value,
		placeholder: "Select",
		style: {
		width: '100%'
		},
		data: [],
		...props

	}
	
	return (
		<DebounceSelect
		// mode='multiple'
		// maxTagCount={2}
		// maxTagTextLength={8}
		// showSearch={true}
		// value={value}
		// placeholder="Select Users"
		{...propData}
		fetchOptions={fetchUserList}
		onChange={(newValue) => {
			setValue(newValue);
			getSelectedValue(newValue);
		}}
		/>
	);
};
