import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Table } from "antd";
import React from "react";

let user_data = JSON.parse(localStorage?.getItem('state'))?.auth?.user?.data

export const ActiveCourseView=(props)=>{
    const columns = [
        {
          title: 'Student',
          dataIndex: 'student_name',
          key: 'student_id',
        },
        {
          title: 'Batch',
          dataIndex: 'student_batch',
          key: 'batch',
        },
        {
          title: 'Rank',
          dataIndex: 'student_rank',
          key: 'rank',
        },
        {
          title: 'Course Name',
          dataIndex: 'course_name',
          key: 'active_course_id',
        },
      ];
      const deleteClass = JSON.parse(localStorage?.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';
      const mapper = [...columns,
        {
          title: 'Action',
          key: 'action',
          fixed:'right',
          render: (record) => (
            <Space size="middle">
              <Popconfirm okType='default' title="Are sure to Delete?" okText="Yes" onConfirm={() => props.handleStudentDelete(record)}>
                <Button 
                      key='deleteButton'
                      className={deleteClass}
                      icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          )
        }
      ]

      let column_mapper = user_data?.user_type == 1?columns: mapper

      
    return (
       <Table dataSource={props.data} columns={column_mapper}/>
    )
}