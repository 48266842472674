export const SearchFormMapper = [
    // {
    //     label: 'Scenario Name',
    //     name: 'scenario_name',
    //     type: 'input',
    //     rules: [],
    //     placeholder: 'Enter Scenario Name',
    // },
    // {
    //     label: 'Teacher Name',
    //     name: 'teacher_name',
    //     type: 'input',
    //     rules: [],
    //     placeholder: 'Enter Teacher Name',
    // },
   
]