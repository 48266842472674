import { Table, PageHeader, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { renderMatches } from "react-router";
import * as http from '../../http';
import { buildFormItem as BuildFormItem } from '../../utils';
import "../../App.css";
import classes from "./EquipmentListPage.module.css";

function EquipmentListPage() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scenario_id, setScenarioID]= useState();
  const [select_data, setSelectData] = useState({
    select_scenario_id_combo:null,
    });

  const get_equipment_data = () => {
    setLoading(true);
    http.get(`/api/equipment_find/${select_data?.select_scenario_id ? select_data.select_scenario_id : 0}`).then((resp)=>{

        setDataSource(resp.data);
        setLoading(false);
      }).catch((err)=>{
        console.log(err);
        setLoading(false);
    });
  }

  useEffect(() => {
    get_equipment_data();
  }, [select_data]);

  const columns = [
    {
      title: "Scenario ID",
      dataIndex: "id",
      width: '25%',
      // sorter: (a, b) => a.id > b.id,
    },
    {
      title: "Equipment Type",
      dataIndex: "equipment_type",
      width: '25%',
    },
    {
      title: "Total Equipment",
      dataIndex: "total_equipment",
      width: '25%',
    },
    {
        title: 'Total Length',
        dataIndex: 'total_length',
        width: '25%',
    }
  ];

  const setChangeSelectData=(field, type, label, value)=>{
    let newData = {};
    if(type == 'header-select'){
        if(value){
            newData[field] = value.value;
            newData[`${field}_combo`] = value;
        }
        else{
            newData[field] = null;
            newData[`${field}_combo`] = null;
        }
    }
    
    setSelectData((prevVal)=>{
        let newValue={...prevVal, ...newData}
        return newValue
    })
    
    // console.log(newData);
  }

  const GetPageHeaderExtra = () => {
    let clr = JSON.parse(localStorage.getItem("theme")) === 'White' ? '#132555' : '#ffffff';

    return(
        <div>
            <Row gutter={16,16}>
                <Col>
                      <h7 className={classes.fakecls} style={{color:clr}} >Standard Scenario : </h7>
                </Col>
                <Col style={{width:'200px'}}>
                    <BuildFormItem
                        key={'select_scenario_id'}
                        name={`select_scenario_id`}
                        label={'Scenario'}
                        index={`0001`}
                        type='header-select'
                        fieldConfig={{
                            url:`/api/combo/scenarios/`,
                            onSelectChange:setChangeSelectData,
                            value: select_data.select_scenario_id_combo,
                            }}
                    />
                </Col>
            </Row>
        </div>
    )
  }

  return (
    <div>
      
      <PageHeader 
          style={{
              border: '1px solid rgb(235, 237, 240)',
          }}
          title="Equipment List"
          extra={[<GetPageHeaderExtra/>]}
          className="damm"
      />

      <Table
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: 20
          }}
      ></Table>
    </div>
  );
}

export default EquipmentListPage;
