import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, message, Popconfirm, Space } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import BaseList from "../../../containers/BaseList/BaseList";
import { wrd_connectors_column_mapper } from './wrd_connectors_column_mapper';
const copy = <FontAwesomeIcon icon={faCopy}/>

class WrdConnectorsList extends BaseList {
getColumns(){
  const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
  const deleteClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';

  return [
    ...wrd_connectors_column_mapper,
    {
      title: 'Action',
      key: 'wrd-connector-action',
      fixed:'right',
      render: (record) => (
        <Space size="middle">
        <Link 
          key='wrd-connector-editButton'
          className={themeClass}
          to={`/wire_connectors/edit/${record.id}`}
        >
          <EditOutlined />
        </Link>
        <Link 
          key='wrd-connector-duplicate'
          className={themeClass}
          to={`/wire_connectors/duplicate/${record.id}`}
        >
          {copy}
        </Link>
        <Popconfirm okType='default' title="Are sure to Delete?" okText="Yes" onConfirm={() => this.handleDelete(record)}>
              <Button 
                  key='wrd-connector-deleteButton'
                  className={deleteClass}
                  icon={<DeleteOutlined />}
              />
            </Popconfirm>
        </Space>
      )
    }
  ];
}

getDataUrl(){
  return '/api/wire_connectors/'; 
}
handleDelete(record){
  axios({
    url: `/api/wire_connectors/${record.id}`,
    method: 'delete',
    type: 'json',
    }).then(res => {
      message.success("Deleted Successfully")
      this.fetch()
    
  }).catch((err) => {
    message.error('Delete Failed');
  });

}
getPageConfig(){
  return {
    pageTitle:'Equipments List',
    pageButtons: [
      <Button
        key='wrd-connector-add'
        className={'create_btn'}
        onClick={()=>this.props.history.push('/wire_connectors/add')}
        type="primary"
        size="small"
        icon={<PlusOutlined />}
      >
          Add Equipment
      </ Button>
    ]
  }
}

// getSearchFields(){
//   return SearchFormMapper;
// }

}
const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps)(withRouter(WrdConnectorsList));
