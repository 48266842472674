export const wrd_connectors_column_mapper = [
  {
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      // width: '2%',
      hidden: true
    },
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      width: '100',
      fixed: 'left'
    }, 
    
  ].filter(item => !item.hidden);