import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Popconfirm, Row, Space, message } from "antd";
import React, { useEffect, useState } from 'react';
import ListView from "../../components/ListView/ListView";
import SearchItem from "../../containers/BaseList/SearchBox/SearchItem";
import * as http from '../../http';
import { useIsMounted } from '../../utils';
import { AssignScenario } from './AssignScenario';
import { AssignedStudentList } from "./AssignedStudentList";
import { ScenarioList } from "./ScenarioList";
import { SearchFormMapper } from "./SearchFormMapper";

export const ScenariosView=(props)=>{
    const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
    const deleteClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';

    const [isModalVisible, setModalVisible] = React.useState(false)
    const [courseID, setCourseID] = React.useState(null);
    const [loaded, setLoaded] = useState(false);
    const [editUrl, setEditUrl] = React.useState(null);

    const [data, setListData] = useState([]);
    const [studentData, setStudentData] = useState({});
    let isMounted = useIsMounted();
    const fetchData = (query=false) =>{
      let url = `/api/scenario/`
      if(query){
        url = url+query
      }
      http.get(url).then(
        (resp) => {
          let dataList = [];
          let studentData = {};
          for (let i = 0; i < resp?.data?.rows?.length; i++) {
            let desc = "Location: " + resp.data.rows[i].location_name_text 
            + ", \nScenario Type: " + resp.data.rows[i].scenario_type_text
            + ", Zoom: " + resp.data.rows[i].zoom
            dataList.push({
              title: resp.data.rows[i].name,
              description: desc,
              id: resp.data.rows[i].id,
              actionItems: [
                <Space>
                  <Button
                      key={resp.data.rows[i].id}
                      icon={<EditOutlined />}
                      className={themeClass}
                      onClick={()=>onEditButtonClicked(resp.data.rows[i].id)}>
                  </Button>

                  <Popconfirm okType="default" title="Are sure to Delete?" okText="Yes" onConfirm={() => handleDelete(resp.data.rows[i])}>
                    <Button 
                      key='deleteButton'
                      className={deleteClass}
                      icon={<DeleteOutlined />}
                    />

                  </Popconfirm>
                  </Space>
                  
                  ]
            })
            
            for(let count =0; count<resp.data.rows[i].assigned_students.length; count++){
              resp.data.rows[i].assigned_students[count]['actionItems']= createStudentRemoveButton(resp.data.rows[i].assigned_students[count].id)
              
            }

            studentData[resp.data.rows[i].id] = resp.data.rows[i].assigned_students
            // console.log(studentData[resp.data.rows[i].id])
          }
          if(isMounted.current){
          if (dataList.length > 0){
            setCourseID(dataList[0].id);//set default selected 
            setListData(dataList);
            setStudentData(studentData);
          }
        }
      }
      )
    }
    useEffect(() => {
      fetchData()
      return () => { isMounted = false;};
    }, [setListData])

  const onSpaceSearch = (value) => {
      console.log(value);
      fetchData(`?name=${value}`)
  }


    const createStudentRemoveButton = (id)=>{
      return(
        <Space size="middle">
          <Popconfirm okType='default' title="Are sure to Delete?" okText="Yes" onConfirm={() => handleStudentDelete(id)}>
                <Button 
                    key='deleteButton'
                    className={deleteClass}
                    icon={<DeleteOutlined />}
                />
              </Popconfirm>
          </Space>
      )
    }

    const onEditButtonClicked=(courseID)=>{
        props.history.push(`/scenarios/edit/${courseID}`)
    }

     const handleDelete=(record)=>{
      // axios({
      //   url: `/api/scenario/${record.id}`,
      //   method: 'delete',
      //   type: 'json',
      //   }).then(res => {
      //     message.success("Deleted Successfully")
      //     fetchData()
      // })


      http.del(`/api/scenario/${record.id}`)
        .then((response)=> {
        if(response.status == 200){
          message.success('Deleted Successfully');
          fetchData()
        }else{
          message.error('Deleted Not Successfully');
        }
      }).catch((err)=>{
        message.error('Delete Failed');
        this.errorCallback(err.response);
      })
    }

    const handleStudentDelete=(id)=>{
      // axios({
      //   url: `/api/scenario_trainee/${id}`,
      //   method: 'delete',
      //   type: 'json',
      //   }).then(res => {
      //     message.success("Deleted Successfully")
      //     window.location.reload(true);
      // }).catch((err) => {
      //   message.error('Delete Failed');
      // });

      http.del(`/api/scenario_trainee/${id}`)
        .then((response)=> {
        if(response.status == 200){
          message.success('Deleted Successfully');
          fetchData()
        }else{
          message.error('Deleted Not Successfully');
        }
      }).catch((err)=>{
        message.error('Delete Failed');
        this.errorCallback(err.response);
      })
    }

    const onItemClick=(item)=>{
      setCourseID(item.id)
    }

    const getSearchFields=()=>{
        const children = [];
        for (let i = 0; i < SearchFormMapper.length; i++) {
          children.push(
              <SearchItem key={i} index={i} config={SearchFormMapper[i]}/>
          );
        }
        return children;
    }

    const handleCreate = ()=>{
      props.history.push(`/scenarios/add_scenarios`)
    }

    const getPageHeaderExtra=()=>{
        return [
          <>
          <Row gutter={16,16}>
            <Col >
              {/* <Button key="add_new_scenario" onClick={()=>setModalVisible(true)}>Create Scenario</Button> */}
              <Button  key="add_new_scenario" onClick={handleCreate}>Create Scenario</Button>
            </Col>
            <Col>
              <Button className='assign_btn' key="assign_scenario" onClick={()=>setModalVisible(true)}>Assign Scenario</Button>
            </Col>
          </Row>
          </>
            
        ]
    }
    const onAddSaveClick = (values) => {
        console.log(values)
        http.post('/api/scenario_trainee/', values).then(
          (resp)=>{
            if(resp.status == 201){
              setModalVisible(false)
              message.success('Active Course Created Successfully')
              fetchData()
            }
          }
        ).catch((err)=>{
          message.error('Active Course Creation Failed, Please Correct Following Errors!');
        })
       
      }
    const handleOk = () => {
        setModalVisible(false);
      };
    
    const handleCancel = () => {
        setModalVisible(false)
    };
    if(data){
    return (
        <>
          <Modal 
              title={<div className={'header_row'}><div className={'header_main'}><div className={'header_main_sub'}><h4>Assign Scenario</h4></div></div></div>}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              destroyOnClose={true}
              footer={null}
              centered={true}
              width={800}
              className={'scenario_add_form active_course_add_form'}
          >
            <AssignScenario 
              onFinish={onAddSaveClick}
              setModalVisible={setModalVisible}
              editUrl={editUrl}
            />
          </Modal>
          <ListView
            pageTitle='Scenarios'
            reactSpaceSearchDesign={true}
            onSpaceSearch={onSpaceSearch}
            listComponent={<ScenarioList {...props} data={data} onItemClick={onItemClick} />}
            viewComponent={<AssignedStudentList {...props} data={studentData?studentData[courseID]: []} />}
            listSpan={props.auth?.user?.data?.user_type==2? 24:6}
            viewSpan={props.auth?.user?.data?.user_type==2? 0:18}
            {...props}
            getSearchFields={getSearchFields}
            getPageHeaderExtra={getPageHeaderExtra}
          />
         
        </>
    )
    }
    else{
      return (<></>)
    }
}
