import { Form } from 'antd';
import { DateField } from '../../../components/Fields/DateField';
import { InputField } from '../../../components/Fields/InputField';
import { SelectField } from '../../../components/Fields/SelectField';
import classes from './SearchItem.module.css';


const buildFormField = (config, onSearchFieldChange)=>{
    if(config.type == 'input'){
        return <InputField {...config} />; 
    }
    else if(config.type == 'date'){
        return <DateField {...config} onChange={(value)=>onSearchFieldChange(config, value)} />
    }
    else if(config.type == 'select'){
        return <SelectField {...config} onSelectChange={(value)=>onSearchFieldChange(config, value)}/>
    }
}
const SearchItem = ({index, getFieldDecorator, config, onFieldChange})=>{
    const onSearchFieldChange = (config, value)=>{
        return onFieldChange(config.name, config.type, value)
    }
    return (
        <Form.Item label={config.label} name={config.name} rules={config.rules} className={[classes.SearchItem, 'search_item'].join(' ')}>
            {buildFormField(config, onSearchFieldChange)}
        </Form.Item>
        );
}


export default SearchItem;
