import { Button, Col, Form, Row, Slider } from 'antd';
import React, { useEffect } from 'react';
import { InputBtnfield } from '../../components/Fields/InputBtnField';
import { InputField } from '../../components/Fields/InputField';
import { SelectField } from '../../components/Fields/SelectField';
import * as http from '../../http';
import classes from './addPage.module.css';


export const Addpage = (props) => {
    const [supply_voltage, setSupplyVoltage] = React.useState(0);
    const [supply_current, setSupplyCurrent] = React.useState(0);
    const [ps_type, setPsType] = React.useState(null);
    const [usabilityType, setUsabilityType] = React.useState(null);
    const [frequency, setFrequnecy] = React.useState(30);
    
    const [form] = Form.useForm();
    useEffect(() => {
        
        if (props.editUrl) {
            http.get(`${props.editUrl}`, {
                method: 'get',
                type: 'json',
            }).then(data => {
                console.log(data.data)
                setSupplyVoltage(data.data.supply_voltage)
                setSupplyCurrent(data.data.supply_current)
                form.setFieldsValue({ 'supply_voltage': data.data.supply_voltage });
                form.setFieldsValue({ 'supply_current': data.data.supply_current });
                form.setFieldsValue({ 'name': data.data.name });
                form.setFieldsValue({ 'frequency': data.data.frequency });
                form.setFieldsValue({ 'id': data.data.id });
                setUsabilityType({ value: data.data.usability_type, label: data.data.usability_type_text });
                setPsType({ value: data.data.supply_type, label: data.data.ps_type_text });
            });
        }
    }, []);
    const onFinish = (values) => {
        let postData = { 'PowerSupply': {} }
        postData['PowerSupply']['id'] = values.id;
        postData['PowerSupply']['supply_voltage'] = values.supply_voltage;
        postData['PowerSupply']['supply_current'] = values.supply_current;
        postData['PowerSupply']['usability_type'] = usabilityType?.value;
        postData['PowerSupply']['supply_type'] = ps_type?.value;
        postData['PowerSupply']['name'] = values.name;
        postData['PowerSupply']['frequency'] = frequency;
        console.log(postData)

        return props.onFinish(postData);
    };


    const handledIcrementSupply_voltage = () => {
        setSupplyVoltage(
            (prev_voltage) => {
                form.setFieldsValue({ 'supply_voltage': prev_voltage + 1 })
                return prev_voltage + 1
            }
        );

    }

    const handledDeccrementSupply_voltage = () => {
        setSupplyVoltage(
            (prev_voltage) => {
                form.setFieldsValue({ 'supply_voltage': prev_voltage - 1 })
                return prev_voltage - 1
            }
        );

    }
    const handledIcrementSupply_current = () => {
        setSupplyCurrent(
            (prev_current) => {
                form.setFieldsValue({ 'supply_current': prev_current + 1 })
                return prev_current + 1
            }
        );
    }

    const handledDeccrementSupply_current = () => {
        setSupplyCurrent(
            (prev_current) => {
                form.setFieldsValue({ 'supply_current': prev_current - 1 })
                return prev_current - 1
            }
        );
    }
    const handleUsabilityTypeChange = (value) => {
        setUsabilityType({ value: value?.value, label: value?.label });
    }
    const handlePSTypeChange = (value) => {
        setPsType({ value: value?.value, label: value?.label });
    }
    const handleFrequency = (value) => {
        setFrequnecy(value);
    }

    // const headerClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteMode_header_main : classes.header_main ;
    // const headerSubClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteMode_header_main_sub : classes.header_main_sub ;
    // const IrDrClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteMode_Ir_Dr_Input : classes.Ir_Dr_Input ;
    // const buttonClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteMode_button_row : classes.button_row ;
    // const InputTitle = JSON.parse()

    let mode = JSON.parse(localStorage.getItem("theme"));

    let headerClass = "", headerSubClass = "", IrDrClass = "", buttonClass = "", InputTitle = "";
    
    if(mode == 'White')
    {
        headerClass = classes.whiteMode_header_main;
        headerSubClass = classes.whiteMode_header_main_sub;
        IrDrClass = classes.whiteMode_Ir_Dr_Input;
        buttonClass = classes.whiteMode_button_row;
        InputTitle = classes.whiteMode_input_title;
    }
    else
    {
        headerClass = classes.header_main;
        headerSubClass = classes.header_main_sub;
        IrDrClass = classes.Ir_Dr_Input;
        buttonClass = classes.button_row;
        InputTitle = classes.input_title;
    }


    return (
        <Form style={{ color: "white" }} onFinish={onFinish} 
        form={form} initialValues={props.data}  
        className={[classes.main_form, 'powersource_add_page'].join(' ')}
        >
            
            <Row className={classes.header_row}>
                <div className={headerClass}>
                    <div className={headerSubClass}>
                         <h4>Add Power Source</h4>
                    </div>

                </div>
            </Row>
            <Row className={'pt-5 justify-content-space-evenly '}>
                <Col span={8} >
                    <p className={InputTitle}>Usability Type</p>
                    <Form.Item 
                        name="usability_type"
                        validateStatus={props.errors?.supply_type_id?.validateStatus}
                        help={props.errors?.supply_type_id?.errorMsg || null}
                        required={true}
                    > <SelectField value={usabilityType} url="/api/combo/power_supply/usability_type" onSelectChange={handleUsabilityTypeChange} /> </Form.Item>
                </Col>
                <Col span={2} pull={1}>
                    <p className={InputTitle}>Supply Type</p>
                    <Form.Item 
                        name="ps_type"
                        validateStatus={props.errors?.supply_type_id?.validateStatus}
                        help={props.errors?.supply_type_id?.errorMsg || null}
                        required={true}
                    > <SelectField value={ps_type} url="/api/combo/power_supply/supply_type" onSelectChange={handlePSTypeChange} /> </Form.Item>
                </Col>
                <Col span={8} pull={1}>
                    <p className={InputTitle}>Name</p>
                    <Form.Item name="id" hidden><InputField /></Form.Item>
                    <Form.Item 
                        name="name"
                        validateStatus={props.errors?.name?.validateStatus}
                        help={props.errors?.name?.errorMsg || null}
                        required={true}
                    ><InputField /></Form.Item>
                </Col>
            </Row>
            <Row className={'pt-5 justify-content-space-evenly' }>
                <Col span={10}>
                    <p className={InputTitle}>Supply Voltage (<span>V</span>)</p>
                    <div className={[IrDrClass, 'd-inline-flex p-2'].join(' ')}>
                        <Form.Item 
                            name='supply_voltage'
                            validateStatus={props.errors?.supply_voltage?.validateStatus}
                            help={props.errors?.supply_voltage?.errorMsg || null}
                            required={true}
                        ><InputBtnfield onBeforeButtonClicked = {() => handledDeccrementSupply_voltage ()} onAfterButtonClicked = {() => handledIcrementSupply_voltage ()}/>
                        </Form.Item>
                    </div>
                </Col>
                <Col span={10}>
                    <p className={InputTitle}>Supply Current (<span>V</span>)</p>
                    <div className={[IrDrClass, 'd-inline-flex p-2'].join(' ')}>
                        <Button onClick={() => handledDeccrementSupply_current()}>-</Button>
                        <Form.Item name='supply_current'
                         validateStatus={props.errors?.supply_current?.validateStatus}
                         help={props.errors?.supply_current?.errorMsg || null}
                         required={true}
                        ><InputField /></Form.Item><Button onClick={() => handledIcrementSupply_current()}>+</Button>
                    </div>

                </Col>

            </Row>
            <Row className={'pt-5 justify-content-space-evenly'}>

                <Col span={18}>
                    <p className={InputTitle}>Frequency (<span>Hz</span>)</p>
                    <Form.Item name='frequency'
                    validateStatus={props.errors?.frequency?.validateStatus}
                    help={props.errors?.frequency?.errorMsg || null}
                    required={true}
                    >
                        <Slider className={classes.frequency_handler} defaultValue={30} onChange={handleFrequency} />
                    </Form.Item>

                </Col>


            </Row>
            <Row className={buttonClass}>
                 <Button htmlType='submit' > { props.duplicate? 'Duplicate' : props.editUrl? 'Update' :'Add'}</Button>
            </Row>

        </Form>
    )
}
