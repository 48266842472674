import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Input, PageHeader, Row, Space } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import classes from './ListView.module.css';
const fasearch = <FontAwesomeIcon icon={faSearch}/>;
const { Search } = Input;

class ListView extends Component{
    constructor(props){
        super(props);
    }

    getSearchFields(){
        const searchItems = this.props.getSearchFields() || [];
        if (searchItems.length > 0){
            return (
                <Row className={'search_field'}>
                    {searchItems}
                    <Col className={'d-flex align-item-end ant-col-btn'}> <button className={'search_btn'}>{fasearch}</button></Col>
                </Row>
            )
        }else{
            return (
                <Row className={'search_field'}>
                </Row>
            )
        }
        
    }
    getPageHeaderExtra(){
        return this.props.getPageHeaderExtra() || [];
    }

    render(){
        const searchClass = JSON.parse(localStorage.getItem("theme")) === "White" ? classes.whitesearchBox : classes.searchBox;
        if(this.props?.reactSpaceSearchDesign){
            return (
                <div>
                <PageHeader
                title={<h4 className={'listview_header_title'}>{this.props.pageTitle?this.props.pageTitle:'List View Page'}</h4>}
                className={[classes.pageHeader, 'listview_header_title_box'].join(' ')}
                extra={this.getPageHeaderExtra()}
                />
                <Row className='list_view_page'>
                        <Col span={this.props.listSpan} className={[classes.leftContent, 'leftcontent'].join(' ')}>
                        <Space direction="vertical">
                            <Search 
                                placeholder="input search text" 
                                onSearch={this.props.onSpaceSearch}  
                            />
                            <div className={'list_content'}>
                                {this.props.listComponent}
                            </div>
                        </Space>
                            
                        </Col>
                        <Col span={this.props.viewSpan} className={classes.rightContent}>
                            {this.props.viewComponent}
                        </Col>
                </Row>
                </div>
            )
        }else{
            return (
                <div>
                <PageHeader
                title={<h4 className={'listview_header_title'}>{this.props.pageTitle?this.props.pageTitle:'List View Page'}</h4>}
                className={[classes.pageHeader, 'listview_header_title_box'].join(' ')}
                extra={this.getPageHeaderExtra()}
    
                />
                <Row>
                        <Col span={this.props.listSpan} className={[classes.leftContent, 'leftcontent'].join(' ')}>
                            <div className={[searchClass, 'search_box_content'].join(' ')} style={{overflowX: "scroll", width:"400px"}}>
                                <Form>
                                    {this.getSearchFields()}
                                </Form>
                            </div>
                            <div className={'list_content'}>
                                {this.props.listComponent}
                            </div>
                        </Col>
                        <Col span={this.props.viewSpan} className={classes.rightContent}>
                            {this.props.viewComponent}
                        </Col>
                </Row>
                </div>
            )
        }
        
    }
}

ListView.propTypes = {
    listSpan: PropTypes.number.isRequired,
    viewSpan: PropTypes.number.isRequired,
    getSearchFields: PropTypes.func,
    getPageHeaderExtra: PropTypes.func
}

export default ListView;