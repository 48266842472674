export const SearchFormMapper = [
    {
        label: 'User Type',
        name: 'user_type.like',
        type: 'input',
        rules: [],
        placeholder: 'User Type',
    },
   
    
]