export const SearchFormMapper = [
    {
        label: '',
        name: 'name',
        type: 'input',
        rules: [],
        placeholder: 'Name',
    },
    {
        label: '',
        name: 'code',
        type: 'input',
        // url: '/api/manufacturer/manufacturer_combo/',
        rules: [],
        placeholder: 'Code',
    },
    // {
    //     label: 'Test-3',
    //     name: 'test2',
    //     type: 'input',
    //     rules: [],
    //     placeholder: 'Test-3',
    // }
]