export const ModalTitle=(props)=>{
    return (
        <div className={'header_row'}>
            <div className={'header_main'}>
                <div className={'header_main_sub'}>
                    <h4>{props.title}</h4>
                </div>
            </div>
        </div>
    )
}