import { PlusOutlined,EditOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalTitle } from '../../components/ModalTitle/ModalTitle';
import BaseList from "../../containers/BaseList/BaseList";
import * as http from '../../http';
import { columnMapper } from './columnMapper';
import { SearchFormMapper } from './SearchFormMapper';
import { AddPage } from './AddPage';
import { values } from 'lodash';

class HFListPage extends BaseList {
    constructor(props){
        super(props)
    }
    getColumns(){
        return [
            ...columnMapper,
            {
                title: 'Action',
                key: 'action',
                fixed:'right',
                width:"auto",
                render: (record) => (
                <Space size="middle">
                <Button 
                    key='editButton'
                    className={'edit_pen'}
                    icon={<EditOutlined />}
                    onClick={()=>this.showModal(`/api/device_settings/${record.id}`)} />
                </Space>
                )
            }
        ]
    }
    getDataUrl(){
        return "/api/device_settings"
    }
    getSearchFields(){
        return [];
    }
 
    onAddSaveClick = (values) =>{
        console.log(values)
        http.post('/api/device_settings', values)
        .then(
            (resp)=>{
                if(resp.status==201){
                    this.setState({
                        isModalVisible:false
                    })
                      message.success('Device Created Successfully');
                      this.fetch();
                    }
            }
        ).catch((err)=>{
            message.error("Device Creation Failed")
        })
        
    }
    onUpdateClick = (values) =>{
        
        http.put(`/api/device_settings/${values.id}`, values)
        .then(
            (resp)=>{
                if(resp.status==200){
                    this.setState({
                        isModalVisible:false
                    })
                      message.success('Device Updated Successfully');
                      this.fetch();
                    }
            }
        ).catch((err)=>{
            message.error("Can't Update Device")
        })
        
    }
   
    onFormFinish = (values) => {
       if (this.state.editUrl) {
          this.onUpdateClick(values);
        }
        else {
          this.onAddSaveClick(values);
        }
      }
    render(){
        return(
            <>
                {super.render()}
                <Modal
                    title={<ModalTitle title='HF Device Config'/>}
                    visible={this.state.isModalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    footer={null}
                    centered={true}
                    width={1000}
                >
                    <AddPage 
                        onFinish ={this.onFormFinish}
                        editUrl={this.state.editUrl}
                    />
                </Modal>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps)(withRouter(HFListPage));
