// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateField_DateField__LIHDe {\n  background: #04233c;\n  border: 1px solid #087b95;\n  border-radius: 5px;\n  /* background: #04233c !important; */\n}\n.DateField_DateField__LIHDe input {\n  color: white;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Fields/DateField.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,oCAAoC;AACtC;AACA;EACE,YAAY;AACd","sourcesContent":[".DateField {\n  background: #04233c;\n  border: 1px solid #087b95;\n  border-radius: 5px;\n  /* background: #04233c !important; */\n}\n.DateField input {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DateField": "DateField_DateField__LIHDe"
};
export default ___CSS_LOADER_EXPORT___;
