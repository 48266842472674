import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, message, Popconfirm, Space } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import BaseList from "../../../containers/BaseList/BaseList";
import { SearchFormMapper } from './SearchFormMapper';
import { wrd_fibercore_column_mapper } from './wrd_fibercore_column_mapper';

const copy = <FontAwesomeIcon icon={faCopy}/>

class WrdFiberCores extends BaseList {
getColumns(){
  const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
  
  return [
    ...wrd_fibercore_column_mapper,
    {
      title: 'Action',
      key: 'wrd-fiber-core-action',
      fixed:'right',
      render: (record) => (
        <Space size="middle">
        <Link 
          key='wrd-equipment-editButton'
          className={themeClass}
          icon={copy}
          to={`/wire_optical_fiber_core/edit/${record.id}`}
        >
          <EditOutlined />
        </Link>
        </Space>
      )
    }
  ];
}

getDataUrl(){
  return '/api/wire_fiber_core/'; 
}

getPageConfig(){
  return {
    pageTitle:'Equipments List',
    pageButtons: [
      <Button
        key='wrd-equipment-add'
        className={'create_btn'}
        onClick={()=>this.props.history.push('/wire_comm_equipments/add')}
        type="primary"
        size="small"
        icon={<PlusOutlined />}
      >
          Add Equipment
      </ Button>
    ]
  }
}

getSearchFields(){
  return SearchFormMapper;
}
}
const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps)(withRouter(WrdFiberCores));
  