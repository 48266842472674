import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageHeader } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import classes from './TrainingHeader.module.css';
const fasearch = <FontAwesomeIcon icon={faSearch}/>;

class TrainingHeader extends Component{
    constructor(props){
        super(props);
    }
    getPageHeaderExtra(){
        return this.props.getPageHeaderExtra() || [];
    }

    render(){
        const searchClass = JSON.parse(localStorage.getItem("theme")) === "White" ? classes.whitesearchBox : classes.searchBox;
        return (
            <div>
                <PageHeader
                    title={<h4 className={'listview_header_title'}>{this.props.pageTitle?this.props.pageTitle:'List View Page'}</h4>}
                    subTitle={<h3 className={'listview_header_sub_title'}>{this.props.scenarioTitle?this.props.scenarioTitle:'Default'}</h3>}
                    className={[classes.pageHeader, 'listview_header_title_box'].join(' ')}
                    extra={this.getPageHeaderExtra()}
                /> 
                {/* <Row>
                    <Col span={this.props.viewSpan} className={classes.rightContent}>
                        {this.props.viewComponent}
                    </Col>
                </Row> */}
            </div>
        )
    }
}

TrainingHeader.propTypes = {
    // viewSpan: PropTypes.number.isRequired,
    getPageHeaderExtra: PropTypes.func
}

export default TrainingHeader;
