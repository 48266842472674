import axios from 'axios';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../setAuthToken';
import { GET_ERRORS, SET_CURRENT_USER } from './types';


export const loginUser = (user, accesstoken) => dispatch => {
    const headers = {
        'accesstoken': accesstoken
      };
      const baseURL = process.env.REACT_APP_GATEWAY
    //   console.log('baseURL: ', baseURL)
      axios.post(`${baseURL}/o/login`, user, {headers: headers})
            .then(res => {
                const { access_token, refresh_token } = res.data;
                localStorage.setItem('jwtToken', access_token);
                localStorage.setItem('refreshToken', refresh_token);
                setAuthToken(access_token);
                const decoded = jwt_decode(access_token);
                dispatch(setCurrentUser(decoded));
            }).catch(err => {
                if(err.response){
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                });
            }
            });
}

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
}

export const removeExistingErrors = decoded => {
    return {
        type: GET_ERRORS,
        payload: decoded
    }
}

export const logoutUser = (history) => dispatch => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('refreshToken');
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    dispatch(removeExistingErrors({}));
    history?history.push('/'):window.location.href = '/';
}

export const refreshJWT = (history) => dispatch => {
    const refreshToken = localStorage.getItem('refreshToken');
    const headers = {
        'refreshtoken': refreshToken
      };
    console.log('From authentication')
    axios.post('/o/refresh_token', {refreshtoken:refreshToken}, {headers: headers})
            .then(res => {
                console.log(res.data)
                const newToken = res.data.access_token;
                const newRefreshToken = res.data.refresh_token;
                localStorage.setItem('jwtToken', newToken); // save the newly refreshed token for other requests to use
                localStorage.setItem('refreshToken', newRefreshToken); // save the newly refreshed token for other requests to use

                // const { access_token } = res.data;
                // localStorage.setItem('jwtToken', access_token);
                setAuthToken(newToken);
                const decoded = jwt_decode(newToken);
                dispatch(setCurrentUser(decoded));
            })
            .catch(err => {

                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                });
                // return Promise.reject(error);
                dispatch(logoutUser())
                
            });
    
}