import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Row } from 'antd';
import React, { useEffect } from 'react';
import { StepPanel } from '../../../containers/StepPanel/StepPanel';
import * as http from '../../../http';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../../utils';
import classes from '../DeviceConfig.module.css';
import { JoinProcessConfig } from './wrd_component/JoinProcessConfig';

export const SplitterConfig=(props)=>{
    console.log("Splitter config")
    const [joiningRowsConfig, setJoiningRowConfig] = React.useState({config:[]});
    const [joiningRowsArray, setJoiningRowsArray] = React.useState({all_rows:[]});

    const [joinProcessConfigData, setJoinProcessConfigData] = React.useState({
        sequence_step1_combo:null, tools_step1_combo:null,
        sequence_step2_combo:null, tools_step2_combo:null,
        sequence_step3_combo:null, tools_step3_combo:null,
        sequence_step4_combo:null, tools_step4_combo:null,
        sequence_step5_combo:null, tools_step5_combo:null,
        sequence_step6_combo:null, tools_step6_combo:null,
        sequence_step7_combo:null, tools_step7_combo:null,
    })

    const [splitterConfigData, setConfigData] = React.useState({
        device_uplink_connector_combo:null,
        device_downlink_connector_combo:null,
        joining_config: joiningRowsConfig,
        wrd_equipment_id_combo:null,
        comm_equipment_type: props.configData?.comm_equipment_type,
        ports: props.configData?.wrd_equipment?.ports,
        join_process_config: joinProcessConfigData,
        image_url: '',
        image_name: '',
    });
    
    const [stepForm] = Form.useForm();
    const [errors, setErrorData] = React.useState({});
    // const[allSteps, setAllSteps] = React.useState({});
    const[steps, setSteps] = React.useState({});
    let isMounted = useIsMounted();

    let device_coord = props.configData.geometry.coordinates
    
    
    useEffect(() => {
        if(props?.configData?.configID){
        http.get(`/api/scenario/${props.configData.scenarioID}/${props.configData.configID}/get_config/`).then((data)=>{
            // console.log("Incoming: ");
            // console.log(data.data);
            let newData = {};
            _.forOwn(data.data?.network_config, (value, key)=>{
                if(!splitterConfigData.hasOwnProperty(key)){
                const val = {};
                val[key] = value;
                stepForm.setFieldsValue(val);
                }
                else{
                newData[key] = value;
                }
            })

            _.forOwn(data.data, (value, key)=>{
                if(!splitterConfigData.hasOwnProperty(key)){
                const val = {};
                val[key] = value;
                stepForm.setFieldsValue(val);
                }
                else{
                newData[key] = value;
                }
            })
            
            //create joining from according to server data
            data.data?.splitter_joining_config.forEach((each)=>{
                let newJoiningRow = []
                newJoiningRow = joiningRowsConfig.config
                newJoiningRow.push(each.config);
                let updateData ={}
                updateData['config']=newJoiningRow 
                setJoiningRowConfig(updateData)
                createsJoiningRows()
            })
            if(isMounted.current){
                setConfigData((prevVal)=>{
                return {...prevVal, ...newData}
                })
            }
        })
        .catch((err)=>{console.log(err);})
    }
      
      return () => { isMounted = true }; 
    }, [setConfigData, props.configData])
    
    const setPostData=(field, type, label, value, index=null)=>{
        let newData = {};
        let newJoiningData = {};
        
        if(field == 'usability_type'){
            if(value && !props?.configData?.equipmentData?.usability_type.includes(value.value)){
                alert(`${props.configData?.equipmentData?.model} doesn't support ${value.label} installation`);
                return
            }
        }
        if(type == 'select'){
            if(value){
                
                if (index!=null){
                    newJoiningData[field] = value.value;
                    newJoiningData[`${field}_combo`] = value;
                }else{
                    newData[field] = value.value;
                    newData[`${field}_combo`] = value;
                }

                if (`${field}_combo` == 'wrd_equipment_id_combo'){
                    getDevicePorts();
                }
            }
            else{
                if(index!=null){
                    newJoiningData[field] = null;
                    newJoiningData[`${field}_combo`] = null;
                }else{
                    newData[field] = null;
                    newData[`${field}_combo`] = null;
                }
                
            }
        }
        else if(type == 'input-button'){
            if(value == 'plus'){
                const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
                const updatedValue = {};
                updatedValue[field] = newValue;
                stepForm.setFieldsValue(updatedValue)
            }
            else{
                const newValue = parseInt(stepForm.getFieldsValue()[field]|| 0) - 1;
                const updatedValue = {};
                updatedValue[field] = newValue;
                stepForm.setFieldsValue(updatedValue)
            }
        }
        else if(type == 'checkbox')
        {   
            newData[field] = value;
        }
        

        setConfigData((prevVal)=>{
            let newValue={...prevVal, ...newData}
            return newValue
        })


        let oldData = joiningRowsConfig.config
        let newValue = {...oldData[index], ...newJoiningData}
        oldData[index] = newValue
        console.log('Test')
        setJoiningRowConfig((pvl)=>{
            let returnValue = {...pvl, ...({config:oldData})}
            return returnValue
        })
        console.log(joiningRowsConfig.config)
    }

    // const setUplinkConfigPage = (field, type, label, value)=>{
    //     console.log(routerConfigData.number_of_dev_uplink); 
    // }
    function getDevicePorts(){
        let url =''
        let device_url =''
        if(props?.configData?.wrd_equipment?.id && !(splitterConfigData?.wrd_equipment_id_combo?.value)){
            device_url =`/api/wire_comm_equipments/${props?.configData?.wrd_equipment?.id}/`
        }
        else{
            device_url =`/api/wire_comm_equipments/${splitterConfigData?.wrd_equipment_id_combo?.value}`
        }
        http.get(device_url).then(
            (data)=>{
                console.log(data.data)
                
                setConfigData((prevVal)=>{
                    return {...prevVal, ...{'ports':data?.data.ports,'image_url':data?.data?.image_url, 'image_name':data?.data?.image_name}}
                })
        })
        .catch((err)=>{
                console.log(err);
        })
    }
    
    const onFinish=(values)=>{
        
        const postData = { ...values, ...({...splitterConfigData, ...{joining_config:joiningRowsConfig}})};
        // const postData = {...values, ...splitterConfigData};
        console.log('Set data')
        console.log(joiningRowsConfig)
        console.log('Post data')
        console.log(postData)
        
        window.frames[0].postMessage({
            data: postData,
            type: 'resolve',
            popupId: props.popupID
        })
    }

    const ConfigForm=()=>{
        return (
            <div className={classes.content}>
                <Row gutter={[24]}>
                    <Col span={24}></Col>
                    <Col span={12}>
                        <BuildFormItem
                        name={`wrd_equipment_id`}
                        label='Select Equipment'
                        index={props.index}
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/get_allocated_wrd_equipments/${props?.configData?.scenarioID}/${props?.configData?.equipment_type?.id}/`,
                            onSelectChange:setPostData,
                            value: splitterConfigData.wrd_equipment_id_combo
                            }}
                            formConfig={{
                            required: true,
                            hidden: props.configData?.scenarioData?.scenario_type == 2 || props.configData?.scenarioData?.scenario_type == 3? false :true,
                            }}
                        />
                    </Col>
                    <Col span={props.configData?.scenarioData?.scenario_type == 2? 12 :24}>
                        <BuildFormItem
                            name='device_name'
                            label='Device Name'
                            type='input'
                            formConfig={{
                                required: true,
                                value: splitterConfigData.device_name,
                            }}
                        />
                    </Col> 
                    <Col span={4}/>
                    <Col span={16}>
                        <Image
                            height = {splitterConfigData?.image_url!=''?300:0}
                            src={`${splitterConfigData?.image_url}`}
                        />
                    </Col>
                    <Col span={4}/>
                </Row>
            </div>
        )
    }
    const JoiningFormRow = (props)=>{
        return(
            <div>
                <Row gutter={[16]}>
                    <Col span={6}>
                        <p style={{color:'white'}}>Join-{props.index}</p>
                    </Col>
                    <Col span={9}>
                        <BuildFormItem
                        name={`portconfig_id`}
                        label='Splitter Port/Core'
                        index={props.index}
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/wrd_nodedeviceports_combo/${device_coord[0]}/${device_coord[1]}/`,
                            onSelectChange:setPostData,
                            value: (joiningRowsConfig?.config)?.length>0? joiningRowsConfig?.config[props.index]?.portconfig_id_combo:null
                            }}
                            formConfig={{
                            required: true
                            }}
                        />
                    </Col>
                    <Col span={9}>
                        <BuildFormItem
                        name={`coreconfig_id`}
                        label='Core'
                        index={props.index}
                        type='select'
                        fieldConfig={{
                            url:`/api/combo/all_core_combo/${device_coord[0]}/${device_coord[1]}/`,
                            onSelectChange:setPostData,
                            value: (joiningRowsConfig?.config)?.length>0? joiningRowsConfig?.config[props.index]?.coreconfig_id_combo: null
                            }}
                            formConfig={{
                            required: true
                            }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
    const addJoiningRow = ()=>{
        console.log('Add row')
        //create null dataset
        let newJoiningRow=[]
        newJoiningRow = joiningRowsConfig.config
        let newJoiningData={}
        newJoiningData = {
            portconfig_id_combo: null,
            coreconfig_id_combo: null,
        }
        newJoiningRow.push(newJoiningData);
        let updateData ={}
        updateData['config']=newJoiningRow
        setJoiningRowConfig(updateData)
        console.log(joiningRowsConfig.config)
        createsJoiningRows()
    }

    function createsJoiningRows(){
        //create individual rows
        console.log('Call .....')
        let newJoiningRowArray = []
        let count =0
        joiningRowsConfig.config.forEach((data)=>{
            newJoiningRowArray.push({single_row:<JoiningFormRow index={count}/>})
            count++
        })
        let updateRows =[]
        updateRows['all_rows'] = newJoiningRowArray
        setJoiningRowsArray(updateRows)
    }
    
    const CoreJoiningForm = ()=>{ 
        return(
            <div className={classes.content}>
                {
                joiningRowsArray?.all_rows.map((item)=>(
                    <div>
                        {item.single_row}
                    </div>
                    
                ))}   
                <Row gutter={[16]}>
                <Col span={3}>
                    <Button 
                        key='equipment-add'
                        className={'create_btn'}
                        onClick={addJoiningRow}
                        type="primary"
                        size="small"
                        icon={<PlusOutlined />}
                    />
                </Col>
                </Row>
            </div>
        )
    }

    let count=0;
    let portConfigsForms=[];
    if(splitterConfigData.ports==null){
        getDevicePorts()
    }

    const joiningProcessConfigSet= (configData)=>{
        console.log("joiningProcessConfigSet")
        console.log(configData)
        let newConfig={...splitterConfigData.join_process_config,  ...configData}
        setConfigData((prevVal)=>{
            return {...prevVal,  ...{join_process_config: newConfig}}
        })
    }

    let step=[
        {
            title: "Configuration",
            content: <ConfigForm />,
            step: 1
        },
        {
            title: "Core Joining",
            content: <CoreJoiningForm />,
            step: 2
        },
        {
            title: "Core Joining Process",
            content: <JoinProcessConfig processConfigSet={joiningProcessConfigSet} config={splitterConfigData.join_process_config} />,
            step: 3
        }
    ]
    return (
        <div className={classes.formmain}>
            <Form form={stepForm} onFinish={onFinish}>
                <StepPanel
                    className={classes.step_panel}
                    steps={step}
                    direction='horizontal'
                    />
            </Form>
        </div>
    )

}