import {Table, PageHeader, Row, Col, Button} from 'antd';
import { useEffect, useState } from 'react';
import * as http from '../../http';
import { buildFormItem as BuildFormItem } from '../../utils';
import '../../App.css';
import classes from "./Report.module.css";
import generatePDF from './pdf_generator';

function ReportListPage() {
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [select_data, setSelectData] = useState({
        select_scenario_id_combo: null,
        select_ideal_scenario_id_combo:null,
        select_student_id_combo: null,
    });

    const get_Report_Data = () => {
        setLoading(true);
        http.get(`/api/comparision_report/${select_data.select_ideal_scenario_id_combo!=null? select_data.select_ideal_scenario_id_combo.value:0}/${select_data.select_scenario_id_combo!=null? select_data.select_scenario_id_combo.value:0}/${select_data.select_student_id_combo!=null? select_data.select_student_id_combo.value:0}`)
        .then((response)=>{
            setDataSource(response.data);
            setLoading(false);
        }).catch((err)=>{
            console.log(err);
            setLoading(false);
        });
    }

    useEffect(() => {
        get_Report_Data();
    }, [select_data]);

    const columns = [
        {
            title: "SL",
            dataIndex: "sl",
            window: "12.5%",
        },
        {
            title: "Equipment Name",
            dataIndex: "equipment_name",
            width: "12.5%",
        },
        {
            title: "Ideal Number",
            dataIndex: "ideal_number",
            width: "12.5%",
        },
        {
            title: "Used Number",
            dataIndex: "used_number",
            width: "12.5%",
        },
        {
            title: "Ideal Length",
            dataIndex: "ideal_length",
            width: "12.5%",
        },
        {
            title: "Used Length",
            dataIndex: "used_length",
            width: "12.5%",
        },
        {
            title: "Conclusion",
            dataIndex: "conclusion",
            width: "12.5%",
        },
        {
            title: "Result",
            dataIndex: "result",
            width: "12.5%",
        }
    ];
    const setChangeSelectData=(field, type, label, value)=>{
        console.log("Field : ", field);
        console.log("Type: ", type);
        console.log("Label : ", label);
        console.log("Value : ", value);
        
        let newData = {};
        if(type == 'header-select'){
            if(value){
                newData[field] = value.value;
                newData[`${field}_combo`] = value;
            }
            else{
                newData[field] = null;
                newData[`${field}_combo`] = null;
            }
        }
        
        setSelectData((prevVal)=>{
            let newValue={...prevVal, ...newData}
            return newValue
        })
        
        // console.log(newData);
      }

    const GetPageHeaderExtra = () => {
        let clr = JSON.parse(localStorage.getItem("theme")) === 'White' ? '#132555' : '#ffffff';
        // let vlu = select_data.select_ideal_scenario_id_combo + "" + select_data.select_scenario_id_combo + "";
        // console.log(vlu);
        return(
            <div>
                <Row gutter={16,16}>
                    <Button className={classes.pdf_btn} onClick={() => dataSource.length === 0 ? alert("Please Select Senario") : generatePDF(dataSource)}>Download PDF</Button>
                    <Col>
                        <h7 className={classes.fakecls} style={{color:clr}} >Standard Scenario : </h7>
                    </Col>
                    <Col style={{width:'200px'}}>
                        <BuildFormItem
                            key={'select_ideal_scenario_id'}
                            name={`select_ideal_scenario_id`}
                            label={'Scenario'}
                            index={`0001`}
                            type='header-select'
                            fieldConfig={{
                                url:`/api/combo/scenarios/`,
                                onSelectChange:setChangeSelectData,
                                value: select_data.select_ideal_scenario_id_combo,
                                }}
                        />
                    </Col>
                    <Col>
                        <h7 className={classes.fakecls} style={{color:clr}}> Practice Scenario : </h7>
                    </Col>
                    <Col style={{width:'200px'}}>
                        <BuildFormItem
                            key={'select_scenario_id'}
                            name={`select_scenario_id`}
                            label={'Scenario'}
                            index={`0001`}
                            type='header-select'
                            fieldConfig={{
                                url:`/api/combo/scenarios/`,
                                onSelectChange:setChangeSelectData,
                                value: select_data.select_scenario_id_combo,
                            }}
                        />
                    </Col>
                    
                    <Col>
                        <h7 className={classes.fakecls} style={{color:clr}}> Student ID : </h7>
                    </Col>
                    <Col style={{width:'200px'}}>
                        <BuildFormItem
                            key={'select_student_id'}
                            name={`select_student_id`}
                            label={'Scenario'}
                            index={`0001`}
                            type='header-select'
                            fieldConfig={{
                                url:`/api/combo/scenario_students/${select_data.select_scenario_id_combo!=null? select_data.select_scenario_id_combo.value:0}`,
                                onSelectChange:setChangeSelectData,
                                value: select_data.select_student_id_combo,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        )
      }

    return (
        <div>
          
          <PageHeader 
              style={{
                  border: '1px solid rgb(235, 237, 240)',
              }}
              title="Comparision Report"
              className="damm"
              extra={[<GetPageHeaderExtra/>]}
              pagination={{
                showSizeChanger: false,
                defaultPageSize: 20
              }}
          />
    
          <Table
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              pagination={{
              pageSize: 20
            }}
          ></Table>
        </div>
      );
}

export default ReportListPage;
