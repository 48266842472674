import { Button, Col, Form, Row } from 'antd';
import React from 'react';
import { buildFormItem as BuildFormItem } from '../../utils';


export const PerformanceAddPage = (props)=>{
    const [form] = Form.useForm();

    const [formPostData, setFormPostData] = React.useState({
        year:null,
        year_combo:null,
        dataList: []
        
    })
    const initialValues = {
        ["dataList_0_parameter"]: 'light_rain',
        ["dataList_1_parameter"]: 'moderate_rain',
        ["dataList_2_parameter"]: 'heavy_rain',
        ["dataList_3_parameter"]: 'thundering',
        ["dataList_4_parameter"]: 'normal_wind',
        ["dataList_5_parameter"]: 'moderate_wind',
        ["dataList_6_parameter"]: 'heavy_wind',
        ["dataList_7_parameter"]: 'strom_wind',
        ["dataList_8_parameter"]: 'light_fog',
        ["dataList_9_parameter"]: 'moderate_fog',
        ["dataList_10_parameter"]: 'dense_fog',
        ["dataList_11_parameter"]: 'low_dense_forest',
        ["dataList_12_parameter"]: 'medium_dense_forest',
        ["dataList_13_parameter"]: 'high_dense_forest',
        ["dataList_14_parameter"]: 'low_voltage_powerline',
        ["dataList_15_parameter"]: 'medium_voltage_powerline',
        ["dataList_16_parameter"]: 'high_voltage_powerline',
      }
    const frequencyTypeOptions = [
        { label: 'VHF', value: '0' },
        { label: 'UHF', value: '1' },
        { label: 'Micro', value: '2' }
      ];
    
    const setPostData=(field, type, label, value)=>{
        let outerfield = null;
        let index = null;
        
        let newData = {};
        if(type == 'select'){
          if(value){
          if(Array.isArray(value)){
            let valueList = [];
            for(let i=0; i<value.length; i++){
              valueList.push(value[i].value);
            }
            newData[field] = valueList;
            newData[`${field}_combo`] = value;
          }
          else{
            newData[field] = value.value;
            newData[`${field}_combo`] = value;
          }
          }
          else{
          newData[field] = null;
          newData[`${field}_combo`] = null;
          }
        }
        else if(type == 'input-button'){
          if(value == 'plus'){
          const newValue = parseInt(form.getFieldsValue()[field] || 0) + 1;
          const updatedValue = {};
          updatedValue[field] = newValue;
          form.setFieldsValue(updatedValue)
          }
          else{
          const newValue = parseInt(form.getFieldsValue()[field]|| 0) - 1;
          const updatedValue = {};
          updatedValue[field] = newValue;
          form.setFieldsValue(updatedValue)
          }
        }
        else if(type == 'checkbox')
        {
          newData[field] = value;
        }
        else if(type=='checkbox-group'){
            newData[field] = value;
        }
        setFormPostData((prevVal)=>{
          return {...prevVal, ...newData}
        })
    }
    Array.prototype.insert = function ( index, item ) {
        this.splice( index, 0, item );
    };

    const sanitizePostData=(data)=>{
            console.log(data)
        _.forOwn(data, (value, key)=>{
            if(/(.*)_(\d+)_(.*)$/.test(key)){
               const  outerfield = key.split('_')[0]

                const index = parseInt(key.split('_')[1]);
                const field = key.split('_')[2]
                if(typeof data[outerfield][index] === 'undefined') {
                    data[outerfield].insert(index, {});
                    data[outerfield][index][field] = value;
                }
                else {
                    data[outerfield][index][field] = value
                }
                
            }
        })
        return data;
        
    }
    const onFinish = (values) =>{
        let postData={...values, ...formPostData}
        postData = sanitizePostData(postData)
        console.log(postData)
        return props.onFinish(postData)
    }
    return(
        <Form style={{color:"#b2e4ff"}} form={form} onFinish={onFinish} initialValues={initialValues}>
            <Row>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name="year" 
                        type ="select"
                        label="Year"
                        fieldConfig={{
                            url: "/api/combo/year",
                            onSelectChange: setPostData,
                            value:formPostData.year_combo

                        }}
                    />
                </Col>
            </Row> <br />
            <Row>
                <Col span={6} push={2}>
                    Parameter
                </Col>
                <Col span={4} push={2}>
                    Propagation Attenuation
                </Col>
                <Col span={4} push={6}>
                    Effected Frequency
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Light Rain ( 2.5 mm/h )
                    <BuildFormItem
                        name='dataList_0_parameter'
                        label=""
                        type="input"
                        formConfig={{
                           hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_0_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                        onBeforeButtonClicked: setPostData,
                        onAfterButtonClicked: setPostData,
                        }}

                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_0_frequency'
                        type = "checkbox-group"
                        label=" "

                        
                        fieldConfig={{
                            options: frequencyTypeOptions,
                            onChange: setPostData,
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Moderate Rain ( 2.5 mm/h )
                    <BuildFormItem
                        name='dataList_1_parameter'
                        label=""
                        type="input"
                        formConfig={{
                           hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_1_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}

                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_1_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                            options:frequencyTypeOptions,
                            onChange: setPostData,
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Heavy Rain ( 2.5 mm/h )
                    <BuildFormItem
                        name='dataList_2_parameter'
                        label=""
                        type="input"
                        formConfig={{
                           hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_2_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}

                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_2_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                            options:frequencyTypeOptions,
                            onChange: setPostData
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Thundering( 2.5 mm/h )
                    <BuildFormItem
                        name='dataList_3_parameter'
                        label=""
                        type="input"
                        formConfig={{
                           hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_3_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}

                    />
                </Col>
                <Col span={4}span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_3_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                            options:frequencyTypeOptions,
                            onChange: setPostData
                        }}
                    />
                </Col>
            </Row>

            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Normal Wind ( 2.5 mm/h )
                    <BuildFormItem
                        name='dataList_4_parameter'
                        label=""
                        type="input"
                        formConfig={{
                           hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_4_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                            }}
                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_4_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                            options:frequencyTypeOptions,
                            onChange: setPostData
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Moderate Wind ( 2.5 mm/h )
                    <BuildFormItem
                        name='dataList_5_parameter'
                        label=""
                        type="input"
                        formConfig={{
                           hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_5_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_5_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                            options:frequencyTypeOptions,
                            onChange: setPostData
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Heavy Wind ( 2.5 mm/h )
                    <BuildFormItem
                        name='dataList_6_parameter'
                        label=""
                        type="input"
                        formConfig={{
                           hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_6_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}

                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_6_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                            options:frequencyTypeOptions,
                            onChange: setPostData
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Strom Wind( 2.5 mm/h )
                    <BuildFormItem
                        name='dataList_7_parameter'
                        label=""
                        type="input"
                        formConfig={{
                            hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_7_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}

                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_7_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                            options:frequencyTypeOptions,
                            onChange: setPostData
                        }}
                    />
                </Col>
            </Row>

            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Light Fog
                    <BuildFormItem
                        name='dataList_8_parameter'
                        label=""
                        type="input"
                        formConfig={{
                            hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_8_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}

                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_8_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                            options:frequencyTypeOptions,
                            onChange: setPostData
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Moderate Fog
                    <BuildFormItem
                        name='dataList_9_parameter'
                        label=""
                        type="input"
                        formConfig={{
                            hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_9_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_9_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                            options:frequencyTypeOptions,
                            onChange: setPostData
                        }}
                    />
                </Col>
            </Row>

            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Dense Fog 
                    <BuildFormItem
                        name='dataList_10_parameter'
                        label=""
                        type="input"
                        formConfig={{
                            hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_10_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_10_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                        options:frequencyTypeOptions,
                        onChange: setPostData
                        }}
                    />
                </Col>
            </Row>

            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Forests (Low Dense)
                    <BuildFormItem
                        name='dataList_11_parameter'
                        label=""
                        type="input"
                        formConfig={{
                            hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_11_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_11_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                        options:frequencyTypeOptions,
                        onChange: setPostData
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Forests (Medium Dense)
                    <BuildFormItem
                        name='dataList_12_parameter'
                        label=""
                        type="input"
                        formConfig={{
                            hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_12_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_12_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                        options:frequencyTypeOptions,
                        onChange: setPostData
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Forests (High Dense)
                    <BuildFormItem
                        name='dataList_13_parameter'
                        label=""
                        type="input"
                        formConfig={{
                            hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_13_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_13_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                        options:frequencyTypeOptions,
                        onChange: setPostData
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Power Line (Low Voltage)
                    <BuildFormItem
                        name='dataList_14_parameter'
                        label=""
                        type="input"
                        formConfig={{
                            hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_14_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}

                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_14_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                        options:frequencyTypeOptions,
                        onChange: setPostData
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Power Line (Medium Voltage)
                    <BuildFormItem
                        name='dataList_15_parameter'
                        label=""
                        type="input"
                        formConfig={{
                            hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_15_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}

                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_15_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                        options:frequencyTypeOptions,
                        onChange: setPostData
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} push={2}>
                    <br/>
                    Power Line (High Voltage)
                    <BuildFormItem
                        name='dataList_16_parameter'
                        label=""
                        type="input"
                        formConfig={{
                            hidden: true
                        }}
                    />
                </Col>
                <Col span={4} push={2}>
                    <BuildFormItem
                        name='dataList_16_attenuation'
                        label=" "
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
                <Col span={6} push={6}>
                    <br/>
                    <BuildFormItem
                        name='dataList_16_frequency'
                        type = "checkbox-group"
                        label=" "
                        fieldConfig={{
                        options:frequencyTypeOptions,
                        onChange: setPostData
                        }}
                    />
                </Col>
            </Row>

            <div  className={'button_row'}>
            <Button type="primary" htmlType="submit">
                    {props.editUrl? 'Update':'Add'}
            </Button>
            </div>
        </Form>
    )

}