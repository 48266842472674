import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Popconfirm, Space, message } from "antd";
import React, { useEffect, useState } from 'react';
import ListView from "../../components/ListView/ListView";
import SearchItem from "../../containers/BaseList/SearchBox/SearchItem";
import * as http from '../../http';
import { useIsMounted } from '../../utils';
import { ActiveCourseList } from "./ActiveCourseList";
import { ActiveCourseView } from "./ActiveCourseView";
import { AddPage } from './AddActiveCourse';
import { AddStudentPage } from './AddStudent';
import { SearchFormMapper } from "./SearchFormMapper";

export const ActiveCourseListView=(props)=>{
    const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
    const deleteClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';

    const [isModalVisible, setModalVisible] = React.useState(false)
    const [isStudentModalVisible, setStudentModalVisible] = React.useState(false)
    const [courseID, setCourseID] = React.useState(null);
    const [loaded, setLoaded] = useState(false);
    const [editUrl, setEditUrl] = React.useState(null);

    const [data, setListData] = useState([]);
    const [studentData, setStudentData] = useState({});
    let isMounted = useIsMounted();
    let user_data = JSON.parse(localStorage.getItem('state'))?.auth?.user?.data
    const fetchData = (query=false) =>{
      let url = `/api/active_courses/`
      if(query){
        url = url+query
      }
      http.get(url).then(
        (resp) => {
          let dataList = [];
          let studentData = {};
          for (let i = 0; i < resp?.data?.length; i++) {
            let desc = "Instructor: " + resp.data[i].teacher_name + " [Start Date: " + resp.data[i].start + ", End Date: " + resp.data[i].end+"]"
            dataList.push({
              title: "Course: " + resp.data[i].course_name,
              description: desc,
              id: resp.data[i].id,
              actionItems: [
                <Space>
                  {/* <Button
                      key={resp.data[i].id}
                      icon={<EditOutlined />}
                      className={themeClass}
                      onClick={()=>onEditButtonClicked(resp.data[i].id)}>
                  </Button> */}

                  <Popconfirm okType="default" title="Are sure to Delete?" okText="Yes" onConfirm={() => handleDelete(resp.data[i])}>
                    <Button 
                      key='deleteButton'
                      className={deleteClass}
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                  </Space>
                  
                  ]
            })
            studentData[resp.data[i].id] = resp.data[i].active_course_students
          }
          if(isMounted.current){
          if (dataList.length > 0){
            setCourseID(dataList[0].id);
            setListData(dataList);
            setStudentData(studentData);
          }
        }
      }
      
      )
    }
    useEffect(() => {
      fetchData()
      return () => { isMounted = false;};
    }, [setListData])
    
    const onSpaceSearch = (value) => {
      console.log(value);
      fetchData(`?name=${value}`)
    }

    const onEditButtonClicked=(courseID)=>{
      setModalVisible(true);
        setEditUrl(`/api/active_courses/${courseID}/`)
     };
     const handleDelete=(record)=>{
      http.del(`/api/active_courses/${record.id}`).then(res => {
          message.success("Deleted Successfully")
          fetchData()
      })
    
    }
    const handleStudentDelete=(record)=>{
      http.del( `/api/active_courses/delete_course_student/${record.id}/`).then(res => {
          message.success("Deleted Successfully")
          fetchData()
        
      }).catch((err) => {
        // message.error('Delete Failed');
      });
    }

    const onItemClick=(item)=>{
      setCourseID(item.id)
    }

    const getSearchFields=()=>{
        const children = [];
        for (let i = 0; i < SearchFormMapper.length; i++) {
          children.push(
              <SearchItem key={i} index={i} config={SearchFormMapper[i]}/>
          );
        }
        return children;
    }
    const getPageHeaderExtra=()=>{
      if(user_data?.user_type == 1){
        return [
          // <Button key="add_student_to_course" onClick={()=>setStudentModalVisible(true)}>Add Students</Button>
      ]
      }else{
        return [
          <Button key="add_new_scenario" onClick={()=>setModalVisible(true)}>Add Active Course</Button>,
          <Button key="add_student_to_course" onClick={()=>setStudentModalVisible(true)}>Add Students</Button>
      ]
      }
        
    }
    const onAddSaveClick = (values) => {

        http.post('/api/active_courses/', values).then(
          (resp)=>{
            if(resp.status == 201){
              setModalVisible(false)
              message.success('Active Course Created Successfully')
              fetchData()
            }
          }
        ).catch((err)=>{
          message.error('Active Course Creation Failed, Please Correct Following Errors!');
        })
       
      }

      const onAddStudentSaveClick = (values) => {

        http.post('/api/active_courses/add_student', values).then(
          (resp)=>{
            if(resp.status == 201){
              setStudentModalVisible(false)
              message.success('Active Course Created Successfully')
              fetchData()
            }
          }
        ).catch((err)=>{
          message.error('Active Course Creation Failed, Please Correct Following Errors!');
        })
       
      }
      
    const handleOk = () => {
        setModalVisible(false);
      };
    
    const handleCancel = () => {
        setModalVisible(false)
    };
    const handleStudentCancel = () => {
      setStudentModalVisible(false)
  };
    if(data){
    return (
        <>
        <Modal 
            title={<div className={'header_row'}><div className={'header_main'}><div className={'header_main_sub'}><h4>Add Active Course</h4></div></div></div>}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={true}
            footer={null}
            centered={true}
            width={800}
            className={'scenario_add_form active_course_add_form'}
        >
        <AddPage 
          onFinish={onAddSaveClick}
          setModalVisible={setModalVisible}
          editUrl={editUrl}
        />
      </Modal>

      <Modal 
            title={<div className={'header_row'}><div className={'header_main'}><div className={'header_main_sub'}><h4>Add Students</h4></div></div></div>}
            visible={isStudentModalVisible}
            onOk={handleOk}
            onCancel={handleStudentCancel}
            destroyOnClose={true}
            footer={null}
            centered={true}
            width={800}
            className={'scenario_add_form active_course_add_form'}
        >
        <AddStudentPage 
          onFinish={onAddStudentSaveClick}
          setModalVisible={setStudentModalVisible}
          editUrl={editUrl}
        />
      </Modal>
 
        <ListView
        pageTitle='Active Course List'
        reactSpaceSearchDesign={true}
        onSpaceSearch={onSpaceSearch}
        listComponent={<ActiveCourseList {...props} data={data} onItemClick={onItemClick} />}
        viewComponent={<ActiveCourseView {...props} data={studentData?studentData[courseID]: []} handleStudentDelete={handleStudentDelete} />}
        listSpan={6}
        viewSpan={18}
        {...props}
        getSearchFields={getSearchFields}
        getPageHeaderExtra={getPageHeaderExtra}
        />
        </>
    )
    }
    else{
      return (<></>)
    }
}
