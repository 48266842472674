import jsPDF from "jspdf";
import "jspdf-autotable";

const generatePDF = (values) => {
    const doc = new jsPDF();

    const tableColumn = ["SL", "Equipment Name", "Ideal Number", "Used Number", "Ideal Length", "Used Length", "Conclusion", "Result"];
    const tableRows = [];

    values.forEach(function (value){
        const tablerow = [value?.sl, value?.equipment_name, value?.ideal_number, value?.used_number, value?.ideal_length, value?.used_length, value?.conclusion, value?.result];

        tableRows.push(tablerow);
    });

    doc.autoTable(tableColumn, tableRows, {startY: 20});
    doc.save(`comparision_report.pdf`);
}

export default generatePDF;
