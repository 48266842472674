// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddPage_footer__T5PTC {\n  background: #012032;\n}\n\n.AddPage_formLabel__wzzGY {\n  color: #4a6e83;\n}\n.AddPage_modalContent__IcLmj {\n  /* display: flex; */\n  justify-content: center;\n  padding-bottom: 10%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/User/AddPage.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".footer {\n  background: #012032;\n}\n\n.formLabel {\n  color: #4a6e83;\n}\n.modalContent {\n  /* display: flex; */\n  justify-content: center;\n  padding-bottom: 10%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "AddPage_footer__T5PTC",
	"formLabel": "AddPage_formLabel__wzzGY",
	"modalContent": "AddPage_modalContent__IcLmj"
};
export default ___CSS_LOADER_EXPORT___;
