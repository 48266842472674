import { DoubleLeftOutlined } from '@ant-design/icons';
import { faCloudMoon, faCloudSunRain, faCogs, faEdit, faPlus, faRainbow, faSortAmountDown, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row, Tabs } from 'antd';
import 'antd/dist/antd.min.css';
import React, { useEffect, useState } from 'react';
import * as http from '../../http';
import classes from './Settings.module.css';

const fatools = <FontAwesomeIcon icon={faTools} />;
const editDataIcon = <FontAwesomeIcon icon={faEdit} />;
const dayTimeIcon = <FontAwesomeIcon icon={faCloudMoon} />
const ionosphereIcon = <FontAwesomeIcon icon={faRainbow} />
const seasonIcon = <FontAwesomeIcon icon={faCloudSunRain} />
const performanceAttIcon = <FontAwesomeIcon icon={faSortAmountDown} />
const fontplusicon = <FontAwesomeIcon icon={faPlus} />
const faCogsicon = <FontAwesomeIcon icon={faCogs} />

const { TabPane } = Tabs;

export const TimeFrequencySettings = (props) => {
    const ThemeChangeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitetabpane_tab : classes.tabpane_tab;
    const ThemeInputDate = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteinput_date : classes.input_date;
    const ThemeInputDateButton = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteinput_date_button : classes.input_date_button;
    const ThemeCardData = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitecardContentData : classes.cardContentData ;
    const ThemeTitle = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitetitle : classes.title;
    const ThemeDtSettingsLeft = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitedt_setting_left : classes.dt_setting_left;
    const ThemeDtSettingsLeftHeader = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitedt_setting_left_header : classes.dt_setting_left_header;
    const ThemeDtSettingsLeftHeaderTitle = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitedt_setting_left_header_title : classes.dt_setting_left_header_title;
    const DtSettingLeftButton = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitedt_setting_left_button : classes.dt_setting_left_button;

    const [data, setData] = useState([]);
    const [detailData, setDetailData] = useState();
    const [search,setSearch]=React.useState({
        year: '',
        month:''

    })
    useEffect(() => {
        http.get(`api/time_frequency`).then((data)=>{
            setData(data.data);
            setDetailData(data.data[0]);
          }).catch((err)=>{ 
            console.log(err);
          })
    }, [setData, props.reload]);

    const handlePlusClick = (id=null) => {
        props.setModalWidth(800);
        props.setModalVisible(true);
        props.setsettingsName('time_frequency_settings');
        props.setDataID(id);
    }

    const handleSearch = (label,value) =>{
        setSearch({label: value?.value})
    }
    
    return (
        <>
            <TabPane tab={<><span>{dayTimeIcon}</span><p>Time Frequency Settings</p></>} key="3" icon={<DoubleLeftOutlined />}
                className={classes.content}>
                <div className={ThemeChangeClass}>
                    <Row className={classes.header}>

                        <Col span={20}>
                            <h4>Time Frequency Settings</h4>
                        </Col>
                        
                        {/* this button has no activity so this comment-out */}
                        {/* <Col push={2}>
                            <Button className={classes.button_header} icon={<EditOutlined />}>Update</Button>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col className={ThemeDtSettingsLeft} span={5}>
                            <Row className={ThemeDtSettingsLeftHeader}>
                                <Col span={12} className={ThemeDtSettingsLeftHeaderTitle}>
                                    {/* <p>Time Frequency Settings</p> */}
                                </Col>
                                <Col span={5} className={DtSettingLeftButton} push={8}>
                                    <Button onClick={() => handlePlusClick()}> {fontplusicon} </Button> <span>...</span>
                                </Col>
                            </Row>
                            {/* <Form className={classes.search_form}>
                                <Row className={classes.year_input_row}>
                                    <Col span={10} pull={1}>
                                        <div className={ThemeInputDate}>
                                            <Row className={classes.year_input}>
                                                <SelectField 
                                                    placeholder="Year"
                                                    name="year"
                                                    url="api/combo/year"
                                                    // onSelectChange={(e)=>handleSearch('year',e)}
                                                    // value={search.year}
                                                /></Row>
                                            <Row className={classes.month_input}>
                                                <SelectField 
                                                    placeholder="Month" 
                                                    name="month"
                                                    url="api/combo/month"
                                                    // value={search.month}
                                                    // onSelectChange={(e)=>handleSearch('month',e)}
                                                /></Row>
                                        </div>
                                    </Col>
                                    <Col className={ThemeInputDateButton} span={4}>
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                                        </Button>
                                        <Button
                                            style={{ margin: '0 8px' }}
                                            onClick={() => {
                                                form.resetFields();
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </Col>
                                </Row>
                                <br />
                                <Row justify='space-between'>
                                </Row>
                            </Form> */}
                            <div className={classes.card_btn}>
                                {data ? data.map((key, index) => <Card className={classes.card}
                                    key={`${key.year}_${key.month}`}
                                    onClick={() => setDetailData(data[index])}>
                                    <p ><span class='left-bar-setting'>{`Time Frequency Settings For  ${key.year}_${key.month}`}</span>
                                    <span onClick={() => handlePlusClick(key.id)}> {editDataIcon} </span> </p>
                                    </Card>) : null}
                            </div>
                            
                        </Col>
                        {detailData?
                        <Col className={classes.dt_setting_right} span={17}>
                            <Row className={classes.dt_setting_right_header}>Settings Details</Row>
                            <Row className={ThemeTitle}>
                                {`Time Frequency Settings Details For ${detailData?.year}_${detailData?.month}`}
                            </Row>
                            <br />
                            <Row className={classes.main_table}>
                                <Col span={12} className={ThemeCardData} style={{ borderRight: '0px' }}>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>hour 0</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_0? detailData?.hour_0 : '0.0'}</Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 1</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_1? detailData?.hour_1:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 2</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_2? detailData?.hour_2:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 3</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_3? detailData?.hour_3:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 4</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_4? detailData?.hour_4:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 5</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_5? detailData?.hour_5:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 6</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_6? detailData?.hour_6:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 7</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_7? detailData?.hour_7:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 8</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_8? detailData?.hour_8:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 9</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_9? detailData?.hour_9:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 10</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_10? detailData?.hour_10:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 11</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_11? detailData?.hour_11:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    
                                    
                                </Col>
                                <Col span={12} className={ThemeCardData}>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 12</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData?.hour_12? detailData?.hour_12:'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 13</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{ detailData?.hour_13? detailData?.hour_13 :'0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 14</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row 
                                            className={classes.table_different_col_txt}>{detailData?.hour_14? detailData?.hour_14 : '0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 15</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row 
                                            className={classes.table_different_col_txt}>{detailData?.hour_15? detailData?.hour_15 : '0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 16</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row 
                                            className={classes.table_different_col_txt}>{detailData?.hour_16? detailData?.hour_16 : '0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 17</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row 
                                            className={classes.table_different_col_txt}>{detailData?.hour_17? detailData?.hour_17 : '0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 18</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row 
                                            className={classes.table_different_col_txt}>{detailData?.hour_18? detailData?.hour_18 : '0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 19</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row 
                                            className={classes.table_different_col_txt}>{detailData?.hour_19? detailData?.hour_19 : '0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 20</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row 
                                            className={classes.table_different_col_txt}>{detailData?.hour_20? detailData?.hour_20 : '0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 21</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row 
                                            className={classes.table_different_col_txt}>{detailData?.hour_21? detailData?.hour_21 : '0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 22</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row 
                                            className={classes.table_different_col_txt}>{detailData?.hour_22? detailData?.hour_22 : '0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Hour 23</Col>
                                        <Col span={6}>
                                            <Row>Frequency</Row>
                                            <Row 
                                            className={classes.table_different_col_txt}>{detailData?.hour_23? detailData?.hour_23 : '0.0'}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                        : ''}
                    </Row>
                </div>
            </TabPane>
        </>
    )
}