import { Button, Col, Form, Row } from 'antd';
import React from 'react';
import { InputNumberField } from '../../../components/Fields/InputNumberField';
import { SelectField } from '../../../components/Fields/SelectField';

export const AddSeasonSettings = (props) => {
    const [year, setYear] = React.useState(null);

    const [postData, setPostData] = React.useState({
        0: {
            from_month: { label: '', value: '' },
            to_month: { label: '', value: '' },
            max_temp: 0,
            min_temp: 0,
            max_humidity: 0,
            min_humidity: 0,
            max_wind: 0,
            min_wind: 0,
            rain_type: { label: '', value: '' },
            fog_type: { label: '', value: '' },
        },
        1: {

            from_month: { label: '', value: '' },
            to_month: { label: '', value: '' },
            max_temp: 0,
            min_temp: 0,
            max_humidity: 0,
            min_humidity: 0,
            max_wind: 0,
            min_wind: 0,
            rain_type: { label: '', value: '' },
            fog_type: { label: '', value: '' },
        },
        2: {

            from_month: { label: '', value: '' },
            to_month: { label: '', value: '' },
            max_temp: 0,
            min_temp: 0,
            max_humidity: 0,
            min_humidity: 0,
            max_wind: 0,
            min_wind: 0,
            rain_type: { label: '', value: '' },
            fog_type: { label: '', value: '' },
        },
        3: {

            from_month: { label: '', value: '' },
            to_month: { label: '', value: '' },
            max_temp: 0,
            min_temp: 0,
            max_humidity: 0,
            min_humidity: 0,
            max_wind: 0,
            min_wind: 0,
            rain_type: { label: '', value: '' },
            fog_type: { label: '', value: '' },
        },
        4: {

            from_month: { label: '', value: '' },
            to_month: { label: '', value: '' },
            max_temp: 0,
            min_temp: 0,
            max_humidity: 0,
            min_humidity: 0,
            max_wind: 0,
            min_wind: 0,
            rain_type: { label: '', value: '' },
            fog_type: { label: '', value: '' },
        },
        5: {

            from_month: { label: '', value: '' },
            to_month: { label: '', value: '' },
            max_temp: 0,
            min_temp: 0,
            max_humidity: 0,
            min_humidity: 0,
            max_wind: 0,
            min_wind: 0,
            rain_type: { label: '', value: '' },
            fog_type: { label: '', value: '' },
        },
    })


    const handleYear = (value) => {
        console.log('year', value);
        setYear({ value: value, label: value.label });
    }
    const handleFromMonth = (season, value) => {
        if (value) {
            setPostData((prevVal) => {
                prevVal[season]['from_month'] = value.value;
                return {
                    ...prevVal
                }
            })

        }
    }
    const handleToMonth = (season, value) => {

        if (value) {
            setPostData((prevVal) => {
                prevVal[season]['to_month'] = value.value;
                return {
                    ...prevVal
                }
            })

        }
    }

    const handleMaxTemp = (season, value) => {

        if (value) {
            setPostData((prevVal) => {
                prevVal[season]['max_temp'] = value;
                return {
                    ...prevVal
                }
            })

        }
    }
    const handleMinTemp = (season, value) => {

        if (value) {
            setPostData((prevVal) => {
                prevVal[season]['min_temp'] = value;
                return {
                    ...prevVal
                }
            })

        }
    }
    const handleMaxHumedity = (season, value) => {

        if (value) {
            setPostData((prevVal) => {
                prevVal[season]['max_humidity'] = value;
                return {
                    ...prevVal
                }
            })

        }
    }
    const handleMinHumedity = (season, value) => {

        if (value) {
            setPostData((prevVal) => {
                prevVal[season]['min_humidity'] = value;
                return {
                    ...prevVal
                }
            })

        }
    }

    const handleMaxWind = (season, value) => {

        if (value) {
            setPostData((prevVal) => {
                prevVal[season]['max_wind'] = value;
                return {
                    ...prevVal
                }
            })

        }
    }
    const handleMinWind = (season, value) => {

        if (value) {
            setPostData((prevVal) => {
                prevVal[season]['min_wind'] = value;
                return {
                    ...prevVal
                }
            })

        }
    }

    const handleRainType = (season, value) => {

        if (value) {
            setPostData((prevVal) => {
                prevVal[season]['rain_type'] = value.value;
                return {
                    ...prevVal
                }
            })

        }
    }
    const handleFogType = (season, value) => {

        if (value) {
            setPostData((prevVal) => {
                prevVal[season]['fog_type'] = value.value;
                return {
                    ...prevVal
                }
            })

        }
    }


    const [form] = Form.useForm();
    const onFinish = (values) => {
        let seasonSettingsPostData = {}
        seasonSettingsPostData['Season_Settings'] = {};

        seasonSettingsPostData['Season_Settings']['year'] = year.label
        seasonSettingsPostData['Season_Settings'] = { ...seasonSettingsPostData['Season_Settings'], ...postData }
        console.log(seasonSettingsPostData)
        return props.onFinish(seasonSettingsPostData);

    };


    return (
        <Form style={{ color: "white", paddingBottom: '30px' }} onFinish={onFinish} form={form}
            initialValues={props.data}>
            <Row className={'header_row'}>
                <div className={'header_main'}>
                    <div className={'header_main_sub'}>
                        <h4>Season Settings</h4>
                    </div>

                </div>
            </Row>

            <Row className={'border-2 border-2_1st'}>

                <Col span={3}>

                    <p className={'font-2'}>Year</p>

                    <Form.Item
                        name="year"
                    > <SelectField placeholder="Select A Year" url="api/combo/year" value={year}
                        onSelectChange={handleYear} /> </Form.Item>

                </Col>
                <Col span={3}></Col>
                <Col span={3}></Col>
                <Col span={3}></Col>
                <Col span={3}></Col>
                <Col span={3}></Col>
                <Col span={3}></Col>


            </Row>
            <Row className={'justify-content-space-around border-2'} >

                <Col className={'display-flex-with-align-center'} style={{ marginTop: '20px' }} span={2}>

                    <p className={'font-3'}>Spring:</p></Col>

                <Col span={2}>

                    <p className={'font-2'}>Form Month</p>
                    <Form.Item name="0_fromMonth">
                        <SelectField placeholder="From Month" url="api/combo/month" value={postData[0]['from_month']}
                            onSelectChange={(e) => handleFromMonth(0, e)} />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <p className={'font-2'}>To Month</p>
                    <Form.Item name="0_toMonth">
                        <SelectField placeholder="To Month" url="api/combo/month" value={postData[0]['to_month']}
                            onSelectChange={(e) => handleToMonth(0, e)} />
                    </Form.Item>
                </Col>

                <Col span={3}>

                    <p className={'font-2'}>Temparature</p>
                    <div className={'d-flex'}>
                        <Form.Item
                            name="0_minTemp"
                        > <InputNumberField placeholder="Min Temparature" value={postData[0]['min_temp']}
                            onChange={(e) => handleMinTemp(0, e)} />
                        </Form.Item>
                        <Form.Item
                            name="0_maxTemp"
                        > <InputNumberField placeholder="Max Temparature" value={postData[0]['max_temp']}
                            onChange={(e) => handleMaxTemp(0, e)} />
                        </Form.Item>

                    </div>


                </Col>
                <Col span={3}>

                    <p className={'font-2'}>Humidity</p>
                    <div className={'d-flex'}>
                        <Form.Item
                            name="0_minHumedity"

                        > <InputNumberField placeholder="Min Humidity" value={postData[0]['min_humidity']}
                            onChange={(e) => handleMinHumedity(0, e)} />
                        </Form.Item>
                        <Form.Item
                            name="0_maxHumedity"

                        > <InputNumberField placeholder="Max Humidity" value={postData[0]['max_humidity']}
                            onChange={(e) => handleMaxHumedity(0, e)} />
                        </Form.Item>

                    </div>

                </Col>
                <Col span={3} >
                    <p className={'font-2'} >Wind</p>
                    <div className={'d-flex'}>
                        <Form.Item
                            name="0_minWind"
                        > <InputNumberField placeholder="Min Wind" value={postData[0]['min_wind']}
                            onChange={(e) => handleMinWind(0, e)} />
                        </Form.Item>
                        <Form.Item
                            name="0_maxWind"

                        > <InputNumberField placeholder="Max Wind" value={postData[0]['max_wind']}
                            onChange={(e) => handleMaxWind(0, e)} />
                        </Form.Item>
                    </div>


                </Col>
                <Col span={3} >
                    <p className={'font-2'} >Rain Type</p>
                    <Form.Item
                        name="0_rainType"
                    >
                        <SelectField placeholder="Rain Type" value={postData[0]['rain_type']} url="api/combo/rain_type"
                            onSelectChange={(e) => handleRainType(0, e)} />
                    </Form.Item>

                </Col>
                <Col span={3}>
                    <p className={'font-2'} >Fog Type</p>
                    <Form.Item
                        name="0_fogType"
                    >
                        <SelectField placeholder="Fog Type" value={postData[0]['fog_type']} url="api/combo/fog_type"
                            onSelectChange={(e) => handleFogType(0, e)} />
                    </Form.Item>

                </Col>
            </Row>


            <Row className={'justify-content-space-around border-2'}>

                <Col span={2} className={'display-flex-with-align-center'}> <p className={'font-3'}>Summer:</p></Col>

                <Col span={2}>
                    <Form.Item name="0_fromMonth">
                        <SelectField placeholder="From Month" url="api/combo/month" value={postData[1]['from_month']}
                            onSelectChange={(e) => handleFromMonth(1, e)} />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item name="0_toMonth">
                        <SelectField placeholder="To Month" url="api/combo/month" value={postData[1]['to_month']}
                            onSelectChange={(e) => handleToMonth(1, e)} />
                    </Form.Item>
                </Col>

                <Col style={{ display: "flex" }} span={3}>

                    <Form.Item
                        name="0_minTemp"
                    > <InputNumberField placeholder="Min Temparature" value={postData[1]['min_temp']}
                        onChange={(e) => handleMinTemp(1, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxTemp"
                    > <InputNumberField placeholder="Max Temparature" value={postData[1]['max_temp']}
                        onChange={(e) => handleMaxTemp(1, e)} />
                    </Form.Item>

                </Col>
                <Col style={{ display: "flex" }} span={3}>

                    <Form.Item
                        name="0_minHumedity"

                    > <InputNumberField placeholder="Min Humidity" value={postData[1]['min_humidity']}
                        onChange={(e) => handleMinHumedity(1, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxHumedity"

                    > <InputNumberField placeholder="Max Humidity" value={postData[1]['max_humidity']}
                        onChange={(e) => handleMaxHumedity(1, e)} />
                    </Form.Item>

                </Col>
                <Col style={{ display: "flex" }} span={3}>

                    <Form.Item
                        name="0_minWind"
                    > <InputNumberField placeholder="Min Wind" value={postData[1]['min_wind']}
                        onChange={(e) => handleMinWind(1, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxWind"

                    > <InputNumberField placeholder="Max Wind" value={postData[1]['max_wind']}
                        onChange={(e) => handleMaxWind(1, e)} />
                    </Form.Item>

                </Col>
                <Col span={3} >
                    <Form.Item
                        name="0_rainType"
                    >
                        <SelectField placeholder="Rain Type" value={postData[1]['rain_type']} url="api/combo/rain_type"
                            onSelectChange={(e) => handleRainType(1, e)} />
                    </Form.Item>

                </Col>
                <Col span={3}>
                    <Form.Item
                        name="0_fogType"
                    >
                        <SelectField placeholder="Fog Type" value={postData[1]['fog_type']} url="api/combo/fog_type"
                            onSelectChange={(e) => handleFogType(1, e)} />
                    </Form.Item>

                </Col>
            </Row>

            <Row className={'justify-content-space-around border-2'}>
                <Col className={'display-flex-with-align-center'} span={2}>
                    <p className={'font-3'}>Rainy:</p>
                </Col>
                <Col span={2}>

                    <Form.Item name="0_fromMonth">
                        <SelectField placeholder="From Month" url="api/combo/month" value={postData[2]['from_month']}
                            onSelectChange={(e) => handleFromMonth(2, e)} />
                    </Form.Item>
                </Col>
                <Col span={2} >
                    <Form.Item name="0_toMonth">
                        <SelectField placeholder="To Month" url="api/combo/month" value={postData[2]['to_month']}
                            onSelectChange={(e) => handleToMonth(2, e)} />
                    </Form.Item>
                </Col>

                <Col style={{ display: "flex" }} span={3}>

                    <Form.Item
                        name="0_minTemp"
                    > <InputNumberField placeholder="Min Temparature" value={postData[2]['min_temp']}
                        onChange={(e) => handleMinTemp(2, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxTemp"
                    > <InputNumberField placeholder="Max Temparature" value={postData[2]['max_temp']}
                        onChange={(e) => handleMaxTemp(2, e)} />
                    </Form.Item>

                </Col>
                <Col style={{ display: "flex" }} span={3} >

                    <Form.Item
                        name="0_minHumedity"

                    > <InputNumberField placeholder="Min Humidity" value={postData[2]['min_humidity']}
                        onChange={(e) => handleMinHumedity(2, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxHumedity"

                    > <InputNumberField placeholder="Max Humidity" value={postData[2]['max_humidity']}
                        onChange={(e) => handleMaxHumedity(2, e)} />
                    </Form.Item>

                </Col>
                <Col style={{ display: "flex" }} span={3}>

                    <Form.Item
                        name="0_minWind"
                    > <InputNumberField placeholder="Min Wind" value={postData[2]['min_wind']}
                        onChange={(e) => handleMinWind(2, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxWind"

                    > <InputNumberField placeholder="Max Wind" value={postData[2]['max_wind']}
                        onChange={(e) => handleMaxWind(2, e)} />
                    </Form.Item>

                </Col>
                <Col span={3}>
                    <Form.Item
                        name="0_rainType"
                    >
                        <SelectField placeholder="Rain Type" value={postData[2]['rain_type']} url="api/combo/rain_type"
                            onSelectChange={(e) => handleRainType(2, e)} />
                    </Form.Item>

                </Col>
                <Col span={3} >
                    <Form.Item
                        name="0_fogType"
                    >
                        <SelectField placeholder="Fog Type" value={postData[2]['fog_type']} url="api/combo/fog_type"
                            onSelectChange={(e) => handleFogType(2, e)} />
                    </Form.Item>

                </Col>
            </Row>
            <Row className={'justify-content-space-around border-2'}>
                <Col className={'display-flex-with-align-center'} span={2}>
                    <p className={'font-3'}>Winter:</p>
                </Col>
                <Col span={2}>
                    <Form.Item name="0_fromMonth">
                        <SelectField placeholder="From Month" url="api/combo/month" value={postData[3]['from_month']}
                            onSelectChange={(e) => handleFromMonth(3, e)} />
                    </Form.Item>
                </Col>
                <Col span={2} >
                    <Form.Item name="0_toMonth">
                        <SelectField placeholder="To Month" url="api/combo/month" value={postData[3]['to_month']}
                            onSelectChange={(e) => handleToMonth(3, e)} />
                    </Form.Item>
                </Col>

                <Col style={{ display: "flex" }} span={3}>

                    <Form.Item
                        name="0_minTemp"
                    > <InputNumberField placeholder="Min Temparature" value={postData[3]['min_temp']}
                        onChange={(e) => handleMinTemp(3, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxTemp"
                    > <InputNumberField placeholder="Max Temparature" value={postData[3]['max_temp']}
                        onChange={(e) => handleMaxTemp(3, e)} />
                    </Form.Item>

                </Col>
                <Col style={{ display: "flex" }} span={3} >

                    <Form.Item
                        name="0_minHumedity"

                    > <InputNumberField placeholder="Min Humidity" value={postData[3]['min_humidity']}
                        onChange={(e) => handleMinHumedity(3, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxHumedity"

                    > <InputNumberField placeholder="Max Humidity" value={postData[3]['max_humidity']}
                        onChange={(e) => handleMaxHumedity(3, e)} />
                    </Form.Item>

                </Col>
                <Col style={{ display: "flex" }} span={3} >

                    <Form.Item
                        name="0_minWind"
                    > <InputNumberField placeholder="Min Wind" value={postData[3]['min_wind']}
                        onChange={(e) => handleMinWind(3, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxWind"

                    > <InputNumberField placeholder="Max Wind" value={postData[3]['max_wind']}
                        onChange={(e) => handleMaxWind(3, e)} />
                    </Form.Item>

                </Col>
                <Col span={3} >
                    <Form.Item
                        name="0_rainType"
                    >
                        <SelectField placeholder="Rain Type" value={postData[3]['rain_type']} url="api/combo/rain_type"
                            onSelectChange={(e) => handleRainType(3, e)} />
                    </Form.Item>

                </Col>
                <Col span={3} >
                    <Form.Item
                        name="0_fogType"
                    >
                        <SelectField placeholder="Fog Type" value={postData[3]['fog_type']} url="api/combo/fog_type"
                            onSelectChange={(e) => handleFogType(3, e)} />
                    </Form.Item>

                </Col>
            </Row>
            <Row className={'justify-content-space-around border-2'}>
                <Col className={'display-flex-with-align-center'} span={2}>
                    <p className={'font-3'}>Autumn:</p>
                </Col>
                <Col span={2}>
                    <Form.Item name="0_fromMonth">
                        <SelectField placeholder="From Month" url="api/combo/month" value={postData[4]['from_month']}
                            onSelectChange={(e) => handleFromMonth(4, e)} />
                    </Form.Item>
                </Col>
                <Col span={2} >
                    <Form.Item name="0_toMonth">
                        <SelectField placeholder="To Month" url="api/combo/month" value={postData[4]['to_month']}
                            onSelectChange={(e) => handleToMonth(4, e)} />
                    </Form.Item>
                </Col>

                <Col style={{ display: "flex" }} span={3}>

                    <Form.Item
                        name="0_minTemp"
                    > <InputNumberField placeholder="Min Temparature" value={postData[4]['min_temp']}
                        onChange={(e) => handleMinTemp(4, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxTemp"
                    > <InputNumberField placeholder="Max Temparature" value={postData[4]['max_temp']}
                        onChange={(e) => handleMaxTemp(4, e)} />
                    </Form.Item>

                </Col>
                <Col style={{ display: "flex" }} span={3} >

                    <Form.Item
                        name="0_minHumedity"

                    > <InputNumberField placeholder="Min Humidity" value={postData[4]['min_humidity']}
                        onChange={(e) => handleMinHumedity(4, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxHumedity"

                    > <InputNumberField placeholder="Max Humidity" value={postData[4]['max_humidity']}
                        onChange={(e) => handleMaxHumedity(4, e)} />
                    </Form.Item>

                </Col>
                <Col style={{ display: "flex" }} span={3} >

                    <Form.Item
                        name="0_minWind"
                    > <InputNumberField placeholder="Min Wind" value={postData[4]['min_wind']}
                        onChange={(e) => handleMinWind(4, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxWind"

                    > <InputNumberField placeholder="Max Wind" value={postData[4]['max_wind']}
                        onChange={(e) => handleMaxWind(4, e)} />
                    </Form.Item>

                </Col>
                <Col span={3}>
                    <Form.Item
                        name="0_rainType"
                    >
                        <SelectField placeholder="Rain Type" value={postData[4]['rain_type']} url="api/combo/rain_type"
                            onSelectChange={(e) => handleRainType(4, e)} />
                    </Form.Item>

                </Col>
                <Col span={3}>
                    <Form.Item
                        name="0_fogType"
                    >
                        <SelectField placeholder="Fog Type" value={postData[4]['fog_type']} url="api/combo/fog_type"
                            onSelectChange={(e) => handleFogType(4, e)} />
                    </Form.Item>

                </Col>
            </Row>
            <Row className={'justify-content-space-around border-2'}>
                <Col className={'display-flex-with-align-center'} span={2}>
                    <p className={'font-3'}>Late Autumn:</p>
                </Col>
                <Col span={2}>
                    <Form.Item name="0_fromMonth">
                        <SelectField placeholder="From Month" url="api/combo/month" value={postData[5]['from_month']}
                            onSelectChange={(e) => handleFromMonth(5, e)} />
                    </Form.Item>
                </Col>
                <Col span={2} >
                    <Form.Item name="0_toMonth">
                        <SelectField placeholder="To Month" url="api/combo/month" value={postData[5]['to_month']}
                            onSelectChange={(e) => handleToMonth(5, e)} />
                    </Form.Item>
                </Col>

                <Col style={{ display: "flex" }} span={3}>

                    <Form.Item
                        name="0_minTemp"
                    > <InputNumberField placeholder="Min Temparature" value={postData[5]['min_temp']}
                        onChange={(e) => handleMinTemp(5, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxTemp"
                    > <InputNumberField placeholder="Max Temparature" value={postData[5]['max_temp']}
                        onChange={(e) => handleMaxTemp(5, e)} />
                    </Form.Item>

                </Col>
                <Col style={{ display: "flex" }} span={3}>

                    <Form.Item
                        name="0_minHumedity"

                    > <InputNumberField placeholder="Min Humidity" value={postData[5]['min_humidity']}
                        onChange={(e) => handleMinHumedity(5, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxHumedity"

                    > <InputNumberField placeholder="Max Humidity" value={postData[5]['max_humidity']}
                        onChange={(e) => handleMaxHumedity(5, e)} />
                    </Form.Item>

                </Col>
                <Col style={{ display: "flex" }} span={3} >

                    <Form.Item
                        name="0_minWind"
                    > <InputNumberField placeholder="Min Wind" value={postData[5]['min_wind']}
                        onChange={(e) => handleMinWind(5, e)} />
                    </Form.Item>
                    <Form.Item
                        name="0_maxWind"

                    > <InputNumberField placeholder="Max Wind" value={postData[5]['max_wind']}
                        onChange={(e) => handleMaxWind(5, e)} />
                    </Form.Item>

                </Col>
                <Col span={3} >
                    <Form.Item
                        name="0_rainType"
                    >
                        <SelectField placeholder="Rain Type" value={postData[5]['rain_type']} url="api/combo/rain_type"
                            onSelectChange={(e) => handleRainType(5, e)} />
                    </Form.Item>

                </Col>
                <Col span={3} >
                    <Form.Item
                        name="0_fogType"
                    >
                        <SelectField placeholder="Fog Type" value={postData[5]['fog_type']} url="api/combo/fog_type"
                            onSelectChange={(e) => handleFogType(5, e)} />
                    </Form.Item>

                </Col>
            </Row>
            <Row className={'button_row'}>
                <Button htmlType='submit'>Add Season Settings</Button>
            </Row>
        </Form>
    )

}
