import PropTypes from 'prop-types';
import BasePage from "../BasePage/BasePage";
import {InputField} from "../../components/Fields/InputField";
import {Form, Checkbox} from "antd";
import {SelectField} from "../../components/Fields/SelectField";
import {InputBtnfield} from "../../components/Fields/InputBtnField";
import {DateField} from "../../components/Fields/DateField";
import {TimeField} from "../../components/Fields/TimeField";
import ContentHeader from "../Layout/ContentHeader/ContentHeader";
import assert from 'assert';
import React from 'react';
import {InputNumberField} from "../../components/Fields/InputNumberField";


class BaseAddPage extends BasePage {
    formRef = React.createRef();
    constructor(props){
        super(props);
        this.pageConfig = this.getPageConfig();
        this.state = {
            postData: {},
            errors: {},
            editUrl: null,
            form: {},
            initial_data: {}
        }
    }

    getPageConfig(){
        return {
            pageTitle: 'Add Page',
            pageButtons: [],
        }
    }

    setPostData(field, type, label, value){
        let newData = {};
        let genericType = ['input', 'checkbox', 'date', 'time', 'checkbox-group', 'input-number']
        if (type == 'select') {
            if (value) {
                if (Array.isArray(value)) {
                    let valueList = [];
                    for (let i = 0; i < value.length; i++) {
                        valueList.push(value[i].value);
                    }
                    newData[field] = valueList;
                    // newData[`${field}_combo`] = value;
                    let selectData = {}
                    selectData[field] = value;
                    this.formRef.current.setFieldsValue(selectData)
                }
                else {
                    newData[field] = null;
                    newData[field] = value.value;
                    // newData[`${field}_combo`] = value;
                    let selectData = {}
                    selectData[field] = value;
                    this.formRef.current.setFieldsValue(selectData)
                }
            }
            else {
                newData[field] = null;
                newData[`${field}_combo`] = null;
            }
        }
        else if (type == 'input-button') {
            if (value == 'plus') {
                const newValue = parseInt(this.formRef.getFieldsValue()[field] || 0) + 1;
                const updatedValue = {};
                updatedValue[field] = newValue;
                newData[field] = newValue;
                this.formRef.current.setFieldsValue(updatedValue)
            }
            else {
                const newValue = parseInt(this.formRef.getFieldsValue()[field] || 0) - 1;
                const updatedValue = {};
                updatedValue[field] = newValue;
                newData[field] = newValue;
                this.formRef.current.setFieldsValue(updatedValue)
            }
        }
        else if (genericType.includes(type)) {
            newData[field] = value;
        }

        const {postData} = this.state;
        this.setState((state, props) => {
            return (
            {
            postData: {...state.postData, ...newData}
         }
        )}
        );
        // this.setState({
        //     postData: {...postData, ...newData}
        // });

    }

    BuildFormItem=({name, label, type='input', fieldConfig={}, errorObj={}, formConfig={}}={})=>{
        const validateStatus = errorObj.hasOwnProperty(`${name}`) ? errorObj[name]?.validateStatus:null;
        const errorMsg = errorObj.hasOwnProperty(`${name}`) ? errorObj[name]?.errorMsg:null;
        if(type == 'input'){
            return (
                <Form.Item
                    label={<p className="formLabel">{label || name}</p>}
                    labelCol={{span: 24}}
                    name={name || label}
                    validateStatus={validateStatus}
                    help={errorMsg}
                    {...formConfig}
                >
                    <InputField
                        onChange={(e) => this.setPostData(name, type, label, e.target.value)} {...fieldConfig} />
                </Form.Item>
            )
        }
        else if(type == 'input-number'){
            return (
                <Form.Item
                    label={<p className="formLabel">{label || name}</p>}
                    labelCol={{span: 24}}
                    name={name || label}
                    validateStatus={validateStatus}
                    help={errorMsg}
                    {...formConfig}
                >
                    <InputNumberField
                        onChange={(value) => this.setPostData(name, type, label, value)} {...fieldConfig} />
                </Form.Item>
            )
        }
        else if(type == 'checkbox'){
            return (<Form.Item
                    label={<p className="formLabel">{label || name}</p>}
                    labelCol={{span: 24}}
                    name={name || label}
                    validateStatus={validateStatus}
                    help={errorMsg}
                    {...formConfig}
                >
                    <Checkbox
                        onChange={(e) => this.setPostData(name, type, label, e.target.checked == true ? 1 : 0)} {...fieldConfig} />
                </Form.Item>
            )
        }
        else if(type == 'select'){
            return (<Form.Item
                    label={<p className="formLabel">{label || name}</p>}
                    labelCol={{span: 24}}
                    name={name || label}
                    validateStatus={validateStatus}
                    help={errorMsg}
                    {...formConfig}
                >
                    <SelectField value={this.state.postData[`${name}_combo`]}
                                 onSelectChange={(value) => this.setPostData(name, type, label, value)} {...fieldConfig} />
                </Form.Item>
            )
        }
        else if(type == 'input-button'){
            return (<Form.Item
                    label={<p className="formLabel">{label || name}</p>}
                    labelCol={{span: 24}}
                    name={name || label}
                    validateStatus={validateStatus}
                    help={errorMsg}
                    {...formConfig}
                >
                    <InputBtnfield
                        onBeforeButtonClicked={()=>this.setPostData(name, type, label, 'minus')}
                        onAfterButtonClicked={()=>this.setPostData(name, type, label, 'plus')}
                        {...fieldConfig}
                    />
                </Form.Item>
            )
        }
        else if(type == 'checkbox-group'){
            return (
                <>
                    <p  className={['formLabel', 'custom-form-level'].join(' ')}>{label || name}</p>
                    <Checkbox.Group
                        onChange={(values)=>this.setPostData(name, type, label, values)}
                        validateStatus={validateStatus}
                        help={errorMsg}
                        className='ant_checkbox_group_item'
                        {...fieldConfig}
                    />
                </>
            )
        }
        else if(type == 'date'){
            return (
                <Form.Item
                    label={<p className="formLabel">{label || name}</p>}
                    labelCol={{span: 24}}
                    name={name || label}
                    validateStatus={validateStatus}
                    help={errorMsg}
                    {...formConfig}
                >
                    <DateField initialValue={this.state.postData[name]}
                               onChange={(value) => this.setPostData(name, type, label, value)} {...fieldConfig} />
                </Form.Item>
            )
        }
        else if(type == 'time'){
            return (
                <Form.Item
                    label={<p className="formLabel">{label || name}</p>}
                    labelCol={{span: 24}}
                    name={name || label}
                    validateStatus={validateStatus}
                    help={errorMsg}
                    {...formConfig}
                >
                    <TimeField onSelect={(value) => this.setPostData(name, type, label, value)} {...fieldConfig} />
                </Form.Item>
            )
        }
        return null;

    };
    renderBody(){
        assert(false, "Child Class Must Implement 'renderBody' function ");
    }
    errorCallback=(error)=>{
        if(error?.status == 422){
            const errorData = {};
            for(var i=0; i<error.data.detail.length; i++){
                let field = error.data.detail[i].loc[1];
                let errorMessage = error.data.detail[i].msg;
                errorData[field] = {errorMsg: errorMessage, validateStatus:'error'}
            }
            this.setState({errors: errorData})
        }
    }
    render(){
        if(!this.props.partial) {
            return (
                <>
                    <ContentHeader
                        title={this.pageConfig.pageTitle}
                        pageButtons={this.pageConfig.pageButtons}/>
                    <div className='container'>
                        {this.renderBody()}
                    </div>
                </>
            )
        }
        else {
            return (
                <>
                    <div className='container'>
                        {this.renderBody()}
                    </div>
                </>
            )
        }

    }
}
BaseAddPage.propTypes = {
    auth: PropTypes.object.isRequired
};

export default BaseAddPage;