import BaseList from "../../containers/BaseList/BaseList";
import ListConfig from '../../containers/BaseList/DataTable/ListConfig';
import PageConfig from '../../containers/Layout/PageConfig';
import { columnMapper } from './columnMapper';
import { SearchFormMapper } from './SearchFormMapper';
import * as http from '../../http';
import { Button,message,Modal,Space,Popconfirm } from "antd";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { AddPage } from "./Addpage";
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
const copy = <FontAwesomeIcon icon={faCopy}/>


class PermissionListPage extends BaseList {
    constructor(props){
      super(props);
    }
    
      getColumns(){
        const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
        const deleteClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';

        return [
          ...columnMapper,
          {
            title: 'Action',
            key: 'action',
            width: 'auto',
            render: (text, record) => (
              <Space size="middle">
                <Button 
                  key='editButton' 
                  className={themeClass} 
                  icon={<EditOutlined />} 
                  onClick={()=>this.showModal(`/api/permissions/${record.id}`)}
                />
                <Button 
                  key='duplicateButton'
                  className={themeClass}
                  icon={copy}
                  style={{marginLeft:"-15px"}}
                  onClick={()=>this.showModal(`/api/permissions/${record.id}`, "duplicate")} 
                />
                 <Popconfirm okType='default' title="Are sure to Delete?" okText="Yes" onConfirm={() => this.handleDelete(record)}>
                    <Button 
                        key='deleteButton'
                        className={deleteClass}
                        icon={<DeleteOutlined />}
                    />
                </Popconfirm>
                
              </Space>
            )
          }
        ];
      }
      handleDelete(record){
        axios({
          url: `/api/permissions/${record.id}`,
          method: 'delete',
          type: 'json',
          }).then(res => {
            message.success("Deleted Successfully")
            this.fetch()
          
        }).catch((err) => {
          message.error('Delete Failed');
        });
      }
      getDataUrl(){
        return '/api/permissions/'; 
      }
    
      getPageConfig(){
        return {
          pageTitle:'Permissions List',
          pageButtons: [
            <Button
              key='add'
              className={'create_btn'}
              onClick={()=>this.showModal()}
              type="primary"
              size="small"
              icon={<PlusOutlined />}
            >
              ADD NEW
            </ Button>
          ],
        }
      }
    
      getSearchFields(){
        return SearchFormMapper;
      }
    onAddSaveClick = (values) =>{
        console.log(values.Permissions)
        http.post('/api/permissions', values.Permissions)
        .then((response)=>{
            if(response.status ==201){
                this.setState({
                    isModalVisible: false
                })
                message.success("New Permissions Added");
                this.fetch()
            }
        })
        
    }
    onUpdateClick=(values)=>{
        console.log(values)
        http.put(`/api/permissions/${values.Permissions.id}`, values.Permissions)
          .then((response)=> {
          if(response.status == 200){
            this.setState({
              isModalVisible: false,
            })
          }
          message.success('Permissions Updated Successfully');
          this.fetch();
        }).catch((err)=>{
          message.error('Permissions Update Failed');
          this.errorCallback(err.response);
        })
      }
    
      onFormFinish=(values)=>{
        if(this.state.duplicate){
          this.onAddSaveClick(values);
        }
        else if(this.state.editUrl){
          this.onUpdateClick(values);
        }
        else{
          this.onAddSaveClick(values);
        }
      }

    render(){
      const colorClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'customantmodal' : 'notfound';

        return (
            <>
                {super.render()}
                <Modal  
                    title={<div className={'header_row'}><div className={'header_main'}><div className={'header_main_sub'}><h4>Add Permissions</h4></div></div></div>}
                    visible={this.state.isModalVisible} 
                    onOk={this.handleOk} onCancel={this.handleCancel} 
                    destroyOnClose={true} footer={null} 
                    centered={true} 
                    className={colorClass}
                >
                    <AddPage 
                        onFinish={this.onFormFinish.bind(this)}
                        editUrl = {this.state.editUrl}
                        errors={this.state.errors}

                    />

                </Modal>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(withRouter(PermissionListPage));
