// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddPage_Form__gFvUA {\n  padding: 15px 15px 15px 15px;\n}\n.AddPage_formLabel__PQVUB {\n  color: #4a6e83;\n}\n.AddPage_step_panel__OxdWj {\n  color: #087b95;\n}\n.AddPage_formmain__TVlvu {\n  background: none;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 20px;\n}\n.AddPage_numWidth__juHxW {\n  width: 200px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Scenarios/AddPage.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,YAAY;AACd","sourcesContent":[".Form {\n  padding: 15px 15px 15px 15px;\n}\n.formLabel {\n  color: #4a6e83;\n}\n.step_panel {\n  color: #087b95;\n}\n.formmain {\n  background: none;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 20px;\n}\n.numWidth {\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": "AddPage_Form__gFvUA",
	"formLabel": "AddPage_formLabel__PQVUB",
	"step_panel": "AddPage_step_panel__OxdWj",
	"formmain": "AddPage_formmain__TVlvu",
	"numWidth": "AddPage_numWidth__juHxW"
};
export default ___CSS_LOADER_EXPORT___;
