import { Button, Col, Form, Image, message, Row } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { FileUploader } from '../../../components/FileUploder.jsx';
import ContentHeader from '../../../containers/Layout/ContentHeader/ContentHeader';
import { StepPanel } from "../../../containers/StepPanel/StepPanel";
import * as http from '../../../http';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../../utils';
import classes from './AddPage.module.css';


  export const WrdEquipmentsAdd = (props) => {
    const themeChange = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitethm' : 'notfound' ;
    // console.log(process.env.REACT_APP_GATEWAY)
    const [formPostData, setFormPostData] = React.useState({
      brand_id_combo:null,
      model_id_combo:null,
      equipment_type_id_combo:null,
      device_uplink_connector_combo:null,
      device_downlink_connector_combo:null,
      cable_type_id_combo:null,
      coaxial_device_uplink_connector_combo:null,
      coaxial_device_downlink_connector_combo:null,
      image_url:process.env.REACT_APP_GATEWAY+"/api/filesapi/getfile/11669711378.73759logo2.png",
      image_name:"",
    });

    const [stepForm] = Form.useForm();
    const [errors, setErrors] = React.useState({});
    const [outlineData, setOutlineData] = React.useState({});
    const [model_combo_url, setModelComboUrl] = React.useState('/api/combo/wrd_equipment_model_combo/0/');

    let isMounted = useIsMounted();
    let dataMounted = useIsMounted();
    const { id } = useParams();

    const getEquipmentData=()=>{
      http.get(`/api/wire_comm_equipments/${id}`).then((resp)=>{
        console.log(resp.data)
          _.forOwn(resp.data, (value, key)=>{
            if(!formPostData.hasOwnProperty(key)){
              const val = {};
              val[key] = value;
              stepForm.setFieldsValue(val);
              delete resp.data[key];
            }
          })
          if(dataMounted.current){
            setFormPostData((prevVal)=>{
              return {...prevVal, ...resp.data}
            })
          }
      }).catch((err)=>{
        console.log(err);
      })
    }

    useEffect(()=>{
      if(props.edit || props.duplicate){
        getEquipmentData();
      }
      
    return () => { dataMounted = false }; 
    }, [])


    const setPostData=(field, type, label, value)=>{
      let newData = {};
       let genericType = ['input', 'checkbox', 'date', 'time', 'checkbox-group'];
      if(type == 'select'){
        if(value){
        if(Array.isArray(value)){
          let valueList = [];
          for(let i=0; i<value.length; i++){
            valueList.push(value[i].value);
          }
          newData[field] = valueList;
          newData[`${field}_combo`] = value;
        }
        else{
          newData[field] = value.value;
          newData[`${field}_combo`] = value;
        }
        }
        else{
        newData[field] = null;
        newData[`${field}_combo`] = null;
        }
        if(`${field}_combo`=='brand_id_combo'){
          if (value!=null){
            setModelComboUrl('/api/combo/wrd_equipment_model_combo/'+value.value+'/');
          }else{
            setModelComboUrl('/api/combo/wrd_equipment_model_combo/0/');
          }
        }
      }
      else if(type == 'input-button'){
        if(value == 'plus'){
        const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
        const updatedValue = {};
        updatedValue[field] = newValue;
        stepForm.setFieldsValue(updatedValue)
        }
        else{
        const newValue = parseInt(stepForm.getFieldsValue()[field]|| 0) - 1;
        const updatedValue = {};
        updatedValue[field] = newValue;
        stepForm.setFieldsValue(updatedValue)
        }
      }
      else if (genericType.includes(type)) {
            newData[field] = value;
        }

      setFormPostData((prevVal)=>{
        return {...prevVal, ...newData}
      })
  }
  
    const Step1Form = () => {
      return (
        <div className={classes.Form}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                name='name'
                label='Name'
                type='input'
                errorObj={errors}
                formConfig={{
                  required: true,
              }}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='brand_id'
                label='Brand'
                type='select'
                fieldConfig={{
                  value:formPostData.brand_id_combo,
                  url:"/api/combo/brand/",
                  onSelectChange:setPostData
                }}
                formConfig={{
                  required: true,
              }}
                errorObj={errors}
              />
            </Col >
          </Row>
          <Row gutter={[16, 16]}>
          <Col span={12}>
              <BuildFormItem
                name='model_id'
                label='Model'
                type='select'
                fieldConfig={{
                  value:formPostData.model_id_combo,
                  url: model_combo_url,
                  onSelectChange:setPostData
                }}
                formConfig={{
                  required: true,
              }}
                errorObj={errors}
              />
            </Col >
          <Col span={12}>
              <BuildFormItem
                name='equipment_type_id'
                label='Device Type'
                type='select'
                fieldConfig={{
                  value:formPostData.equipment_type_id_combo,
                  url:"/api/combo/wrd_equipment_type/",
                  onSelectChange:setPostData
                }}
                formConfig={{
                  required: true,
              }}
                errorObj={errors}
              />
            </Col >
          </Row>
          {/*  */}
          <Row gutter={[16],[16]}>
                <Col span={12}>
                        <BuildFormItem
                            name='number_of_dev_uplink'
                            label='Numbers of Input/Uplink'
                            type='input'
                            formConfig={{
                                required: true,
                                hidden: (formPostData.equipment_type_id_combo != null)?((formPostData.equipment_type_id_combo.value == 12 || formPostData.equipment_type_id_combo.value == 11 || formPostData.equipment_type_id_combo.value == 26 || formPostData.equipment_type_id_combo.value == 20||formPostData.equipment_type_id_combo.value == 10)? true : false):false,
                            }}
                            fieldConfig={{
                                onInputChange:setPostData,
                                value: formPostData.number_of_uplinks,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                        name='device_uplink_connector'
                        label='Input/Uplink Connector'
                        type='select'
                        fieldConfig={{
                            url:"/api/combo/connectors",
                            onSelectChange:setPostData,
                            value: formPostData.device_uplink_connector_combo
                            }}
                        formConfig={{
                            required: true,
                            hidden: (formPostData.equipment_type_id_combo != null)?((formPostData.equipment_type_id_combo.value == 12 || formPostData.equipment_type_id_combo.value == 11 || formPostData.equipment_type_id_combo.value == 26 || formPostData.equipment_type_id_combo.value == 20||formPostData.equipment_type_id_combo.value == 10)? true : false):false,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                            name='number_of_dev_coaxial_uplink'
                            label='Numbers of Coaxial Input/Uplink'
                            type='input'
                            formConfig={{
                                required: true,
                                hidden: (formPostData.equipment_type_id_combo != null)?((formPostData.equipment_type_id_combo.value == 15 || formPostData.equipment_type_id_combo.value == 20)? false : true):true,
                            }}
                            fieldConfig={{
                                onInputChange:setPostData,
                                value: formPostData.number_of_uplinks,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                        name='coaxial_device_uplink_connector'
                        label='Input/Uplink Coaxial Connector'
                        type='select'
                        fieldConfig={{
                            url:"/api/combo/connectors",
                            onSelectChange:setPostData,
                            value: formPostData.coaxial_device_uplink_connector_combo
                            }}
                        formConfig={{
                            required: true,
                            hidden: (formPostData.equipment_type_id_combo != null)?((formPostData.equipment_type_id_combo.value == 15 || formPostData.equipment_type_id_combo.value == 20)? false : true):true,
                            }}
                        />
                    </Col>
                    
                    <Col span={12}>
                        <BuildFormItem
                            name='number_of_dev_downlink'
                            label='Numbers of Out/Downlink'
                            type='input'
                            formConfig={{
                                required: true,
                                hidden: (formPostData.equipment_type_id_combo != null)?((formPostData.equipment_type_id_combo.value == 12 || formPostData.equipment_type_id_combo.value == 11 || formPostData.equipment_type_id_combo.value == 26 || formPostData.equipment_type_id_combo.value == 20 || formPostData.equipment_type_id_combo.value == 21)? true : false):false,
                            }}
                            fieldConfig={{
                                onInputChange:setPostData,
                                value: formPostData.number_of_downlinks,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                        name='device_downlink_connector'
                        label='Out/Downlink Connector'
                        type='select'
                        fieldConfig={{
                            url:"/api/combo/connectors",
                            onSelectChange:setPostData,
                            value: formPostData.device_downlink_connector_combo
                            }}
                        formConfig={{
                            required: true,
                            hidden: (formPostData.equipment_type_id_combo != null)?((formPostData.equipment_type_id_combo.value == 12 || formPostData.equipment_type_id_combo.value == 11 || formPostData.equipment_type_id_combo.value == 26 || formPostData.equipment_type_id_combo.value == 20 || formPostData.equipment_type_id_combo.value == 21)? true : false):false,
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <BuildFormItem
                            name='number_of_dev_coaxial_downlink'
                            label='Numbers of Coaxial Output/Downlink'
                            type='input'
                            formConfig={{
                                required: true,
                                hidden: (formPostData.equipment_type_id_combo != null)?((formPostData.equipment_type_id_combo.value == 16)? false : true):true,
                            }}
                            fieldConfig={{
                                onInputChange:setPostData,
                                value: formPostData.number_of_uplinks,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                        name='coaxial_device_downlink_connector'
                        label='Output/Downlink Coaxial Connector'
                        type='select'
                        fieldConfig={{
                            url:"/api/combo/connectors",
                            onSelectChange:setPostData,
                            value: formPostData.coaxial_device_downlink_connector_combo
                            }}
                        formConfig={{
                            required: true,
                            hidden: (formPostData.equipment_type_id_combo != null)?((formPostData.equipment_type_id_combo.value == 16)? false : true):true,
                            }}
                        />
                    </Col>
                    
                    <Col span={12}>
                        <BuildFormItem
                        name='cable_type_id'
                        label='Cable Type'
                        type='select'
                        fieldConfig={{
                            url:"/api/combo/cables",
                            onSelectChange:setPostData,
                            value: formPostData.cable_type_id_combo
                            }}
                        formConfig={{
                            required: true,
                            hidden: (formPostData.equipment_type_id_combo != null)?((formPostData.equipment_type_id_combo.value == 12 )? false : true):true,
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <BuildFormItem
                            name='number_of_corepairs'
                            label={(formPostData.cable_type_id_combo != null)?((formPostData.cable_type_id_combo.value == 3 && formPostData.equipment_type_id_combo.value==12)? 'Numbers of Pairs' : 'Numbers of Core'):'Numbers of Pairs'}
                            type='input'
                            formConfig={{
                                required: true,
                                hidden: (formPostData.cable_type_id_combo != null)?(((formPostData.cable_type_id_combo.value == 2 ||formPostData.cable_type_id_combo.value == 3) && formPostData.equipment_type_id_combo.value==12)? false : true):true,
                            }}
                            fieldConfig={{
                                onInputChange:setPostData,
                                value: formPostData.number_of_corepairs,
                            }}
                        />
                    </Col> 
                </Row>
        </div>
      );
    };

    const image_url = () =>{

    }

    const FileUpload = () => {
      return (
        <div className={classes.Form}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Image
                width={300}
                src={`${formPostData.image_url}`}
              />
            </Col>
            <Col span={2}>
              <FileUploader onFinished={onUploadFinished}/>
            </Col>
          </Row>
        </div>
      )
    }
    const errorCallback=(error)=>{
      if(error?.status == 422){
        const errorData = {};
        for(var i=0; i<error.data.detail.length; i++){
            let field = error.data.detail[i].loc[1];
            let errorMessage = error.data.detail[i].msg;
            errorData[field] = {errorMsg: errorMessage, validateStatus:'error'}
        }
        setErrors(errorData)
      }
    }
    
    const onFinish = (fieldsValue) => {
      const formData = stepForm.getFieldsValue();
      let postData = {...fieldsValue, ...formPostData}
      if(props.edit){
        http.put(`/api/wire_comm_equipments/${id}`, postData).then((response) => {
          if (response.status == 200) {
            setErrors({})
            message.success('Equipment Updated');
            stepForm.resetFields();
            props.history.push('/wire_comm_equipments')

          }
        }).catch((err) => {
          message.error('Equipment Creation Failed');
          errorCallback(err.response);
        })
    }
      else{
        http.post('/api/wire_comm_equipments/', postData).then((response) => {
          if (response.status == 201) {
            setErrors({})
            message.success('Equipment Added');
            stepForm.resetFields();
            props.history.push('/wire_comm_equipments')

          }
        }).catch((err) => {
          message.error('Equipment Creation Failed');
          // this.errorCallback(err.response);
        })
    }
    };

    const onUploadFinished = (response)=>{
      setFormPostData((prevVal)=>{
        return {...prevVal, ...{image_url:response?.url,image_name:response.name}}
      })
    }

    const steps = [
      {
        title: "Basic Information",
        content: <Step1Form />,
        step: 1
      },
      {
        title: "Upload Image",
        content: <FileUpload/>,
        step: 2
      },
    ];
    return (
      <>
        <ContentHeader
          title={props.edit? 'Update Equipment': 'Add Equipment'}
          pageButtons={[
          <Button
            key='wrd-equipment-list'
            className={'create_btn'}
            onClick={()=>props.history.push('/wire_comm_equipments')}
            type="primary"
            size="small"
        >
          Equipment List
        </ Button>
          ]}
        ></ContentHeader>
        <div className={[classes.formmain, themeChange].join(' ')}>
          <Form form={stepForm} onFinish={onFinish} style={{ height: '90%' }} form={stepForm}>
            <StepPanel className={classes.step_panel} steps={steps} direction='vertical' />
          </Form>
        </div>
      </>
    );
  }
