import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space,Popconfirm } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ModalTitle } from '../../components/ModalTitle/ModalTitle';
import BaseList from "../../containers/BaseList/BaseList";
import * as http from '../../http';
import { Addpage } from './addAntennaPage';
import { columnMapper } from './columnMapper';
import { SearchFormMapper } from './SearchFormMapper';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

const copy = <FontAwesomeIcon icon={faCopy}/>

class AntennasListPage extends BaseList {
  constructor(props){
    super(props)
  }
  getColumns(){
    const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
    const deleteClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';

    return [
      ...columnMapper,
      {
        title: 'Action',
        key: 'action',
        fixed:'right',
        render: (record) => (
          <Space size="middle">
            <Button 
              key='editButton' 
              className={themeClass}
              icon={<EditOutlined />} 
              onClick={()=>this.showModal(`/api/antennas/${record.id}`)}
            />
            <Button 
              key='duplicateButton'
              className={themeClass}
              icon={copy}
              style={{marginLeft:"-15px"}}
              onClick={()=>this.showModal(`/api/antennas/${record.id}`, "duplicate")} 
            />
            <Popconfirm title="Are sure to Delete?" okText="Yes" onConfirm={() => this.handleDelete(record)}>
                <Button 
                  key='deleteButton'
                  type="default"
                  className={deleteClass}
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
          </Space>
        )
      }
    ];
  }

  getDataUrl(){
    return '/api/antennas/'; 
  }
  handleDelete(record){
    axios({
      url: `/api/antennas/${record.id}`,
      method: 'delete',
      type: 'json',
      }).then(res => {
        message.success("Deleted Successfully")
        this.fetch()
      
    }).catch((err) => {
      message.error('Delete Failed');
    });
  }

  getPageConfig(){
    return {
      pageTitle:'Antenna List',
      pageButtons: [
        <Button
          key='add_antenna'
          className={'create_btn'}
          onClick={()=>this.showModal()}
          type="primary"
          size="small" icon={<PlusOutlined />}
        >
          ADD NEW
        </ Button>
      ],
    }
  }

  getSearchFields(){
    return SearchFormMapper;
  }

  onAddSaveClick=(values)=>{
    console.log(values)
    http.post('/api/antennas', values.Antenna)
      .then((response)=> {
      if(response.status == 201){
        this.setState({
          isModalVisible: false
        })
        message.success('Antenna Created Successfully');
        this.fetch();
      }
    }).catch((err)=>{
      message.error('Antenna Creation Failed');
      this.errorCallback(err.response);
    })
}
  onUpdateClick=(values)=>{
    http.put(`/api/antennas/${values.Antenna.id}`, values.Antenna)
      .then((response)=> {
      if(response.status == 200){
        this.setState({
          isModalVisible: false,
        })
        message.success('Antenna Updated Successfully');
        this.fetch();
      }
    }).catch((err)=>{
      message.error('Antenna Update Failed');

      this.errorCallback(err.response)
    })
  }

  onFormFinish=(values)=>{
    if(this.state.duplicate){
      this.onAddSaveClick(values);
    }
    else if(this.state.editUrl){
      this.onUpdateClick(values);
    }
    else{
      this.onAddSaveClick(values);
    }
  }
  render(){
    const colorClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'customantmodal' : 'notfound';

    return (
      <>
      {super.render()}
      <Modal
      title={<ModalTitle title={!this.state.editUrl? 'Add Antenna' : 'Update Antenna'} />}
      visible={this.state.isModalVisible}
      onOk={this.handleOk}
      onCancel={this.handleCancel}
      destroyOnClose={true}
      footer={null}
      width={1000}
      // bodyStyle={{height:"1200px", background:'#14666b'}}
      centered={true}
      className={colorClass}
      >
        <Addpage 
            onFinish={this.onFormFinish.bind(this)}
            data={{antenna_length: 0, antenna_gain:0, vswr1:0,vswr2:0,WindRating:0,AntennaDiameter:0,Impedence:0,Efficiency:0,PowerRating:0}}
            editUrl={this.state.editUrl}
            errors = {this.state.errors}

        />
      </Modal>
      </>
    );

  }

}

const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps)(withRouter(AntennasListPage));
