import { Checkbox, Form } from 'antd';
import { useEffect, useRef } from 'react';
import { DateField } from './components/Fields/DateField';
import { InputBtnfield } from './components/Fields/InputBtnField';
import { InputField } from './components/Fields/InputField';
import { SelectField } from './components/Fields/SelectField';
import { TimeField } from './components/Fields/TimeField';

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);

  return isMounted;
}

export function timeConversionSlicker(s) {
  let AMPM = s.slice(-2);
  let timeArr = s.slice(0, -2).split(":");
  if (AMPM === "AM" && timeArr[0] === "12") {
      // catching edge-case of 12AM
      timeArr[0] = "00";
  } else if (AMPM === "PM") {
      // everything with PM can just be mod'd and added with 12 - the max will be 23
      timeArr[0] = (timeArr[0] % 12) + 12
  }  
  return timeArr.join(":");
}

export const buildFormItem=({name, label, type='input', fieldConfig={}, errorObj={}, formConfig={}, index=null}={})=>{
  const validateStatus = errorObj.hasOwnProperty(`${name}`) ? errorObj[name]?.validateStatus:null;
  const errorMsg = errorObj.hasOwnProperty(`${name}`) ? errorObj[name]?.errorMsg:null
  if(type == 'input'){
    return (
      <Form.Item
      label={<p className="formLabel">{label || name}</p>}
      labelCol={{span: 24}}
      name={name || label}
      validateStatus={validateStatus}
      help={errorMsg}
      {...formConfig}
      {...fieldConfig}
      >
          <InputField {...fieldConfig} />
      </Form.Item> 
    )
  }
  else if(type == 'checkbox'){
    return (<Form.Item
      label={<p className="formLabel">{label || name}</p>}
      labelCol={{span: 24}}
      name={name || label}
      validateStatus={validateStatus}
      help={errorMsg}
      {...formConfig}
      >
        <Checkbox {...fieldConfig} onChange={(e)=>fieldConfig.onChange(name, type, label, e.target.checked == true? 1: 0)}/>
      </Form.Item>
    )
  }
  else if(type == 'select'){
    return (<Form.Item
      label={<p className="formLabel">{label || name}</p>}
      labelCol={{span: 24}}
      name={name || label}
      validateStatus={validateStatus}
      help={errorMsg}
      {...formConfig}
      >
        <SelectField {...fieldConfig} onSelectChange={(value)=>fieldConfig.onSelectChange(name, type, label, value, index)}/> </Form.Item>
    )
  }
  else if(type == 'header-select'){
    return (<Form.Item
      // label={<p className="formLabel">{label || name}</p>}
      labelCol={{span: 24}}
      name={name || label}
      validateStatus={validateStatus}
      help={errorMsg}
      {...formConfig}
      >
        <SelectField {...fieldConfig} onSelectChange={(value)=>fieldConfig.onSelectChange(name, type, label, value, index)} /> </Form.Item>
    )
  }
  else if(type == 'input-button'){
    return (<Form.Item
      label={<p className="formLabel">{label || name}</p>}
      labelCol={{span: 24}}
      name={name || label}
      validateStatus={validateStatus}
      help={errorMsg}
      {...formConfig}
      >
        <InputBtnfield
        {...fieldConfig}
        onBeforeButtonClicked={()=>fieldConfig.onBeforeButtonClicked? fieldConfig.onBeforeButtonClicked(name, type, label, 'minus'): null}
        onAfterButtonClicked={()=>fieldConfig.onAfterButtonClicked? fieldConfig.onAfterButtonClicked(name, type, label, 'plus'): null}
        />
      </Form.Item>
    )
  }
  else if(type == 'checkbox-group'){
    return (
      <>
        <p  className={['formLabel', 'custom-form-level'].join(' ')}>{label || name}</p>
        <Checkbox.Group
          {...fieldConfig}
          onChange={(values)=>fieldConfig.onChange(name, type, label, values)}
          validateStatus={validateStatus}
          help={errorMsg}
          className='ant_checkbox_group_item'
        />
      </>
    )
  }
  else if(type == 'date'){
    return (
      <Form.Item
        label={<p className="formLabel">{label || name}</p>}
        labelCol={{span: 24}}
        name={name || label}
        validateStatus={validateStatus}
        help={errorMsg}
        {...formConfig}
      >
        <DateField {...fieldConfig} onChange={(value)=>fieldConfig.onChange(name, type, label, value)} />
      </Form.Item>
    )
  }
  else if(type == 'time'){
    return (
      <Form.Item
        label={<p className="formLabel">{label || name}</p>}
        labelCol={{span: 24}}
        name={name || label}
        validateStatus={validateStatus}
        help={errorMsg}
        {...formConfig}
      >
        <TimeField {...fieldConfig} onSelect={(value)=>fieldConfig.onChange(name, type, label, value)} />
      </Form.Item>
    )
  }
  return null;
}
