import { Button, Input } from 'antd';
import { useState } from 'react';
import classes from './InputField.module.css';

export const InputBtnfield=({...props})=>{
    const mode = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteInputBtnField : classes.InputBtnField;

    const [value, setValue] = useState();
    const beforeButtonClicked = props.onBeforeButtonClicked || undefined;
    if(props.hasOwnProperty('onBeforeButtonClicked')){
        delete props.onBeforeButtonClicked;
    }
    const afterButtonClicked = props.onAfterButtonClicked || undefined;
    if(props.hasOwnProperty('onAfterButtonClicked')){
        delete props.onAfterButtonClicked;
    }
    const onBeforeButtonClicked=()=>{
            if(beforeButtonClicked){
                beforeButtonClicked()
            }
    }
    const onAfterButtonClicked=(value)=>{
            if(afterButtonClicked){
                afterButtonClicked()
            }
    }
    return (
        <Input
        {...props}
        className={mode}
        addonBefore={<Button onClick={onBeforeButtonClicked}>-</Button>}
        addonAfter={<Button onClick={onAfterButtonClicked}>+</Button>} />
    )
}