import { Col, Form, Row } from 'antd';
import React from 'react';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../../../utils';
import classes from '../../DeviceConfig.module.css';

export const RJ45Config=(props)=>{
    
    let isMounted = useIsMounted();
    const [stepForm] = Form.useForm();
    const [connectorConfigData, setConfigData] = React.useState(
        {...props.config}
        )
    console.log(connectorConfigData)
    const colors={
        port_0:['#FFFFFF','#FFFFFF'],
        port_1:['#3120E0','#3120E0'],
        port_2:['#FF7F3F','#FF7F3F'],
        port_3:['#59CE8F','#59CE8F'],
        port_4:['#874C62','#874C62'],
        port_5:['#3120E0','#FFFFFF'],
        port_6:['#FF7F3F','#FFFFFF'],
        port_7:['#59CE8F','#FFFFFF'],
        port_8:['#874C62','#FFFFFF'],
        
        }

    const setPostData=(field, type, label, value)=>{
        let newData = {};
        if(type == 'select'){
            newData[field] = value.value;
            newData[`${field}_combo`] = value;
        }

        setConfigData((prevVal)=>{
            let updatedData ={...prevVal, ...newData}
            return updatedData
        });
        props.connectorConfigSet(newData)
    }

    // console.log("After")
    // console.log(connectorConfigData)
    // props.connectorConfigSet(newData)

    const SelectionRow=(props)=>{
        // console.log(props)
        return(
            <div >
                <Row gutter={[8,2]}>
                    <Col span={12}>
                        <BuildFormItem
                            name={`port${props.index}`}
                            label={`Port ${props.index}`}
                            type='select'
                            fieldConfig={{
                                url:`/api/combo/wrd_rj45_color_combo/`,
                                onSelectChange:setPostData,
                                value: connectorConfigData[`port${props.index}_combo`],
                                }}
                                formConfig={{
                                    required: true
                                }}
                        />
                    </Col>
                    <Col span={12}>
                        <Row style={{marginTop:'15%'}}>
                            <Col span={12} style={{background:`${props.color[0]}`, width:'100px',height:'25px'}}></Col>
                            <Col span={12} style={{background:`${props.color[1]}`, width:'100px',height:'25px'}}></Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
    return(
        <div className={classes.content}>
            <Row gutter={[16],[16]}>
                <Col span={24}>
                    <SelectionRow color = {colors[`port_${connectorConfigData.port1_combo != null? connectorConfigData.port1_combo.key:0}`]} index={1}/>
                    <SelectionRow color = {colors[`port_${connectorConfigData.port2_combo != null? connectorConfigData.port2_combo.key:0}`]} index={2}/>
                    <SelectionRow color = {colors[`port_${connectorConfigData.port3_combo != null? connectorConfigData.port3_combo.key:0}`]} index={3}/>
                    <SelectionRow color = {colors[`port_${connectorConfigData.port4_combo != null? connectorConfigData.port4_combo.key:0}`]} index={4}/>
                    <SelectionRow color = {colors[`port_${connectorConfigData.port5_combo != null? connectorConfigData.port5_combo.key:0}`]} index={5}/>
                    <SelectionRow color = {colors[`port_${connectorConfigData.port6_combo != null? connectorConfigData.port6_combo.key:0}`]} index={6}/>
                    <SelectionRow color = {colors[`port_${connectorConfigData.port7_combo != null? connectorConfigData.port7_combo.key:0}`]} index={7}/>
                    <SelectionRow color = {colors[`port_${connectorConfigData.port8_combo != null? connectorConfigData.port8_combo.key:0}`]} index={8}/> 
                </Col>
            </Row>
           
        </div>
    )
}