export const SearchFormMapper = [
    {
        label: '',
        name: 'full_name.like',
        type: 'input',
        rules: [],
        placeholder: 'Name',
    },
    {
        label: '',
        name: 'code',
        type: 'input',
        rules: [],
        placeholder: 'Code',
    }
]