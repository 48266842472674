import { Col, Form, Radio, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BaseAddPage from '../../../containers/BaseAddPage/BaseAddPage';
import { StepPanel } from '../../../containers/StepPanel/StepPanel';
import * as http from "../../../http";
import classes from '../DeviceConfig.module.css';


class HRRConfigPage extends BaseAddPage {
    constructor(props){
        super(props);
        // console.log("In Hybrid RR");
        // console.log(this.props);
    }

    

    getEditData=()=>{
        if(this.props?.configData?.configID){
            http.get(`/api/scenario/${this.props.configData.scenarioID}/${this.props.configData.configID}/get_config/`).then((data)=>{
                let select_data = {};
                if((data?.data?.ps_type || data?.data?.ps_type == 0) && data?.data?.ps_type_text){
                    select_data['ps_type_combo'] = {};
                    select_data.ps_type_combo.label = data.data.ps_type_text;
                    select_data.ps_type_combo.value = data.data.ps_type;
                    select_data['ps_type'] = data.data.ps_type;
                    delete data.data.ps_type;
                }
                if(data?.data?.ps_id && data?.data?.power_supply_text){
                    select_data['ps_id_combo'] = {};
                    select_data.ps_id_combo.label = data.data.power_supply_text;
                    select_data.ps_id_combo.value = data.data.ps_id;
                    select_data['ps_id'] = data.data.ps_id;
                    delete data.data.ps_id;
                }
                if(data?.data?.comm_with && data?.data?.comm_with_text){
                    select_data['comm_with_combo'] = {};
                    select_data.comm_with_combo.label = data.data.comm_with_text;
                    select_data.comm_with_combo.value = data.data.comm_with;
                    select_data['comm_with'] = data.data.comm_with;
                    delete data.data.comm_with;
                }
                if(data?.data?.modulation && data?.data?.modulation_text){
                    select_data['modulation_combo'] = {};
                    select_data.modulation_combo.label = data.data.modulation_text;
                    select_data.modulation_combo.value = data.data.modulation;
                    select_data['modulation'] = data.data.modulation;
                    delete data.data.modulation;
                }
                if(data?.data?.station_type && data?.data?.station_type_text){
                    select_data['station_type_combo'] = {};
                    select_data.station_type_combo.label = data.data.station_type_text;
                    select_data.station_type_combo.value = data.data.station_type;
                    select_data.station_type = data.data.station_type;
                    delete data.data.station_type;
                }
                if(data?.data?.antenna_config?.antenna_id && data?.data?.antenna_config?.antenna_name){
                    select_data['antenna_id_combo'] = {};
                    select_data.antenna_id_combo.label = data.data.antenna_config.antenna_name;
                    select_data.antenna_id_combo.value = data.data.antenna_config.antenna_id;
                    select_data.antenna_id = data.data.antenna_config.antenna_id;
                    delete data.data.antenna_config.antenna_id;
                    delete data.data.antenna_config.antenna_name;
                }

                if(data?.data?.antenna_config?.antenna_id2 && data?.data?.antenna_data2?.name){
                    console.log("Antenna 2 found");
                    select_data['antenna_id2_combo'] = {};
                    select_data.antenna_id2_combo.label = data.data.antenna_data2.name;
                    select_data.antenna_id2_combo.value = data.data.antenna_config.antenna_id2;
                    select_data.antenna_id2 = data.data.antenna_config.antenna_id2;
                    delete data.data.antenna_config.antenna_id2;
                    delete data.data.antenna_config.antenna_name2;
                }

                if(data?.data?.antenna_config?.polarization){
                    select_data['polarization_combo'] = {};
                    select_data.polarization_combo.label = data?.data.antenna_config.polarization === 1? 'Vertical' : 'Horizontal';
                    select_data.polarization_combo.value = data.data.antenna_config.polarization;
                    select_data.polarization = data.data.antenna_config.polarization;
                    delete data.data.antenna_config.polarization;
                }

                if(data?.data?.antenna_config?.polarization2){
                    select_data['polarization2_combo'] = {};
                    select_data.polarization2_combo.label = data?.data.antenna_config.polarization2 === 1? 'Vertical' : 'Horizontal';
                    select_data.polarization2_combo.value = data.data.antenna_config.polarization2;
                    select_data.polarization2 = data.data.antenna_config.polarization2;
                    delete data.data.antenna_config.polarization2;
                }

                if(data?.data?.equipment_id){
                    data.data['equipment_id_combo'] = {};
                    data.data.equipment_id_combo.label = `${data?.data?.equipment?.name}[${data?.data?.equipment?.model}]`;
                    data.data.equipment_id_combo.value = data.data.equipment_id
                }

                let antennaConfig = {};
                if(data.data?.antenna_config){
                    antennaConfig = data.data?.antenna_config;
                    delete data.data.antennaConfig;
                }
                _.forOwn(select_data, (value, key)=> {
                    let splitted_key = key.split('_');
                    let last_item = splitted_key.pop();
                    let actual_key = splitted_key.join("_");
                    if (last_item === 'combo') {
                        const val = {};
                        val[actual_key] = value;
                        this.formRef.current.setFieldsValue(val);
                        delete select_data[key];
                    }
                });
                this.formRef.current.setFieldsValue(data.data);
                this.formRef.current.setFieldsValue(antennaConfig);
                // console.log(data.data.config)
                this.setState({postData: {...select_data, ...data.data, ...antennaConfig, ...data.data.config}})
                // console.log(this.state.postData)
            }).catch((err)=>{
                console.log(err);
            })
        }
    };
    componentDidMount(){
        this.formRef.current.setFieldsValue({lat:this.props?.configData?.geometry?.coordinates[1].toFixed(6)});
        this.formRef.current.setFieldsValue({long: this.props?.configData?.geometry?.coordinates[0].toFixed(6)});
        const {postData} = this.state ;
        this.setState({postData: {...postData, 
            ...{lat: this.props?.configData?.geometry?.coordinates[1].toFixed(6),long: this.props?.configData?.geometry?.coordinates[0].toFixed(6)}}});
        this.getEditData()
    }

    onPowerChange=(e)=>{
        const newData={};
        const value = e.target.value;
        newData['power'] = value;
        let ps_id = this.state.postData.ps_id;
        if(!ps_id){
            alert('Select Power Source First');
            return
        }
        // const {equipmentData} = this.props.configData;
        // const device_power_output = equipmentData.power_output.split(',');
        console.log(newData)
        // Calculate Output Power for Low Voltage
        if(value == 2){
            // this.formRef.current.setFieldsValue({'power_output': device_power_output[0]});
            // newData['power_output'] = device_power_output[0];
        }

        // Calculate Output Power for Medium Voltage
        if(value == 3){
            // this.formRef.current.setFieldsValue({'power_output': device_power_output[1]});
            // newData['power_output'] = device_power_output[1];
        }

        // Calculate Output Power for High Voltage
        if(value == 4){
            // this.formRef.current.setFieldsValue({'power_output': device_power_output[2]});
            // newData['power_output'] = device_power_output[2];
        }
        const {postData} = this.state ;
        this.setState({postData: {...postData, ...newData}});
    };

    onFinish = (values) => {
        let postData = {...this.state.postData, ...{comm_equipment_type: this.props.configData?.comm_equipment_type,form_data: true,}};
        // console.log(postData)
        // console.log(this.props)
        window.frames[0].postMessage({
            data: postData,
            type: 'resolve',
            popupId: this.props.popupID
        })
    };

    InstallationForm=()=>{
        return (<div>
                <Row gutter={[16, 16]}>
                <Col span={8}>
                        {this.BuildFormItem(
                            {
                                name: 'equipment_id',
                                type: 'select',
                                label: 'Select Equipment',
                                errorObj: this.state.errors,
                                fieldConfig: {
                                    url:`/api/combo/get_allocated_rr_equipments/${this.props.configData.scenarioID}/2/`,
                                },
                                formConfig: {
                                    require: true,
                                },
                            }
                        )
                        }
                    </Col>
                    <Col span={8}>
                    {this.BuildFormItem(
                            {
                                name: 'lat',
                                label: 'Latitude',
                                type: 'input',
                                errorObj: this.state.errors,
                                formConfig: {
                                    required: true,
                                },
                            }
                        )
                    }
                    </Col>
                    <Col span={8}>
                        {this.BuildFormItem(
                                {
                                    name:'long',
                                    label:'Longitude',
                                    type: 'input',
                                    errorObj: this.state.errors,
                                    formConfig:{
                                        required: true,
                                    },
                                }
                            )
                        }
                    </Col>

                    <Col span={12}>
                        {this.BuildFormItem(
                            {
                                name: 'station_name',
                                type: 'input',
                                label: 'Station Name',
                                errorObj: this.state.errors
                            }
                        )
                        }
                    </Col>
                    <Col span={12}>
                        {this.BuildFormItem(
                            {
                                name: 'station_type',
                                type: 'select',
                                label: 'Station Type',
                                fieldConfig: {
                                    data: [{label: 'Terminal', value: 1}, {label: 'Relay', value: 2}, {label: 'CD Relay', value: 3}]
                                },
                                errorObj: this.state.errors
                            }
                        )
                        }
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={2}>
                        {this.BuildFormItem(
                            {    name:'mh1',
                                label:'MH1',
                                type:'checkbox',
                                fieldConfig:{
                                    style: {color: "white" },
                                    checked: this.state.postData.mh1,
                                },
                            }
                        )}
                    </Col>
                    <Col span={2}>
                        {this.BuildFormItem(
                            {   name:'mh2',
                                label:'MH2',
                                type:'checkbox',
                                fieldConfig:{
                                    style: {color: "white" },
                                    checked: this.state.postData.mh2,
                                },
                                formConfig: {
                                    hidden: this.state.postData.station_type == 1
                                }
                            }
                        )}
                    </Col>
                    <Col span={2}>
                        {this.BuildFormItem(
                            {    name:'cd',
                                label:'CD',
                                type:'checkbox',
                                fieldConfig:{
                                    style: {color: "white" },
                                    checked: this.state.postData.cd,
                                },
                                formConfig: {
                                    hidden: this.state.postData.station_type == 2
                                }
                            }
                        )}
                    </Col>
                    <Col span={5}>
                        {this.BuildFormItem(
                            {   
                                name:'breakout_box',
                                label:'Breakout Box',
                                type:'checkbox',
                                fieldConfig:{
                                    style: {color: "white" },
                                    checked: this.state?.postData?.breakout_box,
                                },
                                formConfig: {
                                    hidden: this.state.postData.station_type == 2
                                }
                            }
                        )}
                    </Col>
                    <Col span={5}>
                        {this.BuildFormItem(
                            {   
                                name:'data_connection',
                                label:'Data Connectivity',
                                type:'checkbox',
                                fieldConfig:{
                                    style: {color: "white" },
                                    checked: this.state?.postData?.data_connection,
                                },
                                formConfig: {
                                    hidden: this.state.postData.station_type == 2
                                }
                            }
                        )}
                    </Col>
                    
                    <Col span={6}>
                        {this.BuildFormItem(
                            {   name:'phone_connection',
                                label:'TnT/E1 Connectivity',
                                type:'checkbox',
                                fieldConfig:{
                                    style: {color: "white" },
                                    checked: this.state.postData.phone_connection,
                                },
                                formConfig: {
                                    hidden: this.state.postData.station_type == 2
                                }
                            }
                        )}
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        {this.BuildFormItem(
                            {
                                name: 'ps_type',
                                type: 'select',
                                label: 'Power System',
                                errorObj: this.state.errors,
                                fieldConfig: {
                                    data:[{label: 'AC', value:1}, {label: 'DC', value: 2}],
                                }
                            }
                        )
                        }
                    </Col>
                    <Col span={12}>
                        {this.BuildFormItem(
                            {
                                name: 'ps_id',
                                type: 'select',
                                label: 'Power Source',
                                errorObj: this.state.errors,
                                fieldConfig: {
                                    url:`/api/combo/equipments/power_source/?supply_type=${this.state.postData.ps_type}&equipment_id=${this.props?.configData?.equipmentData?.id}`,
                                }
                            }
                        )
                        }
                    </Col>
                </Row>
                <div style={{display: 'flex', justifyContent:'center', marginTop: '15px'}}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label={<p>Power</p>}
                                name='power'
                                validateStatus={this.state.errors.hasOwnProperty(`power`) ? this.state.errors['power']?.validateStatus:null}
                                help={this.state.errors.hasOwnProperty(`power`) ? this.state.errors[power]?.errorMsg:null}
                            >
                                <Radio.Group onChange={this.onPowerChange} value={this.state.postData.power} defaultValue={this.state.postData.power}>
                                    <Radio value={1}><p style={{color: 'red', fontWeight: 20}}>Off</p></Radio>
                                    {/* <Radio value={2}><p style={{color: 'yellow', fontWeight: 20}}>Low</p></Radio> */}
                                    {/* <Radio value={3}><p style={{color: 'orange', fontWeight: 20}}>Medium</p></Radio> */}
                                    <Radio value={4}><p style={{color: 'green', fontWeight: 20}}>ON</p></Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    };
    AntennaForm=()=>{
        return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={this.state.postData.station_type == 1? 24:12} >
                    <Col span={this.state.postData.station_type == 1? 0:24}>
                        <h5>Primary</h5>
                    </Col>
                    <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'antenna_id',
                                    type: 'select',
                                    label: 'Antenna',
                                    fieldConfig: {
                                        url:`/api/combo/antenna/?equipment_id=${this.props?.configData?.equipmentData?.id}`,
                                        value: this.state.antenna_id_combo,
                                    },
                                    errorObj: this.state.errors
                                }
                            )
                            }
                        </Col>
                        <Col span={24}>
                        {this.BuildFormItem(
                                {
                                    name: 'polarization',
                                    type: 'select',
                                    label: 'Polarization',
                                    errorObj: this.state.errors,
                                    fieldConfig: {
                                        data: [{label: 'Vertical', value: 1}, {label: 'Horizontal', value: 2}],
                                        value: this.state.polarization_combo,
                                    },
                                    
                                }
                            )
                            }
                        </Col>
                        <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'antenna_gain',
                                    type: 'input-number',
                                    label: 'Attenuation(-dB)',
                                    errorObj: this.state.errors
                                }
                            )
                            }
                        </Col>
                        <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'canopy_elevation',
                                    type: 'input-number',
                                    label: 'Antenna Height(m)',
                                    errorObj: this.state.errors
                                }
                            )
                            }
                        </Col>
                        <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'antenna_angle',
                                    type: 'input-number',
                                    label: 'Azimuth/Bearing(Degree)',
                                    errorObj: this.state.errors
                                }
                            )
                            }
                        </Col>
                        
                        <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'altitude_degree',
                                    type: 'input-number',
                                    label: 'Altitude/Elevation(Degree)',
                                    errorObj: this.state.errors
                                }
                            )
                            }
                        </Col>
                    </Col>
                    <Col span={this.state.postData.station_type != 1? 12:0}>
                        <Col span={24}>
                            <h5>Secondary</h5>
                        </Col>
                        
                        <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'antenna_id2',
                                    type: 'select',
                                    label: 'Antenna',
                                    fieldConfig: {
                                        url:`/api/combo/antenna/?equipment_id=${this.props?.configData?.equipmentData?.id}`,
                                        value: this.state.antenna_id2_combo,
                                    },
                                    errorObj: this.state.errors,
                                    formConfig: {
                                        hidden: this.state.postData.station_type == 1
                                    }
                                }
                            )
                            }
                        </Col>
                        <Col span={24}>
                        {this.BuildFormItem(
                                {
                                    name: 'polarization2',
                                    type: 'select',
                                    label: 'Polarization',
                                    errorObj: this.state.errors,
                                    fieldConfig: {
                                        data: [{label: 'Vertical', value: 1}, {label: 'Horizontal', value: 2}],
                                        value: this.state.polarization2_combo,
                                    },
                                    formConfig: {
                                        hidden: this.state.postData.station_type == 1
                                    }
                                }
                            )
                            }
                        </Col>
                        <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'antenna_gain2',
                                    type: 'input-number',
                                    label: 'Attenuation(-dB)',
                                    errorObj: this.state.errors,
                                    formConfig: {
                                        hidden: this.state.postData.station_type == 1
                                    }
                                }
                            )
                            }
                        </Col>
                        <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'canopy_elevation2',
                                    type: 'input-number',
                                    label: 'Antenna Height(m)',
                                    errorObj: this.state.errors,
                                    formConfig: {
                                        hidden: this.state.postData.station_type == 1
                                    }
                                }
                            )
                            }
                        </Col>
                        <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'antenna_angle2',
                                    type: 'input-number',
                                    label: 'Azimuth/Bearing(Degree)',
                                    errorObj: this.state.errors,
                                    formConfig: {
                                        hidden: this.state.postData.station_type == 1
                                    }
                                }
                            )
                            }
                        </Col>
                        <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'altitude_degree2',
                                    type: 'input-number',
                                    label: 'Altitude/Elevation(Degree)',
                                    errorObj: this.state.errors,
                                    formConfig: {
                                        hidden: this.state.postData.station_type == 1
                                    }
                                }
                            )
                            }
                        </Col>
                    </Col>
            </Row>
        </div>
        )
    };
    
    ConfigForm=()=>{
        return (<div>
                <Row gutter={[16, 16]}>
                <Col span={this.state.postData.station_type == 1? 24:12} >
                    <Col span={this.state.postData.station_type == 1? 0:24}>
                        <h5>Primary</h5>
                    </Col>

                    <Col span={24}>
                        {this.BuildFormItem(
                            {
                                name: 'device_id',
                                type: 'input',
                                label: 'Radio ID',
                                errorObj: this.state.errors
                            }
                        )
                        }
                    </Col>
                    <Col span={24}>
                        {this.BuildFormItem(
                            {
                                name: 'comm_with',
                                type: 'select',
                                label: 'Communicate With',
                                errorObj: this.state.errors,
                                fieldConfig: {
                                    url:`/api/combo/wl_rr_resource_combo/${this.props.configData.scenarioID}`,
                                }
                            }
                        )
                        }
                    </Col>

                    <Col span={24}>
                        {this.BuildFormItem(
                            {
                                name: 'tx_freq1',
                                type: 'input-number',
                                label: 'Tx Freq.(MHz)',
                                errorObj: this.state.errors,
                            }
                        )
                        }
                    </Col>

                    <Col span={24}>
                        {this.BuildFormItem(
                            {
                                name: 'rx_freq1',
                                type: 'input-number',
                                label: 'Rx Freq.(MHz)',
                                errorObj: this.state.errors,
                            }
                        )
                        }
                    </Col>

                </Col>

                <Col span={this.state.postData.station_type != 1? 12:0}>
                    <Col span={24}>
                        <h5>Secondary</h5>
                    </Col>

                    <Col span={24}>
                        {this.BuildFormItem(
                            {
                                name: 'device_id2',
                                type: 'input',
                                label: 'Radio2 ID',
                                errorObj: this.state.errors
                            }
                        )
                        }
                    </Col>

                    <Col span={24}>
                        {this.BuildFormItem(
                            {
                                name: 'comm_with2',
                                type: 'select',
                                label: 'Communicate With',
                                errorObj: this.state.errors,
                                fieldConfig: {
                                    url:`/api/combo/wl_rr_resource_combo/${this.props.configData.scenarioID}`,
                                }
                            }
                        )
                        }
                    </Col>

                    <Col span={24}>
                        {this.BuildFormItem(
                            {
                                name: 'tx_freq2',
                                type: 'input-number',
                                label: 'Radio2 Tx Freq.(MHz)',
                                errorObj: this.state.errors,
                                formConfig: {
                                    hidden: this.state.postData.station_type ==1
                                }
                                
                            }
                        )
                        }
                    </Col>
                    <Col span={24}>
                        {this.BuildFormItem(
                            {
                                name: 'rx_freq2',
                                type: 'input-number',
                                label: 'Rado2 Rx Freq.(MHz)',
                                errorObj: this.state.errors,
                                formConfig: {
                                    hidden: this.state.postData.station_type == 1
                                }
                            }
                        )
                        }
                    </Col>
                </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'power_output',
                                    type: 'input',
                                    label: 'power Consumption(W)',
                                    errorObj: this.state.errors
                                }
                            )
                            }
                        </Col>
                    </Col>
                    <Col span={12}>
                        <Col span={24}>
                            {this.BuildFormItem(
                                {
                                    name: 'modulation',
                                    type: 'select',
                                    label: 'Modulation',
                                    fieldConfig: {
                                        url:"/api/combo/modulation/",
                                    },
                                    errorObj: this.state.errors
                                }
                            )
                            }
                        </Col>
                    </Col>
                </Row>

            </div>
        )
    };

    buildSteps=()=>{
        let steps = [
            {
                title: "Installation",
                content: this.InstallationForm(),
                step: 1
            },
            {
                title: "Antenna",
                content: this.AntennaForm(),
                step: 2
            },
            {
                title: "Device Config",
                content: this.ConfigForm(),
                step: 3
            },
            
        ];
        return steps
    }

    

    renderBody() {
        if(this.props?.configData?.configID) {
            if (this.state.postData) {
                return (
                    <>
                        <div className={classes.formmain}>
                            <Form onFinish={this.onFinish} ref={this.formRef}>
                                <StepPanel
                                    className={classes.step_panel}
                                    steps={this.buildSteps()}
                                    direction='horizontal'
                                />
                            </Form>
                        </div>
                    </>
                );
            }
            else{
                return null;
            }
        }
        return (
            <>
                <div className={classes.formmain}>
                    <Form onFinish={this.onFinish} ref={this.formRef}>
                        <StepPanel
                            className={classes.step_panel}
                            steps={this.buildSteps()}
                            direction='horizontal'
                        />
                    </Form>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});
export default connect(mapStateToProps)(withRouter(HRRConfigPage));