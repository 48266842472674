import { Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../../../utils';

export const JoinProcessConfig=(props)=>{
    
    let isMounted = useIsMounted();
    const [stepForm] = Form.useForm();
    const [joinProcessConfigData, setConfigData] = React.useState(
        {...props.config}
        )
    const [joiningProcessRowsArray, setJoiningProcessRowsArray] = React.useState({all_rows:[]});

    useEffect(() => {
        setConfigData((prevVal)=>{
            let updatedData ={...prevVal, ...props.config}
            return updatedData
        });
        return () => { isMounted = true }; 
    }, [setConfigData, props.config])

    const setPostData=(field, type, label, value)=>{
        let newData = {};
        if(type == 'select'){
            newData[field] = value.value;
            newData[`${field}_combo`] = value;
        }

        setConfigData((prevVal)=>{
            let updatedData ={...prevVal, ...newData}
            return updatedData
        });
        // console.log(newData)
        props.processConfigSet(newData)
    }

    const SelectionRow=(props)=>{
        // console.log(props)
        return(
            <div >
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <BuildFormItem
                            name={`sequence_step${props.index}`}
                            label={`Process ${props.index}`}
                            key={`Process ${props.index}`}
                            type='select'
                            fieldConfig={{
                                url:`/api/combo/wrd_core_joining_process_combo/${props?.type}/`,
                                onSelectChange:setPostData,
                                value: joinProcessConfigData[`sequence_step${props.index}_combo`],
                                }}
                                formConfig={{
                                    required: true
                                }}
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                            name={`tools_step${props.index}`}
                            label={`Tools ${props.index}`}
                            key={`Tools ${props.index}`}
                            type='select'
                            fieldConfig={{
                                url:`/api/combo/wrd_core_joining_tools_combo/${props?.type}/`,
                                onSelectChange:setPostData,
                                value: joinProcessConfigData[`tools_step${props.index}_combo`],
                                }}
                                formConfig={{
                                    required: true
                                }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
    
    const CreatesJoiningProcessRows=(props)=>{
        //create individual rows
        console.log('Call .....')
        let newJoiningRowArray = []
        let count =0
        for(let i = 1; i<= props.count; i++){
            newJoiningRowArray.push({single_row:<SelectionRow index={i} type={props.type}/>})
            count++
        }
        let updateRows =[]
        // updateRows['all_rows'] = newJoiningRowArray
        // setJoiningProcessRowsArray(updateRows)
        return(
            <div>
            {
                newJoiningRowArray?.map((item)=>(
                    <div>
                        {item.single_row}
                    </div>
                    
                ))
            }
        </div>
        )
    }
    
    return(
        // <div>
        //     <SelectionRow  index={1} type = {props.type}/>
        //     <SelectionRow  index={2} type = {props.type}/>
        //     <SelectionRow  index={3} type = {props.type}/>
        //     <SelectionRow  index={4} type = {props.type}/>
        //     <SelectionRow  index={5} type = {props.type}/> 
        //     <SelectionRow  index={6} type = {props.type}/>
        //     <SelectionRow  index={7} type = {props.type}/>
        // </div>
        <div>
            <CreatesJoiningProcessRows type = {props.type} count={props.count}/>
        </div>
        
    )
}