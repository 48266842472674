import { Col, Image, message, Radio, Row, Space } from 'antd';
import React, { useEffect } from "react";
import * as http from '../../http';
import darkImage from './dark.png';
import classes from './UserTheme.module.css';
import whiteImage from './white.png';
function UserThemeMode() {
    // let newData = {};
    const theme = JSON.parse(localStorage.getItem("theme"));
    const clss = theme === "Dark" ? classes.whiteclss : classes.blackclss;
    
    const [checkId, setCheckId] = React.useState();
    const [themeData, setThemeData] =  React.useState({});

    const changeToDark = async () => {
        // var themes = JSON.parse(localStorage.getItem("theme"));
        // if(themes === "Dark")
        //     themes = "White";
        // else
        //     themes = "Dark";
        // var themes = "Dark";
        // var vlu = JSON.stringify("Dark");
        localStorage.setItem("theme", JSON.stringify("Dark"));
        let newData = {}
        newData['color']="Dark";
        // setThemeData((prevVal)=>{
        //     let newVal = {...themeData, ...newData}
        //     console.log('newVal: ',newVal)
        //     return newVal
        // })
        let newVal = {...themeData, ...newData}
        // console.log('Dark:',newVal)

        if(!checkId){
            pushData();
        }else{
            putData(newVal);
        }
    }

    const changeToWhite = async () => {
        // var themes = "White";
        // var vlu = JSON.stringify("White");
        localStorage.setItem("theme", JSON.stringify("White"));
        let newData = {}
        newData['color']="White";
        // setThemeData((prevVal)=>{
        //     let newVal = {...prevVal, ...newData}
        //     console.log('newVal: ',newVal)
        //     return newVal
        // })
        let newVal = {...themeData, ...newData}
        // console.log('White: ',newVal)

        if(!checkId){
            pushData();
        }else{
            putData(newVal);
        }
    }
    
    useEffect(() => {
        get_user_theme();
    }, [checkId]);

    const pushData = ()=>{
        let newData = {};
        // console.log("PUSH: ", themeData)
        http.post('/api/user_theme/', themeData).then((response) => {
        if (response.status == 201) {
            newData['id']=response?.data?.id;
            newData['color']=response?.data?.color;
            newData['user_id']=response?.data?.user_id;
            setThemeData((prevVal)=>{
                let newVal = {...prevVal, ...newData}
                return newVal
            })
            message.success('Mode change and save successfully');
            window.location.reload(true);
        }
        }).catch((err) => {
            message.error('Color Save Failed');
            console.log(err);
        });
    }

    const get_user_theme = () =>{
        let newData = {};
        http.get(`/api/user_theme/`).then((resp)=>{
            // console.log("response : ", resp.data);
            if(resp?.data?.user_id != null){
                newData['id']=resp?.data?.id;
                newData['color']=resp?.data?.color;
                newData['user_id']=resp?.data?.user_id;
                setThemeData(newData)
                setCheckId(true);
            }
            else{
                setCheckId(false);
            }
          }).catch((err)=>{
            console.log(err);
        })

        // console.log('themeData: ', themeData)
    }

    const putData = (newVal) => {
        let newData = {};
        // console.log("PUT: ", newVal)
        http.put(`/api/user_theme/`, newVal)
        .then((response) => {
        if (response.status == 200) {
            newData['id']=newData['id']=response?.data?.id;
            newData['color']=response?.data?.color;
            newData['user_id']=response?.data?.user_id;
            setThemeData((prevVal)=>{
                let newVal = {...prevVal, ...newData}
                return newVal
            })
            message.success('Mode change and save successfully');
            window.location.reload(true);
        }
        }).catch((err) => {
            message.error('Color Update Failed');
            console.log(err);
        });
    }

    return (

        <div className={classes.custom}>  
            <Space direction='horizontal' size={4} >
                <Row gutter={[48, 48]}>
                    <Radio.Group value={theme}>
                        <Space direction='vertical'>
                            <Col span={24}>
                                <Radio value={"White"} onChange={changeToWhite} style={{marginRight:"400px"}}><h2 className={clss}>Light Theme</h2></Radio>
                            </Col>

                            <Col span={24}>
                                <Image src={whiteImage} width={400} height={200} />
                            </Col>
                        </Space>

                        <Space direction='vertical'>
                            <Col span={24}>
                                <Radio value={"Dark"} onChange={changeToDark}><h2 className={clss}>Dark Theme</h2></Radio>
                            </Col>

                            <Col span={24}>
                                <Image src={darkImage} width={400} height={200} />
                            </Col>
                        </Space>
                    </Radio.Group>
                </Row>
            </Space>

        </div>
    );
}

export default UserThemeMode;
