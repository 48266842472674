import { Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import { StepPanel } from '../../../containers/StepPanel/StepPanel';
import * as http from '../../../http';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../../utils';
import classes from './AddTimeFrequency.module.css';

export const AddTimeFrequency = (props) => {
    console.log("In add: ",props?.data_id)
    const [time_frequency_data, setTimeFrequencyData] = React.useState({
        id: props?.data_id,
        year_combo:null,
        month_combo:null,
    });
    
    const [stepForm] = Form.useForm();
    const [errors, setErrorData] = React.useState({});
    // const[allSteps, setAllSteps] = React.useState({});
    const[steps, setSteps] = React.useState({});

    let isMounted = useIsMounted();
    
    useEffect(() => {
        if(time_frequency_data?.id){
        http.get(`/api/time_frequency/${time_frequency_data?.id? time_frequency_data.id:0}/`).then((data)=>{
            console.log("Incoming: ");
            console.log(data);
            let newData = {};
            _.forOwn(data?.data, (value, key)=>{
                if(!time_frequency_data.hasOwnProperty(key)){
                    const val = {};
                    val[key] = value;
                    stepForm.setFieldsValue(val);
                }
                else{
                    newData[key] = value;
                } 
            })
            
            if(isMounted.current){
                setTimeFrequencyData((prevVal)=>{
                return {...prevVal, ...newData}
                })
            }

        }).catch((err)=>{
            console.log(err);
        })
        }
        return () => { isMounted = true }; 
    }, [setTimeFrequencyData, props.configData])
    
    const setPostData=(field, type, label, value)=>{
        let newData = {};
        if(type == 'select'){
            if(value){
                newData[field] = value.value;
                newData[`${field}_combo`] = value;
            }
            else{
                newData[field] = null;
                newData[`${field}_combo`] = null;
            }
        }
        setTimeFrequencyData((prevVal)=>{
            let newValue={...prevVal, ...newData}
            return newValue
        })
        // console.log(newData);
    }

    
    const onFinish=(values)=>{
        const data = {...values, ...time_frequency_data};
        props.onFinish(data);
    }
    
    const DateForm=()=>{
        return (
            <div className={classes.content}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <BuildFormItem
                        name={`year`}
                        label='Year'
                        index={props.index}
                        type='select'
                        fieldConfig={{
                            url:`api/combo/year`,
                            onSelectChange:setPostData,
                            value: time_frequency_data.year_combo
                            }}
                            formConfig={{
                            required: true,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                        name={`month`}
                        label='Month'
                        index={props.index}
                        type='select'
                        fieldConfig={{
                            url:`api/combo/month`,
                            onSelectChange:setPostData,
                            value: time_frequency_data.month_combo
                            }}
                            formConfig={{
                            required: true,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    const FrequencyForm=()=>{
        return (
            <div className={classes.content}>
                <Row gutter={[16,16]}>
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_0'
                            label='Hour 0'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_0,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_1'
                            label='Hour 1'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_1,
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_2'
                            label='Hour 2'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_2,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_3'
                            label='Hour 3'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_3,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_4'
                            label='Hour 4'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_4,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_5'
                            label='Hour 5'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_5,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_6'
                            label='Hour 6'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_6,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_7'
                            label='Hour 7'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_7,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_8'
                            label='Hour 8'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_8,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_9'
                            label='Hour 9'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_9,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_10'
                            label='Hour 10'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_10,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_11'
                            label='Hour 11'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_11,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_12'
                            label='Hour 12'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_12,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_13'
                            label='Hour 13'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_13,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_14'
                            label='Hour 14'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_14,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_15'
                            label='Hour 15'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_15,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_16'
                            label='Hour 16'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_16,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_17'
                            label='Hour 17'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_17,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_18'
                            label='Hour 18'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_18,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_19'
                            label='Hour 19'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_19,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_20'
                            label='Hour 20'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_20,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_21'
                            label='Hour 21'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_21,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_22'
                            label='Hour 22'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_22,
                            }}
                        />
                    </Col> 
                    <Col span={12}>
                        <BuildFormItem
                            name='hour_23'
                            label='Hour 23'
                            type='input'
                            formConfig={{
                                value: time_frequency_data.hour_23,
                            }}
                        />
                    </Col> 

                </Row>
            </div>
        )
    }

   let step=[
        {
            title: "Select Month and Year",
            content: <DateForm />,
            step: 1
        },
        {
            title: "Set Frequency",
            content: <FrequencyForm />,
            step: 2
        }
    ]

    return (
        <div className={classes.formmain}>
            <Form form={stepForm} onFinish={onFinish}>
            <Row className={'header_row'}>
                <div className={'header_main'}>
                    <div className={'header_main_sub'}>
                        <h4>Time and Frequency Settings</h4>
                    </div>

                </div>
            </Row>
                <StepPanel

                    className={classes.step_panel}
                    steps={step}
                    direction='horizontal'
                    />
            </Form>
        </div>
    )

}