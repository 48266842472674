import { Button, Col, Form, message, Popconfirm, Row, Table } from 'antd';
import axios from 'axios';
import React, { useEffect } from 'react';
import '../../App.css';
import { SelectField } from '../../components/Fields/SelectField';
import { StepPanel } from '../../containers/StepPanel/StepPanel';
import classes from '../Courses/AddPage.module.css';

export const AssignScenario = (props) => {
    const [form] = Form.useForm();
    const initialDate = new Date().toISOString().split('T')[0];
    const [scenario, setScenario] = React.useState(null);
    const [perform_as, setPerformAs] = React.useState(null);
    const [course, setCourse] = React.useState(null);
    const [student, setStudent] = React.useState(null);
    const [stepForm] = Form.useForm();
    const [studentResourceKey, setStudentResourceKey] = React.useState(0);
    const [courseResourceKey, setCourseResourceKey] = React.useState(0);
    const [courseData, setCourseData] = React.useState({
        assign_course_scenario: []
    });
    const [studentData, setStudentData] = React.useState({
        assign_student_scenario: []
    });

    const handleScenario=(value)=>{
        setScenario(value)
    }

    const handlePerformAs=(value)=>{
        setPerformAs(value)
    }

    const handleCourse=(value)=>{
        setCourse(value)
    }
    const handleStudent=(value)=>{
        setStudent(value)
    }

    const handleCourseAdd=()=>{
        const course_id = course.value
        const scenario_id = scenario.value
        const scenario_name = scenario.value
        const perform_as_id = perform_as.value
        const course_name = course.label
        const perform_as_name = perform_as.label
        if(!course_id){
            message.error('course Needed')
        }
        else{
        let key = courseResourceKey;
        const data = {
            key:key,
            course_id : course.value,
            course_name : course.label,
            scenario_id : scenario.value,     
            scenario_name : scenario.label,
            perform_as_id: perform_as.value,
            perform_as_name: perform_as.label,
        }
        setCourseResourceKey((prevVal)=>{
            return prevVal+1;
        })

        setCourseData((prev_val)=>{
            const activeCourseStudetData = [...prev_val.assign_course_scenario, data];
            return {...prev_val, assign_course_scenario: activeCourseStudetData}
        })
      }
    }

    const handleStudentAdd=()=>{
        console.log("Add Click")
        const student_id = student.value
        const student_name = student.label

        const scenario_id = scenario.value
        const scenario_name = scenario.value

        const perform_as_id = perform_as.value
        const perform_as_name = perform_as.label
        
        if(!student_id){
            message.error('course Needed')
        }
        else{
        let key = studentResourceKey
        const data = {
            key: key,
            student_id : student.value,
            student_name : student.label,
            scenario_id : scenario.value,     
            scenario_name : scenario.label,
            perform_as_id: perform_as.value,
            perform_as_name: perform_as.label,
        }
        setStudentResourceKey((prevVal)=>{
            return prevVal+1;
        })

        setStudentData((prev_val)=>{
            const scenarioStudentData = [...prev_val.assign_student_scenario, data];
            return {...prev_val, assign_student_scenario: scenarioStudentData}
        })
      }
    }

    const [course_columns, setColumns] = React.useState(
        [
            {
                title: 'Course Id',
                dataIndex: 'course_id',
                editable: false,
                hidden: true
              },
            {
              title: 'Course Name',
              dataIndex: 'course_name',
              editable: false,
              width: 'auto'
            },
            {
                title: ' ',
                dataIndex: 'operation',
                render: (_, record) =>
                    <Popconfirm okType='default' title="Confirm Delete?" onConfirm={() => courseHandleDelete(record.key)}>
                      <a>Delete</a>
                    </Popconfirm>
              },
            
            
          ].filter(item=>!item.hidden)
    )
    const [student_columns, setStudentColumns] = React.useState(
        [
            {
                title: 'Student Id',
                dataIndex: 'student_id',
                editable: false,
                hidden: true
              },
            {
              title: 'Student Name',
              dataIndex: 'student_name',
              editable: false,
              width: 'auto'
            },
            {
                title: ' ',
                dataIndex: 'operation',
                render: (_, record) =>
                    <Popconfirm okType='default' title="Confirm Delete?" onConfirm={() => studentHandleDelete(record.key)}>
                      <a>Delete</a>
                    </Popconfirm>
              },
            
            
          ].filter(item=>!item.hidden)
    )
    const courseHandleDelete=(key)=>{
        setCourseData((prevVal)=>{
            const dataSource = [...prevVal.assign_course_scenario];
            const newcourses = dataSource.filter((item) => item.key !== key)
                return {...prevVal, assign_course_scenario: newcourses}
        });
    }

    const studentHandleDelete=(key)=>{
        setStudentData((prevVal)=>{
            const dataSource = [...prevVal.assign_student_scenario];
            const newcourses = dataSource.filter((item) => item.key !== key)
                return {...prevVal, assign_student_scenario: newcourses}
        });
    }

    useEffect(() => {
        if (props.editUrl) {
            axios({
                url: props.editUrl,
                method: 'get',
                type: 'json',
            }).then(data => {
                console.log((data.data))
                form.setFieldsValue({ 'id': data.data.id });
                form.setFieldsValue({ 'start': data.data.start });
                // form.setFieldsValue({ 'code': data.data.code });
            });
        }
    }, []);
    
    const onFinish=(values)=>{
        
        const PostData = {...values, ...perform_as?.value==2?courseData:studentData};
        PostData['id'] = values.id;
        PostData['perform_as'] = perform_as?.value;
        PostData['scenario_id'] = scenario?.value;
        props.onFinish(PostData);
    
    }
    const Step1Form = () =>{
        return(
            <div className={classes.content}>
                <Row className={'justify-content-space-evenly'}>
                    <Col span={10}>
                    <Form.Item
                        label={<p className={'formLabel'}>Scenario</p>}
                        labelCol={{span: 24}}
                        validateStatus={props.errors?.scenario_name?.validateStatus}
                        help={props.errors?.scenario_name?.errorMsg || null}
                        required={true}> 
                        <SelectField 
                        url="api/combo/scenarios"  
                        value={scenario} 
                        onSelectChange={handleScenario} /> 
                    </Form.Item>
                    </Col>

                    <Col span={10}>
                    <Form.Item
                        label={<p className={'formLabel'}>Perform as</p>}
                        labelCol={{span: 24}}
                        validateStatus={props.errors?.perform_as_name?.validateStatus}
                        help={props.errors?.perform_as_name?.errorMsg || null}
                        required={true}> 
                        <SelectField 
                        url="api/combo/perform_types"  
                        value={perform_as} 
                        onSelectChange={handlePerformAs} />  
                    </Form.Item>
                    </Col>
                </Row>
            </div>
        )
    }

    const Step2Form = () =>{
        return(
            
            <div className={classes.modalContent}>
                
                <Row gutter={16,16} className={'custom-row-1'}>
                    <Col>
                        <Form.Item
                            label={<p className={'formLabel'}>Course</p>}
                            labelCol={{span: 24}}
                            validateStatus={props.errors?.full_name?.validateStatus}
                            help={props.errors?.full_name?.errorMsg || null}
                            hidden={perform_as?.value==2? false:true}> 
                            <SelectField 
                            url="api/combo/active_courses" 
                            value={course} 
                            onSelectChange={handleCourse} /> 
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label={<p className={'formLabel'}>Student</p>}
                            labelCol={{span: 24}}
                            validateStatus={props.errors?.full_name?.validateStatus}
                            help={props.errors?.full_name?.errorMsg || null}
                            hidden={perform_as?.value==1 || perform_as?.value==3? false:true}> 
                            <SelectField 
                            url="api/combo/students"
                            value={student} 
                            onSelectChange={handleStudent} /> 
                        </Form.Item>
                    </Col>
                    <Col className={'justify-content-end'} style={{marginLeft:'5px'}}>
                        <Button onClick={perform_as?.value==2? handleCourseAdd:handleStudentAdd} type='primary'>Add</Button>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Table
                            dataSource={perform_as?.value==2?courseData?.assign_course_scenario:studentData?.assign_student_scenario}
                            columns={perform_as?.value==2?course_columns:student_columns}
                            pagination={null}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
    const steps = [
        {
            title: "Select Scenario",
            content: <Step1Form />,
            step: 1
        },
        {
            title: "Assign Course",
            content: <Step2Form />,
            step: 2
        }
    ]

    return (
        <div className={[classes.formmain, 'formmain'].join(' ')}>
        <Form className={'stepform'}  onFinish={onFinish} style={{height: '90%'}}>
            <StepPanel className={classes.step_panel} steps={steps} direction='horizontal' />
        </Form>
        </div>
    )
}