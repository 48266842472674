import { SettingOutlined } from '@ant-design/icons';
import {
  faChalkboardTeacher, faEdit, faIndustry, faList,
  faMapMarkerAlt, faSynagogue, faUser, faUserGraduate,
  faDoorOpen, faUsersCog, faTools, faToolbox, faMale, faRestroom,
  faBookOpen, faBook, faBroadcastTower, faBolt, faThLarge, faPlusSquare, faBorderAll, 
  faBars
  
}
from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'antd';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import classes from './siderMenu.module.css';


const icons ={
  user : <FontAwesomeIcon icon={faUser} />,
  students : <FontAwesomeIcon icon={faUserGraduate} />,
  permissions : <FontAwesomeIcon icon={faDoorOpen} />,
  trainers : <FontAwesomeIcon icon={faChalkboardTeacher} />,
  system : <FontAwesomeIcon icon={faList} />,
  map : <FontAwesomeIcon icon={faMapMarkerAlt} />,
  deviceMenufacturer : <FontAwesomeIcon icon={faIndustry} />,
  powerSource : <FontAwesomeIcon icon={faBolt} />,
  antennas : <FontAwesomeIcon icon={faBroadcastTower} />,
  equipments : <FontAwesomeIcon icon={faThLarge} />,
  addEquipment : <FontAwesomeIcon icon={faPlusSquare} />,
  wlOutline : <FontAwesomeIcon icon={faBorderAll} />,
  device : <FontAwesomeIcon icon={faToolbox} />,
  effect : <FontAwesomeIcon icon={faTools} />,
  individual : <FontAwesomeIcon icon={faMale} />,
  group : <FontAwesomeIcon icon={faRestroom} />,
  course : <FontAwesomeIcon icon={faBook} />,
  activeCourse : <FontAwesomeIcon icon={faBookOpen} />,
  training : <FontAwesomeIcon icon={faEdit} />,
  artficial : <FontAwesomeIcon icon={faUsersCog} />,
  equipAcc : <FontAwesomeIcon icon={faEdit} />,
  artificial : <FontAwesomeIcon icon={faUsersCog} />,
  natural : <FontAwesomeIcon icon="fa-solid fa-gear" />
}

const { SubMenu } = Menu;

// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub5', 'sub6'];

export const SiderMenu = (props) => {
  // const { menu } = props.user.data;

  const dynamicClassName = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'white_sider_menu' : 'sider_menu';
  const changeSubMenu = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteSubmenu : classes.subMenu;

  let  menu  = {};
  if(localStorage.menu){
    menu = JSON.parse(localStorage.menu)
  }
  else{
    menu = props.user?.data?.menu
  }
  const [openKeys, setOpenKeys] = React.useState([]);

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };


  const submenuStyle = {
    color: 'white',
    background: '#00111e'
  };

  const buildSubMenu = (menuItem) =>{
  
    let menu_list = [];
    for (let i = 0; i < menuItem?.length; i++) {
      if ('children' in menuItem[i]) {
        let submenu = <SubMenu title={menuItem[i].title} key={menuItem[i].key} icon={icons[menuItem[i].icon]} className={changeSubMenu}>
          {
            buildSubMenu(menuItem[i].children)
          }  
        </SubMenu>
        menu_list.push(submenu)
      }
      else{
        let submenu = <Menu.Item icon={icons[menuItem[i].icon]} className={changeSubMenu} key={menuItem[i].key}>
          <Link to={menuItem[i].url} >{menuItem[i].title}</Link>
        </Menu.Item>
         menu_list.push(submenu);
      }
    }
    return menu_list;
  }
  
  return (
    <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} className={[classes.sider_menu, dynamicClassName].join(' ')}>

      {buildSubMenu(menu)}

    </Menu>
  );
  
};
