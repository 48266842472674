// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddTimeFrequency_Form__ZGmuk {\n  padding: 15px 15px 15px 15px;\n}\n.AddTimeFrequency_formLabel__tp2F6 {\n  color: #4a6e83;\n}\n.AddTimeFrequency_step_panel__iecrK {\n  color: #087b95;\n}\n.AddTimeFrequency_formmain__Wr\\+Mg {\n  background: none;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 20px;\n}\n.AddTimeFrequency_numWidth__9abNs {\n  width: 200px;\n}\n.AddTimeFrequency_content__Fx-U- {\n   padding: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Settings/AddPages/AddTimeFrequency.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,YAAY;AACd;AACA;GACG,aAAa;AAChB","sourcesContent":[".Form {\n  padding: 15px 15px 15px 15px;\n}\n.formLabel {\n  color: #4a6e83;\n}\n.step_panel {\n  color: #087b95;\n}\n.formmain {\n  background: none;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 20px;\n}\n.numWidth {\n  width: 200px;\n}\n.content {\n   padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": "AddTimeFrequency_Form__ZGmuk",
	"formLabel": "AddTimeFrequency_formLabel__tp2F6",
	"step_panel": "AddTimeFrequency_step_panel__iecrK",
	"formmain": "AddTimeFrequency_formmain__Wr+Mg",
	"numWidth": "AddTimeFrequency_numWidth__9abNs",
	"content": "AddTimeFrequency_content__Fx-U-"
};
export default ___CSS_LOADER_EXPORT___;
