import { Button, Form, Row,Col } from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import '../../App.css';
import { InputField } from '../../components/Fields/InputField';
import { SelectField } from '../../components/Fields/SelectField';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../utils';
import * as http from '../../http'

export const AddPage = (props) =>{
    const [form] = Form.useForm();


    const setPostData=(field, type, label, value)=>{
        let newData = {};
        if(type == 'select'){
          if(value){
          newData[field] = value.value;
          newData[`${field}_combo`] = value;
          }
          else{
          newData[field] = null;
          newData[`${field}_combo`] = null;
          }
        }
        else if(type == 'input-button'){
          if(value == 'plus'){
          const newValue = parseInt(form.getFieldsValue()[field] || 0) + 1;
          const updatedValue = {};
          updatedValue[field] = newValue;
          form.setFieldsValue(updatedValue)
          }
          else{
          const newValue = parseInt(form.getFieldsValue()[field]|| 0) - 1;
          const updatedValue = {};
          updatedValue[field] = newValue;
          form.setFieldsValue(updatedValue)
          }
        }
        else if(type == 'checkbox')
        {
          newData[field] = value;
        }
  
        // setConfigData((prevVal)=>{
        //   return {...prevVal, ...newData}
        // })
    }
    
    useEffect (() =>{
        if (props.editUrl) {
            axios({
                url: props.editUrl,
                method: 'get',
                type: 'json',
            }).then(data => {
                form.setFieldsValue({ 'id': data.data.id });
                form.setFieldsValue({ 'max_communication_range': data.data.max_communication_range });
                form.setFieldsValue({ 'ground_wave_range': data.data.ground_wave_range });
                form.setFieldsValue({ 'skywave_range_max_antenna': data.data.skywave_range_max_antenna });
                form.setFieldsValue({ 'skywave_range_min_antenna': data.data.skywave_range_min_antenna });
                form.setFieldsValue({ 'skip_zone': data.data.skip_zone });
                // form.setFieldsValue({ 'rank': data.data.rank });
                form.setFieldsValue({ 'optimum_working_freq_per_owfp': data.data.optimum_working_freq_per_owfp });
            });
        }

    })
    const onFinish = (values) =>{
        console.log(values)
        return props.onFinish(values)
    }
    return(
        <Form style={{marginLeft:"110px"}} form={form} onFinish={onFinish} className="formBroken"> 
            <Row gutter={[16,16]}>
                <Col span={12}>
                    <Form.Item
                        name='id'
                        hidden>
                        <InputField />

                    </Form.Item>
                    
                    <BuildFormItem 
                        name = "max_communication_range"
                        label="Max Communication range"
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
               <Col span={12} pull={2}>
                    <BuildFormItem 
                        name = "ground_wave_range"
                        label="Ground Wave Range(km)"
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
               </Col>
                
            </Row>

            <Row gutter={[16,16]}>
                <Col span={12}> 
                    <BuildFormItem 
                        name = "skywave_range_max_antenna"
                        label="Sky Wave NVIS Antenna Max(km)"
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
                <Col span={12} pull={3}>
                    <BuildFormItem 
                        name = "skywave_range_min_antenna"
                        label="Sky Wave NVIS Antenna Min(km)"
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
            </Row>
            
            <Row gutter={[16, 16]}>
                <Col span={12}> 
                    <BuildFormItem 
                        name = "skip_zone"
                        label="Skip Zone(km)"
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
                <Col style={{marginLeft:"5px"}} span={12}>
                    <BuildFormItem 
                        name = "optimum_working_freq_per_owfp"
                        label="Optimum Working Frequency(%)"
                        type = "input-button"
                        fieldConfig={{
                            onBeforeButtonClicked: setPostData,
                            onAfterButtonClicked: setPostData,
                        }}
                    />
                </Col>
            </Row>
            
           
             
            {/* <BuildFormItem 
                name = "range_diff"
                label="Range Diff"
                type = "input-button"
                fieldConfig={{
                    onBeforeButtonClicked: setPostData,
                    onAfterButtonClicked: setPostData,
                }}
            /> */}
            <div className={'button_row'}>
            <Button type="primary" htmlType="submit">
                    {props.editUrl? 'Update':'Add'}
            </Button>
            </div>
        </Form>
    )

}