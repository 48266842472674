import { Button, Checkbox, Col, Form, Row } from 'antd';
import React, { useEffect } from 'react';
import '../../App.css';
import { InputField } from '../../components/Fields/InputField';
import * as http from '../../http';

export const WlAddPage = (props) => {
    const [form] = Form.useForm();
    const [formPostData, setFormPostData] = React.useState({
        name: '',
        code: '',
        comm_freq: 0,
        transmission_media: 0,
        tx_freq_max: 0,
        tx_freq_min:0,
        rx_freq_max: 0,
        rx_freq_min: 0,
        ps_type: 0,
        power_source: 0,
        modulation: 0,
        commsec: 0,
        encryption: 0,
        antenna_type: 0,
        bandwidth: 0,
        no_of_channels: 0,
        voice_encoding: 0,
        tent_coverage: 0,
        power_output: 0,
        power_output_mpack: 0,
        receving_sensitivity: 0,
        antenna_impedance: 0,
        internal_input_voltage:0,
        external_input_voltage: 0,
        tx_power_consumption:0,
        rx_power_consumption:0,
        sb_power_consumption: 0,
        frequency_hop_rate: 0,
        hopping: 0,
        gps: 0,
    });

    useEffect(() => {
        if (props.editUrl) {
            http.get(`${props.editUrl}`, {
                method: 'get',
                type: 'json',
            }).then(data => {
                setFormPostData({...formPostData, ...data.data})
                form.setFieldsValue({'name': data.data.name});
                form.setFieldsValue({'code': data.data.code});
                
            });
        }
    }, []);
  
  const updateFormPostData=(field, value)=>{
    const postData = {}
    postData[field] = value;
    setFormPostData((prevVal)=>{
      return {...prevVal, ...postData}
    })
  }
    
    const onFinish = (values) => {
        const formData = form.getFieldsValue();
        console.log({...formPostData, ...formData});
        let postData = {'WlEquipOutLines': {}}
        postData['WlEquipOutLines']= {...formPostData, ...formData};
        console.log(postData)
        return props.onFinish(postData);

    } 
    

    return (
        <div>
            <Form style={{ color: "white", display: "flex" }} onFinish={onFinish} form={form}>
                    <Col span={24}>
                    <Row >
                    <Col span={12}>
                    <Row>
                        <Form.Item 
                        name="name"
                        label="Name"
                        > 
                        <InputField disabled={true} style={{background:"#02384B", color:"white"}}/>
                        </Form.Item>
                    </Row>

                    <Row>
                    <Checkbox name='tx_freq_max' className={'formLabel'}
                     checked={formPostData.tx_freq_max}
                     onChange={
                         (e)=>{
                         e.target.checked?updateFormPostData('tx_freq_max', 1):updateFormPostData('tx_freq_max', 0)
                     }
                     }
                    > Tx Max Frequency (MHz)</Checkbox>

                    </Row><br />
                    <Row>
                    <Checkbox name='tx_freq_min' className={'formLabel'} 
                     checked={formPostData.tx_freq_min}
                     onChange={
                         (e)=>{
                         e.target.checked?updateFormPostData('tx_freq_min', 1):updateFormPostData('tx_freq_min', 0)
                     }
                     }> Tx Min Frequency (MHz)</Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='rx_freq_max' className={'formLabel'} 
                     checked={formPostData.rx_freq_max}
                     onChange={
                         (e)=>{
                         e.target.checked?updateFormPostData('rx_freq_max', 1):updateFormPostData('rx_freq_max', 0)
                     }
                     }> Rx Max Frequency (MHz)</Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='rx_freq_min' className={'formLabel'}  checked={formPostData.rx_freq_min}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('rx_freq_min', 1):updateFormPostData('rx_freq_min', 0)
                        }
                        }
                    > Rx Min Frequency (MHz)</Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='ps_type' className={'formLabel'}  checked={formPostData.ps_type}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('ps_type', 1):updateFormPostData('ps_type', 0)
                        }
                        }> Power Supply Type </Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='power_source' className={'formLabel'}  checked={formPostData.power_source}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('power_source', 1):updateFormPostData('power_source', 0)
                        }
                        }> Power Source (Watt)</Checkbox>
                    </Row><br />
                    
                    <Row>
                    <Checkbox name='modulation' className={'formLabel'} checked={formPostData.modulation}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('modulation', 1):updateFormPostData('modulation', 0)
                        }
                        }> Modulation </Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='commsec' className={'formLabel'}  checked={formPostData.commsec}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('commsec', 1):updateFormPostData('commsec', 0)
                        }
                        }> Comm Sec </Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='encryption' className={'formLabel'}  checked={formPostData.encryption}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('encryption', 1):updateFormPostData('encryption', 0)
                        }
                        }> Encryption </Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='antenna_type' className={'formLabel'}  checked={formPostData.antenna_type}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('antenna_type', 1):updateFormPostData('antenna_type', 0)
                        }
                        }> Antenna Type </Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='bandwidth' className={'formLabel'} checked={formPostData.bandwidth}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('bandwidth', 1):updateFormPostData('bandwidth', 0)
                        }
                        }> Band Width (MHz)</Checkbox>
                    </Row> <br />
                    <Row>
                    <Checkbox name='hopping' className={'formLabel'} checked={formPostData.hopping}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('hopping', 1):updateFormPostData('hopping', 0)
                        }
                        }> Hopping</Checkbox>
                    </Row> <br />
                    <Row>
                    <Checkbox name='gps' className={'formLabel'} checked={formPostData.gps}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('gps', 1):updateFormPostData('gps', 0)
                        }
                        }>GPS</Checkbox>
                    </Row>

                    
                    </Col>

                <Col span={12}>
                    <Row>
                        <Form.Item label="Code" name='code'>
                            <InputField disabled={true} style={{background:"#02384B", color:"white"}}/>
                        </Form.Item>
                    </Row>
                    <Row>
                    <Checkbox name='receving_sensitivity' className={'formLabel'} checked={formPostData.receving_sensitivity}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('receving_sensitivity', 1):updateFormPostData('receving_sensitivity', 0)
                        }
                        }> Receving Sensitivity</Checkbox>
                    </Row> <br />
                    <Row>
                    <Checkbox name='antenna_impedance' className={'formLabel'} checked={formPostData.antenna_impedance}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('antenna_impedance', 1):updateFormPostData('antenna_impedance', 0)
                        }
                        }> Antenna Impedace (Ohms)</Checkbox>
                    </Row> <br />
                    <Row>
                    <Checkbox name='internal_input_voltage' className={'formLabel'} checked={formPostData.internal_input_voltage}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('internal_input_voltage', 1):updateFormPostData('internal_input_voltage', 0)
                        }
                        }> Internal Input Voltage (V)</Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='external_input_voltage' className={'formLabel'} checked={formPostData.external_input_voltage}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('external_input_voltage', 1):updateFormPostData('external_input_voltage', 0)
                        }
                        }> External Input Voltage (V)</Checkbox>
                    </Row> <br />
                    <Row>
                    <Checkbox name='tx_power_consumption' className={'formLabel'} checked={formPostData.tx_power_consumption}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('tx_power_consumption', 1):updateFormPostData('tx_power_consumption', 0)
                        }
                        }> Tx Current Consumption (Ah)</Checkbox>
                    </Row> <br />
                    <Row>
                    <Checkbox name='rx_power_consumption' className={'formLabel'} checked={formPostData.rx_power_consumption}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('rx_power_consumption', 1):updateFormPostData('rx_power_consumption', 0)
                        }
                        }> Rx Current Consumption (Ah)</Checkbox>
                    </Row> <br />
                    <Row>
                    <Checkbox name='sb_power_consumption' className={'formLabel'} checked={formPostData.sb_power_consumption}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('sb_power_consumption', 1):updateFormPostData('sb_power_consumption', 0)
                        }
                        }> Stand by Current Consumption (Ah)</Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='no_of_channels' className={'formLabel'} checked={formPostData.no_of_channels}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('no_of_channels', 1):updateFormPostData('no_of_channels', 0)
                        }
                        }> Channels</Checkbox>
                    </Row> <br />
                    <Row>
                    <Checkbox name='voice_encoding' className={'formLabel'} checked={formPostData.voice_encoding}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('voice_encoding', 1):updateFormPostData('voice_encoding', 0)
                        }
                        }> Voice Encoding</Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='tent_coverage' className={'formLabel'} checked={formPostData.tent_coverage}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('tent_coverage', 1):updateFormPostData('tent_coverage', 0)
                        }
                        }> Tent Coverage</Checkbox>
                    </Row><br />
                    <Row>
                    <Checkbox name='power_output' className={'formLabel'} checked={formPostData.power_output}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('power_output', 1):updateFormPostData('power_output', 0)
                        }
                        }> Base Station/Terminal/Really Power Output</Checkbox>
                    </Row> <br />

                    <Row>
                    <Checkbox name='power_output_mpack' className={'formLabel'} checked={formPostData.power_output_mpack}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('power_output_mpack', 1):updateFormPostData('power_output_mpack', 0)
                        }
                        }> Manpack Power Output</Checkbox>
                    </Row> <br />
                    <Row>
                    <Checkbox name='frequency_hop_rate' className={'formLabel'} checked={formPostData.frequency_hop_rate}
                        onChange={
                            (e)=>{
                            e.target.checked?updateFormPostData('frequency_hop_rate', 1):updateFormPostData('frequency_hop_rate', 0)
                        }
                        }>Frequency Hope Rate (Hope/Sec)</Checkbox>
                    </Row>
                   
                   
                </Col>

                    </Row>
                <Row style={{display:'flex', justifyContent:'center'}}>

                <Button type="primary" htmlType="submit">
                        {props.editUrl ? 'Update' : 'Add'}
                    </Button>
                </Row>
                    </Col>
            </Form>
        </div>
    )
}
{/* <div></div> */}