// import "antd/dist/antd.css";
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.min.css';
import React from 'react';
import App from "./App";


function Extra() {
    const theme = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'light' : 'dark';
    const currentTheme = theme;

    const lightTheme = {
        colorPrimary: "green",
        colorSuccessBg: "green",
    }

    const darkTheme = {
        colorPrimary: "white",
        colorSuccessBg: "black",
    }

    return (
        <ConfigProvider theme={{
            token: currentTheme === 'light' ? lightTheme : darkTheme,
        }}>
            <App />
        </ConfigProvider>
    );
}

export default Extra;
