import { Button, Col, Form, message, Row } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import ContentHeader from '../../../containers/Layout/ContentHeader/ContentHeader';
import { StepPanel } from "../../../containers/StepPanel/StepPanel";
import * as http from '../../../http';
import { buildFormItem as BuildFormItem, useIsMounted } from '../../../utils';
import classes from './AddPage.module.css';


  export const WrdCableAdd = (props) => {
    const themeChange = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitethm' : 'notfound' ;
    const [formPostData, setFormPostData] = React.useState({
     
    });
    const [stepForm] = Form.useForm();
    const [errors, setErrors] = React.useState({});
    const [outlineData, setOutlineData] = React.useState({});

    let isMounted = useIsMounted();
    let dataMounted = useIsMounted();
    const { id } = useParams();

    const getEquipmentData=()=>{
      http.get(`/api/wire_cables/${id}`).then((resp)=>{
        console.log(resp.data)
          _.forOwn(resp.data, (value, key)=>{
            if(!formPostData.hasOwnProperty(key)){
              const val = {};
              val[key] = value;
              stepForm.setFieldsValue(val);
              delete resp.data[key];
            }
          })
          if(dataMounted.current){
            setFormPostData((prevVal)=>{
              return {...prevVal, ...resp.data}
            })
          }
      }).catch((err)=>{
        console.log(err);
      })
    }

    useEffect(()=>{
      if(props.edit || props.duplicate){
        getEquipmentData();
      }
      
    return () => { dataMounted = false }; 
    }, [])


    const setPostData=(field, type, label, value)=>{
      let newData = {};
       let genericType = ['input', 'checkbox', 'date', 'time', 'checkbox-group'];
      if(type == 'select'){
        if(value){
        if(Array.isArray(value)){
          let valueList = [];
          for(let i=0; i<value.length; i++){
            valueList.push(value[i].value);
          }
          newData[field] = valueList;
          newData[`${field}_combo`] = value;
        }
        else{
          newData[field] = value.value;
          newData[`${field}_combo`] = value;
        }
        }
        else{
        newData[field] = null;
        newData[`${field}_combo`] = null;
        }
      }
      else if(type == 'input-button'){
        if(value == 'plus'){
        const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
        const updatedValue = {};
        updatedValue[field] = newValue;
        stepForm.setFieldsValue(updatedValue)
        }
        else{
        const newValue = parseInt(stepForm.getFieldsValue()[field]|| 0) - 1;
        const updatedValue = {};
        updatedValue[field] = newValue;
        stepForm.setFieldsValue(updatedValue)
        }
      }
      else if (genericType.includes(type)) {
            newData[field] = value;
        }

      setFormPostData((prevVal)=>{
        return {...prevVal, ...newData}
      })
  }
  
    const Step1Form = () => {
      return (
        <div className={[classes.Form,'test_form'].join(' ')}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <BuildFormItem
                name='name'
                label='Name'
                type='input'
                errorObj={errors}
              />
            </Col>
            <Col span={12}>
              <BuildFormItem
                name='connector_id'
                label='Connector'
                type='select'
                fieldConfig={{
                  value:formPostData.connector_id_combo,
                  url:"/api/combo/connectors/",
                  onSelectChange:setPostData
                }}
                errorObj={errors}
              />
            </Col >
          </Row>
        </div>
      );
    };
    
    const errorCallback=(error)=>{
      if(error?.status == 422){
        const errorData = {};
        for(var i=0; i<error.data.detail.length; i++){
            let field = error.data.detail[i].loc[1];
            let errorMessage = error.data.detail[i].msg;
            errorData[field] = {errorMsg: errorMessage, validateStatus:'error'}
        }
        setErrors(errorData)
      }
    }
    
    const onFinish = (fieldsValue) => {
      const formData = stepForm.getFieldsValue();
      let postData = {...fieldsValue, ...formPostData}
      if(props.edit){
        http.put(`/api/wire_cables/${id}`, postData).then((response) => {
          if (response.status == 200) {
            setErrors({})
            message.success('Cable Updated');
            stepForm.resetFields();
            props.history.push('/wire_cables')

          }
        }).catch((err) => {
          message.error('Cable Creation Failed');
          errorCallback(err.response);
        })
    }
      else{
        http.post('/api/wire_cables/', postData).then((response) => {
          if (response.status == 201) {
            setErrors({})
            message.success('Cable Added');
            stepForm.resetFields();
            props.history.push('/wire_cables')

          }
        }).catch((err) => {
          message.error('Cable/Line Creation Failed');
          // this.errorCallback(err.response);
        })
    }
    };

    const steps = [
      {
        title: "Basic Information",
        content: <Step1Form />,
        step: 1
      },
    ];
    return (
      <>
        <ContentHeader
          title={props.edit? 'Update Cable': 'Add Cable'}
          pageButtons={[
          <Button
            key='cable-list'
            className={'create_btn'}
            onClick={()=>props.history.push('/wire_cables')}
            type="primary"
            size="small"
        >
          Equipment List
        </ Button>
          ]}
        ></ContentHeader>
        <div className={[classes.formmain, themeChange].join(' ')}>
          <Form form={stepForm} onFinish={onFinish} style={{ height: '90%' }}>
            <StepPanel className={classes.step_panel} steps={steps} direction='vertical' />
          </Form>
        </div>
      </>
    );
  }
