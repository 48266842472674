import { Button, Form, Row } from 'antd';
import { useEffect } from 'react';
import '../../App.css';
import { InputField } from '../../components/Fields/InputField';
import * as http from '../../http';
import classes from './AddPage.module.css';

export const AddPage = (props) => {
    const [form] = Form.useForm();
    // const [errors, setError]= React.useState({})
    useEffect(() => {
        if (props.editUrl) {
            http.get(`${props.editUrl}`, {
                method: 'get',
                type: 'json',
            }).then(data => {
                console.log(data)
                form.setFieldsValue({ 'id': data.data.id });
                form.setFieldsValue({ 'name': data.data.name });
                form.setFieldsValue({ 'code': data.data.code });
            });
        }
    }, []);
    const onFinish=(values)=>{
        const PostData = {Course: {}};
        PostData['Course']['id'] = values.id;
        PostData['Course']['name'] = values.name;
        PostData['Course']['code'] = values.code;
        return props.onFinish(PostData);
    }
    console.log(props.errors);
    return (
        <Form style={{ color: "white" }} onFinish={onFinish} form={form}>
            <div className={classes.modalContent}>
            <Row>
            <Form.Item 
            name='id'
            label={<p className={'formLabel'}>ID</p>}
            labelCol={{span: 24}}
            hidden>
            <InputField />
            </Form.Item>
            <Form.Item
            name='name'
            label={<p className={'formLabel'}>Name</p>}
            labelCol={{span: 24}}
            validateStatus={props.errors?.name?.validateStatus}
            help={props.errors?.name?.errorMsg || null}
            required={true}
            >
                <InputField />
            </Form.Item>
            </Row>

            <Row>
            <Form.Item
            name='code'
            label={<p className={'formLabel'}>Course Code</p>}
            labelCol={{span: 24}}
            validateStatus={props.errors?.code?.validateStatus}
            help={props.errors?.code?.errorMsg || null}
            required={true}
            >
                <InputField />
            </Form.Item>
            </Row>
        
            </div>
            <div  className={[classes.footer, 'button_row'].join(' ')}>
            <Button type="primary" htmlType="submit">
                    {props.editUrl? 'Update':'Add'}
            </Button>
            </div>
        </Form>
    )
}