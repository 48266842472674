export const columnMapper = [
    {
        title: 'ID',
        dataIndex: 'id',
        sorter: true,
        width: '2%',
        hidden: true
      },
      {
        title: 'Transmission Media',
        dataIndex: 'transmission_media_text',
        width: 'auto',
      },
      {
        title: 'Frequency',
        dataIndex: 'frequency_text',
        width: 'auto',
      },
      {
        title: 'Ground Wave Range(km)',
        dataIndex: 'ground_wave_range',
        width: 'auto',
      },
      {
        title: 'Max Range(km)',
        dataIndex: 'max_communication_range',
        width: 'auto',
      },
      
      {
        title: 'Sky Wave NVIS Antenna Max(km)',
        dataIndex: 'skywave_range_max_antenna',
        width: 'auto',
      },
      {
        title: 'Sky Wave NVIS Antenna Min(km)',
        dataIndex: 'skywave_range_min_antenna',
        width: 'auto',
      },
      
      {
        title: 'Optimum Working Frequency(%)',
        dataIndex: 'optimum_working_freq_per_owfp',
        width: 'auto',
      },
      {
        title: 'Skip Zone(km)',
        dataIndex: 'skip_zone',
        width: 'auto',
      }
    ].filter(item => !item.hidden);