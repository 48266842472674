import React from 'react';
import * as http from '../../http';

export const ScenarioMapView=(props)=>{

    const [scenarioID, setScenarioID] = React.useState(0);
    // console.log(props.refresh)
    function fetchSelectList(url){
        http.get(url).then((data)=>{
            // console.log(data)
            setScenarioID( data?.data.length > 0? data?.data[0]['value']: 0 )
        })
    }

    let api_base_utl = process.env.REACT_APP_GATEWAY
    if(props.scenarioID && props.studentID){
        // console.log("With Both",props.scenarioID, props.groupMashFlag, props.refresh_flag)
        props.onDefaultScenarioSelection(props.scenarioID)
    return (
        <iframe src={`/map.html?refresh=${props.refresh_flag}&group_mash_flag=${props.groupMashFlag}&scenario_id=${props.scenarioID}&student_id=${props.studentID}&api_base_utl=${api_base_utl}`} width="100%" height="900px" name='map_frame' />
    )
    }
    else if(props.scenarioID){
        // console.log("With Scenario ID",props.scenarioID, props.groupMashFlag, props.refresh_flag)
        props.onDefaultScenarioSelection(props.scenarioID)
    return (
        <iframe src={`/map.html?refresh=${props.refresh_flag}&group_mash_flag=${props.groupMashFlag}&scenario_id=${props.scenarioID}&api_base_utl=${api_base_utl}`} width="100%" height="900px" name='map_frame' />
    )
    }
    else{
        fetchSelectList("/api/scenario/last_scenarios/")
        // console.log("Without Both",props.scenarioID, props.groupMashFlag, props.refresh_flag)
        props.onDefaultScenarioSelection(scenarioID)
        return(
            <iframe src={`/map.html?refresh=${props.refresh_flag}&group_mash_flag=${props.groupMashFlag}&scenario_id=${scenarioID}&api_base_utl=${api_base_utl}`} width="100%" height="900px" name='map_frame' />
        )
    }

    
}