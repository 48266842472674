import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import classes from './DateField.module.css';

export const formattedDateForDatePicker=(d)=>{
    const format = 'YYYY-MM-DD';
    return moment(d, format);
    }
export const DateField=(props)=>{
    const dateFormat = props.dateFormat;
    const initialValue = props.initialValue || new Date().toISOString().split('T')[0];
    const defaultValue = formattedDateForDatePicker(initialValue);
    const [value, setValue] = React.useState(defaultValue);

    const onDateChange = (value)=>{
        setValue(value);
        if(props.hasOwnProperty('onChange')){
            props.onChange(value?.format().split('T')[0])
        }
    }
    return (
        <DatePicker value={value}  format={dateFormat} className={classes.DateField} onChange={(value)=>onDateChange(value)} />
    )
}


