import { Button, Col, Form, Row } from 'antd';
import React from 'react';
import { buildFormItem as BuildFormItem } from '../../utils';
import classes from './AddPage.module.css';

export const UserAddPage = (props) => {
    const [partyId, setPartyId] = React.useState(null);
    const [form] = Form.useForm();
    const [ConfigData, setConfigData] = React.useState({
        user_type: null,
        user_type_combo: null,
        party_id: null,
        party_id_combo: null,
        roles: null,
        roles_combo: []
      });

    const onFinish=(values)=>{
        console.log(ConfigData)
        let PostData = {...values, ...ConfigData}

        return props.onFinish(PostData);
    }
  
    const setPostData=(field, type, label, value)=>{
        let newData = {};
        if(type == 'select'){
          if(value){
          if(Array.isArray(value)){
            let valueList = [];
            for(let i=0; i<value.length; i++){
              valueList.push(value[i].value);
            }
            newData[field] = valueList;
            newData[`${field}_combo`] = value;
          }
          else{
            newData[field] = value.value;
            newData[`${field}_combo`] = value;
          }
          }
          else{
          newData[field] = null;
          newData[`${field}_combo`] = null;
          }
        }
        else if(type == 'input-button'){
          if(value == 'plus'){
          const newValue = parseInt(stepForm.getFieldsValue()[field] || 0) + 1;
          const updatedValue = {};
          updatedValue[field] = newValue;
          stepForm.setFieldsValue(updatedValue)
          }
          else{
          const newValue = parseInt(stepForm.getFieldsValue()[field]|| 0) - 1;
          const updatedValue = {};
          updatedValue[field] = newValue;
          stepForm.setFieldsValue(updatedValue)
          }
        }
        else if(type == 'checkbox')
        {
          newData[field] = value;
        }
  
        setConfigData((prevVal)=>{
          return {...prevVal, ...newData}
        })
    }
    return (
        <Form style={{ color: "white" }} onFinish={onFinish} form={form}>
            <div className={classes.modalContent}>
            <Row>
                <BuildFormItem
                    name='id'
                    type='input'
                    formConfig={{
                        hidden : true
                    }}

                />

                <BuildFormItem 
                    name='email'
                    label='Email'
                    type='input'
                    fieldConfig={{
                        type: "email"
                    }}
                    formConfig={{
                        required: true
                    }}
        
                />
                
            </Row>
            <Row>

            <Col>
                <BuildFormItem
                    name='user_type'
                    label='User Type'
                    type='select'
                    fieldConfig={{
                        url:"/api/combo/user_type",
                        onSelectChange: setPostData,
                        value: ConfigData.user_type_combo
                    }}
                    formConfig={{
                        required: true
                    }}
                />
            </Col>
            
            <Col push={1}>
                <BuildFormItem
                    name='party_id'
                    label={ConfigData.user_type_combo?.label}
                    type='select'
                    fieldConfig={{
                        url: `/api/combo/parties/?type=${ConfigData.user_type}&name=`,
                        onSelectChange: setPostData,
                        value: ConfigData.party_id_combo
                    }}
                    formConfig={{
                        required: true,
                        hidden: ConfigData.user_type == null || ConfigData.user_type == 0
                    }}
                />
            </Col>
            <Col push={2}>
                <BuildFormItem
                    name = 'roles'
                    label = "Select Role"
                    type = "select"
                    fieldConfig={{
                        value: ConfigData.roles_combo,
                        url: `/api/combo/user_roles/?user_type=${ConfigData.user_type}`,
                        onSelectChange: setPostData,
                        mode:'multiple'
                    }}
                    formConfig={{
                        hidden: ConfigData.user_type == null || ConfigData.user_type == 2
                    }}
                />
            </Col>
            </Row>
            <Row>
                <BuildFormItem
                        name='password'
                        label='Password'
                        type='input'
                        fieldConfig={{
                            type: "password"
                        }}
                        formConfig={{
                            required: true
                        }}
                />
            </Row>
            </div>
            <div className={[classes.footer , 'button_row'].join(' ')}>
            <Button type="primary" htmlType="submit">
                    {props.editUrl? 'Update':'Add'}
            </Button>
            </div>
        </Form>
    )
}