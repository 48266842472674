import { DoubleLeftOutlined } from '@ant-design/icons';
import { faCloudMoon, faCloudSunRain, faCogs, faPlus, faRainbow, faSortAmountDown, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, message, Row, Tabs } from 'antd';
import 'antd/dist/antd.min.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { InputField } from '../../components/Fields/InputField';
import * as http from '../../http';
import classes from './Settings.module.css';


const fatools = <FontAwesomeIcon icon={faTools} />;
const dayTimeIcon = <FontAwesomeIcon icon={faCloudMoon} />
const ionosphereIcon = <FontAwesomeIcon icon={faRainbow} />
const seasonIcon = <FontAwesomeIcon icon={faCloudSunRain} />
const performanceAttIcon = <FontAwesomeIcon icon={faSortAmountDown} />
const fontplusicon = <FontAwesomeIcon icon={faPlus} />
const faCogsicon = <FontAwesomeIcon icon={faCogs} />

const { TabPane } = Tabs;

export const GeneralSettings = (props) =>{
    const ThemeChangeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitetabpane_tab : classes.tabpane_tab;
    const TextChangeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitelink_txt' : 'link_txt';
    const TitleChangeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteinput_title : classes.input_title;
    const modeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitesetting' : 'notfound';
    const ButtonClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteupdate_btn : classes.update_btn;

    const [data, setData] = useState([]);
    const [form] = Form.useForm();
    const [reload, setReload] = useState(false);
    const [id,setId] =useState()

    useEffect(() => {
        http.get(`api/general_settings`).then((data)=>{
            setId(data.data.rows[0].id)
            form.setFieldsValue({ 'title': data.data.rows[0].title });
            form.setFieldsValue({ 'sub_title': data.data.rows[0].sub_title });
          }).catch((err)=>{ 
            console.log(err);
          })
    }, []);
    const onFinish = (values) => {
    
      axios.put(`/api/general_settings/${id}`, values).then((response) => {
        if (response.status == 201) {
          message.success('General Settings Updated')

        }
      }).catch((err) => {
        message.error('Update Failed');
      })
      window.location.reload()

    };
    return (
        <>
            <TabPane tab={<><span>{faCogsicon}</span><p>General Settings</p></>} key="1" icon={<DoubleLeftOutlined />}
                className={[classes.content, modeClass].join(' ')}>
                <div className={ThemeChangeClass} style={{height:"400px"}}>
                    <Link to="/menu_builder" className={TextChangeClass}>
                        Customize Your Menu
                    </Link>
                <Form style={{ color: "white" }} form={form} onFinish={onFinish}>

                    
                        <br /> <br /> <br /> <br />
                        <Row >
                            <Col span={6} push={8}>
                                <p className={TitleChangeClass}>Title</p>
                                <Form.Item name="id" hidden><InputField /></Form.Item>

                            <Form.Item 
                                name="title"
                            >
                                
                                <InputField placeholder="Title"/>   
                            </Form.Item>
                            </Col>
                            
                        </Row> <br />
                        <Row>
                            <Col span={6} push={8}>
                            <p className={TitleChangeClass}  >Sub Title</p>
                                <Form.Item  
                                    name="sub_title"
                                    
                                >
                                    <InputField placeholder="Sub Title"/>
                                </Form.Item>
                            </Col>

                        </Row> <br />

                        {/* This button was hide because it has no activity */}
                        <Row>
                            <Col push={8}>
                                <Button htmlType='submit' className={ButtonClass}>Update</Button>
                            </Col>
                        </Row>

        </Form>
                </div>
            </TabPane>
        </>
    )
}