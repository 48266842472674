// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TrainingHeader_leftContent__lAK0k {\n\n}\n.TrainingHeader_rightContent__fYH7c {\n\n}\n\n.TrainingHeader_searchBox__d2\\+z6 {\n  border-bottom: 1px solid #062A3F;\n}\n\n.TrainingHeader_whitesearchBox__G7Jhx {\n  border-bottom: 1px solid #DCF1E3;\n}\n\n.TrainingHeader_fakecls__A9Y2R {\n}\n\n.TrainingHeader_extrafakecls__rYBAV{\n}\n\n@media only screen and (max-width: 1209px) {\n  .TrainingHeader_fakecls__A9Y2R {\n    display: none;\n  }\n}\n\n@media only screen and (max-width: 1000px){\n  .TrainingHeader_extrafakecls__rYBAV{\n    display: none;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Training/TrainingHeader.module.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;;AAEA;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;AACA;;AAEA;AACA;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".leftContent {\n\n}\n.rightContent {\n\n}\n\n.searchBox {\n  border-bottom: 1px solid #062A3F;\n}\n\n.whitesearchBox {\n  border-bottom: 1px solid #DCF1E3;\n}\n\n.fakecls {\n}\n\n.extrafakecls{\n}\n\n@media only screen and (max-width: 1209px) {\n  .fakecls {\n    display: none;\n  }\n}\n\n@media only screen and (max-width: 1000px){\n  .extrafakecls{\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftContent": "TrainingHeader_leftContent__lAK0k",
	"rightContent": "TrainingHeader_rightContent__fYH7c",
	"searchBox": "TrainingHeader_searchBox__d2+z6",
	"whitesearchBox": "TrainingHeader_whitesearchBox__G7Jhx",
	"fakecls": "TrainingHeader_fakecls__A9Y2R",
	"extrafakecls": "TrainingHeader_extrafakecls__rYBAV"
};
export default ___CSS_LOADER_EXPORT___;
